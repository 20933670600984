/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { classNames as r } from "@progress/kendo-react-common";
class o extends e.Component {
  /**
   * @return
   * Returns a `<td />` element with the [`value`]({% slug api_dateinputs_calendarweekcellprops %}#toc-value) as a child.
   */
  render() {
    const { className: s, firstDate: l, ...t } = this.props;
    return /* @__PURE__ */ e.createElement("td", { className: r("k-calendar-td", s), ...t }, this.props.children);
  }
}
export {
  o as CalendarWeekCell
};
