/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const v = 11111111111111e-7, ie = 0.31111111111111117, ae = 1, Q = 1, Y = 0, V = /\d/, w = 15, he = () => ({
  eventValue: null,
  prevLooseValue: "",
  currentLooseValue: "",
  focused: !1,
  selectionStart: void 0,
  selectionEnd: void 0,
  decimalSelect: !1,
  valueIsCorrected: !1,
  valueIsOutOfRange: !1
}), Ie = (t, n) => t !== void 0 ? t : n, p = (t, n, r) => t === null && t === void 0 ? "" : typeof t == "string" ? t : r.formatNumber(t, n), H = (t) => (String(t).split(".")[1] || "").length, ee = (t, n) => Math.max(H(t), H(n)), de = (t) => Math.min(t, w), ne = (t, n) => {
  const r = de(n);
  return parseFloat(t.toFixed(r));
}, Ne = (t, n, r, e, u, s, a) => {
  const o = ee(t || 0, r || 0), c = te(ne((t || 0) + (r || 0), o), e, u);
  n.eventValue = c, n.currentLooseValue = p(c, s, a), n.selectionStart = n.selectionEnd = R(n.currentLooseValue, V);
}, Ee = (t, n, r, e, u, s, a) => {
  const o = ee(t || 0, r || 0), c = te(ne((t || 0) - (r || 0), o), e, u);
  n.eventValue = c, n.currentLooseValue = p(c, s, a), n.selectionStart = n.selectionEnd = R(n.currentLooseValue, V);
}, te = (t, n, r) => t == null ? t : !(t > 1 || t < 1 || t === 1) || r !== void 0 && n !== void 0 && r < n ? null : (r !== void 0 && t > r && (t = r), n !== void 0 && t < n && (t = n), t), ge = (t, n) => {
  const r = n.findIndex(([u, s]) => !!s && t.indexOf(s) === t.length - s.length);
  if (r === -1)
    return -1;
  const e = n[r][1];
  return t.length - e.length;
}, fe = (t, n) => {
  const r = n.findIndex(([u, s]) => !!u && t.indexOf(u) === 0);
  return r === -1 ? -1 : n[r][0].length;
}, Ve = (t, n, r) => {
  const e = ge(t, n);
  if (e !== -1 && r.selectionStart > e) {
    r.selectionStart = r.selectionEnd = e;
    return;
  }
  r.selectionStart > t.length && (r.selectionStart = r.selectionEnd = t.length);
  const u = fe(t, n);
  u !== -1 && r.selectionStart < u && (r.selectionStart = r.selectionEnd = u), r.selectionStart === -1 && (r.selectionStart = r.selectionEnd = 0);
}, L = (t, n, r, e) => {
  t.selectionStart = t.selectionEnd = n, Ve(r, e, t);
}, A = (t, n, r, e) => {
  t.eventValue = e.parseNumber(t.prevLooseValue, n), t.currentLooseValue = t.prevLooseValue, t.valueIsCorrected = !0, L(t, t.selectionStart, t.currentLooseValue, r);
}, me = (t, n) => {
  const r = String(t.currentLooseValue), e = String(t.prevLooseValue);
  return r.split(n.minusSign).length !== e.split(n.minusSign).length && r.length === e.length + n.minusSign.length;
}, pe = (t, n) => {
  const r = String(t.currentLooseValue), e = String(t.prevLooseValue);
  return r.indexOf(n.minusSign) === -1 && e.indexOf(n.minusSign) !== -1;
}, xe = (t, n) => String(t.currentLooseValue).split(n.decimal).length > 2, Le = (t, n) => {
  const r = n.formatNumber(v, t), e = n.formatNumber(-v, t), u = n.formatNumber(Y, t), s = n.formatNumber(Q, t), a = P(r), o = P(e), c = P(u), i = P(s), N = M(r), E = M(e), x = M(u), b = M(s);
  return {
    positiveInfo: [a, N],
    negativeInfo: [o, E],
    zeroInfo: [c, x],
    oneInfo: [i, b]
  };
}, be = (t, n) => {
  const r = n.formatNumber(v, t), e = n.formatNumber(-v, t), u = n.formatNumber(Y, t), s = n.formatNumber(Q, t), a = n.numberSymbols(), o = new RegExp(`[\\d\\${a.decimal}${a.group}]`, "g");
  return [r, e, u, s].map((i) => i.replace(o, "")).join("").split("").filter((i, N, E) => E.indexOf(i) === N).join("");
}, _ = (t, n) => {
  const r = t.indexOf(n.decimal);
  return r > -1 ? r : R(t, V);
}, B = (t) => t.split("").reverse().join(""), R = (t, n) => t.length - B(t).search(n), P = (t) => t.split(t[t.search(V)])[0], M = (t) => {
  const n = B(t);
  return B(n.split(n[n.search(V)])[0]);
}, T = (t, n) => t.search(n), J = (t, n) => {
  const r = t.indexOf(n);
  return r > -1 ? t.length - r - 1 : 0;
}, K = (t, n, r, e, u) => {
  const s = t.replace(u, "")[0] === "0", a = n.replace(u, "")[0] === "0";
  if (s && !a)
    return r - 1;
  if (a && e)
    return r + 1;
  let o = 0;
  for (let i = 0; i < r; i++)
    V.test(t.charAt(i)) && o++;
  let c = 0;
  for (; o > 0 && n.length > c; )
    V.test(n.charAt(c)) && o--, c++;
  return c;
}, Oe = (t, n, r) => {
  const e = { ...t }, { prevLooseValue: u } = e, s = r.numberSymbols(), a = be(n, r), o = String(e.currentLooseValue), c = String(u), i = new RegExp(`[^\\d\\${s.decimal}]`, "g"), N = new RegExp(`[^\\d\\${s.decimal}\\${s.group}]`, "g"), E = new RegExp(`[\\d\\${s.decimal}\\${s.group}]`), x = o.replace(i, ""), b = T(o, V), O = b === -1 ? -1 : R(o, V), re = o.indexOf(s.decimal), $ = (o.substring(0, b) + o.substring(b, O).replace(N, "") + o.substring(O, o.length)).split("").filter((m) => a.indexOf(m) !== -1 || m.search(E) !== -1).join(""), G = r.formatNumber(ie, n).replace(i, ""), U = G.indexOf(s.decimal), I = U > -1 ? G.length - U - 1 : 0, j = r.formatNumber(ae, n).replace(i, ""), S = j.indexOf(s.decimal), D = S > -1 ? j.length - S - 1 : 0, {
    positiveInfo: oe,
    negativeInfo: se,
    zeroInfo: ue,
    oneInfo: le
  } = Le(n, r), g = [oe, se, ue, le], ce = g.findIndex((m) => m.findIndex((h) => !!h) !== -1) !== 1, C = o.length > 0 && o.length < c.length, X = typeof n == "string" && n[0] === "p" && o && o.indexOf(s.percentSign) === -1;
  if (!e.isPaste) {
    if (o === "")
      return e.eventValue = null, e.currentLooseValue = "", e;
    if (e.currentLooseValue === s.minusSign && r.formatNumber(-0, n) !== c)
      return e.eventValue = -0, e.currentLooseValue = p(e.eventValue, n, r), L(
        e,
        _(e.currentLooseValue, s),
        e.currentLooseValue,
        g
      ), e;
    if (e.currentLooseValue === s.decimal) {
      e.eventValue = 0;
      const f = p(e.eventValue, n, r);
      if (D === 0 && I > 0) {
        const l = R(f, V);
        e.currentLooseValue = f.substring(0, l) + s.decimal + f.substring(l);
      } else
        e.currentLooseValue = f;
      return L(
        e,
        _(e.currentLooseValue, s) + 1,
        e.currentLooseValue,
        g
      ), e;
    }
    if (me(e, s)) {
      const f = r.parseNumber(u, n);
      e.eventValue = -(f !== null ? f : 0), e.currentLooseValue = p(e.eventValue, n, r);
      const l = T(e.currentLooseValue, V), d = T(c, V);
      return L(
        e,
        e.selectionEnd - 1 + (l - d),
        e.currentLooseValue,
        g
      ), e;
    }
    if (pe(e, s))
      return e.eventValue = r.parseNumber(e.currentLooseValue, n), L(
        e,
        e.selectionStart,
        e.currentLooseValue,
        g
      ), e;
    if (xe(e, s))
      return A(e, n, g, r), e;
    if (X)
      return e.eventValue = r.parseNumber(o, n) / 100, e.currentLooseValue = p(e.eventValue, n, r), e;
    if (String(e.currentLooseValue).replace(/[^\d]/g, "").length > w || x !== o && o && ce && g.findIndex(([l, d]) => {
      const F = o.indexOf(l), z = o.indexOf(d), Z = F === 0, W = z === o.length - d.length, k = F + l.length !== b && b !== -1 && o[F + l.length] !== s.decimal, q = z !== O && O !== -1 && o[z - 1] !== s.decimal;
      return l && d ? k || q ? !1 : Z && W : l ? k ? !1 : Z : d ? q ? !1 : W : !1;
    }) === -1)
      return A(e, n, g, r), e;
    if (x[x.length - 1] === s.decimal && I > 0)
      return e.eventValue = r.parseNumber(o, n), e.currentLooseValue = $, e;
    if (e.currentLooseValue && u && (a + s.decimal + s.group).split("").findIndex((l) => o.split("").filter((d) => d === l).length < c.split("").filter((d) => d === l).length && o.length + 1 === c.length ? !(l === s.decimal && J(c.replace(i, ""), s.decimal) === 0) : !1) > -1)
      return e.eventValue = r.parseNumber(t.prevLooseValue, n), e.currentLooseValue = t.prevLooseValue, e;
    const h = J(x, s.decimal), y = x[x.length - 1] === "0";
    if (C && y && h < D)
      return e.eventValue = r.parseNumber(e.currentLooseValue, n), e.currentLooseValue = p(e.eventValue, n, r), e;
    if (h > 0) {
      const f = o.substring(0, re);
      if (y && (!f || c.indexOf(f) !== 0)) {
        e.eventValue = r.parseNumber(e.currentLooseValue, n);
        const l = p(e.eventValue, n, r);
        return L(
          e,
          K(o, l, e.selectionEnd, C, i),
          l,
          g
        ), e.currentLooseValue = l, e;
      }
      if (h > I) {
        const l = o.indexOf(s.decimal), d = o.substring(0, l) + o.substring(l, l + 1 + I) + o.substring(O, String(e.currentLooseValue).length);
        return e.eventValue = r.parseNumber(d, n), e.currentLooseValue = d, L(e, e.selectionStart, d, g), e;
      }
      if (D !== I && h <= I && y)
        return e.eventValue = r.parseNumber(e.currentLooseValue, n), e.currentLooseValue = $, e;
      if (h < D)
        return e.eventValue = r.parseNumber(e.currentLooseValue, n), e.currentLooseValue = p(e.eventValue, n, r), e;
    }
  }
  if (e.eventValue = r.parseNumber(e.currentLooseValue, n), X && (e.eventValue = e.eventValue / 100), typeof e.eventValue == "number") {
    const m = p(e.eventValue, n, r);
    o.length === 1 ? L(e, _(m, s), m, g) : L(
      e,
      K(o, m, e.selectionEnd, C, i),
      m,
      g
    ), e.currentLooseValue = m;
  } else
    e.currentLooseValue = p(r.parseNumber(x), n, r);
  return e;
};
export {
  K as changeBasedSelection,
  Ee as decreaseValue,
  p as formatValue,
  H as fractionLength,
  J as getDecimalCount,
  T as getFirstNumberIndex,
  Le as getFormatPrefixSufix,
  be as getFormatSymbols,
  _ as getInitialPosition,
  he as getInitialState,
  R as getLastNumberIndex,
  ge as getMaxCursorPosition,
  fe as getMinCursorPosition,
  P as getPrefix,
  Ie as getStateOrPropsValue,
  M as getSuffix,
  Ne as increaseValue,
  xe as isDecimalDuplicated,
  me as isMinusSymbolAdded,
  pe as isMinusSymbolRemoved,
  de as limitPrecision,
  Ve as rangeSelection,
  te as rangeValue,
  B as reverseString,
  Oe as sanitizeNumber,
  A as setInvalid,
  L as setSelection,
  ne as toFixedPrecision
};
