import ShapeElement from "../../core/shape-element";
import { deepExtend, setDefaultOptions } from '../../common';
import { CIRCLE, LEFT, TOP, WHITE, X, Y } from "../../common/constants";
import { DEFAULT_MARKER_BORDER_WIDTH, DEFAULT_MARKER_SIZE } from "./legend-item-marker";

var LegendItemSquare = (function (ShapeElement) {
    function LegendItemSquare () {
        ShapeElement.apply(this, arguments);
    }

    if ( ShapeElement ) LegendItemSquare.__proto__ = ShapeElement;
    LegendItemSquare.prototype = Object.create( ShapeElement && ShapeElement.prototype );
    LegendItemSquare.prototype.constructor = LegendItemSquare;

    LegendItemSquare.prototype.createHighlight = function createHighlight () {
        var options = deepExtend({}, {
            background: this.options.background,
        }, this.options.highlight.markers);
        var highlight = new ShapeElement(options);

        var box = this.paddingBox.clone();
        var targetBox = this.parent.box.clone();
        box.align(targetBox, X, LEFT);
        box.align(targetBox, Y, TOP);
        highlight.reflow(box);

        this.highlight = [ highlight.getElement() ];

        return this.highlight;
    };

    return LegendItemSquare;
}(ShapeElement));

setDefaultOptions(LegendItemSquare, {
    highlight: {
        markers: {
            type: CIRCLE,
            width: DEFAULT_MARKER_SIZE,
            height: DEFAULT_MARKER_SIZE,
            border: {
                width: DEFAULT_MARKER_BORDER_WIDTH,
                color: WHITE
            },
            margin: {
                top: -3,
                left: -3
            }
        }
    }
});

export default LegendItemSquare;
