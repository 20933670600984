/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { guid as o } from "../guid.mjs";
import { useIsomorphicLayoutEffect as i } from "./use-isomorphic-layout-effect.mjs";
function I(t) {
  const [u, n] = e.useState(!1);
  e.useEffect(() => {
    n(!0);
  }, []);
  const [r, c] = e.useState(() => {
    if (t)
      return t;
    if (u)
      return o();
  });
  i(() => {
    r === void 0 && c(o());
  }, []);
  const s = e.useId;
  if (s !== void 0) {
    const f = s();
    return t != null ? t : f;
  }
  return r;
}
export {
  I as useId
};
