/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { ClipboardActionType as t } from "./common.mjs";
class n {
  constructor(r) {
    this.copyHandler = (e) => {
      this.clipboardEvent(t.copy, e);
    }, this.cutHandler = (e) => {
      this.clipboardEvent(t.cut, e);
    }, this.pasteHandler = (e) => {
      this.clipboardEvent(t.paste, e);
    }, this.addEventListeners = (e) => {
      e && (e.addEventListener(t.copy, this.copyHandler), e.addEventListener(t.cut, this.cutHandler), e.addEventListener(t.paste, this.pasteHandler));
    }, this.removeEventListeners = (e) => {
      e && (e.removeEventListener(t.copy, this.copyHandler), e.removeEventListener(t.cut, this.cutHandler), e.removeEventListener(t.paste, this.pasteHandler));
    }, this.clipboardEvent = r;
  }
}
export {
  n as ClipboardService
};
