/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { ConfigurationComponent as o } from "./base/ConfigurationComponent.mjs";
const r = (t) => /* @__PURE__ */ e.createElement(o, { ...t });
r.displayName = "ChartCategoryAxisItem";
export {
  r as ChartCategoryAxisItem
};
