/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { ConfigurationComponent as a } from "./base/ConfigurationComponent.mjs";
const o = (e) => /* @__PURE__ */ t.createElement(a, { ...e });
o.displayName = "ChartValueAxisItem";
export {
  o as ChartValueAxisItem
};
