export var ARC = "arc";
export var ARROW_UP = "ArrowUp";
export var ARROW_DOWN = "ArrowDown";
export var ARROW_LEFT = "ArrowLeft";
export var ARROW_RIGHT = "ArrowRight";
export var TAB = "Tab";
export var ARIA_ACTIVE_DESCENDANT = "aria-activedescendant";
export var AXIS_LABEL_CLICK = "axisLabelClick";
export var BLACK = "#000";
export var BOTTOM = "bottom";
export var CENTER = "center";
export var CIRCLE = "circle";
export var COORD_PRECISION = 3;
export var CROSS = "cross";
export var DATE = "date";
export var DEFAULT_FONT = "12px sans-serif";
export var DEFAULT_HEIGHT = 400;
export var DEFAULT_PRECISION = 10;
export var DEFAULT_WIDTH = 600;
export var END = "end";
export var FORMAT_REGEX = /\{\d+:?/;
export var HEIGHT = "height";
export var HIGHLIGHT_ZINDEX = 100;
export var INSIDE = "inside";
export var INHERIT = "inherit";
export var LEFT = "left";
export var MAX_VALUE = Number.MAX_VALUE;
export var MIN_VALUE = -Number.MAX_VALUE;
export var NONE = "none";
export var NOTE_CLICK = "noteClick";
export var NOTE_HOVER = "noteHover";
export var NOTE_LEAVE = "noteLeave";
export var OBJECT = "object";
export var OUTSIDE = "outside";
export var RIGHT = "right";
export var ROUNDED_RECT = "roundedRect";
export var START = "start";
export var STRING = "string";
export var TOP = "top";
export var TRIANGLE = "triangle";
export var SQUARE = "square";
export var RECT = "rect";
export var VALUE = "value";
export var WHITE = "#fff";
export var WIDTH = "width";
export var X = "x";
export var Y = "y";
export var DEFAULT_SERIES_OPACITY = 1;
export var POINTER = "pointer";
export var HORIZONTAL = "horizontal";
export var VERTICAL = "vertical";
