/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as n from "react";
import e from "prop-types";
import { svgIconPropType as T, classNames as p, IconWrap as f } from "@progress/kendo-react-common";
import { chevronUpIcon as P, chevronDownIcon as q } from "@progress/kendo-svg-icons";
import { Reveal as A } from "@progress/kendo-react-animation";
function D({ imageUrl: d, icon: o, svgIcon: t, iconClass: r }) {
  return d ? /* @__PURE__ */ n.createElement("img", { role: "presentation", className: "k-panelbar-item-icon k-image", src: d }) : o || t ? /* @__PURE__ */ n.createElement(f, { className: "k-panelbar-item-icon", name: o, icon: t }) : r ? /* @__PURE__ */ n.createElement("span", { role: "presentation", className: "k-panelbar-item-icon " + r }) : null;
}
const l = class l extends n.PureComponent {
  constructor(o) {
    super(o), this.handleItemClick = () => {
      const { onSelect: t, disabled: r, id: c } = this.props;
      t && !r && t.call(void 0, {
        uniquePrivateKey: this.props.uniquePrivateKey,
        id: c,
        target: this
      });
    }, this.childFactory = (t) => this.props.keepItemsMounted ? n.cloneElement(t, {
      ...t.props,
      in: this.props.expanded
    }) : t, this.state = {
      show: o.expanded || !1
    };
  }
  /**
   * @hidden
   */
  render() {
    const {
      id: o,
      children: t,
      title: r,
      uniquePrivateKey: c,
      disabled: a,
      selected: m,
      focused: y,
      expanded: s,
      className: E,
      level: u,
      headerClassName: g,
      animation: h,
      keepItemsMounted: i
    } = this.props, v = {
      role: "treeitem",
      "aria-disabled": a,
      "aria-hidden": !a && !s,
      "aria-selected": !a && m,
      "aria-expanded": !a && s && !!t
    }, x = p(
      "k-panelbar-item",
      {
        "k-panelbar-header": u === 0,
        "k-expanded": s && !!t,
        "k-disabled": a
      },
      `k-level-${u}`,
      E
    ), N = p(
      "k-link",
      {
        "k-selected": !a && m,
        "k-focus": !a && y
      },
      g
    ), I = { display: "block" }, w = D(this.props), C = !a && t ? /* @__PURE__ */ n.createElement(
      f,
      {
        name: s ? "chevron-up" : "chevron-down",
        icon: s ? P : q,
        className: p(
          "k-panelbar-toggle",
          s ? "k-panelbar-collapse" : "k-panelbar-expand"
        )
      }
    ) : null, k = !a && s || i ? /* @__PURE__ */ n.createElement(
      "ul",
      {
        role: "group",
        "aria-expanded": s,
        "aria-hidden": !s,
        className: "k-panelbar-group k-panel k-group",
        style: { display: i ? this.state.show ? "block" : "none" : "block" }
      },
      t
    ) : null, S = (h === void 0 || h) && !a && t ? /* @__PURE__ */ n.createElement(
      A,
      {
        transitionEnterDuration: 200,
        transitionExitDuration: 200,
        key: c + "_animation",
        style: I,
        children: k,
        childFactory: i && this.childFactory,
        unmountOnExit: !i,
        onBeforeEnter: () => i && this.setState({ show: !0 }),
        onAfterExited: () => i && this.setState({ show: !1 })
      }
    ) : k;
    return /* @__PURE__ */ n.createElement(
      "li",
      {
        id: o,
        className: x,
        ...v
      },
      /* @__PURE__ */ n.createElement("span", { className: N, onClick: this.handleItemClick }, w, /* @__PURE__ */ n.createElement("span", { className: "k-panelbar-item-text" }, r), C),
      S
    );
  }
};
l.propTypes = {
  animation: e.bool,
  children: e.any,
  className: e.string,
  icon: e.string,
  iconClass: e.string,
  imageUrl: e.string,
  svgIcon: T,
  expanded: e.bool,
  disabled: e.bool,
  onSelect: e.func,
  selected: e.bool,
  level: e.number,
  title: e.oneOfType([
    e.string,
    e.element
  ]),
  id: e.oneOfType([
    e.string,
    e.number
  ]),
  focused: e.bool,
  keepItemsMounted: e.bool
}, l.defaultProps = {
  title: "Untitled"
};
let b = l;
export {
  b as PanelBarItem
};
