/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as n from "react";
import e from "prop-types";
import { Popup as q } from "@progress/kendo-react-popup";
import { getDate as L, cloneDate as C } from "@progress/kendo-date-math";
import { classNames as D, Keys as r, validatePackage as W, canUseDOM as k, AsyncFocusBlur as _, kendoThemeMaps as O, createPropsContext as K, withIdHOC as U, withPropsContext as $ } from "@progress/kendo-react-common";
import { calendarIcon as H } from "@progress/kendo-svg-icons";
import { packageMetadata as X } from "../package-metadata.mjs";
import { DateInput as Z } from "../dateinput/DateInput.mjs";
import { Calendar as j } from "../calendar/components/Calendar.mjs";
import { MAX_DATE as G, MIN_DATE as J, isInDateRange as Q, setTime as Y } from "../utils.mjs";
import { toggleCalendar as S, messages as ee } from "../messages/index.mjs";
import { provideLocalizationService as te, registerForLocalization as ie } from "@progress/kendo-react-intl";
import { ToggleButton as se } from "./ToggleButton.mjs";
import { PickerWrap as oe } from "../common/PickerWrap.mjs";
import { PickerFloatingLabel as ae } from "../hooks/usePickerFloatingLabel.mjs";
import { ActionSheetContent as ne } from "@progress/kendo-react-layout";
import { AdaptiveMode as re } from "../common/AdaptiveMode.mjs";
import { MOBILE_MEDIUM_DEVISE as le } from "../common/constants.mjs";
const o = class o extends n.Component {
  constructor(i) {
    super(i), this._element = null, this._dateInput = n.createRef(), this._calendar = null, this.shouldFocusDateInput = !1, this.prevShow = !1, this.focus = () => {
      this.dateInput && this.dateInput.focus();
    }, this.setCalendarRef = (t) => {
      this._calendar = t;
    }, this.nextValue = (t, s) => t.value !== void 0 ? t.value : s.value, this.nextShow = (t, s) => t.show !== void 0 ? t.show : s.show, this.renderPopup = () => {
      const { disabled: t, min: s, max: a, weekNumber: u, focusedDate: c } = this.props, { popupClass: l, ...m } = this.props.popupSettings, f = this.show, d = this.value, g = d && L(d), v = D(
        "k-calendar-container k-group k-reset",
        l
      ), w = {
        popupClass: "k-datepicker-popup",
        show: f,
        anchor: this._element,
        className: v,
        id: this._popupId,
        anchorAlign: {
          horizontal: "left",
          vertical: "bottom"
        },
        popupAlign: {
          horizontal: "left",
          vertical: "top"
        },
        ...m
      }, h = {
        disabled: t,
        value: g,
        min: s,
        max: a,
        weekNumber: u,
        focusedDate: c,
        className: this.mobileMode ? "k-calendar-lg" : "",
        navigation: !this.mobileMode,
        onChange: this.handleCalendarValueChange
      };
      return this.mobileMode ? /* @__PURE__ */ n.createElement(this.calendarComp, { _ref: this.setCalendarRef, ...h }) : /* @__PURE__ */ n.createElement(this.popupComp, { ...w }, /* @__PURE__ */ n.createElement(this.calendarComp, { _ref: this.setCalendarRef, ...h }));
    }, this.renderAdaptivePopup = () => {
      const { windowWidth: t = 0 } = this.state, s = {
        expand: this.show,
        onClose: this.handleBlur,
        adaptiveTitle: this.props.adaptiveTitle,
        windowWidth: t
      };
      return /* @__PURE__ */ n.createElement(re, { ...s }, /* @__PURE__ */ n.createElement(ne, { className: "!k-overflow-hidden" }, this.renderPopup()));
    }, this.handleInputValueChange = (t) => {
      this.handleValueChange(t.value, t);
    }, this.handleCalendarValueChange = (t) => {
      const s = this.mergeTime(t.value);
      this.handleValueChange(s, t);
    }, this.handleValueChange = (t, s) => {
      this.setState({
        value: C(t || void 0)
      }), this.valueDuringOnChange = t, this.showDuringOnChange = !1, this.mobileMode || (this.shouldFocusDateInput = !0);
      const { onChange: a } = this.props;
      a && a.call(void 0, {
        syntheticEvent: s.syntheticEvent,
        nativeEvent: s.nativeEvent,
        value: this.value,
        show: this.show,
        target: this
      }), this.valueDuringOnChange = void 0, this.showDuringOnChange = void 0, this.setShow(!1);
    }, this.handleFocus = () => {
      this.setState({ focused: !0 });
    }, this.handleBlur = () => {
      this.setState({ focused: !1 }), this.setShow(!1);
    }, this.handleIconClick = () => {
      this.props.disabled || (this.shouldFocusDateInput = !0, this.setShow(!this.show));
    }, this.handleIconMouseDown = (t) => {
      t.preventDefault();
    }, this.handleKeyDown = (t) => {
      const { altKey: s, keyCode: a } = t;
      if (a === r.esc) {
        this.shouldFocusDateInput = !0, this.setShow(!1);
        return;
      }
      s && (a === r.up || a === r.down) && (t.preventDefault(), t.stopPropagation(), this.shouldFocusDateInput = a === r.up, this.setShow(a === r.down));
    }, W(X), this.state = {
      value: this.props.defaultValue || o.defaultProps.defaultValue,
      show: this.props.defaultShow || o.defaultProps.defaultShow,
      focused: !1
    };
  }
  get _popupId() {
    return this.props.id + "-popup-id";
  }
  get document() {
    if (k)
      return this.element && this.element.ownerDocument || document;
  }
  /**
   * Gets the wrapping element of the DatePickerWithoutContext.
   */
  get element() {
    return this._element;
  }
  /**
   * The mobile mode of the DatePicker.
   */
  get mobileMode() {
    return !!(this.state.windowWidth && this.state.windowWidth <= le && this.props.adaptive);
  }
  /**
   * Gets the DateInput component inside the DatePicker component.
   */
  get dateInput() {
    return this._dateInput.current;
  }
  /**
   * Gets the Calendar component inside the DatePicker component.
   */
  get calendar() {
    return this._calendar;
  }
  /**
   * Gets the value of the DatePickerWithoutContext.
   */
  get value() {
    const i = this.valueDuringOnChange !== void 0 ? this.valueDuringOnChange : this.props.value !== void 0 ? this.props.value : this.state.value;
    return i !== null ? C(i) : null;
  }
  /**
   * Gets the popup state of the DatePickerWithoutContext.
   */
  get show() {
    return this.showDuringOnChange !== void 0 ? this.showDuringOnChange : this.props.show !== void 0 ? this.props.show : this.state.show;
  }
  /**
   * Gets the `name` property of the DatePickerWithoutContext.
   */
  get name() {
    return this.props.name;
  }
  get min() {
    return this.props.min !== void 0 ? this.props.min : o.defaultProps.min;
  }
  get max() {
    return this.props.max !== void 0 ? this.props.max : o.defaultProps.max;
  }
  get dateInputComp() {
    return this.props.dateInput || o.defaultProps.dateInput;
  }
  get toggleButtonComp() {
    return this.props.toggleButton || o.defaultProps.toggleButton;
  }
  get calendarComp() {
    return this.props.calendar || o.defaultProps.calendar;
  }
  get popupComp() {
    return this.props.popup || o.defaultProps.popup;
  }
  get pickerWrapComp() {
    return this.props.pickerWrap || o.defaultProps.pickerWrap;
  }
  /**
   * Represents the validity state into which the DatePicker is set.
   */
  get validity() {
    const i = Q(this.value, this.min, this.max), t = this.props.validationMessage !== void 0, s = (!this.required || this.value !== null) && i, a = this.props.valid !== void 0 ? this.props.valid : s;
    return {
      customError: t,
      rangeOverflow: this.value && this.max.getTime() < this.value.getTime() || !1,
      rangeUnderflow: this.value && this.value.getTime() < this.min.getTime() || !1,
      valid: a,
      valueMissing: this.value === null
    };
  }
  /**
   * @hidden
   */
  get validityStyles() {
    return this.props.validityStyles !== void 0 ? this.props.validityStyles : o.defaultProps.validityStyles;
  }
  /**
   * @hidden
   */
  get required() {
    return this.props.required !== void 0 ? this.props.required : o.defaultProps.required;
  }
  /**
   * @hidden
   */
  componentDidMount() {
    var i;
    this.observerResize = k && window.ResizeObserver && new window.ResizeObserver(this.calculateMedia.bind(this)), this.show && this.forceUpdate(), (i = this.document) != null && i.body && this.observerResize && this.observerResize.observe(this.document.body);
  }
  /**
   * @hidden
   */
  componentDidUpdate() {
    this._calendar && this._calendar.element && this.show && !this.prevShow && this._calendar.element.focus({ preventScroll: !0 }), this.dateInput && this.dateInput.element && !this.show && this.shouldFocusDateInput && this.dateInput.element.focus({ preventScroll: !0 }), this.prevShow = this.show, this.shouldFocusDateInput = !1;
  }
  /**
   * @hidden
   */
  componentWillUnmount() {
    var i;
    clearTimeout(this.nextTickId), (i = this.document) != null && i.body && this.observerResize && this.observerResize.disconnect();
  }
  /**
   * @hidden
   */
  render() {
    const {
      size: i = o.defaultProps.size,
      rounded: t = o.defaultProps.rounded,
      fillMode: s = o.defaultProps.fillMode,
      disabled: a,
      tabIndex: u,
      title: c,
      id: l,
      format: m,
      formatPlaceholder: f,
      min: d,
      max: g,
      className: v,
      width: w,
      name: h,
      validationMessage: M,
      required: P,
      validityStyles: T,
      ariaLabelledBy: E,
      ariaDescribedBy: x,
      ariaLabel: B
    } = this.props, z = this.show, V = this.value, b = !this.validityStyles || this.validity.valid, F = {
      disabled: a,
      format: m,
      formatPlaceholder: f,
      id: l,
      ariaLabelledBy: E,
      ariaDescribedBy: x,
      ariaLabel: B,
      max: g,
      min: d,
      name: h,
      onChange: this.handleInputValueChange,
      required: P,
      tabIndex: z ? -1 : u,
      title: c,
      valid: this.validity.valid,
      validationMessage: M,
      validityStyles: T,
      value: V,
      label: void 0,
      placeholder: this.state.focused ? null : this.props.placeholder,
      ariaExpanded: this.show,
      size: null,
      fillMode: null,
      rounded: null
    }, y = te(this).toLanguageString(S, ee[S]), I = /* @__PURE__ */ n.createElement(
      _,
      {
        onFocus: this.handleFocus,
        onBlur: this.mobileMode ? void 0 : this.handleBlur,
        onSyncBlur: this.props.onBlur,
        onSyncFocus: this.props.onFocus
      },
      ({ onFocus: N, onBlur: R }) => /* @__PURE__ */ n.createElement(n.Fragment, null, /* @__PURE__ */ n.createElement(
        "span",
        {
          ref: (A) => {
            this._element = A;
          },
          className: D(
            "k-input",
            "k-datepicker",
            {
              [`k-input-${O.sizeMap[i] || i}`]: i,
              [`k-rounded-${O.roundedMap[t] || t}`]: t,
              [`k-input-${s}`]: s,
              "k-invalid": !b,
              "k-required": this.required,
              "k-disabled": this.props.disabled
            },
            v
          ),
          onKeyDown: this.handleKeyDown,
          style: { width: w },
          onFocus: N,
          onBlur: R,
          onClick: this.mobileMode ? this.handleIconClick : void 0
        },
        /* @__PURE__ */ n.createElement(
          this.dateInputComp,
          {
            _ref: this._dateInput,
            ariaRole: "combobox",
            readonly: this.mobileMode,
            ariaExpanded: this.show,
            ariaControls: this._popupId,
            ...F
          }
        ),
        /* @__PURE__ */ n.createElement(
          this.toggleButtonComp,
          {
            type: "button",
            icon: "calendar",
            svgIcon: H,
            title: y,
            className: "k-input-button",
            rounded: null,
            onClick: this.mobileMode ? void 0 : this.handleIconClick,
            "aria-label": y,
            onMouseDown: this.handleIconMouseDown
          }
        ),
        !this.mobileMode && this.renderPopup()
      ), this.mobileMode && this.renderAdaptivePopup())
    );
    return this.props.label ? /* @__PURE__ */ n.createElement(
      ae,
      {
        dateInput: this._dateInput,
        label: this.props.label,
        editorId: l,
        editorValid: b,
        editorDisabled: this.props.disabled,
        children: I,
        style: { width: this.props.width }
      }
    ) : I;
  }
  setShow(i) {
    const { onOpen: t, onClose: s } = this.props;
    this.show !== i && (this.setState({ show: i }), i && t && t.call(void 0, {
      target: this
    }), !i && s && s.call(void 0, {
      target: this
    }));
  }
  mergeTime(i) {
    return this.value && i ? Y(i, this.value) : i;
  }
  nextTick(i) {
    clearTimeout(this.nextTickId), this.nextTickId = window.setTimeout(() => i());
  }
  calculateMedia(i) {
    for (let t of i)
      this.setState({ windowWidth: t.target.clientWidth });
  }
};
o.displayName = "DatePicker", o.propTypes = {
  className: e.string,
  defaultShow: e.bool,
  defaultValue: e.instanceOf(Date),
  disabled: e.bool,
  focusedDate: e.instanceOf(Date),
  format: e.oneOfType([
    e.string,
    e.shape({
      skeleton: e.string,
      pattern: e.string,
      date: e.oneOf(["short", "medium", "long", "full"]),
      time: e.oneOf(["short", "medium", "long", "full"]),
      datetime: e.oneOf(["short", "medium", "long", "full"]),
      era: e.oneOf(["narrow", "short", "long"]),
      year: e.oneOf(["numeric", "2-digit"]),
      month: e.oneOf(["numeric", "2-digit", "narrow", "short", "long"]),
      day: e.oneOf(["numeric", "2-digit"]),
      weekday: e.oneOf(["narrow", "short", "long"]),
      hour: e.oneOf(["numeric", "2-digit"]),
      hour12: e.bool,
      minute: e.oneOf(["numeric", "2-digit"]),
      second: e.oneOf(["numeric", "2-digit"]),
      timeZoneName: e.oneOf(["short", "long"])
    })
  ]),
  formatPlaceholder: e.oneOfType([
    e.oneOf(["wide", "narrow", "short", "formatPattern"]),
    e.shape({
      year: e.string,
      month: e.string,
      day: e.string,
      hour: e.string,
      minute: e.string,
      second: e.string
    })
  ]),
  id: e.string,
  ariaLabelledBy: e.string,
  ariaDescribedBy: e.string,
  ariaLabel: e.string,
  min: e.instanceOf(Date),
  max: e.instanceOf(Date),
  name: e.string,
  popupSettings: e.shape({
    animate: e.bool,
    appendTo: e.any,
    popupClass: e.string
  }),
  show: e.bool,
  tabIndex: e.number,
  title: e.string,
  value: e.instanceOf(Date),
  weekNumber: e.bool,
  width: e.oneOfType([e.number, e.string]),
  validationMessage: e.string,
  required: e.bool,
  validate: e.bool,
  valid: e.bool,
  size: e.oneOf([null, "small", "medium", "large"]),
  rounded: e.oneOf([null, "small", "medium", "large", "full"]),
  fillMode: e.oneOf([null, "solid", "flat", "outline"]),
  adaptive: e.bool,
  adaptiveTitle: e.string
}, o.defaultProps = {
  defaultShow: !1,
  defaultValue: null,
  dateInput: Z,
  calendar: j,
  toggleButton: se,
  popup: q,
  pickerWrap: oe,
  disabled: !1,
  format: "d",
  max: G,
  min: J,
  popupSettings: {},
  tabIndex: 0,
  weekNumber: !1,
  required: !1,
  validityStyles: !0,
  size: "medium",
  rounded: "medium",
  fillMode: "solid"
};
let p = o;
const de = K(), he = U($(de, p));
he.displayName = "KendoReactDatePicker";
ie(p);
export {
  he as DatePicker,
  de as DatePickerPropsContext,
  p as DatePickerWithoutContext
};
