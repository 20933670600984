import calculateSlope from './calculate-slope';
import checkAllPositive from './check-all-positive';
import getScatterTrendlineData from './get-scatter-trendline-data';
import scatterValueGetter from './scatter-value-getter';
import xValueLimits from './x-value-limits';

function scatterPowerTrendline(context) {
    var options = context.options;
    var seriesValues = context.seriesValues;

    var data = getData({ seriesValues: seriesValues, options: options });
    if (data) {
        return Object.assign({}, options,

            {type: 'scatterLine',
            data: data});
    }

    return null;
}

var valueGetter = function (fieldName) { return function (ref) {
        var valueFields = ref.valueFields;

        return ({ xValue: Math.log(valueFields.x), yValue: Math.log(valueFields[fieldName]) });
; }    };

function getData(ref) {
    var seriesValues = ref.seriesValues;
    var options = ref.options;

    var sourceValues = seriesValues();
    if (!checkAllPositive(sourceValues, 'x')) {
        return null;
    }

    var ref$1 = calculateSlope(sourceValues, valueGetter(options.field));
    var slope = ref$1.slope;
    var intercept = ref$1.intercept;
    var count = ref$1.count;
    var range = xValueLimits(sourceValues, scatterValueGetter(options.field));

    if (count > 0) {
        // Power trendline equation:
        // y = a * x ^ b
        var a = Math.exp(intercept);
        var b = slope;
        return getScatterTrendlineData(function (x) { return a * Math.pow(x, b); }, range, options.trendline);
    }

    return null;
}

export default scatterPowerTrendline;
