/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { PreventableEvent as s } from "./preventable-event.mjs";
class i extends s {
  /**
   * @hidden
   */
  constructor(t, e) {
    super(e), this.axis = t.axis, this.from = t.from, this.to = t.to;
  }
}
export {
  i as SelectEvent
};
