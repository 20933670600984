/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const i = () => {
  let e = "", t, r;
  for (t = 0; t < 32; t++)
    r = Math.random() * 16 | 0, (t === 8 || t === 12 || t === 16 || t === 20) && (e += "-"), e += (t === 12 ? 4 : t === 16 ? r & 3 | 8 : r).toString(16);
  return e;
};
export {
  i as guid
};
