/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as c from "react";
import n from "prop-types";
import { Animation as E } from "./Animation.mjs";
import { noop as g } from "@progress/kendo-react-common";
import l from "./util.mjs";
const a = class a extends c.Component {
  constructor() {
    super(...arguments), this.state = {
      maxHeight: void 0,
      maxWidth: void 0
    }, this.componentWillEnter = (t) => {
      const { onEnter: i, onBeforeEnter: e } = this.props;
      e && e.call(void 0, t), this.updateContainerDimensions(t.animatedElement, () => {
        i && i.call(void 0, t);
      });
    }, this.componentIsEntering = (t) => {
      const { onEntering: i } = this.props;
      this.updateContainerDimensions(t.animatedElement, () => {
        i && i.call(void 0, t);
      });
    }, this.componentWillExit = (t) => {
      const { onExit: i } = this.props;
      this.updateContainerDimensions(t.animatedElement, () => {
        i && i.call(void 0, t);
      });
    }, this.updateContainerDimensions = (t, i = g) => {
      const e = t.firstChild;
      if (e) {
        const m = l.outerHeight(e), s = l.outerWidth(e);
        this.setState(
          {
            maxHeight: m,
            maxWidth: s
          },
          i
        );
      }
    };
  }
  /**
   * @hidden
   */
  render() {
    const {
      direction: t,
      children: i,
      childFactory: e,
      ...m
    } = this.props, {
      maxHeight: s,
      maxWidth: h
    } = this.state;
    let o;
    t === "vertical" ? o = { maxHeight: s ? `${s}px` : "" } : o = { maxWidth: h ? `${h}px` : "" };
    const x = {
      maxHeight: o.maxHeight,
      maxWidth: o.maxWidth
    }, f = (p) => {
      let r = e ? e(p) : p;
      return r.props.in ? r : c.cloneElement(r, {
        ...r.props,
        style: {
          ...r.props.style,
          maxHeight: o.maxHeight,
          maxWidth: o.maxWidth
        }
      });
    };
    return /* @__PURE__ */ c.createElement(
      E,
      {
        ...m,
        childFactory: f,
        onEnter: this.componentWillEnter,
        onEntering: this.componentIsEntering,
        onExit: this.componentWillExit,
        animationEnteringStyle: x,
        transitionName: `reveal-${t}`
      },
      i
    );
  }
};
a.propTypes = {
  children: n.oneOfType([
    n.arrayOf(n.node),
    n.node
  ]),
  childFactory: n.any,
  className: n.string,
  direction: n.oneOf([
    "horizontal",
    "vertical"
  ]),
  component: n.string,
  id: n.string,
  style: n.any
}, a.defaultProps = {
  appear: !1,
  enter: !0,
  exit: !0,
  transitionEnterDuration: 300,
  transitionExitDuration: 300,
  direction: "vertical"
};
let d = a;
export {
  d as Reveal
};
