import getTrendlineData from './get-trendline-data';
import calculatePolynomial from './calculate-polynomial';

function polynomialTrendline(context) {
    var options = context.options;
    var categoryAxis = context.categoryAxis;
    var seriesValues = context.seriesValues;

    var data = getData({ seriesValues: seriesValues, categoryAxis: categoryAxis, options: options });
    if (data) {
        return Object.assign({}, options,

            {type: 'line',
            data: data,
            categoryField: 'category',
            field: 'value'});
    }

    return null;
}

var valueGetter = function (fieldName) { return function (ref) {
        var categoryIx = ref.categoryIx;
        var valueFields = ref.valueFields;

        return ({ xValue: categoryIx + 1, yValue: valueFields[fieldName] });
; }    };

function getData(ref) {
    var seriesValues = ref.seriesValues;
    var categoryAxis = ref.categoryAxis;
    var options = ref.options;

    var order = (options.trendline || {}).order;
    var ref$1 = calculatePolynomial(seriesValues(), valueGetter(options.field), order);
    var count = ref$1.count;
    var valueMapper = ref$1.valueMapper;

    if (count > 0) {
        // Polynomial trendline equation:
        // y = aN * x^N + ... + a2 * x^2 + a1 * x + a0

        return getTrendlineData(function (x) { return valueMapper(x); }, categoryAxis);
    }

    return null;
}

export default polynomialTrendline;
