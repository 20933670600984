// Optimized version of calculatePolynomial for order = 1
function calculateSlope(sourceValues, valueGetter) {
    var x = 0;
    var y = 0;
    var x2 = 0;
    var xy = 0;
    var count = 0;
    var slope, intercept;
    var xMin = Number.MAX_VALUE;
    var xMax = Number.MIN_VALUE;

    for (var i = 0; i < sourceValues.length; i++) {
        var value = sourceValues[i];
        var ref = valueGetter(value);
        var xValue = ref.xValue;
        var yValue = ref.yValue;

        if (isFinite(xValue) && xValue !== null && isFinite(yValue) && yValue !== null) {
            xMin = Math.min(xValue, xMin);
            xMax = Math.max(xValue, xMax);

            count++;
            x += xValue;
            y += yValue;
            x2 += Math.pow(xValue, 2);
            xy += xValue * yValue;
        }
    }

    if (count > 0) {
        slope = (count * xy - x * y) / (count * x2 - Math.pow(x, 2));
        intercept = (y - slope * x) / count;
    }

    return { slope: slope, intercept: intercept, count: count, xMin: xMin, xMax: xMax };
}

export default calculateSlope;
