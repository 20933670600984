/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
class g {
  constructor(s) {
    this.table = null, this.containerHeight = 0, this.topCacheCount = 0, this.attendedSkip = 0, this.propsSkip = 0, this.total = 0, this.scrollableVirtual = !1, this.realSkip = 0, this.pageSize = 0, this.PageChange = null, this.tableBodyRef = r.createRef(), this.fixedScroll = !1, this.askedSkip = void 0, this.containerRef = r.createRef(), this.tableTransform = "", this.prevScrollPos = 0, this.tableTranslate = 0, this.scrollSyncing = !1, this.reactVersion = Number.parseFloat(r.version), this.topItems = (t, l) => {
      if (!this.container || l)
        return { topItemsCount: 0, topItemsHeight: 0 };
      const i = this.container.clientHeight, e = Math.ceil(i / t[0].line), n = Math.ceil((t.length - e) / 2);
      let o = 0;
      for (let a = 0; a < n; a++)
        o += t[a].line + t[a].acc;
      return {
        topItemsCount: n,
        topItemsHeight: o,
        itemsNeededOnScreen: e + e / 2
      };
    }, this.horizontalScrollbarHeight = () => this.container ? this.container.offsetHeight - this.container.clientHeight : 0, s && (this.topCacheCount = 4, this.attendedSkip = -this.topCacheCount), this.scrollHandler = this.scrollHandler.bind(this);
  }
  get container() {
    return this.containerRef.current;
  }
  /**
   * @return - The row heights in an array.
   */
  get rowHeights() {
    const s = [], t = this.tableBodyRef.current && this.tableBodyRef.current.children || [];
    let l = 0;
    for (let i = 0; i < t.length; i++) {
      if (t[i].className.indexOf("k-grouping-row") > -1) {
        l += t[i].scrollHeight;
        continue;
      }
      t[i].className.indexOf("k-detail-row") > -1 ? s[s.length - 1].line += t[i].scrollHeight : (s.push({
        line: t[i].scrollHeight,
        acc: l
      }), l = 0);
    }
    return s;
  }
  changePage(s, t) {
    this.attendedSkip = s - this.topCacheCount, this.PageChange && this.PageChange(
      {
        skip: Math.max(0, s - this.topCacheCount),
        take: this.pageSize
      },
      t
    );
  }
  translate(s, t) {
    this.tableTranslate = s, this.scrollableVirtual && this.table && (this.reactVersion <= 17 || t ? this.table.style.transform = "translateY(" + s + "px)" : this.tableTransform = "translateY(" + s + "px)");
  }
  reset() {
    this.scrollSyncing = !0, !this.fixedScroll && (this.container && (this.container.scrollTop = 0), this.translate(0, !0));
  }
  localScrollUp(s) {
    if (!this.container)
      return;
    const t = this.rowHeights, l = this.container.scrollTop;
    let i = this.tableTranslate, e = 0;
    const {
      topItemsCount: n,
      topItemsHeight: o,
      itemsNeededOnScreen: a
    } = this.topItems(t, !!this.topCacheCount), h = l - i;
    if (!(h > o || t.length <= a)) {
      for (; e < this.topCacheCount + this.attendedSkip - this.realSkip + n && this.propsSkip - e > 0 && !(i + (t[t.length - 1 - e].line + t[t.length - 1 - e].acc) + h <= l); )
        i -= t[t.length - 1 - e].line + t[t.length - 1 - e].acc, e++;
      if (e === 0 && this.topCacheCount === 0 && this.attendedSkip > 0 && (i = Math.max(i - t[0].line, 0), e = 1), this.propsSkip - e <= 0 && i > l) {
        this.translate(0), this.changePage(0, s), this.container.scrollTop = 0;
        return;
      }
      if (i > l && (i = l), i !== this.tableTranslate) {
        this.translate(Math.max(0, i - o));
        const c = Math.max(0, this.propsSkip - e - n);
        this.changePage(c, s);
      }
    }
  }
  localScrollDown(s) {
    if (!this.container)
      return;
    const t = this.rowHeights, l = this.container.scrollTop;
    let i = this.tableTranslate, e = 0;
    const {
      topItemsCount: n,
      topItemsHeight: o,
      itemsNeededOnScreen: a
    } = this.topItems(t, !!this.topCacheCount);
    for (; e < t.length - this.topCacheCount && !(i + t[e].line + t[e].acc > l); )
      i += t[e].line + t[e].acc, e++;
    n > this.propsSkip + e || t.length <= a || (e >= t.length - this.topCacheCount && this.propsSkip + e >= this.total ? (this.translate(i - o), this.changePage(this.total - 1 - n, s)) : i !== this.tableTranslate && this.propsSkip + e - n !== this.propsSkip && (this.translate(i - o), this.changePage(this.propsSkip + e - n, s)));
  }
  scrollNonStrict(s) {
    const t = this.total * this.prevScrollPos / this.containerHeight;
    let l = Math.floor(t);
    l >= this.total && (l = this.total - 1);
    const i = Math.min(t - l, 1);
    let e = 0;
    const n = l - this.propsSkip, o = this.rowHeights;
    n >= 0 && n <= 1 ? e = -((o[0].line + o[0].acc) * i) : n === -1 && (e = -((o[o.length - 1].line + o[o.length - 1].acc) * i));
    const { topItemsCount: a, topItemsHeight: h } = this.topItems(o, !!this.topCacheCount);
    this.translate(Math.max(0, e - h - this.horizontalScrollbarHeight() + this.containerHeight * t / this.total)), this.changePage(l - a, s);
  }
  scrollHandler(s) {
    if (!this.scrollableVirtual)
      return;
    if (this.scrollSyncing || !this.container || !this.table) {
      this.scrollSyncing = !1;
      return;
    }
    const t = this.container.scrollTop, l = this.prevScrollPos;
    if (this.prevScrollPos = t, this.askedSkip !== void 0) {
      this.translate(this.containerHeight * this.askedSkip / this.total), this.changePage(this.askedSkip, s), this.prevScrollPos = t, this.askedSkip = void 0;
      return;
    }
    t - l < 0 && t > this.tableTranslate - this.table.scrollHeight / 10 ? this.localScrollUp(s) : t - l > 0 && t < this.tableTranslate + this.table.scrollHeight * 2 / 3 ? this.localScrollDown(s) : this.scrollNonStrict(s), this.prevScrollPos = t;
  }
}
export {
  g as VirtualScroll
};
