/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
function n(e, l) {
  return e === l || Number.isNaN(e) && Number.isNaN(l);
}
function a(e, l) {
  if (e.length !== l.length)
    return !1;
  for (let t = 0; t < e.length; t++)
    if (!n(e[t], l[t]))
      return !1;
  return !0;
}
function s(e, l = a) {
  let t = null;
  function u(...r) {
    if (t && t.lastThis === this && l(r, t.lastArgs))
      return t.lastResult;
    const i = e.apply(this, r);
    return t = {
      lastResult: i,
      lastArgs: r,
      lastThis: this
    }, i;
  }
  return u.clear = function() {
    t = null;
  }, u;
}
export {
  s as memoizeOne
};
