/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { Button as o } from "@progress/kendo-react-buttons";
import { xIcon as n } from "@progress/kendo-svg-icons";
const s = ({ children: e, onCloseButtonClick: a, id: i, closeIcon: l }) => /* @__PURE__ */ t.createElement("div", { className: "k-window-titlebar k-dialog-titlebar", id: i }, /* @__PURE__ */ t.createElement("span", { className: "k-window-title k-dialog-title" }, e), /* @__PURE__ */ t.createElement("div", { className: "k-window-titlebar-actions k-dialog-titlebar-actions" }, l && /* @__PURE__ */ t.createElement(
  o,
  {
    role: "button",
    "aria-label": "Close",
    onClick: a,
    icon: "x",
    svgIcon: n,
    fillMode: "flat",
    className: "k-window-titlebar-action k-dialog-titlebar-action"
  }
)));
export {
  s as DialogTitleBar
};
