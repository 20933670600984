/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
class h {
  /**
   * @hidden
   */
  constructor(t, s) {
    this.value = t.value, this.category = t.category, this.categoryIndex = t.categoryIx, this.series = t.series, this.dataItem = t.dataItem, this.percentage = t.percentage, this.runningTotal = t.runningTotal, this.total = t.total, this.low = t.low, this.high = t.high, this.xLow = t.xLow, this.xHigh = t.xHigh, this.yLow = t.yLow, this.yHigh = t.yHigh, this.point = t, this.format = ((t.options || {}).tooltip || {}).format || s;
  }
  get formattedValue() {
    return this.format ? this.point.formatValue(this.format) : String(this.value);
  }
}
export {
  h as TooltipPoint
};
