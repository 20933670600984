var MapService = function MapService(widget, context) {
    if ( context === void 0 ) context = {};

    this.sender = context.sender || widget;
    this.widget = widget;
    this.rtl = Boolean(context.rtl);
};

MapService.prototype.notify = function notify (name, args) {
    if (this.widget) {
        this.widget.trigger(name, args);
    }
};

export default MapService;
