/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { BaseEvent as e } from "./base-event.mjs";
class r extends e {
  /**
   * @hidden
   */
  constructor(t, s) {
    super(s), this.category = t.category, this.dataItem = t.dataItem, this.series = t.series, this.value = t.value, this.visual = t.visual;
  }
}
export {
  r as NoteClickEvent
};
