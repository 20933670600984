/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import { classNames as m, kendoThemeMaps as k } from "@progress/kendo-react-common";
import { Button as u } from "@progress/kendo-react-buttons";
import { pagerMobileSelect as n, messages as i, pagerPage as p, pagerMoreButtonLabel as l } from "../messages/index.mjs";
import { provideLocalizationService as v } from "@progress/kendo-react-intl";
const c = "...";
class K extends r.Component {
  constructor() {
    super(...arguments), this.handlePageChange = (e, s) => {
      e.preventDefault(), this.props.pageChange(s, e);
    }, this.handleSelectPageChange = (e) => {
      e.preventDefault(), this.props.pageChange(e.target.value, e);
    };
  }
  render() {
    const e = v(this), s = this.props.messagesMap ? this.props.messagesMap(n) : { messageKey: n, defaultMessage: i[n] }, a = this.props.messagesMap ? this.props.messagesMap(p) : { messageKey: p, defaultMessage: i[p] }, g = this.props.messagesMap ? this.props.messagesMap(l) : { messageKey: l, defaultMessage: i[l] }, h = e.toLanguageString(g.messageKey, g.defaultMessage), d = this.start > 1 && this.renderDots(this.start - 1, h), M = this.end < this.props.totalPages && this.renderDots(this.end + 1, h), f = this.start > 1 && this.renderOptionDots(this.start - 1), b = this.end < this.props.totalPages && this.renderOptionDots(this.end + 1), o = [];
    for (let t = this.start; t <= this.end; t++)
      o.push(t);
    const y = o.map((t) => /* @__PURE__ */ r.createElement(
      u,
      {
        className: m({ "k-selected": this.props.currentPage === t }),
        key: t,
        fillMode: "flat",
        themeColor: "primary",
        size: this.props.size,
        rounded: null,
        role: "button",
        "aria-label": e.toLanguageString(a.messageKey, a.defaultMessage) + " " + t,
        "aria-current": this.props.currentPage === t ? !0 : void 0,
        onClick: (P) => this.handlePageChange(P, t)
      },
      t
    )), C = o.map((t) => /* @__PURE__ */ r.createElement("option", { key: t }, t));
    return /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement(
      "select",
      {
        style: { width: "5em", margin: "0px 1em", display: this.props.type === "dropdown" ? "inline-flex" : "none" },
        className: m(
          "k-picker k-dropdown-list k-dropdown k-rounded-md",
          {
            [`k-picker-${k.sizeMap[this.props.size] || this.props.size}`]: this.props.size
          }
        ),
        "aria-label": e.toLanguageString(s.messageKey, s.defaultMessage),
        value: this.props.currentPage,
        onChange: (t) => this.handleSelectPageChange(t)
      },
      f,
      C,
      b
    ), /* @__PURE__ */ r.createElement(
      "div",
      {
        className: "k-pager-numbers",
        style: { display: this.props.type === "numbers" ? "" : "none" }
      },
      d,
      y,
      M
    ));
  }
  get end() {
    return Math.min(this.start + this.props.buttonCount - 1, this.props.totalPages);
  }
  get start() {
    const e = this.props.currentPage, s = this.props.buttonCount;
    if (e > s) {
      const a = e % s;
      return a === 0 ? e - s + 1 : e - a + 1;
    }
    return 1;
  }
  renderDots(e, s) {
    return /* @__PURE__ */ r.createElement(
      u,
      {
        fillMode: "flat",
        themeColor: "primary",
        size: this.props.size,
        rounded: null,
        role: "button",
        "aria-label": s,
        onClick: (a) => this.handlePageChange(a, e)
      },
      c
    );
  }
  renderOptionDots(e) {
    return /* @__PURE__ */ r.createElement("option", { value: e }, c);
  }
}
export {
  K as PagerNumericButtons
};
