/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const o = (i, t, s) => [
  ...i.slice(0, t + 1),
  ...i.slice(t + 1).map((e) => e + s)
];
class g {
  constructor(t = 0, s, e) {
    this.total = t, this.rowHeight = s, this.detailRowHeight = e, this.offsets = [], this.heights = [];
    let h = 0;
    for (let f = 0; f < t; f++)
      this.offsets.push(h), h += s, this.heights.push(s);
  }
  height(t) {
    return this.heights[t];
  }
  expandDetail(t) {
    this.height(t) === this.rowHeight && this.updateRowHeight(t, this.detailRowHeight);
  }
  collapseDetail(t) {
    this.height(t) > this.rowHeight && this.updateRowHeight(t, this.detailRowHeight * -1);
  }
  index(t) {
    for (let s = 0; s < this.offsets.length; s++) {
      if (t === this.offsets[s])
        return s;
      if (t < this.offsets[s])
        return s - 1;
    }
    return this.total - 1;
  }
  offset(t) {
    return this.offsets[t];
  }
  totalHeight() {
    return this.heights.reduce((t, s) => t + s, 0);
  }
  updateRowHeight(t, s) {
    this.heights[t] += s, this.offsets = o(this.offsets, t, s);
  }
}
export {
  g as RowHeightService
};
