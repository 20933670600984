import { deepExtend, eventElement, grep, inArray, setDefaultOptions, createHashSet, cycleIndex } from '../../common';
import { DATE } from '../../common/constants';
import { CategoryAxis, DateCategoryAxis, Point } from '../../core';
import { dateEquals } from '../../date-utils';
import { HEATMAP } from '../constants';
import HeatmapChart from '../heatmap-chart/heatmap-chart';
import PlotAreaEventsMixin from '../mixins/plotarea-events-mixin';
import SeriesBinder from '../series-binder';
import { appendIfNotNull, equalsIgnoreCase, filterSeriesByType, singleItemOrArray } from '../utils';
import PlotAreaBase from './plotarea-base';


var HeatmapPlotArea = (function (PlotAreaBase) {
    function HeatmapPlotArea () {
        PlotAreaBase.apply(this, arguments);
    }

    if ( PlotAreaBase ) HeatmapPlotArea.__proto__ = PlotAreaBase;
    HeatmapPlotArea.prototype = Object.create( PlotAreaBase && PlotAreaBase.prototype );
    HeatmapPlotArea.prototype.constructor = HeatmapPlotArea;

    HeatmapPlotArea.prototype.initFields = function initFields () {
        this.namedXAxes = {};
        this.namedYAxes = {};
    };

    HeatmapPlotArea.prototype.render = function render (panes) {
        if ( panes === void 0 ) panes = this.panes;

        this.bindCategories();
        this.createAxes(panes);
        this.createCharts(panes);
        this.createAxisLabels();
    };

    HeatmapPlotArea.prototype.bindCategories = function bindCategories () {
        var this$1 = this;

        var series = this.srcSeries || this.series;

        for (var i = 0; i < series.length; i++) {
            var currentSeries = series[i];
            var data = currentSeries.data || [];
            var ref = this$1.seriesAxes(currentSeries);
            var xAxis = ref.xAxis;
            var yAxis = ref.yAxis;

            var xCategories = createHashSet(xAxis.categories || []);
            var yCategories = createHashSet(yAxis.categories || []);

            for (var pointIndex = 0; pointIndex < data.length; pointIndex++) {
                var ref$1 = SeriesBinder.current.bindPoint(currentSeries, pointIndex).valueFields;
                var x = ref$1.x;
                var y = ref$1.y;

                if (!xCategories.has(x)) {
                    xCategories.add(x);
                }

                if (!yCategories.has(y)) {
                    yCategories.add(y);
                }
            }

            xAxis.categories = xCategories.values();
            yAxis.categories = yCategories.values();
        }
    };

    HeatmapPlotArea.prototype.createCharts = function createCharts (panes) {
        var this$1 = this;

        var seriesByPane = this.groupSeriesByPane();

        for (var i = 0; i < panes.length; i++) {
            var pane = panes[i];
            var paneSeries = seriesByPane[pane.options.name || "default"] || [];
            this$1.addToLegend(paneSeries);
            var filteredSeries = this$1.filterVisibleSeries(paneSeries);

            if (!filteredSeries) {
                continue;
            }

            this$1.createHeatmapChart(
                filterSeriesByType(filteredSeries, [ HEATMAP ]),
                pane
            );
        }
    };

    HeatmapPlotArea.prototype.createHeatmapChart = function createHeatmapChart (series, pane) {
        var chart = new HeatmapChart(this, {
            series: series
        });

        this.appendChart(chart, pane);
    };

    HeatmapPlotArea.prototype.seriesPaneName = function seriesPaneName (series) {
        var options = this.options;
        var xAxisName = series.xAxis;
        var xAxisOptions = [].concat(options.xAxis);
        var xAxis = grep(xAxisOptions, function(a) { return a.name === xAxisName; })[0];
        var yAxisName = series.yAxis;
        var yAxisOptions = [].concat(options.yAxis);
        var yAxis = grep(yAxisOptions, function(a) { return a.name === yAxisName; })[0];
        var panes = options.panes || [ {} ];
        var defaultPaneName = panes[0].name || "default";
        var paneName = (xAxis || {}).pane || (yAxis || {}).pane || defaultPaneName;

        return paneName;
    };

    HeatmapPlotArea.prototype.seriesAxes = function seriesAxes (series) {
        var xAxis;
        var yAxis;

        var options = this.options;

        var xAxisOptions = [].concat(options.xAxis);
        var xAxisName = series.xAxis;
        if (xAxisName) {
            xAxis = xAxisOptions.find(function (axis) { return axis.name === xAxisName; });
        } else {
            xAxis = xAxisOptions[0];
        }

        var yAxisOptions = [].concat(options.yAxis);
        var yAxisName = series.yAxis;
        if (yAxisName) {
            yAxis = yAxisOptions.find(function (axis) { return axis.name === yAxisName; });
        } else {
            yAxis = yAxisOptions[0];
        }

        if (!xAxis) {
            throw new Error("Unable to locate X axis with name " + xAxisName);
        }

        if (!yAxis) {
            throw new Error("Unable to locate Y axis with name " + yAxisName);
        }

        return { xAxis: xAxis, yAxis: yAxis };
    };

    HeatmapPlotArea.prototype.createAxisLabels = function createAxisLabels () {
        var axes = this.axes;
        for (var i = 0; i < axes.length; i++) {
            axes[i].createLabels();
        }
    };

    HeatmapPlotArea.prototype.createXYAxis = function createXYAxis (options, vertical, axisIndex) {
        var axisName = options.name;
        var namedAxes = vertical ? this.namedYAxes : this.namedXAxes;
        var axisOptions = Object.assign({
            axisCrossingValue: 0
        }, options, {
            vertical: vertical,
            reverse: (vertical || this.chartService.rtl) ? !options.reverse : options.reverse,
            justified: false
        });
        var firstCategory = axisOptions.categories ? axisOptions.categories[0] : null;
        var typeSamples = [ axisOptions.min, axisOptions.max, firstCategory ];
        var series = this.series;

        for (var seriesIx = 0; seriesIx < series.length; seriesIx++) {
            var currentSeries = series[seriesIx];
            var seriesAxisName = currentSeries[vertical ? "yAxis" : "xAxis"];
            if ((seriesAxisName === axisOptions.name) || (axisIndex === 0 && !seriesAxisName)) {
                var firstPointValue = SeriesBinder.current.bindPoint(currentSeries, 0).valueFields;
                typeSamples.push(firstPointValue[vertical ? "y" : "x"]);

                break;
            }
        }

        var inferredDate;

        for (var i = 0; i < typeSamples.length; i++) {
            if (typeSamples[i] instanceof Date) {
                inferredDate = true;
                break;
            }
        }

        var axisType;
        if (equalsIgnoreCase(axisOptions.type, DATE) || (!axisOptions.type && inferredDate)) {
            axisType = DateCategoryAxis;
        } else {
            axisType = CategoryAxis;
        }

        var axis = new axisType(axisOptions, this.chartService);
        axis.axisIndex = axisIndex;

        if (axisName) {
            if (namedAxes[axisName]) {
                throw new Error(((vertical ? "Y" : "X") + " axis with name " + axisName + " is already defined"));
            }
            namedAxes[axisName] = axis;
        }

        this.appendAxis(axis);
        axis.indexCategories();

        return axis;
    };

    HeatmapPlotArea.prototype.createAxes = function createAxes (panes) {
        var this$1 = this;

        var options = this.options;
        var xAxesOptions = [].concat(options.xAxis);
        var xAxes = [];
        var yAxesOptions = [].concat(options.yAxis);
        var yAxes = [];

        for (var idx = 0; idx < xAxesOptions.length; idx++) {
            var axisPane = this$1.findPane(xAxesOptions[idx].pane);
            if (inArray(axisPane, panes)) {
                xAxes.push(this$1.createXYAxis(xAxesOptions[idx], false, idx));
            }
        }

        for (var idx$1 = 0; idx$1 < yAxesOptions.length; idx$1++) {
            var axisPane$1 = this$1.findPane(yAxesOptions[idx$1].pane);
            if (inArray(axisPane$1, panes)) {
                yAxes.push(this$1.createXYAxis(yAxesOptions[idx$1], true, idx$1));
            }
        }

        this.axisX = this.axisX || xAxes[0];
        this.axisY = this.axisY || yAxes[0];
    };

    HeatmapPlotArea.prototype.removeAxis = function removeAxis (axis) {
        var axisName = axis.options.name;

        PlotAreaBase.prototype.removeAxis.call(this, axis);

        if (axis.options.vertical) {
            delete this.namedYAxes[axisName];
        } else {
            delete this.namedXAxes[axisName];
        }

        if (axis === this.axisX) {
            delete this.axisX;
        }

        if (axis === this.axisY) {
            delete this.axisY;
        }
    };

    HeatmapPlotArea.prototype._dispatchEvent = function _dispatchEvent (chart, e, eventType) {
        var coords = chart._eventCoordinates(e);
        var point = new Point(coords.x, coords.y);
        var allAxes = this.axes;
        var length = allAxes.length;
        var xValues = [];
        var yValues = [];

        for (var i = 0; i < length; i++) {
            var axis = allAxes[i];
            var values = axis.options.vertical ? yValues : xValues;
            appendIfNotNull(values, axis.getCategory(point));
        }

        if (xValues.length > 0 && yValues.length > 0) {
            chart.trigger(eventType, {
                element: eventElement(e),
                originalEvent: e,
                x: singleItemOrArray(xValues),
                y: singleItemOrArray(yValues)
            });
        }
    };

    HeatmapPlotArea.prototype.updateAxisOptions = function updateAxisOptions$1 (axis, options) {
        var vertical = axis.options.vertical;
        var axes = this.groupAxes(this.panes);
        var index = (vertical ? axes.y : axes.x).indexOf(axis);

        updateAxisOptions(this.options, index, vertical, options);
        updateAxisOptions(this.originalOptions, index, vertical, options);
    };

    HeatmapPlotArea.prototype.crosshairOptions = function crosshairOptions (axis) {
        // Stack the crosshair above the series points.
        return Object.assign({}, axis.options.crosshair, { zIndex: 0 });
    };

    HeatmapPlotArea.prototype._pointsByVertical = function _pointsByVertical (basePoint, offset) {
        var this$1 = this;
        if ( offset === void 0 ) offset = 0;

        var normalizedOffset = this.axisX.options.reverse ? offset * -1 : offset;
        var axisXItems = this.axisX.children;
        var xIndex = this._getPointAxisXIndex(basePoint) + normalizedOffset;

        xIndex = cycleIndex(xIndex, axisXItems.length);
        var targetXValue = axisXItems[xIndex].value;

        var points = this
            .filterPoints(function (point) { return compareValues(point.pointData().x, targetXValue); })
            .sort(function (a, b) { return this$1._getPointAxisYIndex(a) - this$1._getPointAxisYIndex(b); });

        if (this.axisY.options.reverse) {
            return points.reverse();
        }

        return points;
    };

    HeatmapPlotArea.prototype._pointsByHorizontal = function _pointsByHorizontal (basePoint, offset) {
        var this$1 = this;
        if ( offset === void 0 ) offset = 0;

        var normalizedOffset = this.axisY.options.reverse ? offset * -1 : offset;
        var axisYItems = this.axisY.children;
        var yIndex = this._getPointAxisYIndex(basePoint) + normalizedOffset;

        yIndex = cycleIndex(yIndex, axisYItems.length);
        var targetYValue = axisYItems[yIndex].value;

        var points = this
            .filterPoints(function (point) { return compareValues(point.pointData().y, targetYValue); })
            .sort(function (a, b) { return this$1._getPointAxisXIndex(a) - this$1._getPointAxisXIndex(b); });

        if (this.axisX.options.reverse) {
            return points.reverse();
        }

        return points;
    };

    HeatmapPlotArea.prototype._getPointAxisXIndex = function _getPointAxisXIndex (point) {
        return this._getPointAxisIndex(this.axisX, point.pointData().x);
    };

    HeatmapPlotArea.prototype._getPointAxisYIndex = function _getPointAxisYIndex (point) {
        return this._getPointAxisIndex(this.axisY, point.pointData().y);
    };

    HeatmapPlotArea.prototype._getPointAxisIndex = function _getPointAxisIndex (axis, pointValue) {
        return axis.children.findIndex(function (axisItem) { return compareValues(pointValue, axisItem.value); });
    };

    return HeatmapPlotArea;
}(PlotAreaBase));

function compareValues(a, b) {
    if (a instanceof Date && b instanceof Date) {
        return dateEquals(a, b);
    }

    return a === b;
}

function updateAxisOptions(targetOptions, axisIndex, vertical, options) {
    var axisOptions = ([].concat(vertical ? targetOptions.yAxis : targetOptions.xAxis))[axisIndex];
    deepExtend(axisOptions, options);
}

setDefaultOptions(HeatmapPlotArea, {
    xAxis: {},
    yAxis: {}
});

deepExtend(HeatmapPlotArea.prototype, PlotAreaEventsMixin);

export default HeatmapPlotArea;
