/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import e from "prop-types";
import { validatePackage as C, classNames as m } from "@progress/kendo-react-common";
import { provideLocalizationService as E, registerForLocalization as S } from "@progress/kendo-react-intl";
import { labelsOptional as h, messages as v } from "./messages/index.mjs";
import { packageMetadata as z } from "./package-metadata.mjs";
const l = class l extends s.Component {
  constructor(t) {
    super(t), this.state = {
      focused: !1
    }, this.handleFocus = (a) => {
      this.setState({ focused: !0 });
    }, this.handleBlur = (a) => {
      this.setState({ focused: !1 });
    }, C(z);
  }
  /**
   * @hidden
   */
  render() {
    const { label: t, editorId: a, className: u, labelClassName: b, editorValue: r, editorPlaceholder: f, editorValid: g, editorDisabled: i, style: k, id: n, optional: N } = this.props, y = E(this), c = N ? y.toLanguageString(h, v[h]) : "", d = c && /* @__PURE__ */ s.createElement("span", { className: "k-label-optional" }, c), x = m(
      {
        "k-floating-label-container": !0,
        "k-focus": this.state.focused,
        "k-empty": !f && !r && r !== 0,
        "k-text-disabled": i,
        "k-rtl": this.props.dir === "rtl"
      },
      u
    ), p = m(
      {
        "k-label": !0,
        "k-text-error": g === !1,
        "k-text-disabled": i
      },
      b
    );
    return /* @__PURE__ */ s.createElement(
      "span",
      {
        id: this.props.id,
        className: x,
        onFocus: this.handleFocus,
        onBlur: this.handleBlur,
        style: k,
        dir: this.props.dir
      },
      this.props.children,
      t ? a ? /* @__PURE__ */ s.createElement("label", { id: n, htmlFor: a, className: p }, t, d) : /* @__PURE__ */ s.createElement("span", { id: n, className: p }, t, d) : null
    );
  }
};
l.propTypes = {
  label: e.string,
  editorId: e.string,
  editorValue: e.oneOfType([e.string, e.bool, e.number]),
  editorPlaceholder: e.string,
  editorValid: e.bool,
  editorDisabled: e.bool,
  id: e.string,
  style: e.object,
  className: e.string,
  labelClassName: e.string,
  optional: e.bool
};
let o = l;
S(o);
export {
  o as FloatingLabel
};
