/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { getNestedValue as v } from "../utils/index.mjs";
import { selectRow as g, messages as R } from "../messages/index.mjs";
import { useLocalization as T } from "@progress/kendo-react-intl";
import { useTableKeyboardNavigation as z } from "@progress/kendo-react-data-tools";
import { useId as L, classNames as V } from "@progress/kendo-react-common";
const P = (a) => {
  const {
    selectionChange: o,
    field: p,
    dataItem: k,
    id: b,
    className: f,
    colSpan: h,
    style: C,
    ariaColumnIndex: x,
    rowType: l,
    cells: N,
    render: n
  } = a, y = T(), I = e.useCallback(
    (c) => {
      o && o({ syntheticEvent: c });
    },
    [o]
  ), S = L(), s = v(p, k), w = z(b), E = V(
    "k-table-td",
    f
  ), r = {
    colSpan: h,
    style: C,
    className: E,
    "aria-colindex": x,
    role: "gridcell",
    ...w
  }, i = typeof s == "boolean" && s, d = /* @__PURE__ */ e.createElement("span", { key: 1, className: "k-checkbox-wrap" }, /* @__PURE__ */ e.createElement(
    "input",
    {
      id: S,
      tabIndex: -1,
      type: "checkbox",
      className: "k-checkbox k-checkbox-md k-rounded-md",
      "aria-label": y.toLanguageString(g, R[g]),
      "aria-checked": i,
      checked: i,
      onChange: I
    }
  )), m = l !== "groupHeader" ? /* @__PURE__ */ e.createElement(
    "td",
    {
      ...r
    },
    d
  ) : null, u = l || "data", t = N;
  if (t && t.select && t.select[u]) {
    const c = t.select[u];
    return /* @__PURE__ */ e.createElement(c, { ...a, tdProps: r }, d);
  }
  return n ? n.call(void 0, m, a) : m;
};
export {
  P as GridSelectionCell
};
