/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as l from "react";
import f from "prop-types";
import { provideIntlService as v, registerForIntl as w } from "@progress/kendo-react-intl";
import { InstanceObserver as g, DomEventsBuilder as I } from "@progress/kendo-charts";
import { validatePackage as D, shouldShowValidationUI as O, WatermarkOverlay as C, canUseDOM as y } from "@progress/kendo-react-common";
import { toggle as S, hasParent as E } from "./utils/index.mjs";
import { DomEventsBuilder as P } from "./events/dom-events-builder.mjs";
import { create as T } from "./events/chart-event-builder.mjs";
import { toDomEvent as k } from "./events/dom-event.mjs";
import { loadTheme as L } from "./theming/theme-service.mjs";
import m from "./store/store.mjs";
import { optionsReducer as x, observersReducer as U, themeReducer as R } from "./store/reducer.mjs";
import { SeriesTooltip as M } from "./tooltip/Series.mjs";
import { CrosshairTooltipContainer as W } from "./tooltip/CrosshairContainer.mjs";
import { ChartContext as F } from "./ChartContext.mjs";
import { packageMetadata as b } from "./package-metadata.mjs";
import { DrilldownEvent as z } from "./events/drilldown-event.mjs";
const d = class d extends l.Component {
  constructor(t) {
    super(t), this.chartInstance = null, this.surface = null, this._element = null, this.optionsStore = {}, this.optionsUnsubscriber = Function.prototype, this.themeStore = {}, this.themeUnsubscriber = Function.prototype, this.observersStore = {}, this.suppressTransitions = !1, this.showLicenseWatermark = !1, this.onRender = (e) => {
      this.chartInstance !== null && (this.surface = e.sender.surface, this.trigger("render", e));
    }, this.onDrilldown = (e) => {
      var a;
      const { series: s } = this.optionsStore.getState(), r = s.find((n) => n.name === e.series.name);
      if (!r.drilldownSeriesFactory)
        return !0;
      const i = "onDrilldown";
      if (this.props.hasOwnProperty(i)) {
        const n = { seriesName: r.name, drilldownValue: e.value }, h = [...((a = this.props.drilldownState) == null ? void 0 : a.steps) || [], n], c = this.props.getTarget(), p = {
          ...e,
          currentState: this.props.drilldownState,
          nextState: { steps: h }
        }, o = new z(p, c);
        this.props[i].call(void 0, o);
      }
      return !0;
    }, this.onLegendItemClick = (e) => {
      if (this.chartInstance !== null)
        if (this.props.onLegendItemClick)
          this.trigger("legendItemClick", e);
        else {
          const { series: s } = this.optionsStore.getState();
          if (!s)
            return;
          let r = {};
          const { seriesIndex: i, pointIndex: a } = e, n = s[i];
          if (a === void 0)
            r = Object.assign({}, n, { visible: S(n.visible) });
          else {
            const h = n.pointVisibility = n.pointVisibility || [];
            h[a] = S(h[a]), r = Object.assign({}, n);
          }
          this.optionsStore.dispatch({
            chartCollectionIdxKey: `series_${i}`,
            payload: r
          }), this.suppressTransitions = !0;
        }
    }, this.onWindowResize = () => {
      this.chartInstance !== null && this.chartInstance.resize();
    }, this.onChartMouseLeave = (e) => {
      const s = k(this, e);
      this.triggerDomEvent("onMouseLeave", s) ? e.preventDefault() : this.chartInstance !== null && this.chartInstance.hideElements();
    }, this.onChildMouseLeave = (e) => {
      const { syntheticEvent: s } = e;
      return this.chartInstance && !E(s.relatedTarget, this.element) && this.chartInstance.hideElements(), !1;
    }, D(b), this.showLicenseWatermark = O(b), this.optionsStore = m(x), this.observersStore = m(U), this.childrenObserver = new g(this, { onMouseLeave: "onChildMouseLeave" }), this.state = {
      optionsStore: this.optionsStore,
      observersStore: this.observersStore,
      childrenObserver: this.childrenObserver,
      drilldownState: { steps: [] }
    }, this.themeStore = m(R), this.chartObserver = new g(this, {
      render: "onRender",
      legendItemClick: "onLegendItemClick",
      drilldown: "onDrilldown"
    });
  }
  /**
   * @hidden
   */
  get element() {
    return this._element;
  }
  /**
   * @hidden
   */
  static getDerivedStateFromProps(t, e) {
    return {
      ...e,
      drilldownState: t.drilldownState || []
    };
  }
  /**
   * @hidden
   */
  componentDidMount() {
    var e;
    const t = ((e = this._element) == null ? void 0 : e.ownerDocument) || document;
    L(this.themeStore, this.instantiateCoreChart.bind(this), t), this.optionsUnsubscriber = this.optionsStore.subscribe(this.refresh.bind(this)), this.themeUnsubscriber = this.themeStore.subscribe(this.refresh.bind(this)), window.addEventListener("resize", this.onWindowResize);
  }
  /**
   * @hidden
   */
  componentWillUnmount() {
    this.optionsUnsubscriber(), this.themeUnsubscriber(), this.chartInstance !== null && (this.chartInstance.destroy(), this.chartInstance = null), window.removeEventListener("resize", this.onWindowResize);
  }
  /**
   * @hidden
   */
  componentDidUpdate(t) {
    const { dir: e, children: s, ...r } = this.props;
    if (this.chartInstance !== null) {
      const i = v(this), a = this.chartInstance.chartService, n = i.locale !== a._intlService.locale, h = Object.entries(t).filter((c) => c[0] !== "dir" && c[0] !== "children").some((c) => {
        const [p, o] = c;
        return !(r.hasOwnProperty(p) && r[p] === o);
      });
      n && (this.chartInstance.chartService._intlService = i, this.chartInstance.chartService.format._intlService = i, h || this.chartInstance.noTransitionsRedraw()), h && this.refresh(), t.dir !== e && this.chartInstance.setDirection(this.getDirection(e));
    }
  }
  /**
   * @hidden
   */
  render() {
    const { style: t = {}, className: e, wrapper: s, children: r } = this.props, i = Object.assign({}, t, { position: "relative" }), a = l.createElement(
      s,
      {
        className: e,
        style: i,
        key: "chartElement"
      },
      /* @__PURE__ */ l.createElement(
        "div",
        {
          onMouseLeave: this.onChartMouseLeave,
          ref: (n) => this._element = n,
          className: "k-chart-surface"
        },
        r
      ),
      /* @__PURE__ */ l.createElement(l.Fragment, null, this.showLicenseWatermark && /* @__PURE__ */ l.createElement(C, null))
    );
    return /* @__PURE__ */ l.createElement(F.Provider, { value: this.state }, /* @__PURE__ */ l.createElement(M, { key: "seriesTooltip" }), /* @__PURE__ */ l.createElement(W, { key: "crosshairTooltips" }), a);
  }
  /**
   * @hidden
   */
  getDirection(t) {
    return (t !== void 0 ? t : y && window.getComputedStyle(this.element).direction || "ltr") === "rtl";
  }
  /**
   * @hidden
   */
  getChartOptions() {
    const {
      renderAs: t,
      pannable: e,
      zoomable: s,
      paneDefaults: r,
      panes: i,
      transitions: a,
      seriesColors: n,
      seriesDefaults: h,
      axisDefaults: c,
      deriveOptionsFromParent: p
    } = this.props;
    let o = {};
    return t !== void 0 && (o.renderAs = t), e !== void 0 && (o.pannable = e), s !== void 0 && (o.zoomable = s), r !== void 0 && (o.paneDefaults = r), i !== void 0 && (o.panes = i), a !== void 0 && (o.transitions = a), n !== void 0 && (o.seriesColors = n), h !== void 0 && (o.seriesDefaults = h), c !== void 0 && (o.axisDefaults = c), o = Object.assign(o, this.optionsStore.getState()), p && (o = p(o)), o;
  }
  /**
   * @hidden
   */
  refresh() {
    if (this.chartInstance !== null) {
      const t = this.themeStore.getState(), e = this.getChartOptions(), s = e.transitions;
      this.suppressTransitions && (e.transitions = !1), this.props.onRefresh ? this.props.onRefresh.call(void 0, e, t, this.chartInstance) : this.chartInstance.setOptions(e, t), this.suppressTransitions && (e.transitions = s, this.suppressTransitions = !1);
    }
  }
  /**
   * @hidden
   */
  instantiateCoreChart() {
    const { dir: t, chartConstructor: e } = this.props, s = this.getChartOptions();
    this.chartInstance = new e(
      this.element,
      s,
      this.themeStore.getState(),
      {
        rtl: this.getDirection(t),
        intlService: v(this),
        observer: this.chartObserver,
        sender: this
      }
    );
  }
  /* Triggers public dom event handlers */
  /**
   * @hidden
   */
  trigger(t, e) {
    const s = this.props.getTarget(), r = T(t, e, s), i = "on" + t.charAt(0).toUpperCase() + t.slice(1), a = this.observersStore.getState();
    let n = !1;
    for (let h = 0; h < a.length; h++)
      a[h].trigger(t, e) && (n = !0);
    return n === !1 && r && this.props.hasOwnProperty(i) ? (this.props[i].call(void 0, r), r.isDefaultPrevented && r.isDefaultPrevented()) : n;
  }
  /* Used by (event)InstanceObserver to check the wrapper for supported events */
  /**
   * @hidden
   */
  requiresHandlers(t) {
    for (let e = 0; e < t.length; e++) {
      const s = t[e], r = "on" + s.charAt(0).toUpperCase() + s.slice(1);
      if (this.props.hasOwnProperty(r))
        return !0;
    }
    return !1;
  }
  /* Triggers private dom event handlers */
  /**
   * @hidden
   */
  triggerDomEvent(t, e) {
    const s = this.observersStore.getState();
    let r = !1;
    for (let i = 0; i < s.length; i++)
      s[i].trigger(t, e) && (r = !0);
    return r;
  }
};
d.propTypes = {
  dir: f.string,
  renderAs: f.oneOf(["svg", "canvas"])
}, d.defaultProps = {
  renderAs: "svg"
};
let u = d;
w(u);
I.register(P);
export {
  u as BaseChart
};
