import getScatterTrendlineData from './get-scatter-trendline-data';
import scatterValueGetter from './scatter-value-getter';
import calculatePolynomial from './calculate-polynomial';

function scatterPolynomialTrendline(context) {
    var options = context.options;
    var seriesValues = context.seriesValues;

    var data = getData({ seriesValues: seriesValues, options: options });
    if (data) {
        return Object.assign({}, options,

            {type: 'scatterLine',
            data: data});
    }

    return null;
}

function getData(ref) {
    var seriesValues = ref.seriesValues;
    var options = ref.options;

    var order = (options.trendline || {}).order;
    var valueGetter = scatterValueGetter(options.field);
    var ref$1 = calculatePolynomial(seriesValues(), valueGetter, order);
    var count = ref$1.count;
    var valueMapper = ref$1.valueMapper;
    var xMin = ref$1.xMin;
    var xMax = ref$1.xMax;
    var range = { xMin: xMin, xMax: xMax };

    if (count > 0) {
        // Polynomial trendline equation:
        // y = aN * x^N + ... + a2 * x^2 + a1 * x + a0
        return getScatterTrendlineData(valueMapper, range, options.trendline);
    }

    return null;
}

export default scatterPolynomialTrendline;
