/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
const u = "k-chart-shared-tooltip-marker", E = (l) => {
  const { categoryText: r, colorMarker: n, colspan: a, nameColumn: o, points: c } = l, m = (t, s) => /* @__PURE__ */ e.createElement("tr", { key: s }, n && /* @__PURE__ */ e.createElement("td", null, /* @__PURE__ */ e.createElement(
    "span",
    {
      className: u,
      style: { backgroundColor: t.series.color }
    }
  )), o && /* @__PURE__ */ e.createElement("td", null, t.series.name), /* @__PURE__ */ e.createElement("td", { dangerouslySetInnerHTML: { __html: t.formattedValue } }));
  return /* @__PURE__ */ e.createElement("table", null, /* @__PURE__ */ e.createElement("thead", null, /* @__PURE__ */ e.createElement("tr", null, /* @__PURE__ */ e.createElement("th", { colSpan: a }, r))), /* @__PURE__ */ e.createElement("tbody", null, c.map(m)));
};
export {
  E as SharedTooltipContent
};
