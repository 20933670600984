/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
function o({ onFocus: u, onBlur: l, onSyncFocus: i, onSyncBlur: a }) {
  const r = t.useRef(!1), c = t.useRef(0), s = t.useCallback(
    (e) => {
      clearTimeout(c.current), c.current = window.setTimeout(() => e());
    },
    [c]
  ), f = t.useCallback(
    (...e) => {
      clearTimeout(c.current), i && i.call(void 0, e[0]), !r.current && (r.current = !0, u && u.call(void 0, ...e));
    },
    [r, u, i]
  ), n = t.useCallback(
    (...e) => {
      a && a.call(void 0, e[0]), s(() => {
        r.current && l && (r.current = !1, l.call(void 0, ...e));
      });
    },
    [r, s, l, a]
  );
  return t.useEffect(() => () => {
    clearTimeout(c.current);
  }, []), {
    onFocus: f,
    onBlur: n
  };
}
export {
  o as useAsyncFocusBlur
};
