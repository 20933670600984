/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
const t = typeof window != "undefined" ? e.useLayoutEffect : e.useEffect;
export {
  t as useIsomorphicLayoutEffect
};
