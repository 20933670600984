/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import n from "prop-types";
import { register as a } from "@progress/kendo-ripple";
import { packageMetadata as l } from "./package-metadata.mjs";
import { validatePackage as c } from "@progress/kendo-react-common";
const s = class s extends o.PureComponent {
  constructor(e) {
    super(e), this._element = null, this.removeListeners = () => {
    }, this.registerListeners = () => {
      const t = this._element;
      this.removeListeners();
      const i = a(t, [
        { selector: ".k-button:not(li)" },
        { selector: ".k-list>.k-item,.k-list>.k-list-item", options: { global: !0 } },
        { selector: ".k-checkbox-label,.k-radio-label" },
        {
          selector: ".k-checkbox,.k-radio",
          options: {
            events: ["focusin", "animationend", "click"]
          }
        }
      ]);
      this.removeListeners = i;
    }, c(l);
  }
  /**
   * @hidden
   */
  componentDidMount() {
    this.registerListeners();
  }
  /**
   * @hidden
   */
  componentDidUpdate() {
    const { disabled: e } = this.props;
    e ? this.removeListeners() : this.registerListeners();
  }
  /**
   * @hidden
   */
  componentWillUnmount() {
    this.removeListeners();
  }
  /**
   * @hidden
   */
  render() {
    const e = this.props.className ? "k-ripple-container " + this.props.className : "k-ripple-container";
    return /* @__PURE__ */ o.createElement(
      "span",
      {
        className: e,
        ref: (t) => {
          this._element = t;
        }
      },
      this.props.children
    );
  }
};
s.propTypes = {
  disabled: n.bool
};
let r = s;
export {
  r as Ripple
};
