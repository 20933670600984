/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import { GridColumnMenuItem as S } from "./GridColumnMenuItem.mjs";
import { GridColumnMenuItemGroup as B } from "./GridColumnMenuItemGroup.mjs";
import { GridColumnMenuItemContent as N } from "./GridColumnMenuItemContent.mjs";
import { Input as D, Checkbox as m } from "@progress/kendo-react-inputs";
import { provideLocalizationService as b, registerForLocalization as y } from "@progress/kendo-react-intl";
import { searchPlaceholder as x, messages as h, filterTitle as g, filterCheckAll as F, filterSelectedItems as C, filterSubmitButton as I, filterClearButton as k } from "../messages/index.mjs";
import { filterBy as v } from "@progress/kendo-data-query";
import { clone as q, IconWrap as L } from "@progress/kendo-react-common";
import { Button as p } from "@progress/kendo-react-buttons";
import { getNestedValue as E } from "../utils/index.mjs";
import { searchIcon as O, xIcon as w, filterIcon as M } from "@progress/kendo-svg-icons";
const P = (f, o) => f.length !== o.length ? !1 : f.every((e, t) => e === o[t]), u = class u extends s.Component {
  constructor(o) {
    super(o), this.defaultFilter = () => this.props.filter ? q(this.props.filter) : { filters: [], logic: "and" }, this.isControlled = () => this.props.expanded !== void 0, this.parseData = (e, t) => {
      const n = this.props.column.field || "", r = e.map((a) => E(n, a));
      return t ? r.filter((a, i) => r.indexOf(a) === i) : r;
    }, this.getFilterIndex = () => {
      const e = this.props.column.field;
      return this.defaultFilter().filters.findIndex((r) => r.filters && r.filters.length > 0 && r.filters[0].field === e);
    }, this.onFilterExpand = () => {
      const e = this.isControlled(), t = !(e ? this.props.expanded : this.state.expanded);
      this.props.onExpandChange && this.props.onExpandChange(t), e || this.setState({
        expanded: t
      });
    }, this.handleSearchChange = (e) => {
      const t = this.props.searchBoxFilterOperator ? this.props.searchBoxFilterOperator : "startswith", n = {
        logic: "and",
        filters: [
          { field: this.props.column.field, operator: t, value: e.target.value, ignoreCase: !0 }
        ]
      };
      this.setState({
        value: e.target.value,
        data: this.parseData(v(this.props.data || [], n), this.props.uniqueData)
      });
    }, this.handleClear = () => {
      const e = this.props.searchBoxFilterOperator ? this.props.searchBoxFilterOperator : "startswith", t = {
        logic: "and",
        filters: [
          { field: this.props.column.field, operator: e, value: "", ignoreCase: !0 }
        ]
      };
      this.setState({
        value: "",
        data: this.parseData(v(this.props.data || [], t), this.props.uniqueData)
      });
    }, this.clear = (e) => {
      if (e.preventDefault(), !this.props.onFilterChange)
        return;
      const t = this.state.currentFilter || null;
      t !== null && t.filters.length > 0 ? (this.compositeFilterIndex >= 0 && t.filters.splice(this.compositeFilterIndex, 1), this.props.onFilterChange(t, e)) : this.props.onFilterChange(null, e), this.props.onCloseMenu && this.props.onCloseMenu();
    }, this.submit = (e) => {
      if (e.preventDefault(), !this.props.onFilterChange)
        return;
      const t = this.state.currentFilter || null;
      this.props.onFilterChange(t, e), this.props.onCloseMenu && this.props.onCloseMenu();
    }, this.handleCheckBoxChange = (e, t) => {
      const n = this.props.column.field || "", r = { ...this.state.currentFilter }, a = [...this.state.currentFilter.filters];
      let i = [];
      if (this.compositeFilterIndex !== -1 && r.filters[this.compositeFilterIndex].filters && t !== "all" && (i = r.filters[this.compositeFilterIndex].filters), e.value && t === "all")
        this.state.data.forEach((l) => {
          i.push({ field: n, operator: "eq", value: l });
        });
      else if (e.value)
        i.push({ field: n, operator: "eq", value: t });
      else if (this.state.currentFilter) {
        const l = i.findIndex((c) => c.value === t);
        i.splice(l, 1);
      }
      r.logic = "and", this.compositeFilterIndex !== -1 ? a[this.compositeFilterIndex] = {
        logic: "or",
        filters: i
      } : a.push({
        logic: "or",
        filters: i
      }), (!e.value && t === "all" || i.length === 0) && a.splice(this.compositeFilterIndex, 1), r.filters = a, this.setState({ currentFilter: r });
    }, this.isAllSelected = () => {
      let e = !1;
      if (this.state.currentFilter) {
        const t = [...this.state.currentFilter.filters];
        return this.compositeFilterIndex === -1 ? !1 : (e = this.state.data.every((n) => this.compositeFilterIndex !== -1 && t[this.compositeFilterIndex].filters ? t[this.compositeFilterIndex].filters.findIndex((a) => a.value === n) >= 0 : !1), e);
      }
      return e;
    }, this.compositeFilterIndex = this.getFilterIndex(), this.state = {
      expanded: o.expanded || !1,
      value: "",
      data: this.parseData(this.props.data, this.props.uniqueData) || [],
      dataFromProps: this.parseData(this.props.data, !1) || [],
      currentFilter: this.defaultFilter()
    };
  }
  /**
   * @hidden
   */
  componentDidUpdate() {
    const o = this.props.column.field || "", e = this.props.data.map((t) => E(o, t));
    P(e, this.state.dataFromProps) || this.setState({
      data: e,
      dataFromProps: e
    });
  }
  /**
   * @hidden
   */
  render() {
    const { column: o } = this.props;
    if (!o || !o.field)
      return /* @__PURE__ */ s.createElement("div", null);
    const e = b(this), t = this.isControlled() ? this.props.expanded : this.state.expanded, n = [];
    if (this.state.currentFilter) {
      const i = [...this.state.currentFilter.filters];
      this.compositeFilterIndex = i.findIndex((l) => l.filters && l.filters.length > 0 ? l.filters[0].field === o.field : !1), this.compositeFilterIndex !== -1 && i[this.compositeFilterIndex].filters.length > 0 && i[this.compositeFilterIndex].filters.forEach((l) => {
        l.field === this.props.column.field && n.push(l.value);
      });
    }
    const r = this.props.searchBox ? /* @__PURE__ */ s.createElement(this.props.searchBox, { value: this.state.value, onChange: this.handleSearchChange }) : /* @__PURE__ */ s.createElement("div", { className: "k-searchbox k-textbox k-input k-input-md k-input-solid" }, /* @__PURE__ */ s.createElement(L, { className: "k-input-icon", name: "search", icon: O }), /* @__PURE__ */ s.createElement(
      D,
      {
        className: "k-input-inner",
        type: "text",
        placeholder: e.toLanguageString(x, h[x]),
        value: this.state.value,
        onChange: (i) => this.handleSearchChange(i.nativeEvent)
      }
    ), /* @__PURE__ */ s.createElement(
      p,
      {
        type: "button",
        rounded: null,
        className: "k-input-button",
        onClick: this.handleClear,
        icon: "x",
        svgIcon: w
      }
    )), a = n.filter((i, l) => n.indexOf(i) === l);
    return /* @__PURE__ */ s.createElement(B, null, /* @__PURE__ */ s.createElement(
      S,
      {
        title: e.toLanguageString(g, h[g]),
        iconClass: "k-i-filter",
        svgIcon: M,
        onClick: this.onFilterExpand
      }
    ), /* @__PURE__ */ s.createElement(N, { show: !!t }, /* @__PURE__ */ s.createElement(
      "form",
      {
        className: "k-filter-menu",
        onSubmit: this.submit,
        onReset: this.clear
      },
      /* @__PURE__ */ s.createElement("div", { className: "k-filter-menu-container" }, r, /* @__PURE__ */ s.createElement("ul", { className: "k-reset k-multicheck-wrap" }, /* @__PURE__ */ s.createElement("li", { className: "k-item k-check-all-wrap" }, /* @__PURE__ */ s.createElement(
        m,
        {
          label: e.toLanguageString(F, h[F]),
          onChange: (i) => this.handleCheckBoxChange(i, "all"),
          checked: this.isAllSelected()
        }
      )), this.state.data.map((i, l) => /* @__PURE__ */ s.createElement("li", { className: "k-item", key: l }, /* @__PURE__ */ s.createElement(
        m,
        {
          label: String(i),
          onChange: (c) => this.handleCheckBoxChange(c, i),
          checked: a.includes(i)
        }
      )))), /* @__PURE__ */ s.createElement("div", { className: "k-filter-selected-items" }, a.length + " " + e.toLanguageString(C, h[C])), /* @__PURE__ */ s.createElement("div", { className: "k-actions k-hstack k-justify-content-stretch" }, /* @__PURE__ */ s.createElement(
        p,
        {
          themeColor: "primary",
          type: "submit"
        },
        e.toLanguageString(I, h[I])
      ), /* @__PURE__ */ s.createElement(
        p,
        {
          className: "k-button",
          type: "reset"
        },
        e.toLanguageString(k, h[k])
      )))
    )));
  }
};
u.defaultProps = {
  uniqueData: !0
};
let d = u;
y(d);
export {
  d as GridColumnMenuCheckboxFilter
};
