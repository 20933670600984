/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as a from "react";
import { isCompositeFilterDescriptor as L } from "@progress/kendo-data-query";
import { GridColumnMenuItem as B } from "./GridColumnMenuItem.mjs";
import { GridColumnMenuItemGroup as T } from "./GridColumnMenuItemGroup.mjs";
import { GridColumnMenuItemContent as z } from "./GridColumnMenuItemContent.mjs";
import { provideLocalizationService as N, registerForLocalization as H } from "@progress/kendo-react-intl";
import { getFilterType as D, getDefaultOperator as R, IsUnaryFilter as c, operatorMap as g, booleanFilterValues as K, filterLogicList as j, defaultHideSecondFilter as q } from "../filterCommon.mjs";
import { filterTitle as b, messages as C, filterSubmitButton as M, filterClearButton as P } from "../messages/index.mjs";
import { GridColumnMenuFilterUI as A } from "./GridColumnMenuFilterUI.mjs";
import { Button as k } from "@progress/kendo-react-buttons";
import { filterIcon as J } from "@progress/kendo-svg-icons";
const F = (h) => h || { filters: [], logic: "and" }, u = (h, i) => F(i).filters.filter((t) => L(t) ? t.filters && t.filters.length && t.filters.length <= 2 && !t.filters.find((r) => L(r) || r.field !== h) : !1)[0] || null, G = class G extends a.Component {
  constructor(i) {
    super(i), this.removeGroup = (e, t) => {
      const r = [...t.filters], s = r.findIndex((l) => l === e);
      return s > -1 && r.splice(s, 1), {
        ...t,
        filters: r
      };
    }, this.insertGroup = (e, t) => ({
      ...t,
      filters: [
        e,
        ...t.filters
      ]
    }), this.isControlled = () => this.props.expanded !== void 0, this.onFilterExpand = () => {
      const e = this.isControlled(), t = !(e ? this.props.expanded : this.state.expanded);
      this.props.onExpandChange && this.props.onExpandChange(t), e || this.setState({
        expanded: t
      });
    }, this.firstFilterChange = (e) => {
      this.filterChange(0, e);
    }, this.secondFilterChange = (e) => {
      this.filterChange(1, e);
    }, this.filterChange = (e, t) => {
      const r = this.state.filterGroup.filters.map((s, l) => l === e ? {
        ...s,
        value: t.value,
        operator: t.operator
      } : s);
      this.setState({
        filterGroup: {
          ...this.state.filterGroup,
          filters: r
        }
      });
    }, this.logicChange = (e) => {
      this.setState({
        filterGroup: {
          ...this.state.filterGroup,
          logic: e.target.value.operator
        }
      });
    }, this.clear = (e) => {
      if (e.preventDefault(), !this.props.onFilterChange)
        return;
      const t = this.props.column.field, r = F(this.props.filter), s = u(t, this.props.filter), l = r.filters.filter((n) => n !== s);
      l.length ? this.props.onFilterChange({ ...r, filters: l }, e) : this.props.onFilterChange(null, e), this.props.onCloseMenu && this.props.onCloseMenu();
    }, this.currentFilterGroup = () => ({
      ...this.state.filterGroup,
      filters: this.state.filterGroup.filters.filter((e) => e.value !== void 0 && e.value !== null && e.value !== "" || e.value === null && e.operator)
    }), this.submit = (e) => {
      if (e.preventDefault(), !this.props.onFilterChange)
        return;
      const t = this.props.column.field, r = F(this.props.filter), s = u(t, this.props.filter), l = this.currentFilterGroup();
      let n = null;
      if (s && l.filters.length > 0) {
        const f = r.filters.map((p) => p === s ? l : p);
        n = {
          ...r,
          filters: f
        };
      } else if (l.filters.length === 0) {
        const f = r.filters.filter((p) => p !== s);
        f.length && (n = {
          ...r,
          filters: f
        });
      } else
        n = {
          ...r,
          filters: [...r.filters, l]
        };
      this.props.onFilterChange(n, e), this.props.onCloseMenu && this.props.onCloseMenu();
    };
    let o;
    if (i.column && i.column.field) {
      const e = D(i.column.filter), t = R(i.filterOperators, e);
      o = u(i.column.field, i.filter), o = o ? {
        ...o,
        filters: o.filters.map((r) => ({ ...r }))
      } : {
        logic: "and",
        filters: [
          { field: i.column.field, operator: t, value: c(t) ? null : void 0 },
          { field: i.column.field, operator: t, value: c(t) ? null : void 0 }
        ]
      }, o.filters.filter((r) => r.field === i.column.field).length === 1 && o.filters.splice(1, 0, { field: i.column.field, operator: t });
    }
    this.state = {
      expanded: i.expanded || !1,
      filterGroup: o
    };
  }
  /**
   * @hidden
   */
  render() {
    const { column: i, filterUI: o, hideSecondFilter: e, filterOperators: t } = this.props;
    if (!i || !i.field)
      return /* @__PURE__ */ a.createElement("div", null);
    const r = D(i.filter), s = e !== void 0 ? e : q[r], l = N(this), n = this.state.filterGroup.filters, f = g(t[r], l), p = g(K, l), O = {
      field: i.field,
      value: n[0].value,
      operator: n[0].operator,
      operators: f,
      booleanValues: p,
      onChange: this.firstFilterChange,
      filterType: r
    }, U = {
      field: i.field,
      value: n[1].value,
      operator: n[1].operator,
      operators: f,
      booleanValues: p,
      onChange: this.secondFilterChange,
      filterType: r
    }, x = this.state.filterGroup.logic, E = g(j, l), d = {
      value: E.find((m) => m.operator === (x === null ? "" : x)),
      onChange: this.logicChange,
      data: E
    }, S = o, I = {
      firstFilterProps: O,
      secondFilterProps: U,
      logicData: d.data,
      logicValue: d.value,
      onLogicChange: d.onChange,
      hideSecondFilter: s
    }, V = this.currentFilterGroup().filters.length !== 0 || c(n[0].operator) || c(n[1].operator), w = this.isControlled() ? this.props.expanded : this.state.expanded, y = this.props.alwaysExpand;
    return /* @__PURE__ */ a.createElement(T, null, !y && /* @__PURE__ */ a.createElement(
      B,
      {
        title: l.toLanguageString(b, C[b]),
        iconClass: "k-i-filter",
        svgIcon: J,
        onClick: this.onFilterExpand
      }
    ), /* @__PURE__ */ a.createElement(z, { show: y || !!w }, /* @__PURE__ */ a.createElement(
      "form",
      {
        className: "k-filter-menu",
        onSubmit: this.submit,
        onReset: this.clear,
        onKeyDown: (m) => m.stopPropagation()
      },
      /* @__PURE__ */ a.createElement("div", { className: "k-filter-menu-container" }, S ? /* @__PURE__ */ a.createElement(S, { ...I }) : /* @__PURE__ */ a.createElement(A, { ...I }), /* @__PURE__ */ a.createElement("div", { className: "k-actions k-actions-stretched" }, /* @__PURE__ */ a.createElement(
        k,
        {
          themeColor: "primary",
          disabled: !V
        },
        l.toLanguageString(M, C[M])
      ), /* @__PURE__ */ a.createElement(
        k,
        {
          type: "reset"
        },
        l.toLanguageString(P, C[P])
      )))
    )));
  }
};
G.active = (i, o) => !!u(i, o);
let v = G;
H(v);
export {
  v as GridColumnMenuFilter,
  u as filterGroupByField,
  F as rootFilterOrDefault
};
