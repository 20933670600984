/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import { ConfigurationComponent as e } from "../base/ConfigurationComponent.mjs";
const i = (t) => /* @__PURE__ */ o.createElement(e, { ...t, _chartKey: "tooltip" });
export {
  i as ChartSeriesItemTooltip
};
