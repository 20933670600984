/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { GridColumn as l } from "../GridColumn.mjs";
import { tableKeyboardNavigationTools as x } from "@progress/kendo-react-data-tools";
function c(e, r) {
  const a = (e || "").split(".");
  let i = r;
  return a.forEach((o) => {
    i = i ? i[o] : void 0;
  }), i;
}
function g(e, r, a, i, o, h, n = 0) {
  let d = n;
  for (let t = 0; t < r.length; t++) {
    if (!o || r[t].value === void 0 || r[t].items === void 0) {
      e[e.length] = {
        dataIndex: i.index,
        dataItem: r[t],
        rowType: "data",
        level: n,
        expanded: h === void 0 || c(h, r[t])
      }, i.index++;
      continue;
    }
    d = Math.max(d, n + 1);
    const f = h === void 0 || c(h, r[t]) === void 0 || c(h, r[t]);
    e[e.length] = {
      dataIndex: -1,
      dataItem: r[t],
      level: n,
      rowType: "groupHeader",
      expanded: f
    }, f && (d = Math.max(
      g(e, r[t].items, a, i, o, h, n + 1),
      d
    )), (a === "always" || f && a === "visible") && (e[e.length] = {
      dataIndex: -1,
      dataItem: r[t],
      rowType: "groupFooter",
      level: n,
      expanded: f
    });
  }
  return d;
}
function p(e, r, a, i = 0) {
  let o = [];
  if (!e)
    return [];
  e && e.length === void 0 && (e = [e]), e.forEach((n, d) => {
    n = n.props ? n.props : n;
    const t = r[d] || null, f = p(n.children, t && t.children || [], a, i + 1);
    o.push(
      Object.assign(
        { depth: i },
        l.defaultProps,
        f.length ? { cell: () => null, filterCell: () => null } : {},
        t ? { width: t.width, orderIndex: t.orderIndex } : {},
        n,
        {
          id: n.id ? n.id : x.generateNavigatableId(`${a.prevId++}`, a.idPrefix, "column"),
          declarationIndex: o.length,
          children: f,
          rowSpan: 0,
          colSpan: 0,
          isAccessible: !0,
          left: t && Math.floor(t.left),
          right: t && Math.floor(t.right)
        }
      )
    );
  });
  const h = (n, d) => n.orderIndex === d.orderIndex ? n.declarationIndex - d.declarationIndex : (n.orderIndex || 0) - (d.orderIndex || 0);
  if (o.sort(h), i === 0) {
    let n = [];
    const d = (t, f) => t.forEach((s) => {
      s.parentIndex = f, d(s.children, n.push(s) - 1);
    });
    return d(o, -1), n;
  }
  return o;
}
function y(e, r, a, i) {
  let o = [];
  Array.isArray(e) ? o = e : e && (o = e.data);
  const h = [];
  if (o.length > 0) {
    let n = o[0];
    if (r)
      for (let t = 0; t < r.length; t++)
        n = n.items && n.items[0];
    Object.getOwnPropertyNames(n).forEach((t) => {
      t !== a && h.push(Object.assign(
        {
          id: x.generateNavigatableId(`${i.prevId++}`, i.idPrefix, "column"),
          declarationIndex: -1,
          parentIndex: -1,
          depth: 0,
          colSpan: 0,
          rowSpan: 0,
          index: 0,
          left: 0,
          right: 0,
          rightBorder: !1,
          children: [],
          ariaColumnIndex: 0,
          isAccessible: !0
        },
        l.defaultProps,
        { field: t }
      ));
    });
  }
  return h;
}
const I = (e, r) => {
  let a = e[r.parentIndex];
  for (; a; ) {
    if (a.footerCell)
      return !0;
    a = e[a.parentIndex];
  }
  return !1;
}, b = (e) => e.filter((r) => I(e, r) ? !1 : !!r.footerCell || !(r.children && r.children.length > 0)), S = (e) => e.width !== void 0 ? Math.floor(parseFloat(e.width.toString())) + "px" : void 0, w = (e) => (e.sort((r, a) => r.declarationIndex - a.declarationIndex), e.map((r) => {
  const {
    declarationIndex: a,
    parentIndex: i,
    depth: o,
    colSpan: h,
    rowSpan: n,
    index: d,
    kFirst: t,
    children: f,
    ...s
  } = r;
  return f.length ? {
    children: w(f),
    ...s
  } : s;
})), F = typeof window != "undefined" && /Firefox/.test(window.navigator.userAgent), M = 17895697, m = (e, r) => {
  if (!(!e && !r))
    return r ? e ? {
      ...e,
      ...r,
      select: {
        ...e.select || {},
        ...r.select || {}
      },
      hierarchy: {
        ...e.hierarchy || {},
        ...r.hierarchy || {}
      },
      group: {
        ...e.group || {},
        ...r.group || {}
      },
      edit: {
        ...e.edit || {},
        ...r.edit || {}
      }
    } : r : e;
};
export {
  y as autoGenerateColumns,
  F as firefox,
  M as firefoxMaxHeight,
  g as flatData,
  b as footerColumns,
  S as getColumnWidth,
  c as getNestedValue,
  p as readColumns,
  m as resolveCells,
  w as sanitizeColumns
};
