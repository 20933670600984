/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const a = (e) => Math.max(e, 0);
class f {
  constructor(t) {
    this.offset = t;
  }
}
class d {
  constructor(t) {
    this.skip = t;
  }
}
class w {
  constructor(t, s) {
    this.onScrollAction = t, this.onPageAction = s, this.direction = "vertical", this.firstLoaded = 0, this.lastLoaded = 0, this.lastScrollTop = 0, this.take = 0, this.total = 0, this.rowHeightService = null, this.bottomOffset = 0, this.topOffset = 0;
  }
  create(t, s, h, l, i = 0, n = 0, r = "vertical") {
    this.rowHeightService = t, this.firstLoaded = s, this.lastLoaded = s + h, this.take = h, this.total = l, this.lastScrollTop = 0, this.topOffset = i, this.bottomOffset = n, this.direction = r;
    const o = this.rowsForHeight(i), c = a(s - o);
    this.onScrollAction(new f(this.rowOffset(c))), this.onPageAction(new d(c));
  }
  onScroll({ scrollLeft: t, scrollTop: s, offsetHeight: h, offsetWidth: l }) {
    const i = this.direction === "vertical" ? s : t, n = this.direction === "vertical" ? h : l;
    if (this.lastScrollTop === i || !this.rowHeightService)
      return;
    const r = this.lastScrollTop >= i;
    this.lastScrollTop = i;
    const o = this.rowHeightService.index(a(i - this.topOffset)), c = this.rowHeightService.index(a(i + n - this.bottomOffset));
    if (!r && c >= this.lastLoaded && this.lastLoaded < this.total && (this.firstLoaded = o, this.onScrollAction(new f(this.rowOffset(o))), this.lastLoaded = Math.min(this.firstLoaded + this.take, this.total), this.onPageAction(new d(this.firstLoaded))), r && o <= this.firstLoaded) {
      const S = Math.floor(this.take * 0.3);
      this.firstLoaded = a(o - S), this.onScrollAction(new f(this.rowOffset(this.firstLoaded))), this.lastLoaded = Math.min(this.firstLoaded + this.take, this.total), this.onPageAction(new d(this.firstLoaded));
    }
  }
  rowOffset(t) {
    return this.rowHeightService ? this.rowHeightService.offset(t) + this.topOffset : 0;
  }
  rowsForHeight(t) {
    return this.rowHeightService ? Math.ceil(t / this.rowHeightService.height(0)) : 0;
  }
}
export {
  d as PageAction,
  f as ScrollAction,
  w as ScrollerService
};
