/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as c from "react";
const o = (e) => {
  const t = c.useCallback(
    () => e.current && e.current.element ? e.current.element : e.current,
    [e]
  );
  return c.useCallback(() => {
    const n = t();
    return n && n.ownerDocument || document;
  }, [t]);
};
export {
  o as useDocument
};
