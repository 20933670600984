/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import i from "prop-types";
import { registerForLocalization as c } from "@progress/kendo-react-intl";
import { classNames as p } from "@progress/kendo-react-common";
import { CalendarHeaderTitle as h } from "./CalendarHeaderTitle.mjs";
import { MIN_DATE as l, MAX_DATE as m } from "../../utils.mjs";
const t = class t extends s.Component {
  constructor() {
    super(...arguments), this.getTitle = () => {
      if (!this.props.currentDate)
        return "";
      const e = this.rangeLength - 1, a = this.props.service.title(this.props.currentDate), r = this.props.service.addToDate(this.props.currentDate, e);
      return e < 1 || !this.props.service.isInRange(r, this.min, this.max) ? a : `${a} - ${this.props.service.title(r)}`;
    }, this.handleTitleClick = (e) => {
      this.canMoveUp && this.props.bus.moveUp(this.props.activeView, e);
    };
  }
  get min() {
    return this.props.min !== void 0 ? this.props.min : t.defaultProps.min;
  }
  get max() {
    return this.props.max !== void 0 ? this.props.max : t.defaultProps.max;
  }
  get rangeLength() {
    return this.props.rangeLength !== void 0 ? this.props.rangeLength : t.defaultProps.rangeLength;
  }
  get canMoveUp() {
    return this.props.bus.canMoveUp(this.props.activeView);
  }
  render() {
    const e = this.getTitle(), a = p(
      "k-calendar-title"
    ), r = {
      children: e,
      value: e,
      view: this.props.activeView,
      className: a,
      onClick: this.handleTitleClick,
      disabled: !this.canMoveUp
    }, o = this.props.headerTitle ? /* @__PURE__ */ s.createElement(this.props.headerTitle, { ...r }, e) : /* @__PURE__ */ s.createElement(h, { ...r }, e);
    return /* @__PURE__ */ s.createElement(
      "div",
      {
        className: p("k-calendar-header", {
          "k-vstack": this.props.verticalView,
          "k-hstack": !this.props.verticalView
        })
      },
      o,
      /* @__PURE__ */ s.createElement("span", { className: "k-spacer" }),
      /* @__PURE__ */ s.createElement("span", { className: "k-calendar-nav k-hstack" }, this.props.commands)
    );
  }
};
t.propTypes = {
  activeView: i.number.isRequired,
  currentDate: i.instanceOf(Date).isRequired,
  max: i.instanceOf(Date).isRequired,
  min: i.instanceOf(Date).isRequired,
  rangeLength: i.number
}, t.defaultProps = {
  rangeLength: 1,
  min: l,
  max: m
};
let n = t;
c(n);
export {
  n as Header
};
