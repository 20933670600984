/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import n from "prop-types";
import { classNames as o } from "@progress/kendo-react-common";
const a = "ActionSheetContent", e = (t) => /* @__PURE__ */ s.createElement(
  "div",
  {
    className: o(
      "k-actionsheet-content",
      t.className
    )
  },
  t.children
);
e.propTypes = {
  className: n.string
};
e.displayName = a;
e.propTypes = {
  children: n.any
};
export {
  e as ActionSheetContent,
  a as contentDisplayName
};
