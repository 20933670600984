/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import { noop as t } from "../../noop.mjs";
const n = o.createContext([
  void 0,
  t
]), r = o.createContext([
  void 0,
  t
]), c = o.createContext([[], t, t]), x = o.createContext([[], t, t]);
export {
  n as DragContext,
  c as DragsContext,
  r as DropContext,
  x as DropsContext
};
