/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as m from "react";
import s from "prop-types";
import v from "./VirtualScroll.mjs";
import { Navigation as y } from "./Navigation.mjs";
import { scrollToItem as S, areSame as g } from "./utils.mjs";
const a = class a {
  constructor(n) {
    this.wrapper = null, this.list = null, this.vs = new v(), this.navigation = new y(), this.handleItemClick = (i, e) => {
      const t = this.initState();
      t.syntheticEvent = e, e.stopPropagation(), this.component.handleItemSelect(i, t), this.togglePopup(t), this.applyState(t);
    }, this.handleFocus = (i) => {
      if (!this.component.state.focused) {
        const e = this.initState();
        e.data.focused = !0, e.events.push({ type: "onFocus" }), e.syntheticEvent = i, this.applyState(e);
      }
    }, this.filterChanged = (i, e) => {
      const { textField: t, filterable: o } = this.component.props;
      o && e.events.push({
        type: "onFilterChange",
        filter: {
          field: t,
          operator: "contains",
          ignoreCase: !0,
          value: i
        }
      });
    }, this.togglePopup = (i) => {
      const e = this.component.props, t = e.opened !== void 0 ? e.opened : this.component.state.opened;
      e.opened === void 0 && (i.data.opened = !t), t ? i.events.push({ type: "onClose" }) : (i.events.push({ type: "onOpen" }), this.calculatePopupWidth());
    }, this.pageChange = (i, e) => {
      const t = this.initState();
      t.syntheticEvent = e, this.triggerOnPageChange(t, i.skip, i.take), this.applyState(t);
    }, this.scrollToVirtualItem = (i, e) => {
      const t = this.vs;
      if (t.enabled = !1, i.skip === 0)
        t.reset();
      else if (i.skip + i.pageSize === i.total)
        t.scrollToEnd();
      else {
        let o = t.translate;
        o === 0 && (t.calcScrollElementHeight(), o = t.itemHeight * i.skip, t.translateTo(o - t.itemHeight)), t.container && (t.container.scrollTop = o), this.scrollToItem(e, !0);
      }
      window.setTimeout(() => t.enabled = !0, 10);
    }, this.scrollPopupByPageSize = (i) => {
      var l, r, h, c, u, d;
      const e = this.vs, t = (r = (l = this.list) == null ? void 0 : l.parentElement) == null ? void 0 : r.scrollTop, o = e.enabled && e.itemHeight ? e.itemHeight : this.list ? this.list.children[0].offsetHeight : 0, p = (c = (h = this.list) == null ? void 0 : h.parentElement) == null ? void 0 : c.offsetHeight;
      t !== void 0 && p !== void 0 && ((d = (u = this.list) == null ? void 0 : u.parentElement) == null || d.scroll({ top: t + i * Math.floor(p / o) * o }));
    }, this.renderScrollElement = () => {
      const i = this.vs;
      return i.enabled && /* @__PURE__ */ m.createElement(
        "div",
        {
          ref: (e) => i.scrollElement = e,
          key: "scrollElementKey"
        }
      );
    }, this.resetGroupStickyHeader = (i, e) => {
      i !== e.state.group && e.setState({ ...e.state, group: i });
    }, this.listBoxId = n.props.id + "list", this.guid = n.props.id, this.component = n, this.vs.PageChange = this.pageChange;
  }
  didMount() {
    const n = this.component.props, i = n.popupSettings || {}, e = n.style || {}, t = i.width;
    let o = n.opened === !0;
    t === void 0 && this.calculatePopupWidth(), n.dir === void 0 && e.direction === void 0 && (this.calculateDir(), o = !0), o && this.component.forceUpdate();
  }
  calculateDir() {
    const n = this.component.element;
    n && n.ownerDocument && n.ownerDocument.defaultView && (this.dirCalculated = n.ownerDocument.defaultView.getComputedStyle(n).direction || void 0);
  }
  calculatePopupWidth() {
    this.wrapper && (this.popupWidth = this.wrapper.offsetWidth + "px");
  }
  scrollToItem(n, i, e) {
    const t = this.list || this.vs.list;
    if (!t && !e && setTimeout(() => {
      this.scrollToItem(n, i, !0);
    }, 10), t && n >= 0) {
      const o = this.vs, p = o.container || t.parentNode, l = i !== void 0 ? i : o.enabled;
      S(p, t, n, o.translate, l);
    }
  }
  initState() {
    return {
      data: {},
      events: [],
      syntheticEvent: void 0
    };
  }
  applyState(n) {
    Object.keys(n.data).length > 0 && this.component.setState(n.data);
    const i = {
      syntheticEvent: n.syntheticEvent,
      nativeEvent: n.syntheticEvent ? n.syntheticEvent.nativeEvent : void 0,
      target: this.component,
      value: this.component.value
    };
    n.events.forEach((e) => {
      const t = e.type;
      delete e.type;
      const o = t && this.component.props[t];
      o && o.call(void 0, {
        ...i,
        ...e
      });
    });
  }
  triggerOnPageChange(n, i, e) {
    const t = this.component.props.virtual;
    if (t) {
      const o = Math.min(Math.max(0, i), Math.max(0, t.total - e));
      o !== t.skip && n.events.push({
        type: "onPageChange",
        page: { skip: o, take: e }
      });
    }
  }
  triggerPageChangeCornerItems(n, i) {
    const e = this.component.props, { data: t = [], dataItemKey: o, virtual: p } = e, l = e.opened !== void 0 ? e.opened : this.component.state.opened;
    n && p && this.vs.enabled && (p.skip > 0 && g(n, t[0], o) ? this.triggerOnPageChange(i, p.skip - 1, p.pageSize) : !l && p.skip + p.pageSize < p.total && g(n, t[t.length - 1], o) && this.triggerOnPageChange(i, p.skip + 1, p.pageSize));
  }
  getPopupSettings() {
    return Object.assign({}, a.defaultProps.popupSettings, this.component.props.popupSettings);
  }
  getGroupedDataModernMode(n, i) {
    let e = [];
    return n.forEach((t, o) => {
      n[o - 1] && t[i] !== n[o - 1][i] && e.push({ [i]: t[i] }), e.push(n[o]);
    }), e;
  }
};
a.basicPropTypes = {
  opened: s.bool,
  disabled: s.bool,
  dir: s.string,
  tabIndex: s.number,
  accessKey: s.string,
  data: s.array,
  textField: s.string,
  className: s.string,
  label: s.string,
  loading: s.bool,
  popupSettings: s.shape({
    animate: s.oneOfType([s.bool, s.shape({
      openDuration: s.number,
      closeDuration: s.number
    })]),
    popupClass: s.string,
    className: s.string,
    appendTo: s.any,
    width: s.oneOfType([s.string, s.number]),
    height: s.oneOfType([s.string, s.number])
  }),
  onOpen: s.func,
  onClose: s.func,
  onFocus: s.func,
  onBlur: s.func,
  onChange: s.func,
  itemRender: s.func,
  listNoDataRender: s.func,
  focusedItemIndex: s.func,
  header: s.node,
  footer: s.node
}, a.propTypes = {
  ...a.basicPropTypes,
  value: s.any,
  defaultValue: s.any,
  filterable: s.bool,
  filter: s.string,
  virtual: s.shape({
    pageSize: s.number.isRequired,
    skip: s.number.isRequired,
    total: s.number.isRequired
  }),
  onFilterChange: s.func,
  onPageChange: s.func
}, a.defaultProps = {
  popupSettings: {
    height: "200px"
  },
  required: !1,
  validityStyles: !0
};
let f = a;
export {
  f as default
};
