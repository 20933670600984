/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as n from "react";
import { getNestedValue as s } from "../utils/index.mjs";
import { useTableKeyboardNavigation as h } from "@progress/kendo-react-data-tools";
import { useLocalization as y } from "@progress/kendo-react-intl";
import { Keys as I, IconWrap as C } from "@progress/kendo-react-common";
import { messages as x, detailCollapse as b, detailExpand as E } from "../messages/index.mjs";
import { minusIcon as k, plusIcon as v } from "@progress/kendo-svg-icons";
const z = (e) => {
  let i = null, l = null, r = null;
  const t = s(e.field, e.dataItem), c = h(e.id), u = y(), f = n.useCallback(
    (a) => {
      a.isDefaultPrevented() || a.keyCode === I.enter && e.onChange && (a.preventDefault(), e.onChange({
        dataItem: e.dataItem,
        dataIndex: e.dataIndex,
        syntheticEvent: a,
        field: e.field,
        value: !t
      }));
    },
    [e.dataItem, e.dataIndex, e.field, e.onChange, t]
  );
  if (e.rowType === "groupFooter")
    l = {
      className: "k-table-td k-hierarchy-cell",
      role: "gridcell",
      ...c
    }, i = /* @__PURE__ */ n.createElement(
      "td",
      {
        ...l
      }
    );
  else if (e.rowType !== "groupHeader") {
    const a = t ? b : E, g = u.toLanguageString(a, x[a]);
    l = {
      className: "k-table-td k-hierarchy-cell",
      onKeyDown: f,
      "aria-expanded": !!t,
      role: "gridcell",
      "aria-colindex": e.ariaColumnIndex,
      ...c
    }, r = /* @__PURE__ */ n.createElement(
      "a",
      {
        onClick: (m) => {
          m.preventDefault(), e.onChange && e.onChange({
            dataItem: e.dataItem,
            dataIndex: e.dataIndex,
            syntheticEvent: m,
            field: e.field,
            value: !t
          });
        },
        "aria-label": g,
        href: "#",
        tabIndex: -1
      },
      /* @__PURE__ */ n.createElement(C, { name: t ? "minus" : "plus", icon: t ? k : v })
    ), i = /* @__PURE__ */ n.createElement(
      "td",
      {
        ...l
      },
      r
    );
  }
  const o = e.rowType || "data", d = e.cells;
  if (d && d.hierarchy && d.hierarchy[o]) {
    const a = d.hierarchy[o];
    return /* @__PURE__ */ n.createElement(a, { ...e, tdProps: l }, r);
  }
  return e.render ? e.render.call(void 0, i, e) : i;
};
export {
  z as GridHierarchyCell
};
