/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { GlobalizationContext as o } from "../globalization/GlobalizationContext.mjs";
const e = () => t.useContext(o).intl;
export {
  e as useInternationalization
};
