/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import { FloatingLabel as a } from "@progress/kendo-react-labels";
const c = (e) => {
  const [t, n] = o.useState(!1), r = () => {
    e.current && n(!!e.current.text);
  };
  return o.useEffect(r), {
    editorValue: t
  };
}, l = (e) => {
  const t = c(e.dateInput);
  return /* @__PURE__ */ o.createElement(
    a,
    {
      ...e,
      ...t
    }
  );
};
export {
  l as PickerFloatingLabel,
  c as usePickerFloatingLabel
};
