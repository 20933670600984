/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as i from "react";
import { getNestedValue as r } from "../utils/index.mjs";
import { useInternationalization as s } from "@progress/kendo-react-intl";
import { useTableKeyboardNavigation as C } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE as g } from "../constants/index.mjs";
import { classNames as I } from "@progress/kendo-react-common";
const S = (e) => {
  let n = null;
  const u = s(), f = C(e.id), o = i.useCallback((l) => {
    e.onContextMenu && e.onContextMenu.call(void 0, l, e.dataItem, e.field);
  }, [e.onContextMenu, e.dataItem, e.field]);
  let t = null, c = null;
  if (e.rowType === "groupFooter")
    t = {
      onContextMenu: o,
      className: e.className
    }, n = /* @__PURE__ */ i.createElement("td", { ...t });
  else if (e.rowType !== "groupHeader") {
    if (e.field !== void 0) {
      const a = r(e.field, e.dataItem);
      a != null && (c = e.format ? u.format(e.format, a) : a.toString());
    }
    const l = I(
      "k-table-td",
      e.className,
      { "k-selected": e.isSelected }
    );
    t = {
      onContextMenu: o,
      colSpan: e.colSpan,
      style: e.style,
      className: l,
      role: "gridcell",
      "aria-colindex": e.ariaColumnIndex,
      "aria-selected": e.isSelected,
      [g]: e.columnIndex,
      ...f
    }, n = /* @__PURE__ */ i.createElement("td", { ...t }, c);
  }
  const m = e.rowType || "data", d = e.cells;
  if (d && d[m]) {
    const l = d[m];
    return /* @__PURE__ */ i.createElement(l, { ...e, tdProps: t }, c);
  }
  return e.render ? e.render.call(void 0, n, e) : n;
};
export {
  S as GridCell
};
