/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { IconWrap as s, toIconName as m } from "@progress/kendo-react-common";
class l extends t.Component {
  /**
   * @hidden
   */
  render() {
    const { title: o, iconClass: e, svgIcon: n, selected: c } = this.props;
    return /* @__PURE__ */ t.createElement("div", { onClick: this.props.onClick, className: `k-columnmenu-item ${c ? "k-selected" : ""}` }, (e || n) && /* @__PURE__ */ t.createElement(s, { name: e && m(e), icon: n }), o);
  }
}
export {
  l as GridColumnMenuItem
};
