/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
var r = /* @__PURE__ */ ((e) => (e[e.Toggle = 0] = "Toggle", e[e.Next = 1] = "Next", e[e.Previous = 2] = "Previous", e))(r || {});
export {
  r as NavigationAction
};
