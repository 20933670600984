/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as v from "react";
import s from "prop-types";
import { Keys as m, validatePackage as I, setScrollbarWidth as b, classNames as N, createPropsContext as E, withIdHOC as P, withPropsContext as A } from "@progress/kendo-react-common";
import { cloneDate as f, isEqualDate as D, getDate as c } from "@progress/kendo-date-math";
import { provideIntlService as L, registerForIntl as M } from "@progress/kendo-react-intl";
import { packageMetadata as O } from "../../package-metadata.mjs";
import { ViewList as B } from "./ViewList.mjs";
import { Navigation as T } from "./Navigation.mjs";
import { CalendarViewEnum as r } from "../models/CalendarViewEnum.mjs";
import { getToday as S, dateInRange as w, viewInRange as y, MIN_DATE as K, MAX_DATE as F, isInRange as C } from "../../utils.mjs";
import { BusViewService as _ } from "../services/BusViewService.mjs";
import { DOMService as $ } from "../services/DOMService.mjs";
import { NavigationService as R } from "../services/NavigationService.mjs";
import { ScrollSyncService as U } from "../services/ScrollSyncService.mjs";
const V = (u) => u ? u.virtualization : null, x = (u = d.defaultProps.min, o = d.defaultProps.max, i, t) => t !== void 0 ? t !== null && C(c(t), u, o) ? t : null : i !== null && C(c(i), u, o) ? i : null, l = class l extends v.Component {
  constructor(o) {
    super(o), this.scrollSyncService = null, this.focusedDate = null, this.Navigation = null, this.calendarViewList = null, this._element = null, this.intl = null, this.service = null, this.isActive = !1, this.didNavigationChange = !1, this.focus = () => {
      this._element && this._element.focus();
    }, this.shouldScroll = () => this.didNavigationChange, this.handleScroll = (t) => {
      this.scrollSyncService && this.scrollSyncService.sync(
        V(this.Navigation),
        V(this.calendarViewList),
        t
      );
    }, this.handleNavigationChange = (t) => {
      if (this.props.disabled)
        return;
      this.didNavigationChange = !0;
      const e = f(t.value);
      this.setState({ focusedDate: e });
    }, this.handleViewChange = ({ view: t }) => {
      this.scrollSyncService && this.scrollSyncService.configure(t), this.setState({ activeView: t });
    }, this.handleDateChange = (t) => {
      const e = f(t.value), a = f(t.value), p = this.bus.canMoveDown(this.state.activeView);
      if (this.props.disabled)
        return;
      if (p)
        if (t.isTodayClick)
          this.bus.moveToBottom(this.state.activeView);
        else {
          this.bus.moveDown(this.state.activeView, t.syntheticEvent), this.setState({ focusedDate: a });
          return;
        }
      this.setState({ value: e, focusedDate: a }), this.valueDuringOnChange = e;
      const { onChange: h } = this.props;
      if (h) {
        const n = {
          syntheticEvent: t.syntheticEvent,
          nativeEvent: t.nativeEvent,
          value: e,
          target: this
        };
        h.call(void 0, n);
      }
      this.valueDuringOnChange = void 0;
    }, this.handleFocus = (t) => {
      if (this.isActive = !0, !this.calendarViewList)
        return;
      this.calendarViewList.focusActiveDate();
      const { onFocus: e } = this.props;
      e && e.call(void 0, t);
    }, this.handleBlur = (t) => {
      if (this.isActive = !1, !this.calendarViewList)
        return;
      this.calendarViewList.blurActiveDate();
      const { onBlur: e } = this.props;
      e && e.call(void 0, t);
    }, this.handleKeyDown = (t) => {
      const { keyCode: a, ctrlKey: p, metaKey: h } = t;
      if (!(!this.focusedDate || !this.service)) {
        if (a === 84 && this.setState({ focusedDate: S() }), (p || h) && (a === m.left || a === m.right)) {
          if (this.props.disabled)
            return;
          this.didNavigationChange = !0, this.focusedDate.getDate() === 31 && this.focusedDate.setDate(30);
          const n = a === m.left ? this.focusedDate.getMonth() - 1 : this.focusedDate.getMonth() + 1, g = new Date(this.focusedDate.setMonth(n)), k = f(g);
          this.setState({ focusedDate: k });
        }
        if (a === m.enter) {
          if (this.value !== null && D(this.focusedDate, this.value)) {
            const g = w(this.focusedDate, this.min, this.max);
            V(this.calendarViewList).scrollToIndex(this.service.skip(g, this.min));
          }
          const n = {
            syntheticEvent: t,
            nativeEvent: t.nativeEvent,
            value: this.focusedDate,
            target: this
          };
          this.handleDateChange(n);
        } else {
          const n = w(
            this.navigation.move(
              this.focusedDate,
              this.navigation.action(t),
              this.state.activeView,
              this.service,
              t
            ),
            this.min,
            this.max
          );
          if (D(this.focusedDate, n))
            return;
          this.setState({ focusedDate: n });
        }
        t.preventDefault();
      }
    }, this.handleMouseDown = (t) => {
      t.preventDefault();
    }, this.handleClick = (t) => {
      this._element && this._element.focus({ preventScroll: !0 });
    }, I(O);
    const i = x(
      this.min,
      this.max,
      this.props.defaultValue || l.defaultProps.defaultValue,
      this.props.value
    );
    this.state = {
      value: i,
      activeView: y(
        r[o.defaultActiveView],
        this.bottomView,
        this.topView
      ),
      focusedDate: w(
        o.focusedDate || i || S(),
        this.min,
        this.max
      )
    }, this.dom = new $(), this.bus = new _(this.handleViewChange), this.navigation = new R(this.bus), this.oldValue = i;
  }
  get cellUID() {
    return this.props.id + "-cell-uid";
  }
  get id() {
    return this.props.id + "-id";
  }
  /**
   * Gets the wrapping element of the Calendar.
   */
  get element() {
    return this._element;
  }
  /**
   * Gets the value of the Calendar.
   */
  get value() {
    return this.valueDuringOnChange !== void 0 ? this.valueDuringOnChange : this.props.value !== void 0 ? this.props.value : this.state.value;
  }
  get min() {
    return c(this.props.min !== void 0 ? this.props.min : l.defaultProps.min);
  }
  get max() {
    return c(this.props.max !== void 0 ? this.props.max : l.defaultProps.max);
  }
  get bottomView() {
    return r[this.props.bottomView !== void 0 ? this.props.bottomView : l.defaultProps.bottomView];
  }
  get topView() {
    return r[this.props.topView !== void 0 ? this.props.topView : l.defaultProps.topView];
  }
  /**
   * @hidden
   */
  componentDidMount() {
    Promise.resolve().then(() => {
      b(), this._element && (this.dom.calculateHeights(this._element), this.scrollSyncService = new U(this.dom), this.scrollSyncService.configure(this.state.activeView), this.forceUpdate());
    });
  }
  /**
   * @hidden
   */
  componentDidUpdate(o, i) {
    b(), i.activeView !== this.state.activeView && this.scrollSyncService && this.scrollSyncService.configure(this.state.activeView), this.calendarViewList && (this.isActive ? this.calendarViewList.focusActiveDate : this.calendarViewList.blurActiveDate)(), this.didNavigationChange = !1, this.isActive && (this.oldValue = this.value);
  }
  /**
   * @hidden
   */
  render() {
    this.props._ref && this.props._ref(this);
    const o = this.value !== null && this.oldValue !== null ? !D(this.value, this.oldValue) : this.value !== this.oldValue, i = y(
      this.state.activeView,
      r[this.props.bottomView !== void 0 ? this.props.bottomView : l.defaultProps.bottomView],
      r[this.props.topView !== void 0 ? this.props.topView : l.defaultProps.topView]
    ), t = x(this.min, this.max, this.value, this.value), e = t ? c(t) : null;
    this.focusedDate = c(w(
      o && t !== null ? t : this.state.focusedDate,
      this.min,
      this.max
    )), this.intl = L(this), this.bus.configure(this.bottomView, this.topView), this.service = this.bus.service(i, this.intl);
    const { smoothScroll: a = Number.parseFloat(v.version) < 18 } = this.props, p = N(
      "k-widget k-calendar k-calendar-infinite",
      {
        "k-disabled": this.props.disabled,
        "k-week-number": this.props.weekNumber,
        "k-calendar-lg": this.props.mobileMode
      },
      this.props.className
    ), h = [this.props.navigation && /* @__PURE__ */ v.createElement(
      T,
      {
        key: 0,
        ref: (n) => {
          this.Navigation = n;
        },
        activeView: this.state.activeView,
        focusedDate: this.focusedDate,
        min: this.min,
        max: this.max,
        onScroll: this.handleScroll,
        onChange: this.handleNavigationChange,
        service: this.service,
        dom: this.dom,
        navigationItem: this.props.navigationItem,
        tabIndex: this.props.tabIndex
      }
    ), /* @__PURE__ */ v.createElement(
      B,
      {
        key: 1,
        ref: (n) => {
          this.calendarViewList = n;
        },
        activeView: this.state.activeView,
        focusedDate: this.focusedDate,
        min: this.min,
        max: this.max,
        bus: this.bus,
        shouldScroll: this.shouldScroll,
        onScroll: this.handleScroll,
        service: this.service,
        cell: this.props.cell,
        weekCell: this.props.weekCell,
        dom: this.dom,
        smoothScroll: a,
        showWeekNumbers: this.props.weekNumber,
        onChange: this.handleDateChange,
        value: e,
        cellUID: this.cellUID,
        headerTitle: this.props.headerTitle,
        tabIndex: this.props.tabIndex
      }
    )];
    return /* @__PURE__ */ v.createElement(
      "div",
      {
        ref: (n) => {
          this._element = n;
        },
        className: p,
        id: this.props.id || this.id,
        "aria-labelledby": this.props.ariaLabelledBy,
        "aria-describedby": this.props.ariaDescribedBy,
        "aria-disabled": this.props.disabled,
        tabIndex: this.props.disabled ? void 0 : this.props.tabIndex || 0,
        onFocus: this.handleFocus,
        onBlur: this.handleBlur,
        onKeyDown: this.handleKeyDown,
        onMouseDown: this.handleMouseDown,
        onClick: this.handleClick
      },
      h
    );
  }
};
l.displayName = "Calendar", l.propTypes = {
  className: s.string,
  defaultActiveView: s.oneOf(["month", "year", "decade", "century"]),
  defaultValue: s.instanceOf(Date),
  disabled: s.bool,
  focusedDate: s.instanceOf(Date),
  id: s.string,
  ariaLabelledBy: s.string,
  ariaDescribedBy: s.string,
  max: s.instanceOf(Date),
  min: s.instanceOf(Date),
  navigation: s.bool,
  smoothScroll: s.bool,
  onBlur: s.func,
  onChange: s.func,
  onFocus: s.func,
  tabIndex: s.number,
  value: s.instanceOf(Date),
  weekNumber: s.bool,
  topView: (o, i, t) => {
    const e = o[i], a = o.bottomView;
    return e && a && r[e] < r[a] ? new Error(
      `Invalid prop + ${i} suplied to ${t}.
                    ${i} can not be smaller than bottomView.
                    `
    ) : null;
  },
  bottomView: (o, i, t) => {
    const e = o[i], a = o.topView;
    return e && a && r[e] > r[a] ? new Error(
      `Invalid prop + ${i} suplied to ${t}.
                    ${i} can not be bigger than topView.
                    `
    ) : null;
  }
}, l.defaultProps = {
  disabled: !1,
  min: K,
  max: F,
  navigation: !0,
  defaultActiveView: "month",
  defaultValue: null,
  topView: "century",
  bottomView: "month"
};
let d = l;
const Y = E(), z = P(A(Y, d));
z.displayName = "KendoReactCalendar";
M(d);
export {
  z as Calendar,
  Y as CalendarPropsContext,
  d as CalendarWithoutContext
};
