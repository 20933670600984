/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { createPortal as r } from "react-dom";
import { canUseDOM as a, IconWrap as l } from "@progress/kendo-react-common";
import { cancelIcon as i, plusIcon as o } from "@progress/kendo-svg-icons";
class u extends e.PureComponent {
  constructor(t) {
    super(t), this.elementRef = e.createRef(), this.state = {
      visible: !1,
      top: 0,
      left: 0,
      innerText: "",
      status: "k-i-cancel"
    }, this.hiddenElementRef = e.createRef();
  }
  get element() {
    return this.elementRef.current;
  }
  render() {
    const t = this.hiddenElementRef.current, { status: n } = this.state, s = this.state.visible && a && r(
      /* @__PURE__ */ e.createElement(
        "div",
        {
          ref: this.elementRef,
          className: "k-header k-drag-clue",
          style: {
            display: "block",
            position: "absolute",
            zIndex: 2e4,
            padding: "8px 12px",
            top: this.state.top + "px",
            left: this.state.left + "px"
          }
        },
        /* @__PURE__ */ e.createElement(l, { className: "k-drag-status", name: n, icon: n === "k-i-cancel" ? i : o }),
        this.state.innerText
      ),
      t && t.ownerDocument ? t.ownerDocument.body : document.body
    );
    return /* @__PURE__ */ e.createElement(e.Fragment, null, s, /* @__PURE__ */ e.createElement("div", { ref: this.hiddenElementRef, style: { display: "none" } }));
  }
}
export {
  u as DragClue
};
