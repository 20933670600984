import { Class } from '../../../common';
import { EncodingResult } from '../encoding-result';
import { DataModeInstances } from '../data-modes/data-mode-instances';
import { extend } from '../../utils';
import { toBitsString } from '../../utils';
import { getVersion } from '../encoding';

var BYTE = "byte";

export var Utf8Encoder = (function (Class) {
    function Utf8Encoder() {
        Class.call(this);

        this.initProperties();

        this.mode = DataModeInstances[this.encodingMode];
    }

    if ( Class ) Utf8Encoder.__proto__ = Class;
    Utf8Encoder.prototype = Object.create( Class && Class.prototype );
    Utf8Encoder.prototype.constructor = Utf8Encoder;

    Utf8Encoder.prototype.initProperties = function initProperties () {
        extend(this, {
            encodingMode: BYTE,
            utfBOM: "111011111011101110111111",
            initialModeCountStringLength: 20,
            ranges: [128, 2048, 65536, 2097152, 67108864]
        });
    };

    Utf8Encoder.prototype.getEncodingResult = function getEncodingResult (inputString, errorCorrectionLevel) {
        var data = this.encode(inputString),
            dataCodewordsCount = this.getDataCodewordsCount(data),
            version = getVersion(dataCodewordsCount, errorCorrectionLevel),
            dataString = this.mode.getModeCountString(data.length / 8, version) + data;

        return new EncodingResult(dataString, version);
    };

    Utf8Encoder.prototype.getDataCodewordsCount = function getDataCodewordsCount (data) {
        var dataLength = data.length,
            dataCodewordsCount = Math.ceil((this.initialModeCountStringLength + dataLength) / 8);

        return dataCodewordsCount;
    };

    Utf8Encoder.prototype.encode = function encode (str) {
        var this$1 = this;

        var result = this.utfBOM;

        for (var i = 0; i < str.length; i++) {
            result += this$1.encodeCharacter(str.charCodeAt(i));
        }

        return result;
    };

    Utf8Encoder.prototype.encodeCharacter = function encodeCharacter (code) {
        var bytesCount = this.getBytesCount(code),
            bc = bytesCount - 1,
            result = "";

        if (bytesCount === 1) {
            result = toBitsString(code, 8);
        } else {
            var significantOnes = 8 - bytesCount;

            for (var i = 0; i < bc; i++) {
                result = toBitsString(code >> (i * 6) & 63 | 128, 8) + result;
            }

            result = ((code >> bc * 6) | ((255 >> significantOnes) << significantOnes)).toString(2) + result;
        }

        return result;
    };

    Utf8Encoder.prototype.getBytesCount = function getBytesCount (code) {
        var ranges = this.ranges;

        for (var i = 0; i < ranges.length; i++) {
            if (code < ranges[i]) {
                return i + 1;
            }
        }
    };

    return Utf8Encoder;
}(Class));
