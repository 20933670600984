/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as a from "react";
import { classNames as n } from "@progress/kendo-react-common";
class o extends a.Component {
  constructor() {
    super(...arguments), this.handleClick = (e) => {
      const { onClick: t, value: r } = this.props;
      t && t.call(void 0, r, e);
    };
  }
  /**
   * @return
   * Returns a `<li />` element with a `<span />` inside. The text inside represents the [`value`]({% slug api_dateinputs_calendarnavigationitemprops %}#toc-value) of the `navigationItem`.
   */
  render() {
    const { isRangeStart: e, value: t, text: r, ...s } = this.props;
    return /* @__PURE__ */ a.createElement("li", { ...s, onClick: this.handleClick }, /* @__PURE__ */ a.createElement("span", { className: n({ "k-calendar-navigation-marker": e }) }, this.props.children));
  }
}
export {
  o as CalendarNavigationItem
};
