import {
    geometry as geom,
    drawing as draw
} from '@progress/kendo-drawing';

import {
    Class,
    defined,
    addClass,
    setDefaultOptions,
    deepExtend,
    getSpacing,
    isObject,
    defaultErrorHandler
} from '../common';

import {
    Box,
    TextBox
} from '../core';

import { Encodings } from './encodings/main';
import { surfaceSize } from './surface-size';

var DEFAULT_BARCODE_WIDTH = 300;
var DEFAULT_BARCODE_HEIGHT = 100;

var Barcode = (function (Class) {
    function Barcode(element, options, errorHandler) {
        if ( errorHandler === void 0 ) errorHandler = defaultErrorHandler;

        Class.call(this);

        this.options = deepExtend({}, this.options, options);
        this.element = element;
        this.onError = errorHandler;

        this._initElement();
        this._initSurface();
        this._setOptions(options);

        if (options && defined(options.value)) {
            this.redraw();
        }
    }

    if ( Class ) Barcode.__proto__ = Class;
    Barcode.prototype = Object.create( Class && Class.prototype );
    Barcode.prototype.constructor = Barcode;

    Barcode.prototype.destroy = function destroy () {
        this._destroySurface();
    };

    Barcode.prototype._initElement = function _initElement () {
        addClass(this.element, "k-barcode");
    };

    Barcode.prototype._initSurface = function _initSurface () {
        var ref = this;
        var options = ref.options;
        var surface = ref.surface;

        if (!surface || surface.options.type !== options.renderAs) {
            this._destroySurface();
            this._initSurfaceElement();
            this.surface = this._createSurface();
        }
    };

    Barcode.prototype._createSurface = function _createSurface () {
        return draw.Surface.create(this.surfaceElement, {
            type: this.options.renderAs
        });
    };

    Barcode.prototype._destroySurface = function _destroySurface () {
        if (this.surface) {
            this.surface.destroy();
            this.surface = null;
            this._destroySurfaceElement();
        }
    };

    Barcode.prototype._initSurfaceElement = function _initSurfaceElement () {
        if (!this.surfaceElement) {
            this.surfaceElement = document.createElement('div');
            this.surfaceElement.style.position = 'relative';
            this.element.appendChild(this.surfaceElement);
        }
    };

    Barcode.prototype._destroySurfaceElement = function _destroySurfaceElement () {
        if (this.surfaceElement && this.surfaceElement.parentNode) {
            this.surfaceElement.parentNode.removeChild(this.surfaceElement);
            this.surfaceElement = null;
        }
    };

    Barcode.prototype.setOptions = function setOptions (options) {
        this._setOptions(options);
        this._initSurface();
        this.redraw();
    };

    Barcode.prototype.redraw = function redraw () {
        var size = this._getSize();

        this.surface.clear();

        this.surface.setSize({
            width: size.width,
            height: size.height
        });

        this.createVisual();

        this.surface.draw(this.visual);
    };

    Barcode.prototype.getSize = function getSize () {
        return {
            width: this.element.offsetWidth,
            height: this.element.offsetHeight
        };
    };

    Barcode.prototype._resize = function _resize () {
        this.redraw();
    };

    Barcode.prototype.createVisual = function createVisual () {
        this.visual = this._render();
    };

    Barcode.prototype._render = function _render () {
        var options = this.options;
        var value = options.value;
        var textOptions = options.text;
        var textMargin = getSpacing(textOptions.margin);
        var size = this._getSize();
        var border = options.border || {};
        var encoding = this.encoding;
        var contentBox = new Box(0, 0, size.width, size.height).unpad(border.width).unpad(options.padding);
        var barHeight = contentBox.height();
        var encodedValue;
        var textToDisplay;
        var textHeight;
        var visual = new draw.Group();

        this.contentBox = contentBox;
        visual.append(this._getBackground(size));

        if (textOptions.visible) {
            textHeight = draw.util.measureText(value, { font: textOptions.font }).height;
            barHeight -= textHeight + textMargin.top + textMargin.bottom;
        }

        try {
            encodedValue = encoding.encode(value, contentBox.width(), barHeight);
        } catch (error) {
            this.onError(error);
            return visual;
        }

        if (textOptions.visible) {
            textToDisplay = value;

            if (options.checksum && defined(encoding.checksum)) {
                textToDisplay += " " + encoding.checksum;
            }

            visual.append(this._getText(textToDisplay));
        }

        this.barHeight = barHeight;
        this._bandsGroup = this._getBands(encodedValue.pattern, encodedValue.baseUnit);
        visual.append(this._bandsGroup);

        return visual;
    };

    Barcode.prototype.exportVisual = function exportVisual () {
        return this._render();
    };

    Barcode.prototype._getSize = function _getSize () {
        var element = this.element;
        var elementSize = surfaceSize(element, this.options.renderAs);
        var size = new geom.Size(DEFAULT_BARCODE_WIDTH, DEFAULT_BARCODE_HEIGHT);

        if (elementSize.width > 0) {
            size.width = elementSize.width;
        }

        if (elementSize.height > 0) {
            size.height = elementSize.height;
        }

        if (this.options.width) {
            size.width = this.options.width;
        }

        if (this.options.height) {
            size.height = this.options.height;
        }

        return size;
    };

    Barcode.prototype.value = function value (value$1) {
        if (!defined(value$1)) {
            return this.options.value;
        }

        this.options.value = String(value$1);
        this.redraw();
    };

    Barcode.prototype._getBands = function _getBands (pattern, baseUnit) {
        var this$1 = this;

        var contentBox = this.contentBox;
        var offsetX = contentBox.x1;
        var stepX;
        var patternItem;
        var group = new draw.Group();

        for (var i = 0; i < pattern.length; i++) {
            patternItem = isObject(pattern[i]) ? pattern[i] : {
                width: pattern[i],
                y1: 0,
                y2: this$1.barHeight
            };

            stepX = patternItem.width * baseUnit;

            if (i % 2) {
                var rect = geom.Rect.fromPoints(
                    new geom.Point(offsetX, patternItem.y1 + contentBox.y1),
                    new geom.Point(offsetX + stepX, patternItem.y2 + contentBox.y1)
                );

                var path = draw.Path.fromRect(rect, {
                    fill: {
                        color: this$1.options.color
                    },
                    stroke: null
                });

                group.append(path);
            }

            offsetX += stepX;
        }

        return group;
    };

    Barcode.prototype._getBackground = function _getBackground (size) {
        var options = this.options;
        var border = options.border || {};
        var box = new Box(0, 0, size.width, size.height).unpad(border.width / 2);
        var path = draw.Path.fromRect(box.toRect(), {
            fill: {
                color: options.background
            },
            stroke: {
                color: border.width ? border.color : "",
                width: border.width,
                dashType: border.dashType
            }
        });

        return path;
    };

    Barcode.prototype._getText = function _getText (value) {
        var textOptions = this.options.text;
        var text = this._textbox = new TextBox(value, {
            font: textOptions.font,
            color: textOptions.color,
            align: "center",
            vAlign: "bottom",
            margin: textOptions.margin
        });

        text.reflow(this.contentBox);
        text.renderVisual();

        return text.visual;
    };

    Barcode.prototype._setOptions = function _setOptions (options) {
        this.type = (options.type || this.options.type).toLowerCase();

        if (this.type === "upca") {
            this.type = "ean13";
            options.value = '0' + options.value;
        }

        if (this.type === "upce") {
            this.type = "ean8";
            options.value = '0' + options.value;
        }

        if (!Encodings[this.type]) {
            throw new Error(("Encoding '" + (this.type) + "' is not supported."));
        }

        this.encoding = new Encodings[this.type]();

        this.options = deepExtend({}, this.options, options);
    };

    return Barcode;
}(Class));

setDefaultOptions(Barcode, {
    name: "Barcode",
    renderAs: "svg",
    value: "",
    type: "code39",
    checksum: false,
    width: 0,
    height: 0,
    color: "black",
    background: "white",
    text: {
        visible: true,
        font: "16px Consolas, Monaco, Sans Mono, monospace, sans-serif",
        color: "black",
        margin: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    },
    border: {
        width: 0,
        dashType: "solid",
        color: "black"
    },
    padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    }
});

export default Barcode;
