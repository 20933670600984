/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { canUseDOM as c } from "./canUseDOM.mjs";
const s = (o) => {
  if (!c)
    return !1;
  const e = o ? o.ownerDocument : document;
  if (!e || !e.body)
    return !1;
  const i = 10, n = e.createElement("div");
  n.style.transform = "matrix(10, 0, 0, 10, 0, 0)";
  const t = e.createElement("div");
  t.appendChild(e.createTextNode("child")), t.style.position = "fixed", t.style.top = i + "px", n.appendChild(t), e.body.appendChild(n);
  const r = t.getBoundingClientRect().top !== i;
  return e.body.removeChild(n), r;
};
export {
  s as hasRelativeStackingContext
};
