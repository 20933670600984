/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { getDate as f, cloneDate as v } from "@progress/kendo-date-math";
import { EMPTY_SELECTIONRANGE as F } from "./calendar/models/SelectionRange.mjs";
function W(t) {
  const e = (r, o, s, ...u) => o[s] === null ? null : (r ? t.isRequired : t)(o, s, ...u), n = e.bind(null, !1);
  return n.isRequired = e.bind(null, !0), n;
}
const d = (t, e, n) => e === void 0 || n === void 0 || e <= t && t <= n ? t : t < e ? e : n, k = new Date(1980, 0, 1), H = new Date(1900, 0, 1), L = new Date(2099, 11, 31), q = new Date(1980, 0, 1), G = new Date(1980, 0, 1, 23, 59, 59), O = (t, e) => {
  const n = v(t);
  return n.setHours(
    e.getHours(),
    e.getMinutes(),
    e.getSeconds(),
    e.getMilliseconds()
  ), n;
}, X = () => f(/* @__PURE__ */ new Date()), j = (t, e, n) => !t || !(e && e > t || n && n < t), P = (t, e, n) => t === null || !(e && f(e) > f(t) || n && f(n) < f(t)), Y = (t, e) => {
  const { start: n, end: r } = e || F;
  return !n || !r ? !1 : n < t && t < r;
}, z = (t, e, n = 1) => {
  const r = [];
  for (let o = t; o < e; o = o + n)
    r.push(o);
  return r;
}, B = (t, e, n) => e.getTime() <= t.getTime() && t.getTime() <= n.getTime(), J = (t, e) => t.slice(e).concat(t.slice(0, e)), K = (t, e, n) => t && (e && t < e ? v(e) : n && t > n ? v(n) : t), Q = (t) => (e, n = "", r = {}) => {
  const o = document.createElement(t);
  return o.className = n, Object.keys(r).map((s) => o.style[s] = r[s]), typeof e == "string" ? o.innerHTML = e || "" : (e || []).forEach((s) => s && o.appendChild(s)), o;
};
function U(t, e, n = {}) {
  let r, o;
  n.maxWait;
  let s, u, l;
  const g = window;
  let M = !1, h = !1;
  const D = !e && e !== 0 && typeof g.requestAnimationFrame == "function";
  if (typeof t != "function")
    throw new TypeError("Expected a function");
  e = +e || 0;
  function I(i) {
    const c = r, m = o;
    return r = o = void 0, s = t.apply(m, c), s;
  }
  function T(i, c) {
    return D ? (g.cancelAnimationFrame(u), g.requestAnimationFrame(i)) : setTimeout(i, c);
  }
  function R(i) {
    if (D)
      return g.cancelAnimationFrame(i);
    clearTimeout(i);
  }
  function b(i) {
    return u = T(E, e), M ? I() : s;
  }
  function N(i) {
    const c = i - l;
    return e - c;
  }
  function p(i) {
    const c = i - l;
    return l === void 0 || c >= e || c < 0 || h;
  }
  function E() {
    const i = Date.now();
    if (p(i))
      return A();
    u = T(E, N(i));
  }
  function A(i) {
    return u = void 0, r ? I() : (r = o = void 0, s);
  }
  function w() {
    u !== void 0 && R(u), r = l = o = u = void 0;
  }
  function y() {
    return u === void 0 ? s : A();
  }
  function C() {
    return u !== void 0;
  }
  function a(...i) {
    const c = Date.now(), m = p(c);
    return r = i, o = this, l = c, m && u === void 0 ? b() : (u === void 0 && (u = T(E, e)), s);
  }
  return a.cancel = w, a.flush = y, a.pending = C, a;
}
export {
  L as MAX_DATE,
  G as MAX_TIME,
  k as MIDNIGHT_DATE,
  H as MIN_DATE,
  q as MIN_TIME,
  K as dateInRange,
  U as debounce,
  Q as domContainerFactory,
  X as getToday,
  B as intersects,
  P as isInDateRange,
  j as isInRange,
  Y as isInSelectionRange,
  W as nullable,
  z as range,
  O as setTime,
  J as shiftWeekNames,
  d as viewInRange
};
