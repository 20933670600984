/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { cloneDate as g, addDays as f } from "@progress/kendo-date-math";
import { TIME_PART as l } from "./models/TimePart.mjs";
import { setTime as u, MIDNIGHT_DATE as c } from "../utils.mjs";
const a = (e) => (t, r) => {
  const n = g(t);
  return n[e](r), n;
}, p = [
  { type: l.hour, getter: (e) => e.getHours() },
  { type: l.minute, getter: (e) => e.getMinutes() },
  { type: l.second, getter: (e) => e.getSeconds() },
  { type: l.millisecond, getter: (e) => e.getMilliseconds() }
], V = (e) => (t, r) => e(t), T = (e) => (t, r) => e(r), M = (e) => e.reduce((t, r) => (t[r.type] = r.type, t), {}), y = (e) => (t) => e[t.type] ? T(t.getter) : V(t.getter), H = (e) => (t) => e.map(y(M(t))), d = (e, t, r, n) => (s) => {
  const o = e(s), m = e(r);
  return n === "hour" ? o - (o - m) % t : s.getTime() <= r.getTime() && o !== 0 && o <= m ? Math.ceil(o / t) * t : o - o % t;
}, h = (e) => (t, r) => e.map((n) => {
  const s = Math.floor(t[n.type]);
  return s ? d(n.getter, s, r, n.type) : n.getter;
}), S = H(p), R = h(p), _ = (e) => (t, r) => (t.setHours(...e.map((n) => n(t, r))), t), x = (e) => (t) => {
  const r = g(t);
  return r.setHours(...e.map((n) => n(r))), r;
}, G = a("setHours"), w = a("setMinutes"), A = a("setSeconds"), B = () => /* @__PURE__ */ new Date(), E = (e, t, r = 1) => {
  const n = [];
  for (let s = e; s < t; s = s + r)
    n.push(s);
  return n;
}, i = (e, t, r) => ({
  candidateValue: u(c, e),
  maxValue: f(u(c, r), t.getHours() < r.getHours() ? 0 : 1),
  minValue: u(c, t)
}), F = (e, t, r) => {
  if (!e || !t || !r)
    return e;
  const {
    candidateValue: n,
    minValue: s,
    maxValue: o
  } = i(e, t, r);
  return n < s ? u(e, t) : n > o ? u(e, r) : e;
}, N = (e, t, r) => {
  if (!e || !t || !r)
    return !0;
  const {
    candidateValue: n,
    minValue: s,
    maxValue: o
  } = i(e, t, r);
  return s <= n && n <= o;
}, P = (e, t, r) => {
  if (e === null)
    return !0;
  const {
    candidateValue: n,
    minValue: s,
    maxValue: o
  } = i(e, t, r);
  return s <= n && n <= o;
}, v = (e, t) => {
  if (e === null || t === null)
    return !1;
  const r = u(c, e), n = u(c, t);
  return r.getTime() < n.getHours();
}, O = (e, t) => {
  if (e === null || t === null)
    return !1;
  const r = u(c, e);
  return u(c, t).getTime() < r.getHours();
};
export {
  S as generateGetters,
  R as generateSnappers,
  B as getNow,
  O as isBiggerThanMax,
  P as isInRange,
  N as isInTimeRange,
  v as isSmallerThanMin,
  E as range,
  G as setHours,
  w as setMinutes,
  A as setSeconds,
  x as snapTime,
  F as timeInRange,
  _ as valueMerger
};
