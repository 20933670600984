/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import { PopupWithoutContext as r } from "./PopupWithoutContext.mjs";
const c = o.createContext((t) => t), n = o.forwardRef((t, e) => {
  const p = o.useContext(c).call(void 0, t);
  return /* @__PURE__ */ o.createElement(
    r,
    {
      ref: e,
      ...p
    }
  );
});
n.displayName = "Popup";
export {
  n as Popup,
  c as PopupPropsContext
};
