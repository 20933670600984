import { autoTextColor, deepExtend, getTemplate, valueOrDefault } from '../../common';
import { CENTER, ROUNDED_RECT, BOTTOM, WHITE } from '../../common/constants';
import { ChartElement, Point, rectToBox, ShapeElement, TextBox } from '../../core';
import { CHART_POINT_CLASSNAME, CHART_POINT_ROLE, CHART_POINT_ROLE_DESCRIPTION, TOOLTIP_OFFSET } from '../constants';
import AccessibilityAttributesMixin from '../mixins/accessibility-attributes-mixin';
import NoteMixin from '../mixins/note-mixin';
import PointEventsMixin from '../mixins/point-events-mixin';

var HeatmapPoint = (function (ChartElement) {
    function HeatmapPoint(value, options) {
        ChartElement.call(this);

        this.options = options;
        this.color = options.color || WHITE;
        this.value = value;
    }

    if ( ChartElement ) HeatmapPoint.__proto__ = ChartElement;
    HeatmapPoint.prototype = Object.create( ChartElement && ChartElement.prototype );
    HeatmapPoint.prototype.constructor = HeatmapPoint;

    HeatmapPoint.prototype.render = function render () {
        if (this._rendered) {
            return;
        }

        this._rendered = true;

        this.createMarker();
        this.createLabel();
        this.createNote();
    };

    HeatmapPoint.prototype.createLabel = function createLabel () {
        var options = this.options;
        var labels = options.labels;

        if (labels.visible) {
            this.label = this.createLabelElement(labels);
            this.append(this.label);
        }
    };

    HeatmapPoint.prototype.createLabelElement = function createLabelElement (options) {
        var labelColor = options.color;

        if (!labelColor) {
            labelColor = autoTextColor(this.color);
        }

        return new TextBox(this.getLabelText(options),
            deepExtend({
                align: CENTER,
                vAlign: CENTER,
                margin: {
                    left: 5,
                    right: 5
                },
                zIndex: valueOrDefault(options.zIndex, this.series.zIndex)
            },
            options, {
                color: labelColor
            }),
            this.pointData()
        );
    };

    HeatmapPoint.prototype.getLabelText = function getLabelText (options) {
        var labelTemplate = getTemplate(options);

        if (labelTemplate) {
            return labelTemplate(this.pointData());
        }

        return this.formatValue(options.format);
    };

    HeatmapPoint.prototype.formatValue = function formatValue (format) {
        return this.owner.formatPointValue(this, format);
    };

    HeatmapPoint.prototype.reflow = function reflow (targetBox) {
        this.render();

        var label = this.label;

        this.box = targetBox;

        if (label) {
            label.reflow(this.markerBox());
        }

        if (this.note) {
            this.note.reflow(targetBox);
        }

        this.marker.reflow(this.markerBox());
    };

    HeatmapPoint.prototype.createVisual = function createVisual () {
        ChartElement.prototype.createVisual.call(this);

        this.addAccessibilityAttributesToVisual();
    };

    HeatmapPoint.prototype.markerBox = function markerBox () {
        var options = this.options;
        var markers = options.markers;
        var border = markers.border;
        var rect = this.box.toRect();
        var type = valueOrDefault(markers.type, 'rect');
        var isRoundRect = type === ROUNDED_RECT;
        var borderWidth = valueOrDefault(border.width, isRoundRect ? 1 : 0);
        var halfBorderWidth = Math.round(borderWidth / 2);

        if (markers.size) {
            var center = rect.center();
            rect.size.width = rect.size.height = markers.size;
            rect.origin.x = Math.round(center.x - rect.size.width / 2);
            rect.origin.y = Math.round(center.y - rect.size.height / 2);
        }

        rect.size.width -= borderWidth;
        rect.size.height -= borderWidth;
        rect.origin.y += halfBorderWidth + 0.5;
        rect.origin.x += halfBorderWidth + 0.5;

        return rectToBox(rect);
    };

    HeatmapPoint.prototype.markerBorder = function markerBorder () {
        var options = this.options;
        var markers = options.markers;
        var border = markers.border;
        var opacity = valueOrDefault(border.opacity, options.opacity);

        return {
            color: border.color || this.color,
            width: border.width,
            opacity: opacity,
            dashType: border.dashType
        };
    };

    HeatmapPoint.prototype.createMarker = function createMarker () {
        var options = this.options;
        var markerOptions = options.markers;
        var marker = new ShapeElement({
            type: valueOrDefault(markerOptions.type, 'rect'),
            width: markerOptions.size,
            height: markerOptions.size,
            rotation: markerOptions.rotation,
            background: this.color,
            border: this.markerBorder(),
            borderRadius: markerOptions.borderRadius,
            opacity: this.series.opacity || options.opacity,
            zIndex: valueOrDefault(options.zIndex, this.series.zIndex),
            animation: options.animation,
            visual: options.visual
        }, {
            dataItem: this.dataItem,
            value: this.value,
            series: this.series,
            category: this.category
        });

        this.marker = marker;
        this.append(marker);
    };

    HeatmapPoint.prototype.createHighlight = function createHighlight (style) {
        var options = this.options;
        var markerOptions = this.options.highlight.markers || this.options.markers;

        var highlight = new ShapeElement({
            type: valueOrDefault(markerOptions.type, 'rect'),
            width: markerOptions.size,
            height: markerOptions.size,
            rotation: markerOptions.rotation,
            background: markerOptions.color || this.color,
            border: this.markerBorder(),
            borderRadius: markerOptions.borderRadius,
            opacity: this.series.opacity || options.opacity,
            zIndex: valueOrDefault(options.zIndex, this.series.zIndex)
        });

        highlight.reflow(this.markerBox());
        var visual = highlight.getElement();

        visual.options.fill = style.fill;
        visual.options.stroke = style.stroke;

        return visual;
    };

    HeatmapPoint.prototype.highlightVisual = function highlightVisual () {
        return this.rectVisual;
    };

    HeatmapPoint.prototype.highlightVisualArgs = function highlightVisualArgs () {
        return {
            options: this.options,
            rect: this.box.toRect(),
            visual: this.rectVisual
        };
    };

    HeatmapPoint.prototype.createFocusHighlight = function createFocusHighlight () {
        var markerOptions = this.options.markers;
        var highlightOptions = this.options.accessibility.highlight;

        var highlight = new ShapeElement({
            type: valueOrDefault(markerOptions.type, 'rect'),
            width: markerOptions.size,
            height: markerOptions.size,
            rotation: markerOptions.rotation,
            background: highlightOptions.color,
            border: highlightOptions.border,
            borderRadius: markerOptions.borderRadius,
            padding: highlightOptions.border.width / 2,
            zIndex: highlightOptions.zIndex
        });

        highlight.reflow(this.markerBox());

        return highlight.getElement();
    };

    HeatmapPoint.prototype.tooltipAnchor = function tooltipAnchor () {
        var left = this.box.center().x;
        var top = this.box.y1 - TOOLTIP_OFFSET;

        return {
            point: new Point(left, top),
            align: {
                horizontal: CENTER,
                vertical: BOTTOM
            }
        };
    };

    HeatmapPoint.prototype.overlapsBox = function overlapsBox (box) {
        return this.box.overlaps(box);
    };

    HeatmapPoint.prototype.unclipElements = function unclipElements () {
        /* noop, clip labels */
    };

    HeatmapPoint.prototype.pointData = function pointData () {
        return {
            x: this.value.x,
            y: this.value.y,
            value: this.value.value,
            dataItem: this.dataItem,
            series: this.series
        };
    };

    return HeatmapPoint;
}(ChartElement));

deepExtend(HeatmapPoint.prototype, PointEventsMixin);
deepExtend(HeatmapPoint.prototype, NoteMixin);
deepExtend(HeatmapPoint.prototype, AccessibilityAttributesMixin);

HeatmapPoint.prototype.defaults = {
    markers: {
        type: 'rect',
        borderRadius: 4,
        border: {
            color: 'transparent'
        }
    },
    padding: { top: 1 },
    labels: {
        visible: false,
        padding: 3
    },
    opacity: 1,
    notes: {
        label: {}
    },
    accessibility: {
        role: CHART_POINT_ROLE,
        className: CHART_POINT_CLASSNAME,
        ariaRoleDescription: CHART_POINT_ROLE_DESCRIPTION
    }
};

export default HeatmapPoint;
