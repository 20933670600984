/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const n = /\[(?:(\d+)|['"](.*?)['"])\]|((?:(?!\[.*?\]|\.).)+)/g, t = [
  "input:not([disabled]):not([type=hidden])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "button:not([disabled])",
  "a[href]",
  "area[href]",
  "summary",
  "iframe",
  "object",
  "embed",
  "audio[controls]",
  "video[controls]",
  "[contenteditable]"
], o = [...t, "[tabindex]"], d = [...t, "[tabindex]"].map((e) => e + ':not([tabindex="-1"])');
export {
  n as FIELD_REGEX,
  o as FOCUSABLE_ELEMENTS,
  t as FOCUSABLE_ELEMENTS_BASE,
  d as TABBABLE_ELEMENTS
};
