/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import { provideLocalizationService as p, registerForLocalization as u } from "@progress/kendo-react-intl";
import { pagerItemPerPage as r, messages as l, pagerPageSizeAriaLabel as g } from "../messages/index.mjs";
import { DropDownList as h } from "@progress/kendo-react-dropdowns";
class f extends s.Component {
  constructor() {
    super(...arguments), this.pageSizeChange = (e) => {
      this.props.pageChange && this.props.pageChange({ skip: 0, take: parseInt(e.target.value, 10) }, e);
    };
  }
  render() {
    const { value: e, pageSizes: m, pageSize: t, messagesMap: a } = this.props, i = m.slice();
    e === void 0 && i.filter((c) => c === t).length === 0 && i.unshift(t);
    const n = a ? a(r) : { messageKey: r, defaultMessage: l[r] }, o = a ? a(g) : { messageKey: g, defaultMessage: l[g] };
    return /* @__PURE__ */ s.createElement("span", { className: "k-pager-sizes" }, /* @__PURE__ */ s.createElement(
      h,
      {
        value: e !== void 0 ? e : t,
        data: i,
        onChange: this.pageSizeChange,
        ariaLabel: p(this).toLanguageString(o.messageKey, o.defaultMessage),
        size: this.props.size
      }
    ), /* @__PURE__ */ s.createElement("span", null, p(this).toLanguageString(n.messageKey, n.defaultMessage)));
  }
}
u(f);
export {
  f as PagerPageSizes
};
