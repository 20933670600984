/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { AxisLabelClickEvent as m } from "./axis-label-click-event.mjs";
import { DragEvent as i } from "./drag-event.mjs";
import { DragEndEvent as n } from "./drag-end-event.mjs";
import { DragStartEvent as v } from "./drag-start-event.mjs";
import { DrilldownEvent as l } from "./drilldown-event.mjs";
import { LegendItemHoverEvent as E } from "./legend-item-hover-event.mjs";
import { LegendItemClickEvent as a } from "./legend-item-click-event.mjs";
import { LegendItemLeaveEvent as p } from "./legend-item-leave-event.mjs";
import { NavigatorFilterEvent as f } from "./navigator-filter-event.mjs";
import { NoteClickEvent as c } from "./note-click-event.mjs";
import { NoteHoverEvent as d } from "./note-hover-event.mjs";
import { PlotAreaClickEvent as g } from "./plot-area-click-event.mjs";
import { PlotAreaHoverEvent as s } from "./plot-area-hover-event.mjs";
import { RenderEvent as S } from "./render-event.mjs";
import { SelectEvent as k } from "./select-event.mjs";
import { SelectEndEvent as C } from "./select-end-event.mjs";
import { SelectStartEvent as H } from "./select-start-event.mjs";
import { SeriesClickEvent as L } from "./series-click-event.mjs";
import { SeriesHoverEvent as A } from "./series-hover-event.mjs";
import { ZoomEvent as I } from "./zoom-event.mjs";
import { ZoomEndEvent as D } from "./zoom-end-event.mjs";
import { ZoomStartEvent as N } from "./zoom-start-event.mjs";
const t = {
  axisLabelClick: m,
  drag: i,
  dragEnd: n,
  dragStart: v,
  drilldownEvent: l,
  legendItemHover: E,
  legendItemClick: a,
  legendItemLeave: p,
  navigatorFilter: f,
  noteClick: c,
  noteHover: d,
  plotAreaClick: g,
  plotAreaHover: s,
  render: S,
  select: k,
  selectEnd: C,
  selectStart: H,
  seriesClick: L,
  seriesHover: A,
  zoom: I,
  zoomEnd: D,
  zoomStart: N
};
function Q(e, r, o) {
  if (t[e])
    return new t[e](r, o);
}
export {
  Q as create
};
