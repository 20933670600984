/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { GridColumnMenuFilterCell as t } from "./GridColumnMenuFilterCell.mjs";
import { DropDownList as m } from "@progress/kendo-react-dropdowns";
class p extends e.Component {
  render() {
    const {
      firstFilterProps: r,
      hideSecondFilter: n,
      secondFilterProps: l,
      logicData: o,
      logicValue: a,
      onLogicChange: i
    } = this.props;
    return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(t, { ...r }), !n && /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(
      m,
      {
        data: o,
        value: a,
        onChange: i,
        className: "k-filter-and",
        textField: "text"
      }
    ), /* @__PURE__ */ e.createElement(t, { ...l })));
  }
}
export {
  p as GridColumnMenuFilterUI
};
