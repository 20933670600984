import { support } from '@progress/kendo-drawing';

var browser = support.browser || {};

export default function mousewheelDelta(e) {
    var delta = 0;

    if (e.wheelDelta) {
        delta = -e.wheelDelta / 120;

        if (browser.webkit) {
            // Webkit browsers scale the delta by twice the device resolution.
            // Possibly related to https://bugs.webkit.org/show_bug.cgi?id=196339
            //
            // Low device resolutions (e.g. zoom-out to 30%) also behave strangely.
            delta = delta / (2 * Math.max(window.devicePixelRatio, 0.625));
        }
    } else if (e.detail) {
        delta = e.detail / 3;
    }

    delta = delta > 0 ? Math.ceil(delta) : Math.floor(delta);

    return delta;
}
