/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { CenturyViewService as s } from "./CenturyViewService.mjs";
import { DecadeViewService as m } from "./DecadeViewService.mjs";
import { MonthViewService as c } from "./MonthViewService.mjs";
import { YearViewService as h } from "./YearViewService.mjs";
import { CalendarViewEnum as o } from "../models/CalendarViewEnum.mjs";
const a = {
  [o.month]: c,
  [o.year]: h,
  [o.decade]: m,
  [o.century]: s
}, u = (i, t) => {
  const e = o[o[i + t]];
  return e !== void 0 ? e : i;
};
class V {
  constructor(t) {
    this.bottom = o.month, this.top = o.century, this.onViewChanged = t;
  }
  configure(t, e) {
    this.bottom = t, this.top = e;
  }
  service(t, e) {
    return new a[`${t}`](e);
  }
  moveDown(t, e) {
    this.move(t, -1, e);
  }
  moveUp(t, e) {
    this.move(t, 1, e);
  }
  moveToBottom(t) {
    t !== this.bottom && this.onViewChanged({ view: this.bottom });
  }
  canMoveDown(t) {
    return this.bottom < t;
  }
  canMoveUp(t) {
    return t < this.top;
  }
  clamp(t) {
    return t < this.bottom ? this.bottom : t > this.top ? this.top : t;
  }
  move(t, e, n) {
    const r = this.clamp(u(t, e));
    r !== t && this.onViewChanged({ view: r }, n);
  }
}
export {
  V as BusViewService
};
