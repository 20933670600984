/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
class s {
  /**
   * @hidden
   */
  constructor(t) {
    this.target = t;
  }
}
export {
  s as BaseEvent
};
