/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { useId as c } from "../hooks/use-id.mjs";
const m = (r) => t.forwardRef((e, o) => {
  const i = c(e.id);
  return /* @__PURE__ */ t.createElement(r, { ...e, id: i, ref: o });
});
export {
  m as withIdHOC
};
