/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { createPortal as s } from "react-dom";
import { canUseDOM as r } from "@progress/kendo-react-common";
class a extends e.PureComponent {
  constructor(t) {
    super(t), this.state = {
      height: 0,
      visible: !1,
      left: 0,
      top: 0
    }, this.hiddenElementRef = e.createRef();
  }
  render() {
    const t = this.hiddenElementRef.current, n = this.state.visible && r && s(
      /* @__PURE__ */ e.createElement(
        "div",
        {
          className: "k-grouping-dropclue",
          style: {
            zIndex: 1e4,
            display: "block",
            top: this.state.top + "px",
            left: this.state.left + "px",
            height: this.state.height + "px"
          }
        }
      ),
      t && t.ownerDocument ? t.ownerDocument.body : document.body
    );
    return /* @__PURE__ */ e.createElement(e.Fragment, null, n, /* @__PURE__ */ e.createElement("div", { ref: this.hiddenElementRef, style: { display: "none" } }));
  }
}
export {
  a as DropClue
};
