/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { addDays as g, addWeeks as I, addMonths as h, firstDayOfMonth as y, lastDayOfMonth as D, dayOfWeek as F, getDate as u, durationInMonths as d } from "@progress/kendo-date-math";
import { Action as o } from "../models/NavigationAction.mjs";
import { EMPTY_SELECTIONRANGE as O } from "../models/SelectionRange.mjs";
import { range as w, getToday as W, isInRange as T, isInSelectionRange as Y } from "../../utils.mjs";
const U = [[]], S = 7, _ = 6, $ = 6, C = 0, G = {
  [o.Left]: (s) => g(s, -1),
  [o.Up]: (s) => I(s, -1),
  [o.Right]: (s) => g(s, 1),
  [o.Down]: (s) => I(s, 1),
  [o.PrevView]: (s) => h(s, -1),
  [o.NextView]: (s) => h(s, 1),
  [o.FirstInView]: (s) => y(s),
  [o.LastInView]: (s) => D(s)
};
class Q {
  constructor(t) {
    this.intl = t;
  }
  addToDate(t, e) {
    return h(t, e);
  }
  datesList(t, e) {
    return w(0, e).map((n) => h(t, n));
  }
  data(t) {
    const {
      cellUID: e,
      focusedDate: n,
      isActiveView: l,
      max: r,
      min: a,
      selectedDate: c,
      selectionRange: m = O,
      viewDate: f
    } = t;
    if (!f)
      return U;
    const M = y(f), R = D(f), N = F(M, this.intl.firstDay(), -1), b = w(0, S), q = W();
    return w(0, _).map((V) => {
      const v = g(N, V * S);
      return b.map((L) => {
        const i = this.normalize(g(v, L), a, r);
        if (i < M || i > R)
          return null;
        const p = this.isEqual(i, m.start), A = this.isEqual(i, m.end), E = !p && !A && Y(i, m), k = l && (Array.isArray(c) ? this.isSelectedFromArray(i, c, a, r) : T(c, a, r) && this.isEqual(i, c));
        return {
          formattedValue: this.value(i),
          id: `${e}${i.getTime()}`,
          isFocused: this.isEqual(i, n),
          isSelected: k,
          isInRange: T(i, a, r),
          isWeekend: this.isWeekend(i),
          isRangeStart: p,
          isRangeMid: E,
          isRangeEnd: A,
          isRangeSplitStart: E && this.isEqual(i, M),
          isRangeSplitEnd: E && this.isEqual(i, R),
          isToday: this.isEqual(i, q),
          title: this.cellTitle(i),
          value: i
        };
      });
    });
  }
  isEqual(t, e) {
    return !t || !e ? !1 : u(t).getTime() === u(e).getTime();
  }
  isSelectedFromArray(t, e, n, l) {
    let r = !1;
    return e.forEach((a) => {
      T(t, n, l) && this.isEqual(t, a) && (r = !0);
    }), r;
  }
  isInArray(t, e) {
    return !!e.length && y(e[0]) <= t && t <= D(e[e.length - 1]);
  }
  isInRange(t, e, n) {
    const l = u(t), r = !e || u(e) <= l, a = !n || l <= u(n);
    return r && a;
  }
  isInSameView(t, e) {
    return d(t, e) === 0;
  }
  isRangeStart(t) {
    return !t.getMonth();
  }
  move(t, e) {
    const n = G[e];
    return n ? n(t) : t;
  }
  cellTitle(t) {
    return this.intl.formatDate(t, "D");
  }
  navigationTitle(t) {
    return t ? this.isRangeStart(t) ? t.getFullYear().toString() : this.abbrMonthNames()[t.getMonth()] : "";
  }
  title(t) {
    return `${this.wideMonthNames()[t.getMonth()]} ${t.getFullYear()}`;
  }
  rowLength(t) {
    return S + (t ? 1 : 0);
  }
  skip(t, e) {
    return d(e, t);
  }
  total(t, e) {
    return d(t, e) + 1;
  }
  value(t) {
    return t ? t.getDate().toString() : "";
  }
  viewDate(t, e, n = 1) {
    return d(t, e) < n ? h(t, -1) : t;
  }
  isWeekend(t) {
    const e = t.getDay();
    return e === $ || e === C;
  }
  abbrMonthNames() {
    return this.intl.dateFormatNames({ nameType: "abbreviated", type: "months" });
  }
  normalize(t, e, n) {
    return t < e && this.isEqual(t, e) ? u(e) : t > n && this.isEqual(t, n) ? u(n) : t;
  }
  wideMonthNames() {
    return this.intl.dateFormatNames({ nameType: "wide", type: "months", standAlone: !0 });
  }
}
export {
  Q as MonthViewService
};
