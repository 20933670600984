/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import { provideLocalizationService as i, registerForLocalization as s } from "@progress/kendo-react-intl";
import { noRecords as o, messages as a } from "./messages/index.mjs";
const t = class t extends r.Component {
  /**
   * @hidden
   */
  render() {
    return this.props.children || i(this).toLanguageString(o, a[o]);
  }
};
t.displayName = "KendoReactGridNoRecords";
let e = t;
s(e);
export {
  e as GridNoRecords
};
