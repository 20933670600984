/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as h from "react";
import S from "prop-types";
import { classNames as P, Keys as v, validatePackage as J, svgIconPropType as Q, canUseDOM as A, getTabIndex as X, IconWrap as Z, createPropsContext as ee, withIdHOC as te, withPropsContext as se, kendoThemeMaps as ie } from "@progress/kendo-react-common";
import { FloatingLabel as ae } from "@progress/kendo-react-labels";
import { caretAltDownIcon as oe, xIcon as ne } from "@progress/kendo-svg-icons";
import le from "../common/ListContainer.mjs";
import re from "../common/ListFilter.mjs";
import H from "../common/GroupStickyHeader.mjs";
import de from "../common/ListDefaultItem.mjs";
import pe from "../common/List.mjs";
import O from "../common/DropDownBase.mjs";
import { areSame as C, getItemValue as K, sameCharsOnly as he, shuffleData as ce, matchText as B, isPresent as q, itemIndexStartsWith as ue } from "../common/utils.mjs";
import { packageMetadata as me } from "../package-metadata.mjs";
import { Button as W } from "@progress/kendo-react-buttons";
import { provideLocalizationService as fe } from "@progress/kendo-react-intl";
import { dropDownListArrowBtnAriaLabel as U, messages as ge } from "../messages/index.mjs";
import { MOBILE_MEDIUM_DEVISE as G, MOBILE_SMALL_DEVISE as $ } from "../common/constants.mjs";
import { ActionSheet as ve, ActionSheetHeader as be, ActionSheetContent as Ie } from "@progress/kendo-react-layout";
const ye = "Please select a value from the list!", { sizeMap: z, roundedMap: ke } = ie, E = class E extends h.Component {
  constructor(s) {
    super(s), this.state = {}, this._element = null, this.base = new O(this), this.searchState = { word: "", last: "" }, this._select = null, this._skipFocusEvent = !1, this._filterInput = null, this._navigated = !1, this._lastKeypressIsFilter = !1, this.itemHeight = 0, this.focus = () => {
      this.base.wrapper && this.base.wrapper.focus();
    }, this.handleItemSelect = (e, t) => {
      const { data: a = [], virtual: r, dataItemKey: o, defaultItem: n } = this.props, c = r ? r.skip : 0, d = e === -1 && n !== void 0 ? n : a[e - c], p = !C(d, this.value, o);
      this.triggerOnChange(d, t), p && this.base.triggerPageChangeCornerItems(d, t);
    }, this.componentRef = (e) => {
      this._element = e, this.base.wrapper = e;
    }, this.dummySelect = (e) => /* @__PURE__ */ h.createElement(
      "select",
      {
        name: this.props.name,
        ref: (t) => {
          this._select = t;
        },
        tabIndex: -1,
        "aria-hidden": !0,
        title: this.props.label,
        style: { opacity: 0, width: 1, border: 0, zIndex: -1, position: "absolute", left: "50%" }
      },
      /* @__PURE__ */ h.createElement(
        "option",
        {
          value: this.props.valueMap ? this.props.valueMap.call(void 0, e) : e
        }
      )
    ), this.renderListContainer = () => {
      const { header: e, footer: t, dir: a, data: r = [], size: o, groupField: n, groupStickyHeaderItemRender: c, list: d } = this.props, p = this.base, i = p.getPopupSettings(), g = this.props.opened !== void 0 ? this.props.opened : this.state.opened, b = i.width !== void 0 ? i.width : p.popupWidth, y = {
        dir: a !== void 0 ? a : p.dirCalculated,
        width: b,
        popupSettings: {
          ...i,
          popupClass: P(
            i.popupClass,
            "k-list-container",
            "k-dropdownlist-popup"
          ),
          anchor: i.anchor || this.element,
          show: g,
          onOpen: this.onPopupOpened,
          onClose: this.onPopupClosed
        },
        itemsCount: [r.length]
      };
      let { group: m } = this.state;
      return m === void 0 && n !== void 0 && (m = K(r[0], n)), /* @__PURE__ */ h.createElement(le, { ...y }, this.renderListFilter(), e && /* @__PURE__ */ h.createElement("div", { className: "k-list-header" }, e), /* @__PURE__ */ h.createElement(
        "div",
        {
          className: P(
            "k-list",
            {
              [`k-list-${z[o] || o}`]: o,
              "k-virtual-list": this.base.vs.enabled
            }
          )
        },
        this.renderDefaultItem(),
        !d && m && r.length !== 0 && /* @__PURE__ */ h.createElement(H, { group: m, groupMode: "modern", render: c }),
        this.renderList()
      ), t && /* @__PURE__ */ h.createElement("div", { className: "k-list-footer" }, t));
    }, this.renderList = () => {
      const {
        data: e = [],
        textField: t,
        dataItemKey: a,
        virtual: r = { skip: 0, total: void 0 },
        groupHeaderItemRender: o,
        listNoDataRender: n,
        itemRender: c
      } = this.props, d = this.base.vs, p = r.skip, i = this.props.opened !== void 0 ? this.props.opened : this.state.opened, g = this.base.getPopupSettings(), b = `translateY(${d.translate}px)`;
      return /* @__PURE__ */ h.createElement(
        pe,
        {
          id: this._listboxId,
          show: i,
          data: e.slice(),
          focusedIndex: this.getFocusedIndex(),
          value: this.value,
          textField: t,
          valueField: a,
          optionsGuid: this._guid,
          groupField: this.props.groupField,
          groupMode: "modern",
          listRef: (y) => d.list = this.base.list = y,
          wrapperStyle: { maxHeight: g.height },
          wrapperCssClass: "k-list-content",
          listStyle: d.enabled ? { transform: b } : void 0,
          key: "listkey",
          skip: p,
          onClick: this.handleItemClick,
          itemRender: c,
          groupHeaderItemRender: o,
          noDataRender: n,
          onScroll: this.onScroll,
          wrapperRef: d.scrollerRef,
          scroller: this.base.renderScrollElement(),
          ariaSetSize: r.total
        }
      );
    }, this.onScroll = (e) => {
      const { vs: t, list: a } = this.base;
      t.scrollHandler(e);
      const { groupField: r } = this.props;
      let { data: o = [] } = this.props;
      if (!(!r || !o.length) && r) {
        const n = this.itemHeight = this.itemHeight || (t.enabled ? t.itemHeight : a ? a.children[0].offsetHeight : 0), d = e.target.scrollTop - t.skip * n;
        o = this.base.getGroupedDataModernMode(o, r);
        let p = o[0][r];
        for (let i = 1; i < o.length && !(n * i > d); i++)
          o[i] && o[i][r] && (p = o[i][r]);
        p !== this.state.group && this.setState({
          group: p
        });
      }
    }, this.renderListFilter = () => {
      const e = this.props.filter !== void 0 ? this.props.filter : this.state.text;
      return this.props.filterable && /* @__PURE__ */ h.createElement(
        re,
        {
          value: e,
          ref: (t) => this._filterInput = t && t.element,
          onChange: this.handleListFilterChange,
          onKeyDown: this.handleKeyDown,
          size: this.props.size,
          rounded: this.props.rounded,
          fillMode: this.props.fillMode
        }
      );
    }, this.renderDefaultItem = () => {
      const { textField: e, defaultItem: t, dataItemKey: a } = this.props;
      return t !== void 0 && /* @__PURE__ */ h.createElement(
        de,
        {
          defaultItem: t,
          textField: e,
          selected: C(this.value, t, a),
          key: "defaultitemkey",
          onClick: this.handleDefaultItemClick
        }
      );
    }, this.search = (e) => {
      clearTimeout(this._typingTimeout), this.props.filterable || (this._typingTimeout = window.setTimeout(() => this.searchState.word = "", this.props.delay), this.selectNext(e));
    }, this.selectNext = (e) => {
      const { data: t = [], dataItemKey: a } = this.props;
      let r = t.map((w, N) => ({ item: w, itemIndex: N }));
      const o = this.searchState.word, n = this.searchState.last, c = he(o, n);
      let d = r.length, p = Math.max(0, t.findIndex((w) => C(w, this.value, a))), i;
      this.props.defaultItem && (i = { item: this.props.defaultItem, itemIndex: -1 }, d += 1, p += 1), p += c ? 1 : 0, r = ce(r, p, i);
      let g, b, y, m = 0;
      const { textField: l, ignoreCase: x } = this.props;
      for (; m < d; m++)
        if (g = K(r[m].item, l), b = c && B(g, n, x), y = B(g, o, x), b || y) {
          m = r[m].itemIndex;
          break;
        }
      if (m !== d) {
        const w = this.base.initState();
        w.syntheticEvent = e, this.handleItemSelect(m, w), this.applyState(w), this._valueDuringOnChange = void 0;
      }
    }, this.handleKeyDown = (e) => {
      e && e.target instanceof Element && e.target.nodeName === "INPUT" && e.stopPropagation && e.stopPropagation();
      const {
        data: t = [],
        filterable: a,
        disabled: r,
        defaultItem: o,
        leftRightKeysNavigation: n = !0,
        virtual: c = { skip: 0, total: 0, pageSize: 0 },
        dataItemKey: d,
        groupField: p = "",
        textField: i,
        skipDisabledItems: g = !0
      } = this.props, b = this.value, y = t.findIndex((I) => C(I, b, d)), m = this.props.opened !== void 0 ? this.props.opened : this.state.opened, l = e.keyCode, x = l === v.home || l === v.end, w = l === v.up || l === v.down, N = !m && (e.altKey && l === v.down || l === v.enter || l === v.space), M = m && (e.altKey && l === v.up || l === v.esc), D = n && (l === v.left || l === v.right), L = w || D && !a || x, k = this.base.initState();
      if (k.syntheticEvent = e, !r) {
        if (x && this.base.vs.enabled)
          l === v.home ? c.skip !== 0 ? (this.base.triggerOnPageChange(k, 0, c.pageSize), this._navigated = !0) : this.triggerOnChange(t[0], k) : c.skip < c.total - c.pageSize ? (this.base.triggerOnPageChange(k, c.total - c.pageSize, c.pageSize), this._navigated = !0) : this.triggerOnChange(t[t.length - 1], k);
        else if (m && l === v.pageUp)
          this.base.scrollPopupByPageSize(-1);
        else if (m && l === v.pageDown)
          this.base.scrollPopupByPageSize(1);
        else if (m && l === v.enter) {
          const I = this.getFocusedIndex();
          this.haveFocusedItemAndDataNotEmpty(t, I) ? (this.triggerOnChange(null, k), this.applyState(k)) : this.handleItemSelect(I, k), this.base.togglePopup(k), e.preventDefault();
        } else if (N || M)
          M && this.resetValueIfDisabledItem(), this.base.togglePopup(k), e.preventDefault();
        else if (L) {
          if (this._lastKeypressIsFilter = !1, p !== "" && i)
            if (!g && m)
              this.onNavigate(k, l);
            else {
              let I;
              if (l === v.down || l === v.right) {
                const f = t.slice(y + 1).find((u) => !u.disabled && u[i]);
                I = f && t.findIndex((u) => u[i] === f[i]);
              } else if (l === v.up || l === v.left) {
                let f;
                if (y === 0 && o)
                  I = -1;
                else if (y === -1)
                  f = t, I = t.findIndex((u) => !u.disabled && u[i]);
                else {
                  f = t.slice(0, y);
                  let u = f.pop();
                  for (; u && u.disabled; )
                    u = f.pop();
                  I = u && t.findIndex((F) => F[i] === u[i]);
                }
              }
              if (I !== void 0) {
                const f = I - y;
                this.onNavigate(k, l, f);
              } else
                I === void 0 && t.findIndex((f) => f[i] === b[i]) === t.length - 1 && this.onNavigate(k, l);
            }
          else if (!g && m || x)
            this.onNavigate(k, l);
          else if (i) {
            let I;
            if (l === v.down || l === v.right) {
              const f = t.slice(y + 1).find((u) => !u.disabled && u[i]);
              I = f && t.findIndex((u) => u[i] === f[i]);
            } else if (l === v.up || l === v.left) {
              let f;
              if (y === 0 && o)
                I = -1;
              else if (y === -1)
                f = t, I = t.find((u) => !u.disabled && u[i]);
              else {
                f = t.slice(0, y);
                let u = f.pop();
                for (; u && u.disabled; )
                  u = f.pop();
                I = u && t.findIndex((F) => F[i] === u[i]);
              }
            }
            if (I !== void 0) {
              const f = I - y;
              this.onNavigate(k, l, f);
            } else
              I === void 0 && t.findIndex((f) => f[i] === b[i]) === t.length - 1 && this.onNavigate(k, l);
          } else
            this.onNavigate(k, l);
          e.preventDefault();
        }
        this.applyState(k);
      }
    }, this.handleItemClick = (e, t) => {
      this.base.handleItemClick(e, t), this._valueDuringOnChange = void 0;
    }, this.handleFocus = (e) => {
      this._skipFocusEvent || this.base.handleFocus(e);
    }, this.handleBlur = (e) => {
      if (this._skipFocusEvent || !this.state.focused)
        return;
      const t = this.props.opened !== void 0 ? this.props.opened : this.state.opened, { adaptive: a } = this.props, { windowWidth: r = 0 } = this.state, o = r <= G && a, n = this.base.initState();
      n.syntheticEvent = e, n.data.focused = !1, n.events.push({ type: "onBlur" }), t && this.resetValueIfDisabledItem(), t && !o && this.base.togglePopup(n), this.applyState(n);
    }, this.handleDefaultItemClick = (e) => {
      const t = this.base.initState();
      t.syntheticEvent = e, this.base.togglePopup(t), this.triggerOnChange(this.props.defaultItem, t), this.applyState(t);
    }, this.handleWrapperClick = (e) => {
      if (e.isPropagationStopped())
        return;
      const t = this.base.initState();
      t.syntheticEvent = e, this.state.focused || (t.data.focused = !0), this.resetValueIfDisabledItem(), this.base.togglePopup(t), this.applyState(t);
    }, this.handleKeyPress = (e) => {
      if (this.props.filterable || e.which === 0 || e.keyCode === v.enter)
        return;
      let t = String.fromCharCode(e.charCode || e.keyCode);
      this.props.ignoreCase && (t = t.toLowerCase()), t === " " && e.preventDefault(), this.searchState = {
        word: this.searchState.word + t,
        last: this.searchState.last + t
      }, this.search(e);
    }, this.handleListFilterChange = (e) => {
      const t = this.base.initState();
      t.syntheticEvent = e.syntheticEvent, this.props.filter === void 0 && (t.data.text = e.target.value), this.base.filterChanged(e.target.value, t), this._lastKeypressIsFilter = !0, this.applyState(t), this.setState({ group: void 0 });
    }, this.onPopupOpened = () => {
      this._filterInput && this.focusElement(this._filterInput), this.props.adaptive && setTimeout(() => {
        this._filterInput && this.focusElement(this._filterInput);
      }, 300);
    }, this.onPopupClosed = () => {
      this.state.focused && window.setTimeout(
        () => {
          this.state.focused && this.base.wrapper && this.focusElement(this.base.wrapper);
        }
      );
    }, this.setValidity = () => {
      this._select && this._select.setCustomValidity && this._select.setCustomValidity(
        this.validity.valid ? "" : this.props.validationMessage || ye
      );
    }, J(me);
  }
  get _inputId() {
    return this.props.id + "-accessibility-id";
  }
  get _listboxId() {
    return this.props.id + "-listbox-id";
  }
  get _guid() {
    return this.props.id + "-guid";
  }
  get document() {
    if (A)
      return this.element && this.element.ownerDocument || document;
  }
  /**
   * @hidden
   */
  get element() {
    return this._element;
  }
  /**
   * The value of the DropDownList.
   */
  get value() {
    let s;
    return this._valueDuringOnChange !== void 0 ? s = this._valueDuringOnChange : this.props.value !== void 0 ? s = this.props.value : this.state.value !== void 0 ? s = this.state.value : this.props.defaultValue !== void 0 && (s = this.props.defaultValue), !q(s) && this.props.defaultItem !== void 0 && (s = this.props.defaultItem), s;
  }
  /**
   * The index of the selected item.
   */
  get index() {
    const { data: s = [], dataItemKey: e } = this.props, t = this.value;
    return s.findIndex((a) => C(a, t, e));
  }
  /**
   * Gets the `name` property of the DropDownList.
   */
  get name() {
    return this.props.name;
  }
  /**
   * Represents the validity state into which the DropDownList is set.
   */
  get validity() {
    const s = this.props.validationMessage !== void 0, e = !this.required || this.value !== null && this.value !== "" && this.value !== void 0, t = this.props.valid !== void 0 ? this.props.valid : e;
    return {
      customError: s,
      valid: t,
      valueMissing: this.value === null
    };
  }
  get validityStyles() {
    return this.props.validityStyles !== void 0 ? this.props.validityStyles : E.defaultProps.validityStyles;
  }
  /**
   * @hidden
   */
  get required() {
    return this.props.required !== void 0 ? this.props.required : E.defaultProps.required;
  }
  /**
   * @hidden
   */
  componentDidUpdate(s, e) {
    var g;
    const { dataItemKey: t, virtual: a, groupField: r = "", textField: o } = this.props, { data: n = [] } = this.props, c = s.virtual ? s.virtual.total : 0, d = this.props.opened !== void 0 ? this.props.opened : this.state.opened, p = s.opened !== void 0 ? s.opened : e.opened, i = !p && d;
    if (this.base.getPopupSettings().animate || i && this.onPopupOpened(), a && a.total !== c)
      this.base.vs.calcScrollElementHeight(), this.base.vs.reset();
    else {
      const b = this.value, y = s.value !== void 0 ? s.value : e.value;
      let m = n.findIndex((x) => C(x, b, t));
      r !== "" && b && o && (m = (g = this.base.getGroupedDataModernMode(n, r)) == null ? void 0 : g.map((x) => x[o]).indexOf(b[o]));
      const l = !C(y, b, t);
      i && a ? this.base.scrollToVirtualItem(a, m) : i && !a ? (this.onPopupOpened(), n && n.length !== 0 && this.base.resetGroupStickyHeader(n[0][r], this), this.base.scrollToItem(m)) : d && p && b && l && !this._navigated ? this.base.scrollToItem(m) : d && p && this._navigated && (this._navigated && a && a.skip === 0 ? this.base.vs.reset() : this._navigated && a && a.skip === a.total - a.pageSize && this.base.vs.scrollToEnd());
    }
    this._navigated = !1, this.setValidity();
  }
  /**
   * @hidden
   */
  componentDidMount() {
    var s;
    this.observerResize = A && window.ResizeObserver && new window.ResizeObserver(this.calculateMedia.bind(this)), this.base.didMount(), this.setValidity(), (s = this.document) != null && s.body && this.observerResize && this.observerResize.observe(this.document.body);
  }
  /** @hidden */
  componentWillUnmount() {
    var s;
    (s = this.document) != null && s.body && this.observerResize && this.observerResize.disconnect();
  }
  /**
   * @hidden
   */
  render() {
    const s = fe(this).toLanguageString(U, ge[U]), { style: e, className: t, label: a, dir: r, virtual: o, size: n, rounded: c, fillMode: d, adaptive: p } = this.props, { windowWidth: i = 0 } = this.state, g = this.props.opened !== void 0 ? this.props.opened : this.state.opened, b = this.value, y = K(b, this.props.textField), m = !this.validityStyles || this.validity.valid, l = this.base, x = l.vs, w = i <= G && p;
    x.enabled = o !== void 0, o !== void 0 && (l.vs.skip = o.skip, l.vs.total = o.total, l.vs.pageSize = o.pageSize);
    const {
      dataItemKey: N,
      data: M = [],
      disabled: D,
      tabIndex: L,
      loading: k,
      iconClassName: I,
      svgIcon: f,
      valueRender: u
    } = this.props, { focused: F } = this.state, Y = M.findIndex((_) => C(_, b, N)), R = /* @__PURE__ */ h.createElement("span", { id: this._inputId, className: "k-input-inner" }, /* @__PURE__ */ h.createElement("span", { className: "k-input-value-text" }, y)), j = u !== void 0 ? u.call(void 0, R, b) : R, V = /* @__PURE__ */ h.createElement(h.Fragment, null, /* @__PURE__ */ h.createElement(
      "span",
      {
        ref: this.componentRef,
        className: P(
          "k-dropdownlist k-picker",
          t,
          {
            [`k-picker-${z[n] || n}`]: n,
            [`k-rounded-${ke[c] || c}`]: c,
            [`k-picker-${d}`]: d,
            "k-focus": F,
            "k-disabled": D,
            "k-invalid": !m,
            "k-loading": k,
            "k-required": this.required
          }
        ),
        style: a ? { ...e, width: void 0 } : e,
        dir: r,
        onMouseDown: g ? (_) => {
          _.target.nodeName !== "INPUT" && (this.focusElement(this.base.wrapper), _.preventDefault());
        } : void 0,
        onFocus: this.handleFocus,
        onBlur: this.handleBlur,
        tabIndex: X(L, D),
        accessKey: this.props.accessKey,
        onKeyDown: this.handleKeyDown,
        onKeyPress: this.handleKeyPress,
        onClick: D ? void 0 : this.handleWrapperClick,
        role: "combobox",
        "aria-required": this.required,
        "aria-disabled": D || void 0,
        "aria-haspopup": "listbox",
        "aria-expanded": g || !1,
        "aria-owns": this._listboxId,
        "aria-activedescendant": g ? "option-" + this._guid + "-" + (Y + (o ? o.skip : 0)) : void 0,
        "aria-label": this.props.ariaLabel || this.props.label,
        "aria-labelledby": this.props.ariaLabelledBy,
        "aria-describedby": this.props.ariaDescribedBy || this._inputId,
        id: this.props.id,
        title: this.props.title
      },
      j,
      k && /* @__PURE__ */ h.createElement(Z, { className: "k-input-loading-icon", name: "loading" }),
      /* @__PURE__ */ h.createElement(
        W,
        {
          tabIndex: -1,
          type: "button",
          "aria-label": s,
          "aria-hidden": !0,
          size: n,
          fillMode: d,
          iconClass: I,
          className: "k-input-button",
          rounded: null,
          themeColor: "base",
          icon: I ? void 0 : "caret-alt-down",
          svgIcon: f || oe,
          onMouseDown: (_) => this.state.focused && _.preventDefault()
        }
      ),
      this.dummySelect(b),
      !w && this.renderListContainer()
    ), w && this.renderAdaptiveListContainer());
    return a ? /* @__PURE__ */ h.createElement(
      ae,
      {
        label: a,
        editorValue: y,
        editorValid: m,
        editorDisabled: this.props.disabled,
        style: { width: e ? e.width : void 0 },
        children: V
      }
    ) : V;
  }
  /**
   * @hidden
   */
  onNavigate(s, e, t) {
    const {
      data: a = [],
      defaultItem: r,
      dataItemKey: o,
      virtual: n = { skip: 0, total: 0, pageSize: 0 }
    } = this.props, c = this.base.vs, d = this.value, p = a.findIndex((g) => C(g, d, o)), i = this.base.navigation.navigate({
      current: n.skip + p,
      max: (c.enabled ? n.total : a.length) - 1,
      min: r !== void 0 ? -1 : 0,
      keyCode: e,
      skipItems: t || void 0
    });
    i !== void 0 && this.handleItemSelect(i, s), this.applyState(s);
  }
  renderAdaptiveListContainer() {
    const { windowWidth: s = 0 } = this.state, { header: e, footer: t, size: a, adaptiveTitle: r, groupField: o, groupStickyHeaderItemRender: n, list: c, data: d = [] } = this.props, p = this.props.opened !== void 0 ? this.props.opened : this.state.opened, i = {
      navigatable: !1,
      navigatableElements: [],
      expand: p,
      animation: !0,
      onClose: (b) => this.handleWrapperClick(b),
      animationStyles: s <= $ ? { top: 0, width: "100%", height: "100%" } : void 0,
      className: s <= $ ? "k-adaptive-actionsheet k-actionsheet-fullscreen" : "k-adaptive-actionsheet k-actionsheet-bottom"
    };
    let { group: g } = this.state;
    return g === void 0 && o !== void 0 && (g = K(d[0], o)), /* @__PURE__ */ h.createElement(ve, { ...i }, /* @__PURE__ */ h.createElement(be, { className: "k-text-center" }, /* @__PURE__ */ h.createElement("div", { className: "k-actionsheet-titlebar-group k-hbox" }, /* @__PURE__ */ h.createElement("div", { className: "k-actionsheet-title" }, /* @__PURE__ */ h.createElement("div", null, r)), /* @__PURE__ */ h.createElement("div", { className: "k-actionsheet-actions" }, /* @__PURE__ */ h.createElement(
      W,
      {
        tabIndex: 0,
        "aria-label": "Cancel",
        "aria-disabled": "false",
        type: "button",
        fillMode: "flat",
        onClick: this.handleWrapperClick,
        icon: "x",
        svgIcon: ne
      }
    ))), /* @__PURE__ */ h.createElement("div", { className: "k-actionsheet-titlebar-group k-actionsheet-filter" }, this.renderListFilter())), /* @__PURE__ */ h.createElement(Ie, { className: "!k-overflow-hidden" }, e && /* @__PURE__ */ h.createElement("div", { className: "k-list-header" }, e), /* @__PURE__ */ h.createElement(
      "div",
      {
        className: P(
          "k-list",
          {
            [`k-list-${z[a] || a}`]: a,
            "k-virtual-list": this.base.vs.enabled
          }
        )
      },
      this.renderDefaultItem(),
      !c && g && d.length !== 0 && /* @__PURE__ */ h.createElement(H, { group: g, groupMode: "modern", render: n }),
      this.renderList()
    ), t && /* @__PURE__ */ h.createElement("div", { className: "k-list-footer" }, t)));
  }
  getFocusedIndex() {
    const s = this.value, {
      data: e = [],
      textField: t,
      dataItemKey: a,
      virtual: r = { skip: 0 },
      focusedItemIndex: o = ue,
      filterable: n,
      skipDisabledItems: c = !0
    } = this.props, d = this.props.filter ? this.props.filter : this.state.text;
    return c && t && !d && !s ? e.findIndex((p) => !p.disabled && p[t]) : q(s) && d === void 0 || n && d === "" ? e.findIndex((p) => C(p, s, a)) : d ? this._lastKeypressIsFilter ? o(e, d, t) : e.findIndex((p) => C(p, s, a)) : r.skip === 0 ? 0 : -1;
  }
  focusElement(s) {
    this._skipFocusEvent = !0, s.focus(), window.setTimeout(() => this._skipFocusEvent = !1, 30);
  }
  triggerOnChange(s, e) {
    C(this.value, s, this.props.dataItemKey) || (this.props.value === void 0 && (e.data.value = s), this._valueDuringOnChange = s, e.events.push({ type: "onChange" }));
  }
  applyState(s) {
    this.base.applyState(s), this._valueDuringOnChange = void 0;
  }
  calculateMedia(s) {
    for (const e of s)
      this.setState({ windowWidth: e.target.clientWidth });
  }
  resetValueIfDisabledItem() {
    const { data: s = [] } = this.props, e = this.base.initState(), t = this.getFocusedIndex();
    this.haveFocusedItemAndDataNotEmpty(s, t) && (this.triggerOnChange(null, e), this.applyState(e));
  }
  haveFocusedItemAndDataNotEmpty(s, e) {
    return e !== void 0 && e !== -1 && s && s.length > 0 && s[e].disabled;
  }
};
E.displayName = "DropDownList", E.propTypes = {
  delay: S.number,
  ignoreCase: S.bool,
  iconClassName: S.string,
  svgIcon: Q,
  defaultItem: S.any,
  valueRender: S.func,
  valueMap: S.func,
  validationMessage: S.string,
  required: S.bool,
  id: S.string,
  ariaLabelledBy: S.string,
  ariaDescribedBy: S.string,
  ariaLabel: S.string,
  leftRightKeysNavigation: S.bool,
  title: S.string,
  groupField: S.string,
  list: S.any,
  skipDisabledItems: S.bool,
  ...O.propTypes
}, E.defaultProps = {
  delay: 500,
  tabIndex: 0,
  ignoreCase: !0,
  ...O.defaultProps,
  required: !1,
  size: "medium",
  rounded: "medium",
  fillMode: "solid",
  groupMode: "modern"
};
let T = E;
const Se = ee(), xe = te(se(
  Se,
  T
));
xe.displayName = "KendoReactDropDownList";
export {
  xe as DropDownList,
  Se as DropDownListPropsContext,
  T as DropDownListWithoutContext
};
