/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
const h = (o, l, u = {}) => {
  const c = e.useCallback(
    (n) => {
      u.onMouseDown && u.onMouseDown.call(void 0, n), o.onMouseDown && o.onMouseDown.call(void 0, {
        target: l.current,
        syntheticEvent: n
      });
    },
    [u.onMouseDown, o.onMouseDown, l]
  ), M = e.useCallback(
    (n) => {
      u.onMouseUp && u.onMouseUp.call(void 0, n), o.onMouseUp && o.onMouseUp.call(void 0, {
        target: l.current,
        syntheticEvent: n
      });
    },
    [u.onMouseUp, o.onMouseUp, l]
  ), i = e.useCallback(
    (n) => {
      u.onClick && u.onClick.call(void 0, n), o.onClick && o.onClick.call(void 0, {
        target: l.current,
        syntheticEvent: n
      });
    },
    [u.onClick, o.onClick, l]
  ), s = e.useCallback(
    (n) => {
      u.onDoubleClick && u.onDoubleClick.call(void 0, n), o.onDoubleClick && o.onDoubleClick.call(void 0, {
        target: l.current,
        syntheticEvent: n
      });
    },
    [u.onDoubleClick, o.onDoubleClick, l]
  ), a = e.useCallback(
    (n) => {
      u.onMouseEnter && u.onMouseEnter.call(void 0, n), o.onMouseEnter && o.onMouseEnter.call(void 0, {
        target: l.current,
        syntheticEvent: n
      });
    },
    [u.onMouseEnter, o.onMouseEnter, l]
  ), v = e.useCallback(
    (n) => {
      u.onMouseLeave && u.onMouseLeave.call(void 0, n), o.onMouseLeave && o.onMouseLeave.call(void 0, {
        target: l.current,
        syntheticEvent: n
      });
    },
    [u.onMouseLeave, o.onMouseLeave, l]
  ), k = e.useCallback(
    (n) => {
      u.onMouseMove && u.onMouseMove.call(void 0, n), o.onMouseMove && o.onMouseMove.call(void 0, {
        target: l.current,
        syntheticEvent: n
      });
    },
    [u.onMouseMove, o.onMouseMove, l]
  ), C = e.useCallback(
    (n) => {
      u.onMouseOut && u.onMouseOut.call(void 0, n), o.onMouseOut && o.onMouseOut.call(void 0, {
        target: l.current,
        syntheticEvent: n
      });
    },
    [u.onMouseOut, o.onMouseOut, l]
  ), f = e.useCallback(
    (n) => {
      u.onMouseOver && u.onMouseOver.call(void 0, n), o.onMouseOver && o.onMouseOver.call(void 0, {
        target: l.current,
        syntheticEvent: n
      });
    },
    [u.onMouseOver, o.onMouseOver, l]
  );
  return {
    onClick: i,
    onMouseUp: M,
    onMouseDown: c,
    onDoubleClick: s,
    onMouseEnter: a,
    onMouseLeave: v,
    onMouseMove: k,
    onMouseOut: C,
    onMouseOver: f
  };
};
export {
  h as useMouse
};
