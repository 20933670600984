/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { TABLE_ROW_INDEX_ATTRIBUTE as E, TABLE_COL_INDEX_ATTRIBUTE as T, TABLE_PREVENT_SELECTION_ELEMENT as _ } from "@progress/kendo-react-data-tools";
const R = E, N = T, L = _;
export {
  N as GRID_COL_INDEX_ATTRIBUTE,
  L as GRID_PREVENT_SELECTION_ELEMENT,
  R as GRID_ROW_INDEX_ATTRIBUTE
};
