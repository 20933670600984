/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as g from "react";
import s from "prop-types";
import { PagerNumericButtons as X } from "./PagerNumericButtons.mjs";
import { PagerInput as Y } from "./PagerInput.mjs";
import { PagerPageSizes as Z } from "./PagerPageSizes.mjs";
import { Button as ee } from "@progress/kendo-react-buttons";
import { pagerFirstPage as M, messages as n, pagerPreviousPage as v, pagerNextPage as y, pagerLastPage as S, pagerInfo as k, pagerAriaKeyshortcuts as z, pagerPage as b, pagerOf as R, pagerTotalPages as E } from "../messages/index.mjs";
import { provideLocalizationService as te, provideIntlService as se, registerForIntl as ae, registerForLocalization as ie } from "@progress/kendo-react-intl";
import { validatePackage as re, classNames as q, kendoThemeMaps as oe } from "@progress/kendo-react-common";
import { caretAltToRightIcon as D, caretAltToLeftIcon as F, caretAltRightIcon as V, caretAltLeftIcon as U } from "@progress/kendo-svg-icons";
import { packageMetadata as ne } from "../package-metadata.mjs";
const j = 600, ge = 768, f = class f extends g.Component {
  constructor(p) {
    super(p), this._element = null, this.onPageChange = (i, t) => {
      const l = {
        target: this,
        syntheticEvent: t.syntheticEvent,
        nativeEvent: t.nativeEvent,
        targetEvent: t,
        ...i
      };
      this.props.onPageChange && this.props.onPageChange.call(void 0, l);
    }, this.changePage = (i, t) => {
      this.props.onPageChange && i > 0 && i <= this.totalPages && this.props.onPageChange({
        target: this,
        skip: (i - 1) * this.props.take,
        take: this.props.take,
        syntheticEvent: t,
        nativeEvent: t.nativeEvent,
        targetEvent: t
      });
    }, this.onWindowResize = () => {
      const i = this.element;
      if (!i || !this.props.responsive)
        return;
      const t = i.offsetWidth;
      t < j ? this.setState({ showPagerSizes: !1 }) : t >= j && t < ge ? this.setState({ showPagerInfo: !1, showPagerSizes: !0 }) : this.setState({ showPagerInfo: !0, showPagerSizes: !0 });
    }, re(ne), this.state = { showPagerSizes: !0, showPagerInfo: !0 };
  }
  get isRtl() {
    return this.props.dir === "rtl";
  }
  /**
   * Returns the HTML element of the Pager component.
   */
  get element() {
    return this._element;
  }
  /**
   * @hidden
   */
  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize), this.onWindowResize();
  }
  /**
   * @hidden
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }
  /**
   * @hidden
   */
  render() {
    const { showPagerSizes: p, showPagerInfo: i } = this.state, { skip: t, take: l, total: h, pageSizes: u, size: o, responsive: d, messagesMap: e, pageSizeValue: G } = this.props, r = te(this), P = se(this), a = Math.floor(t / l) + 1, H = this.props.type === "numeric" ? /* @__PURE__ */ g.createElement(
      X,
      {
        type: d && !p ? "dropdown" : "numbers",
        buttonCount: this.props.buttonCount || 0,
        totalPages: this.totalPages,
        currentPage: a,
        pageChange: this.changePage,
        messagesMap: this.props.messagesMap,
        size: o
      }
    ) : /* @__PURE__ */ g.createElement(
      Y,
      {
        buttonCount: this.props.buttonCount || 0,
        totalPages: this.totalPages,
        currentPage: a,
        pageChange: this.changePage,
        messagesMap: e,
        size: o
      }
    ), K = e ? e(M) : { messageKey: M, defaultMessage: n[M] }, w = e ? e(v) : { messageKey: v, defaultMessage: n[v] }, C = e ? e(y) : { messageKey: y, defaultMessage: n[y] }, L = e ? e(S) : { messageKey: S, defaultMessage: n[S] }, I = e ? e(k) : { messageKey: k, defaultMessage: n[k] }, N = e ? e(z) : { messageKey: z, defaultMessage: n[z] }, A = e ? e(b) : { messageKey: b, defaultMessage: n[b] }, B = e ? e(R) : { messageKey: R, defaultMessage: n[R] }, c = e ? e(E) : { messageKey: E, defaultMessage: n[E] }, J = `${r.toLanguageString(
      A.messageKey,
      A.defaultMessage
    )} ${P.format(
      r.toLanguageString(
        c.messageKey,
        c.defaultMessage
      ),
      [a]
    )} ${r.toLanguageString(
      B.messageKey,
      B.defaultMessage
    )} ${P.format(
      r.toLanguageString(
        c.messageKey,
        c.defaultMessage
      ),
      [this.totalPages]
    )}`;
    let O, T, x, W;
    this.props.previousNext && (O = this.renderButton(
      1,
      "k-pager-nav k-pager-first" + (a === 1 ? " k-disabled" : ""),
      r.toLanguageString(K.messageKey, K.defaultMessage),
      this.isRtl ? "caret-alt-to-right" : "caret-alt-to-left",
      this.isRtl ? D : F,
      a === 1 ? !0 : void 0
    ), T = this.renderButton(
      a - 1,
      "k-pager-nav" + (a === 1 ? " k-disabled" : ""),
      r.toLanguageString(w.messageKey, w.defaultMessage),
      this.isRtl ? "caret-alt-right" : "caret-alt-left",
      this.isRtl ? V : U,
      a === 1 ? !0 : void 0
    ), x = this.renderButton(
      a + 1,
      "k-pager-nav" + (a >= this.totalPages ? " k-disabled" : ""),
      r.toLanguageString(C.messageKey, C.defaultMessage),
      this.isRtl ? "caret-alt-left" : "caret-alt-right",
      this.isRtl ? U : V,
      a >= this.totalPages ? !0 : void 0
    ), W = this.renderButton(
      this.totalPages,
      "k-pager-nav k-pager-last" + (a >= this.totalPages ? " k-disabled" : ""),
      r.toLanguageString(L.messageKey, L.defaultMessage),
      this.isRtl ? "caret-alt-to-left" : "caret-alt-to-right",
      this.isRtl ? F : D,
      a >= this.totalPages ? !0 : void 0
    ));
    const _ = u && /* @__PURE__ */ g.createElement(
      Z,
      {
        pageChange: this.onPageChange,
        pageSize: l,
        pageSizes: u,
        value: G,
        messagesMap: e,
        size: o
      }
    ), $ = this.props.info && /* @__PURE__ */ g.createElement("span", { className: "k-pager-info" }, P.format(r.toLanguageString(I.messageKey, I.defaultMessage), [
      Math.min(t + 1, h),
      Math.min(t + l, h),
      h
    ]));
    return /* @__PURE__ */ g.createElement(
      "div",
      {
        className: q(
          "k-pager",
          {
            [`k-pager-${oe.sizeMap[o] || o}`]: o
          },
          this.props.className
        ),
        style: this.props.style,
        role: "application",
        "aria-roledescription": "pager",
        dir: this.props.dir,
        "aria-keyshortcuts": r.toLanguageString(N.messageKey, N.defaultMessage),
        "aria-label": J,
        ref: (Q) => this._element = Q
      },
      /* @__PURE__ */ g.createElement("div", { className: q("k-pager-numbers-wrap") }, O, T, H, x, W),
      d ? p && _ : _,
      d ? i && $ : $
    );
  }
  renderButton(p, i, t, l, h, u) {
    return /* @__PURE__ */ g.createElement(
      ee,
      {
        fillMode: "flat",
        themeColor: "base",
        size: this.props.size,
        rounded: null,
        className: i,
        icon: l,
        svgIcon: h,
        title: t,
        role: "button",
        "aria-disabled": u,
        onClick: (o) => {
          o.preventDefault(), this.changePage(p, o);
        }
      }
    );
  }
  get totalPages() {
    return Math.ceil((this.props.total || 0) / this.props.take);
  }
};
f.propTypes = {
  className: s.string,
  style: s.object,
  total: s.number.isRequired,
  skip: s.number.isRequired,
  take: s.number.isRequired,
  buttonCount: s.number,
  info: s.bool,
  type: s.oneOf(["numeric", "input"]),
  pageSizes: s.arrayOf(s.oneOfType([
    s.string,
    s.number
  ])),
  previousNext: s.bool,
  onPageChange: s.func,
  messagesMap: s.func,
  size: s.oneOf([null, "small", "medium", "large"]),
  dir: s.string
}, f.defaultProps = {
  buttonCount: 10,
  info: !0,
  type: "numeric",
  size: "medium"
};
let m = f;
ae(m);
ie(m);
export {
  m as Pager
};
