/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const n = (l) => (t) => Math.floor(t / l), o = (l) => (t) => t * l;
class s {
  constructor(t) {
    this.dom = t, this.divideByMagnitude = null, this.powerByMagnitude = null, this.navigator = null, this.view = null, this.monthScrolled = !1, this.navScrolled = !1;
  }
  configure(t) {
    const i = Math.max(this.dom.viewHeight(t) / this.dom.navigationItemHeight, 1);
    this.divideByMagnitude = n(i), this.powerByMagnitude = o(i);
  }
  sync(t, i, e) {
    if (!(!t || !i)) {
      if (this.navigator = t, this.view = i, e.target === this.navigator.element) {
        if (this.monthScrolled) {
          this.monthScrolled = !1;
          return;
        }
        this.navScrolled = !0, this.scrollSiblingOf(this.navigator.element);
      }
      if (e.target === this.view.element) {
        if (this.navScrolled) {
          this.navScrolled = !1;
          return;
        }
        this.monthScrolled = !0, this.scrollSiblingOf(this.view.element);
      }
    }
  }
  scrollSiblingOf(t) {
    const i = this.siblingComponent(t), e = this.calculateScroll(i, t.scrollTop);
    i.scrollTo(e);
  }
  siblingComponent(t) {
    return this.navigator.element === t ? this.view : this.navigator;
  }
  calculateScroll(t, i) {
    const e = t === this.navigator ? this.divideByMagnitude : this.powerByMagnitude;
    return e ? e(i) : 0;
  }
}
export {
  s as ScrollSyncService
};
