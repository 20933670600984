/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { useTableKeyboardNavigation as r } from "@progress/kendo-react-data-tools";
import * as t from "react";
const x = (e) => {
  const { colSpan: a, ariaColIndex: n, dataItem: l, dataIndex: i, id: o } = e, d = r(o), c = t.useCallback((m) => {
    e.onContextMenu && e.onContextMenu.call(void 0, m, e.dataItem);
  }, [e.onContextMenu, e.dataItem]);
  return /* @__PURE__ */ t.createElement(
    "td",
    {
      onContextMenu: c,
      className: "k-table-td k-detail-cell",
      colSpan: a,
      "aria-colindex": n,
      role: "gridcell",
      ...d
    },
    /* @__PURE__ */ t.createElement(e.detail, { dataItem: l, dataIndex: i })
  );
};
export {
  x as GridDetailCell
};
