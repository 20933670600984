import { Class, deepExtend, setDefaultOptions } from '../../common';
import { SHOW_TOOLTIP, HIDE_TOOLTIP } from '../constants';

export var Tooltip = (function (Class) {
    function Tooltip(widgetService, options) {
        Class.call(this);

        this.widgetService = widgetService;
        this.options = deepExtend({}, this.options, options);
        this.offset = { x: 0, y: 0 };
    }

    if ( Class ) Tooltip.__proto__ = Class;
    Tooltip.prototype = Object.create( Class && Class.prototype );
    Tooltip.prototype.constructor = Tooltip;

    var prototypeAccessors = { anchor: { configurable: true } };

    Tooltip.prototype.show = function show (anchor, args) {
        if (this.location === args.location) {
            return;
        }

        this.anchor = anchor;
        this.location = args.location;

        this.widgetService.notify(SHOW_TOOLTIP,
            Object.assign({ anchor: this.anchor }, args)
        );

        this.visible = true;
    };

    Tooltip.prototype.hide = function hide () {
        if (this.widgetService) {
            this.widgetService.notify(HIDE_TOOLTIP);
        }

        this.visible = false;
        this.location = null;
    };

    prototypeAccessors.anchor.get = function () {
        return this._anchor;
    };

    prototypeAccessors.anchor.set = function (anchor) {
        var documentPoint = this.widgetService.widget._toDocumentCoordinates({
            x: anchor.left - this.offset.x,
            y: anchor.top - this.offset.y
        });

        this._anchor = {
            left: documentPoint.left,
            top: documentPoint.top
        };
    };

    Tooltip.prototype.destroy = function destroy () {
        this.widgetService = null;
    };

    Object.defineProperties( Tooltip.prototype, prototypeAccessors );

    return Tooltip;
}(Class));

setDefaultOptions(Tooltip, {
    border: {
        width: 1
    },
    opacity: 1
});
