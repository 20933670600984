import { Code39 } from './code39';
import deepExtend from '../../common/deep-extend';

export var Code39ExtendedBase = {
    addCharacter: function addCharacter(character) {
        if (this.characterMap[character]) {
            this.addBase(this.characterMap[character]);
        } else if (character.charCodeAt(0) > 127) {
            this.invalidCharacterError(character);
        } else {
            this.addExtended(character.charCodeAt(0));
        }
    },

    addExtended: function addExtended(code) {
        var this$1 = this;

        var patterns;

        for (var i = 0; i < this.extendedMappings.length; i++) {
            patterns = this$1.extendedMappings[i].call(this$1, code);

            if (patterns) {
                for (var patternIndex = 0; patternIndex < patterns.length; patternIndex++) {
                    var pattern = patterns[patternIndex];
                    this$1.addBase(pattern);
                }

                this$1.dataLength += patterns.length - 1;
                return;
            }
        }
    },

    extendedMappings: [
        function(code) {
            if (97 <= code && code <= 122) {
                return [this.characterMap[this.shiftCharacters[0]], this.characterMap[String.fromCharCode(code - 32)]];
            }
        },
        function(code) {
            if (33 <= code && code <= 58) {
                return [this.characterMap[this.shiftCharacters[1]], this.characterMap[String.fromCharCode(code + 32)]];
            }
        },
        function(code) {
            if (1 <= code && code <= 26) {
                return [this.characterMap[this.shiftCharacters[2]], this.characterMap[String.fromCharCode(code + 64)]];
            }
        },
        function(code) {
            var this$1 = this;

            var result;
            var dataCharacter;

            if (!this.specialAsciiCodes[code]) {
                dataCharacter = Math.floor(code / 32) * 6 + (code - 27) % 32 + 64;
                result = [this.characterMap[this.shiftCharacters[3]], this.characterMap[String.fromCharCode(dataCharacter)]];
            } else {
                result = [];

                for (var i = 0; i < this.specialAsciiCodes[code].length; i++) {
                    result.push(this$1.characterMap[this$1.shiftCharacters[3]]);
                    result.push(this$1.characterMap[this$1.specialAsciiCodes[code][i]]);
                }
            }

            return result;
        }
    ],
    specialAsciiCodes: {
        "0": ["U"],
        "64": ["V"],
        "96": ["W"],
        "127": ["T", "X", "Y", "Z"]
    },
    shiftValuesAsciiCodes: {
        "39": 36,
        "40": 47,
        "41": 43,
        "42": 37
    },
    characterMap: {
        "+": false,
        "/": false,
        "$": false,
        "%": false
    },
    shiftCharacters: ["SHIFT0", "SHIFT1", "SHIFT2", "SHIFT3"]
};

export var Code39Extended = (function (Code39) {
    function Code39Extended () {
        Code39.apply(this, arguments);
    }

    if ( Code39 ) Code39Extended.__proto__ = Code39;
    Code39Extended.prototype = Object.create( Code39 && Code39.prototype );
    Code39Extended.prototype.constructor = Code39Extended;

    Code39Extended.prototype.initProperties = function initProperties () {
        Code39.prototype.initProperties.call(this);

        deepExtend(this, Code39ExtendedBase, {
            name: "Code 39 extended",
            characterMap: {
                SHIFT0: { "pattern": "bWbwbWbWb", "value": 41 },
                SHIFT1: { "pattern": "bWbWbwbWb", "value": 40 },
                SHIFT2: { "pattern": "bWbWbWbwb", "value": 39 },
                SHIFT3: { "pattern": "bwbWbWbWb", "value": 42 }
            }
        });
    };

    return Code39Extended;
}(Code39));
