
import { ChartElement, BoxElement, Box, TextBox, FloatElement } from '../../core';
import LegendLayout from './legend-layout';
import LegendItem from './legend-item';

import { TOP, RIGHT, BOTTOM, LEFT, CENTER, X, Y, BLACK, CIRCLE, POINTER, START, END, HORIZONTAL, SQUARE } from '../../common/constants';
import { deepExtend, defined, getTemplate, getSpacing, inArray, setDefaultOptions } from '../../common';

var CUSTOM = "custom";

var Legend = (function (ChartElement) {
    function Legend(options, chartService) {
        if ( chartService === void 0 ) chartService = {};

        ChartElement.call(this, options);

        this.chartService = chartService;

        if (!inArray(this.options.position, [ TOP, RIGHT, BOTTOM, LEFT, CUSTOM ])) {
            this.options.position = RIGHT;
        }

        this.createContainers();

        this.createLegendTitle(options.title);

        this.createItems();
    }

    if ( ChartElement ) Legend.__proto__ = ChartElement;
    Legend.prototype = Object.create( ChartElement && ChartElement.prototype );
    Legend.prototype.constructor = Legend;

    Legend.prototype.createContainers = function createContainers () {
        var options = this.options;
        var position = options.position;
        var userAlign = options.align;
        var align = position;
        var vAlign = CENTER;

        if (position === CUSTOM) {
            align = LEFT;
        } else if (inArray(position, [ TOP, BOTTOM ])) {
            if (userAlign === START) {
                align = LEFT;
            } else if (userAlign === END) {
                align = RIGHT;
            } else {
                align = CENTER;
            }
            vAlign = position;
        } else if (userAlign) {
            if (userAlign === START) {
                vAlign = TOP;
            } else if (userAlign === END) {
                vAlign = BOTTOM;
            }
        }

        this.container = new BoxElement({
            margin: options.margin,
            padding: options.padding,
            background: options.background,
            border: options.border,
            vAlign: vAlign,
            align: align,
            zIndex: options.zIndex,
            shrinkToFit: true
        });

        if (this.hasTitle()) {
            this.itemsContainer = new BoxElement({
                vAlign: vAlign,
                align: align,
                zIndex: options.zIndex,
                shrinkToFit: true
            });
        } else {
            this.itemsContainer = this.container;
        }

        this.append(this.container);
    };

    Legend.prototype.createItems = function createItems () {
        var chartService = this.getService();
        var options = this.options;
        var vertical = this.isVertical();
        var innerElement = new LegendLayout({
            vertical: vertical,
            spacing: options.spacing,
            rtl: chartService.rtl
        }, chartService);
        var data = options.data;

        if (options.reverse) {
            data = data.slice(0).reverse();
        }

        var count = data.length;

        for (var i = 0; i < count; i++) {
            var dataItem = data[i];
            var ref = dataItem.series || {};
            var markers = ref.markers; if ( markers === void 0 ) markers = {};
            var dashType = ref.dashType;
            var legendItem = ref.legendItem;
            var opacity = ref.opacity;
            var markersOptions = deepExtend({ visible: markers.visible !== false, type: CIRCLE }, markers);
            delete markersOptions.size;

            var itemOptions = deepExtend({},
                {
                    markers: markersOptions,
                    labels: options.labels,
                    rtl: chartService.rtl,
                    line: Object.assign({}, {dashType: dashType},
                        options.line),
                    area: Object.assign({}, {opacity: opacity},
                        options.area),
                    opacity: opacity,
                    accessibility: options.accessibility
                },
                options.item,
                legendItem,
                dataItem,
                { markers: options.markers }
            );

            innerElement.append(new LegendItem(itemOptions));
        }

        innerElement.render();
        this.itemsContainer.append(innerElement);
    };

    Legend.prototype.isVertical = function isVertical () {
        var ref = this.options;
        var orientation = ref.orientation;
        var position = ref.position;
        var vertical = (position === CUSTOM && orientation !== HORIZONTAL) ||
               (defined(orientation) ? orientation !== HORIZONTAL : inArray(position, [ LEFT, RIGHT ]));

        return vertical;
    };

    Legend.prototype.hasItems = function hasItems () {
        return this.container.children[0].children.length > 0;
    };

    Legend.prototype.getItems = function getItems () {
        return this.itemsContainer.children[0].children;
    };

    Legend.prototype.reflow = function reflow (targetBox) {
        var options = this.options;
        var legendBox = targetBox.clone();

        if (!this.hasItems()) {
            this.box = legendBox;
            return;
        }

        if (options.position === CUSTOM) {
            this.containerCustomReflow(legendBox);
            this.box = legendBox;
        } else {
            this.containerReflow(legendBox);
        }

        if (this.hasTitle()) {
            this.title.reflow(new Box(this.container.box.x1, this.title.box.y1, this.container.box.x2, this.title.box.y2));
        }
    };

    Legend.prototype.containerReflow = function containerReflow (targetBox) {
        var ref = this;
        var options = ref.options;
        var container = ref.container;
        var position = options.position;
        var width = options.width;
        var height = options.height;
        var pos = position === TOP || position === BOTTOM ? X : Y;
        var vertical = this.isVertical();
        var alignTarget = targetBox.clone();
        var containerBox = targetBox.clone();

        if (position === LEFT || position === RIGHT) {
            containerBox.y1 = alignTarget.y1 = 0;
        }

        if (vertical && height) {
            containerBox.y2 = containerBox.y1 + height;
            containerBox.align(alignTarget, Y, container.options.vAlign);
        } else if (!vertical && width) {
            containerBox.x2 = containerBox.x1 + width;
            containerBox.align(alignTarget, X, container.options.align);
        }

        container.reflow(containerBox);
        containerBox = container.box;

        var box = containerBox.clone();

        if (options.offsetX || options.offsetY) {
            containerBox.translate(options.offsetX, options.offsetY);
            container.reflow(containerBox);
        }

        box[pos + 1] = targetBox[pos + 1];
        box[pos + 2] = targetBox[pos + 2];

        this.box = box;
    };

    Legend.prototype.containerCustomReflow = function containerCustomReflow (targetBox) {
        var ref = this;
        var options = ref.options;
        var container = ref.container;
        var offsetX = options.offsetX;
        var offsetY = options.offsetY;
        var width = options.width;
        var height = options.height;
        var vertical = this.isVertical();
        var containerBox = targetBox.clone();

        if (vertical && height) {
            containerBox.y2 = containerBox.y1 + height;
        } else if (!vertical && width) {
            containerBox.x2 = containerBox.x1 + width;
        }
        container.reflow(containerBox);
        containerBox = container.box;

        container.reflow(new Box(
            offsetX, offsetY,
            offsetX + containerBox.width(), offsetY + containerBox.height()
        ));
    };

    Legend.prototype.renderVisual = function renderVisual () {
        if (this.hasItems()) {
            ChartElement.prototype.renderVisual.call(this);
        }
    };

    Legend.prototype.createLegendTitle = function createLegendTitle (title) {
        var titleOptions = deepExtend({}, {
            color: BLACK,
            position: TOP,
            align: CENTER
        }, title);
        var text = titleOptions.text;

        if (!title || title.visible === false || !title.text) {
            return;
        }

        if (defined(titleOptions) && titleOptions.visible) {
            var labelTemplate = getTemplate(titleOptions);
            if (labelTemplate) {
                text = labelTemplate({ text: text });
            } else if (titleOptions.format) {
                text = this.chartService.format.auto(titleOptions.format, text);
            }
        }

        this.title = new TextBox(text, titleOptions);

        this.createTitleLayout();

        this.appendTitleLayoutContent();
    };

    Legend.prototype.createTitleLayout = function createTitleLayout () {
        this.layout = new FloatElement({
            vertical: true,
            wrap: false
        });

        this.container.append(this.layout);
    };

    Legend.prototype.hasTitle = function hasTitle () {
        return Boolean(this.options.title && this.options.title.visible !== false && this.options.title.text);
    };

    Legend.prototype.appendTitleLayoutContent = function appendTitleLayoutContent () {
        var options = this.options;

        if (options.title.position === BOTTOM) {
            this.layout.append(this.itemsContainer);
            this.layout.append(this.title);
        } else {
            this.layout.append(this.title);
            this.layout.append(this.itemsContainer);
        }
    };

    return Legend;
}(ChartElement));

setDefaultOptions(Legend, {
    position: RIGHT,
    data: [],
    offsetX: 0,
    offsetY: 0,
    margin: getSpacing(2),
    padding: getSpacing(5),
    border: {
        color: BLACK,
        width: 0
    },
    item: {
        cursor: POINTER,
        spacing: 6
    },
    spacing: 6,
    background: "",
    zIndex: 1,
    markers: {},
    line: {
        width: 20,
        height: 2,
        cursor: POINTER,
        opacity: 1
    },
    area: {
        type: SQUARE,
        align: RIGHT,
        width: 15,
        height: 15,
    }
});

export default Legend;
