/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
function c(t, n, e, i) {
  if (t) {
    const a = {
      syntheticEvent: n,
      nativeEvent: n.nativeEvent,
      target: e
    };
    t.call(void 0, Object.assign(a, i));
  }
}
export {
  c as dispatchEvent
};
