/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { guid as S, getActiveElement as C, Keys as f } from "@progress/kendo-react-common";
import { tableKeyboardNavigationTools as a, findId as y, findNextIdByRowIndex as h, findNextIdByCellIndex as F, getHeaderElement as R, getBodyElement as A, getNavigatableId as D } from "./utils.mjs";
const k = (e) => {
  const { navigatable: o, contextStateRef: n, navigationStateRef: t, idPrefix: c } = e;
  o && (n.current = {
    activeId: "",
    level: 0
  }, t.current = {
    activeElementIsFocused: !1,
    prevNavigationIndexes: void 0,
    idPrefix: c || S(),
    navigationMatrix: [],
    lastHeaderIndex: -1
  });
}, w = (e) => {
  const { scope: o, contextStateRef: n, navigationStateRef: t } = e;
  if (n.current && t.current && o) {
    N(e);
    const c = t.current.navigationMatrix.flat().find((r) => r.endsWith("cell"));
    if (c) {
      const r = a.getActiveNavDataElement(o, c);
      r && (n.current.activeId = c, r.setAttribute("tabIndex", "0"));
    }
  }
}, M = (e) => {
  const { contextStateRef: o, navigationStateRef: n, document: t } = e;
  if (o.current && n.current && t) {
    const c = C(t), r = a.getNavigatableId(c);
    r && r === o.current.activeId && (n.current.activeElementIsFocused = !0);
  }
}, B = (e) => {
  const { scope: o, contextStateRef: n, navigationStateRef: t } = e;
  if (N(e), n.current && t.current && o) {
    if (!a.getActiveNavDataElement(o, n.current.activeId)) {
      const r = t.current.navigationMatrix[0][0], i = a.getActiveNavDataElement(o, r);
      r && i && (n.current.activeId = r, i.setAttribute("tabIndex", "0"), t.current.activeElementIsFocused && i.focus());
    }
    t.current.activeElementIsFocused = !1;
  }
}, K = (e, o) => {
  const { contextStateRef: n } = o;
  if (e.isDefaultPrevented() || !n.current)
    return;
  const t = e.target, c = a.getNavigatableId(t);
  if (c && c !== n.current.activeId) {
    const r = a.getClosestScope(t);
    if (!r)
      return;
    const i = a.getActiveNavDataElement(r, n.current.activeId);
    i && i.setAttribute("tabIndex", "-1"), t.setAttribute("tabIndex", "0"), n.current.activeId = c;
  }
}, P = (e, o) => {
  const {
    contextStateRef: n,
    navigationStateRef: t,
    onNavigationAction: c
  } = o;
  if (e.isDefaultPrevented() || !n.current || !t.current)
    return;
  if (e.keyCode === f.esc) {
    const l = a.getClosestNavigatableElement(e.target);
    a.focusElement({ elementForFocus: l, event: e, contextStateRef: n });
    return;
  }
  const r = e.target, i = r.className.indexOf("k-checkbox") === -1 ? r : a.getClosestNavigatableElement(r), v = a.getNavigatableId(i), m = a.getNavigatableLevel(i), I = a.getClosestScope(i), u = t.current.navigationMatrix;
  if (m !== void 0 && I) {
    if (e.keyCode === f.enter) {
      const l = a.getNavigatableElement(i, { level: m + 1 });
      if (l) {
        a.focusElement({
          elementForFocus: l,
          event: e,
          contextStateRef: n,
          prevElement: i
        });
        return;
      } else {
        const E = a.getFocusableElements(i)[0];
        a.focusElement({ elementForFocus: E, event: e, contextStateRef: n, prevElement: i });
        return;
      }
    }
    if (e.keyCode === f.up || e.keyCode === f.down || e.keyCode === f.left || e.keyCode === f.right) {
      const l = e.keyCode === f.up || e.keyCode === f.left, E = e.keyCode === f.up || e.keyCode === f.down;
      let g;
      if (t.current && t.current.prevNavigationIndexes) {
        const [d, s] = t.current.prevNavigationIndexes;
        u[d][s] === v ? g = t.current.prevNavigationIndexes : g = y(u, v);
      } else
        g = y(u, v);
      if (g) {
        const [d, s] = g, [x, p] = E ? h(d, s, v, u, l) : F(d, s, v, u, l);
        if (x) {
          const b = a.getActiveNavDataElement(I, x);
          a.focusElement({ elementForFocus: b, event: e, contextStateRef: n, prevElement: i }), t.current.prevNavigationIndexes = p, c && c({ focusElement: b, event: e });
        }
      }
    }
  }
}, N = (e) => {
  const { navigationStateRef: o, scope: n } = e;
  if (!o.current || !n)
    return;
  const t = [], c = R(n), r = A(n);
  if (!c || !r)
    return;
  const i = Array.from(c.children), v = Array.from(r.children);
  [...i, ...v].forEach((m, I) => {
    Array.from(m.children).forEach((u) => {
      const l = D(u);
      if (!l)
        return;
      const E = u.rowSpan || 1, g = u.colSpan || 1;
      let d;
      for (let s = I, x = I + E; s < x; s++) {
        if (t[s] || (t[s] = []), d === void 0) {
          const p = t[s].findIndex((b) => !b);
          d = p > -1 ? p : t[s].length;
        }
        t[s][d] = l || "";
      }
      for (let s = d + 1, x = d + g; s < x; s++)
        t[I][s] = l || "";
    });
  }), o.current.navigationMatrix = t.filter((m) => !!m), o.current.lastHeaderIndex = i.length - 1;
}, U = {
  onConstructor: k,
  onComponentDidMount: w,
  onGetSnapshotBeforeUpdate: M,
  onComponentDidUpdate: B,
  onFocus: K,
  onKeyDown: P,
  generateMatrix: N
};
export {
  U as tableKeyboardNavigation
};
