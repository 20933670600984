import { Title as ChartTitle } from "../core";
import { SankeyElement } from "./element";
import { setDefaultOptions, getSpacing } from '../common';
import { CENTER, TOP } from "../common/constants";

export var Title = (function (SankeyElement) {
    function Title () {
        SankeyElement.apply(this, arguments);
    }

    if ( SankeyElement ) Title.__proto__ = SankeyElement;
    Title.prototype = Object.create( SankeyElement && SankeyElement.prototype );
    Title.prototype.constructor = Title;

    Title.prototype.getElement = function getElement () {
        var options = this.options;
        var drawingRect = options.drawingRect;
        var text = options.text;

        if (options.visible === false || !text) {
            return null;
        }

        var title = ChartTitle.buildTitle(text, options);

        title.reflow(drawingRect);

        title.renderVisual();
        return title.visual;
    };

    Title.prototype.createElement = function createElement () {
        return this.getElement();
    };

    return Title;
}(SankeyElement));

setDefaultOptions(Title, {
    position: TOP, // 'top', 'bottom'
    align: CENTER, // 'left', 'right', 'center'
    opacity: 1,
    border: {
        width: 0
    },
    margin: getSpacing(5),
    padding: getSpacing(5)
});
