import { Encoding } from './encoding';
import inArray from '../../common/in-array';

var numberRegex = /^\d+$/;
var extend = Object.assign;

export var Postnet = (function (Encoding) {
    function Postnet () {
        Encoding.apply(this, arguments);
    }

    if ( Encoding ) Postnet.__proto__ = Encoding;
    Postnet.prototype = Object.create( Encoding && Encoding.prototype );
    Postnet.prototype.constructor = Postnet;

    Postnet.prototype.initProperties = function initProperties () {
        Encoding.prototype.initProperties.call(this);

        extend(this, {
            name: "Postnet",
            START: "2",
            VALID_CODE_LENGTHS: [5, 9, 11],
            DIGIT_SEPARATOR: "-",
            characterMap: ["22111", "11122", "11212", "11221", "12112", "12121", "12211", "21112", "21121", "21211"]
        });
    };

    Postnet.prototype.initValue = function initValue (value, width, height) {
        this.height = height;
        this.width = width;
        this.baseHeight = height / 2;
        this.value = value.replace(new RegExp(this.DIGIT_SEPARATOR, "g"), "");
        this.pattern = [];
        this.validate(this.value);
        this.checkSum = 0;
        this.setBaseUnit();
    };

    Postnet.prototype.addData = function addData () {
        var this$1 = this;

        var value = this.value;

        this.addPattern(this.START);

        for (var i = 0; i < value.length; i++) {
            this$1.addCharacter(value.charAt(i));
        }

        if (this.options.addCheckSum) {
            this.addCheckSum();
        }

        this.addPattern(this.START);
        this.pattern.pop();
    };

    Postnet.prototype.addCharacter = function addCharacter (character) {
        var pattern = this.characterMap[character];
        this.checkSum += parseInt(character, 10);
        this.addPattern(pattern);
    };

    Postnet.prototype.addCheckSum = function addCheckSum () {
        this.checksum = (10 - (this.checkSum % 10)) % 10;
        this.addCharacter(this.checksum);
    };

    Postnet.prototype.setBaseUnit = function setBaseUnit () {
        var startStopLength = 3;

        this.baseUnit = this.width / ((this.value.length + 1) * 10 + startStopLength + this.quietZoneLength);
    };

    Postnet.prototype.validate = function validate (value) {
        if (!numberRegex.test(value)) {
            this.invalidCharacterError(value.match(/[^0-9]/)[0]);
        }

        if (!inArray(value.length, this.VALID_CODE_LENGTHS)) {
            throw new Error("Invalid value length. Valid lengths for the Postnet symbology are " + this.VALID_CODE_LENGTHS.join(",") + ".");
        }
    };

    Postnet.prototype.addPattern = function addPattern (pattern) {
        var this$1 = this;

        var y1;

        for (var i = 0; i < pattern.length; i++) {
            y1 = this$1.height - this$1.baseHeight * pattern.charAt(i);
            this$1.pattern.push({ width: 1, y1: y1, y2: this$1.height });
            this$1.pattern.push(1);
        }
    };

    return Postnet;
}(Encoding));
