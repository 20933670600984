/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const e = "multiviewcalendar.prevView", t = "multiviewcalendar.nextView", a = "dateinput.increment", c = "dateinput.decrement", n = "calendar.today", i = "datepicker.toggleCalendar", r = "daterangepicker.swapStartEnd", o = "daterangepicker.start", l = "daterangepicker.end", s = "daterangepicker.separator", d = "datetimepicker.toggleDateTimeSelector", g = "timepicker.now", m = "timepicker.selectNow", p = "timepicker.cancel", k = "timepicker.set", w = "timepicker.toggleTimeSelector", S = "timepicker.toggleClock", T = "datetimepicker.date", v = "datetimepicker.time", u = "datetimepicker.cancel", C = "datetimepicker.set", N = "daterangepicker.cancel", P = "daterangepicker.set", V = {
  [n]: "TODAY",
  [g]: "NOW",
  [k]: "Set",
  [p]: "Cancel",
  [T]: "Date",
  [v]: "Time",
  [u]: "Cancel",
  [C]: "Set",
  [N]: "Cancel",
  [P]: "Set",
  [o]: "Start",
  [l]: "End",
  [s]: " ",
  [m]: "Select Now",
  [w]: "Toggle TimeSelector",
  [S]: "Toggle Clock",
  [a]: "Increase value",
  [c]: "Decrease value",
  [i]: "Toggle calendar",
  [e]: "Navigate to previous view",
  [t]: "Navigate to next view",
  [r]: "Swap start and end values",
  [d]: "Toggle date-time selector"
};
export {
  T as date,
  N as dateRangePickerCancel,
  P as dateRangePickerSet,
  u as dateTimePickerCancel,
  C as dateTimePickerSet,
  c as decreaseValue,
  l as end,
  a as increaseValue,
  V as messages,
  t as nextView,
  g as now,
  e as prevView,
  m as selectNow,
  s as separator,
  o as start,
  r as swapStartEnd,
  v as time,
  p as timePickerCancel,
  k as timePickerSet,
  n as today,
  i as toggleCalendar,
  S as toggleClock,
  d as toggleDateTimeSelector,
  w as toggleTimeSelector
};
