import { parseColor } from '@progress/kendo-drawing';

// Linear color scale from the given color to white minus minimum lightness offset.
var colorScale = function (color, minLightnessOffset) {
    if ( minLightnessOffset === void 0 ) minLightnessOffset = 0.05;

    var baseColor = parseColor(color);
    var offset = 1 - minLightnessOffset;

    return function (value) {
        var hsl = baseColor.toHSL();
        var range = 100 - hsl.l;
        var point = offset - value;
        hsl.l += Math.min(point * range, range);

        return hsl.toCss();
    };
};

export default colorScale;
