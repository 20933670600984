/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { classNames as o, kendoThemeMaps as r } from "@progress/kendo-react-common";
const s = class s extends e.Component {
  /**
   * @hidden
   */
  render() {
    return /* @__PURE__ */ e.createElement(
      "div",
      {
        className: o(
          "k-toolbar k-grid-toolbar",
          {
            "k-toolbar-md": !this.props.size,
            [`k-toolbar-${r.sizeMap[this.props.size] || this.props.size}`]: this.props.size
          },
          this.props.className
        ),
        "aria-label": this.props.ariaLabel,
        "aria-controls": this.props.ariaControls,
        role: "toolbar"
      },
      this.props.children
    );
  }
};
s.displayName = "KendoReactGridToolbar";
let a = s;
export {
  a as GridToolbar
};
