/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { Action as t } from "../models/NavigationAction.mjs";
const n = {
  33: t.PrevView,
  34: t.NextView,
  35: t.LastInView,
  36: t.FirstInView,
  37: t.Left,
  38: t.Up,
  39: t.Right,
  40: t.Down,
  "meta+38": t.UpperView,
  "meta+40": t.LowerView
};
class p {
  constructor(e) {
    this.bus = e;
  }
  action(e) {
    const r = `${e.ctrlKey || e.metaKey ? "meta+" : ""}${e.keyCode}`;
    return n[r];
  }
  move(e, r, o, i, s) {
    return i ? r === t.UpperView && this.bus.canMoveUp(o) ? (this.bus.moveUp(o, s), e) : r === t.LowerView && this.bus.canMoveDown(o) ? (this.bus.moveDown(o, s), e) : i.move(e, r) : e;
  }
}
export {
  p as NavigationService
};
