/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as i from "react";
import { Button as h } from "@progress/kendo-react-buttons";
import { DropDownList as a } from "@progress/kendo-react-dropdowns";
import { TextBox as c, NumericTextBox as u } from "@progress/kendo-react-inputs";
import { DatePicker as m } from "@progress/kendo-react-dateinputs";
import { filterChooseOperator as n, messages as p, filterClearButton as l } from "../messages/index.mjs";
import { provideLocalizationService as f, registerForLocalization as d } from "@progress/kendo-react-intl";
import { filterIcon as g, filterClearIcon as C } from "@progress/kendo-svg-icons";
import { cellInputChange as v, cellOperatorChange as b, cellBoolDropdownChange as z } from "../filterCommon.mjs";
import { classNames as E } from "@progress/kendo-react-common";
class L extends i.Component {
  constructor(e) {
    super(e), this._inputRef = i.createRef(), this.renderOperatorEditor = (t) => {
      if (this.props.filterType === "boolean")
        return;
      const o = this.props.operators.find((r) => r.operator === this.props.operator) || null;
      return /* @__PURE__ */ i.createElement(
        a,
        {
          size: this.props.size,
          value: o,
          onChange: this.operatorChange,
          className: "k-dropdown-operator",
          iconClassName: "k-i-filter k-icon",
          svgIcon: g,
          data: this.props.operators,
          textField: "text",
          title: t.toLanguageString(n, p[n]),
          popupSettings: {
            width: ""
          }
        }
      );
    }, this.inputChange = this.inputChange.bind(this), this.clear = this.clear.bind(this), this.operatorChange = this.operatorChange.bind(this), this.boolDropdownChange = this.boolDropdownChange.bind(this);
  }
  /**
   * @hidden
   */
  render() {
    const e = f(this), t = /* @__PURE__ */ i.createElement("div", { className: "k-filtercell" }, /* @__PURE__ */ i.createElement("div", { className: "k-filtercell-wrapper" }, this.filterComponent(this.props.filterType, this.props.value, this.props.booleanValues), /* @__PURE__ */ i.createElement("div", { className: "k-filtercell-operator" }, this.renderOperatorEditor(e), " ", /* @__PURE__ */ i.createElement(
      h,
      {
        size: this.props.size,
        icon: "filter-clear",
        svgIcon: C,
        className: E({
          "k-clear-button-visible": !!(!(this.props.value === null || this.props.value === "") || this.props.operator)
        }),
        title: e.toLanguageString(l, p[l]),
        type: "button",
        onClick: this.clear,
        disabled: !(!(this.props.value === null || this.props.value === "") || this.props.operator)
      }
    ))));
    return this.props.render ? this.props.render.call(void 0, t, this.props) : t;
  }
  inputChange(e, t) {
    v(e, t, this.props);
  }
  operatorChange(e) {
    b(e, this.props.value, this.props.onChange);
  }
  boolDropdownChange(e) {
    z(e, this.props.onChange);
  }
  clear(e) {
    e.preventDefault(), this.props.onChange({ value: "", operator: "", syntheticEvent: e }), this._inputRef.current && (this._inputRef.current.element.value = "", setTimeout(() => {
      this._inputRef.current.element.focus();
    }, 0));
  }
  filterComponent(e, t, o) {
    switch (e) {
      case "numeric":
        return /* @__PURE__ */ i.createElement(
          u,
          {
            ref: this._inputRef,
            size: this.props.size,
            value: t,
            onChange: (r) => {
              this.inputChange(r.value, r.syntheticEvent);
            },
            title: this.props.title,
            ariaLabel: this.props.ariaLabel
          }
        );
      case "date":
        return /* @__PURE__ */ i.createElement(
          m,
          {
            ref: this._inputRef,
            size: this.props.size,
            value: t,
            onChange: (r) => {
              this.inputChange(r.value, r.syntheticEvent);
            },
            title: this.props.title,
            ariaLabel: this.props.ariaLabel
          }
        );
      case "boolean": {
        const r = (s) => s == null;
        return /* @__PURE__ */ i.createElement(
          a,
          {
            ref: this._inputRef,
            size: this.props.size,
            onChange: this.boolDropdownChange,
            value: o.find((s) => s.operator === (r(t) ? "" : t)),
            data: o,
            textField: "text",
            title: this.props.title,
            ariaLabel: this.props.ariaLabel
          }
        );
      }
      default:
        return /* @__PURE__ */ i.createElement(
          c,
          {
            ref: this._inputRef,
            size: this.props.size,
            value: t || "",
            onChange: (r) => {
              this.inputChange(r.target.value, r.syntheticEvent);
            },
            title: this.props.title,
            "aria-label": this.props.ariaLabel
          }
        );
    }
  }
}
d(L);
export {
  L as GridFilterCell
};
