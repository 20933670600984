/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as a from "react";
import { classNames as s } from "@progress/kendo-react-common";
const m = a.forwardRef((e, n) => {
  const { _ref: r } = e, t = a.useRef(null);
  a.useImperativeHandle(n, () => t.current), a.useImperativeHandle(r, () => t.current);
  const c = a.useMemo(
    () => s(e.className, "k-picker-wrap"),
    [e.className]
  );
  return /* @__PURE__ */ a.createElement(
    "span",
    {
      ref: t,
      id: e.id,
      style: e.style,
      className: c,
      tabIndex: e.tabIndex
    },
    e.children
  );
});
export {
  m as PickerWrap
};
