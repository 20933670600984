/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { isArray as h, mapTree as g } from "@progress/kendo-react-common";
const w = "items";
var L = /* @__PURE__ */ ((t) => (t.copy = "copy", t.cut = "cut", t.paste = "paste", t))(L || {});
const b = (t, e, s) => {
  const n = t.cellDelimiter || "	", i = t.newLineDelimiter || `\r
`, l = e.includes(i) ? i : e.includes(`
`) ? `
` : null, a = l ? e.split(l) : [e], m = s.copiedItems.length ? s.copiedItems[0].fields[0] : t.columns[0].field || "";
  return a.map((c) => {
    const o = c.includes(n) ? c.split(n) : [c], p = t.columns.map((u) => u.field || ""), d = p.indexOf(m), f = p.slice(d, d + o.length), D = {};
    return p.slice(d, d + o.length).forEach((u, I) => D[u] = o[I]), {
      dataItem: D,
      fields: f
    };
  });
}, C = (t, e) => {
  if (!e.length)
    return null;
  let s = "";
  return e.forEach((n, i, l) => s += `${t[n]}${i < l.length - 1 ? "	" : ""}`), s;
}, E = (t, e, s) => {
  const n = s.cellDelimiter || "	", i = s.newLineDelimiter || `\r
`;
  return `${e.join(n)}${i}${t}`;
}, F = (t) => {
  const { event: e, selectedState: s, data: n, dataItemKey: i } = t, l = {}, a = { copiedItems: [], pastedItems: [], clipboardData: "" }, m = t.subItemsField || w;
  return Object.entries(s).forEach(([r, c]) => {
    l[r] = h(c) ? c.map((o) => e.columns[o]) : e.columns;
  }), g(n, m, (r) => {
    const c = r[i || e.dataItemKey || ""] || "";
    return l[c] && a.copiedItems.push({
      dataItem: r,
      fields: l[c].map((o) => o.field || "")
    }), r;
  }), a;
}, x = (t, e) => {
  const s = e.newLineDelimiter || `\r
`, n = t.copiedItems.map((i, l) => {
    let a = C(i.dataItem, i.fields) || "";
    return e.copyHeaders && l === 0 && (a = E(a, i.fields, e)), a ? [a] : [];
  }).join(s);
  return navigator.clipboard.writeText(n), n;
}, O = (t) => {
  const { event: e } = t, s = F(t);
  let n = "";
  if (e.type === "paste") {
    const i = e.nativeEvent ? e.nativeEvent.clipboardData : "";
    n = i ? i.getData("text").trim() : "", s.pastedItems = b(e, n, s);
  } else
    s.pastedItems = [], n = x(s, e);
  return s.clipboardData = n, s;
};
export {
  L as ClipboardActionType,
  O as populateClipboardData
};
