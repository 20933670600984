import isObject from './is-object';
import isPlainObject from './is-plain-object';
import isString from './is-string';
import deepExtend from './deep-extend';
import setDefaultOptions from './set-default-options';
import addClass from './add-class';
import removeClass from './remove-class';

var KICON = 'k-icon';
var KI_PREFFIX = 'k-i-';
var KSVGICON = 'k-svg-icon';
var KSVG_PREFFIX = 'k-svg-i-';

var HTMLBaseIcon = function HTMLBaseIcon(element, options) {
    this.element = element;
    this.options = deepExtend({}, this.options, options);

    this.wrapper();
};

HTMLBaseIcon.prototype.wrapper = function wrapper () {
    this.addClasses();
};

HTMLBaseIcon.prototype.addClasses = function addClasses () {
};

HTMLBaseIcon.prototype.html = function html () {
    return this.element.outerHTML;
};

setDefaultOptions(HTMLBaseIcon, {
    name: '',
    size: 'none',
    themeColor: 'none',
    flip: 'default',
    iconClass: '',
    stylingOptions: [ 'size', 'themeColor', 'fill' ]
});

var HTMLFontIcon = (function (HTMLBaseIcon) {
    function HTMLFontIcon(element, options) {
        HTMLBaseIcon.call(this, element, options);
    }

    if ( HTMLBaseIcon ) HTMLFontIcon.__proto__ = HTMLBaseIcon;
    HTMLFontIcon.prototype = Object.create( HTMLBaseIcon && HTMLBaseIcon.prototype );
    HTMLFontIcon.prototype.constructor = HTMLFontIcon;

    HTMLFontIcon.prototype.wrapper = function wrapper () {
        // Find if there is an existing k-i- class appended to the element.
        var currentIconClass = this.element.className.split(" ").find(function (x) { return x.startsWith(KI_PREFFIX); });
        var className = this.options.icon ? ("" + (this.options.icon.startsWith(KI_PREFFIX) ? "" : KI_PREFFIX) + (this.options.icon)) : "";

        this._className = className;

        addClass(this.element, KICON);
        removeClass(this.element, currentIconClass); // Remove any existing icons.
        addClass(this.element, className);
        addClass(this.element, this.options.iconClass || '');

        HTMLBaseIcon.prototype.wrapper.call(this);
    };

    return HTMLFontIcon;
}(HTMLBaseIcon));

setDefaultOptions(HTMLFontIcon, {
    name: 'HTMLFontIcon',
    icon: null
});

var HTMLSvgIcon = (function (HTMLBaseIcon) {
    function HTMLSvgIcon(element, options) {
        // Ensure that the inner contents of the wrapping span element are always removed for re-rendering purposes.
        element.innerHTML = "";

        HTMLBaseIcon.call(this, element, options);
    }

    if ( HTMLBaseIcon ) HTMLSvgIcon.__proto__ = HTMLBaseIcon;
    HTMLSvgIcon.prototype = Object.create( HTMLBaseIcon && HTMLBaseIcon.prototype );
    HTMLSvgIcon.prototype.constructor = HTMLSvgIcon;

    HTMLSvgIcon.prototype.wrapper = function wrapper () {
        var icon = this.options.icon;
        var iconClass = this.options.iconClass;
        var currentIconClass = this.element.className.split(" ").find(function (x) { return x.startsWith(KSVG_PREFFIX); });

        if (!icon && iconClass) {
            // match k-i-(some-icon-name)
            var regex = /k-i-(\w+(?:-\w+)*)/;
            var iconNameMatch = iconClass.match(regex);
            if (iconNameMatch) {
                icon = iconNameMatch[1];
                iconClass = iconClass.replace(iconNameMatch[0], "");
            }
        }

        if (isString(icon)) {
            icon = icon.replace("k-i-", "").replace(/-./g, function (x) { return x[1].toUpperCase(); });
            icon = this.options.svgIcons[icon] || this.options.svgIcons[(icon + "Icon")];
        }

        var className = icon && icon.name ? ("" + KSVG_PREFFIX + (icon.name)) : "";
        this._className = className;

        addClass(this.element, KSVGICON);
        removeClass(this.element, currentIconClass);
        addClass(this.element, className);
        addClass(this.element, iconClass || "");
        this.element.setAttribute("aria-hidden", "true");

        if (icon && isPlainObject(icon)) {
            var svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            svgElement.setAttribute("viewBox", icon.viewBox || "");
            svgElement.setAttribute("focusable", "false");
            svgElement.innerHTML = icon.content || "";

            this.element.appendChild(svgElement);
        }

        HTMLBaseIcon.prototype.wrapper.call(this);
    };

    return HTMLSvgIcon;
}(HTMLBaseIcon));

setDefaultOptions(HTMLSvgIcon, {
    name: 'HTMLSvgIcon',
    icon: null,
    svgIcons: {}
});

var ICON_TYPES = {
    'svg': HTMLSvgIcon,
    'font': HTMLFontIcon
};

export default function renderIcon(iconElement, iconOptions) {
    var element = iconElement;
    var options = iconOptions;

    if (!element
        || (isObject(element) && !(element instanceof HTMLElement))
        || isString(element)) {
        options = element;
        element = document.createElement("span");
    }

    if (isString(options)) {
        options = {
            icon: options
        };
    }

    if (!options.type) {
        options.type = 'svg';
    }

    if (!ICON_TYPES[options.type]) {
        return null;
    }

    return (new ICON_TYPES[options.type](element, options).html());
}
