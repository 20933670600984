/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
class c extends e.Component {
  render() {
    const {
      group: r,
      groupMode: a,
      render: t
    } = this.props, s = /* @__PURE__ */ e.createElement("div", { className: "k-list-group-sticky-header" }, a === "classic" ? r : /* @__PURE__ */ e.createElement("div", { className: "k-list-header-text" }, r));
    return t !== void 0 ? t.call(void 0, s, this.props) : s;
  }
}
export {
  c as default
};
