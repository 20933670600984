/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { getScrollbarWidth as s, classNames as r, kendoThemeMaps as o } from "@progress/kendo-react-common";
import * as t from "react";
class l extends t.Component {
  constructor() {
    super(...arguments), this.footerWrap = null, this.table = null, this.scrollbarWidth = s();
  }
  componentDidMount() {
    this.setState({});
  }
  /**
   *
   * @param scrollLeft - Scrolls to the left (in pixels).
   */
  setScrollLeft(e) {
    this.footerWrap && (this.footerWrap.scrollLeft = e);
  }
  /**
   *
   * @param width - Scrolls the width (in pixels).
   */
  setWidth(e) {
    this.table && (this.table.style.width = e + "px");
  }
  render() {
    return this.props.staticHeaders ? /* @__PURE__ */ t.createElement(
      "div",
      {
        className: "k-grid-footer",
        role: "presentation"
      },
      /* @__PURE__ */ t.createElement(
        "div",
        {
          ref: (e) => {
            this.footerWrap = e;
          },
          className: "k-grid-footer-wrap",
          style: this.scrollbarWidth ? {} : { borderWidth: 0 },
          role: "presentation"
        },
        /* @__PURE__ */ t.createElement(
          "table",
          {
            ref: (e) => {
              this.table = e;
            },
            className: r(
              "k-table k-grid-footer-table",
              {
                "k-table-md": !this.props.size,
                [`k-table-${o.sizeMap[this.props.size] || this.props.size}`]: this.props.size
              },
              this.props.className
            ),
            role: "presentation"
          },
          /* @__PURE__ */ t.createElement("colgroup", { ref: (e) => {
            this.props.columnResize.colGroupFooter = e;
          }, role: "presentation" }, this.props.cols),
          /* @__PURE__ */ t.createElement("tfoot", { className: "k-table-tfoot", role: "presentation" }, this.props.row)
        )
      )
    ) : /* @__PURE__ */ t.createElement("tfoot", { className: "k-table-tfoot" }, this.props.row);
  }
}
export {
  l as Footer
};
