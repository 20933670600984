import { Encoding } from './encoding';
import { setDefaultOptions } from '../../common';

var extend = Object.assign;

export var Code11 = (function (Encoding) {
    function Code11 () {
        Encoding.apply(this, arguments);
    }

    if ( Encoding ) Code11.__proto__ = Encoding;
    Code11.prototype = Object.create( Encoding && Encoding.prototype );
    Code11.prototype.constructor = Code11;

    Code11.prototype.initProperties = function initProperties () {
        Encoding.prototype.initProperties.call(this);

        extend(this, {
            name: "Code 11",
            characterMap: ["111121", "211121", "121121", "221111", "112121", "212111", "122111", "111221", "211211", "211111", "112111"],
            cCheckSumTotal: 10,
            kCheckSumTotal: 9,
            kCheckSumMinLength: 10,
            checkSumMod: 11,
            DASH_VALUE: 10,
            DASH: "-",
            START: "112211",
            STOP: "11221"
        });
    };

    Code11.prototype.initValue = function initValue (value, width) {
        this.pattern = [];
        this.value = value;
        this.width = width;
        this.totalUnits = 0;
    };

    Code11.prototype.addData = function addData () {
        var this$1 = this;

        var value = this.value;

        this.addPattern(this.START);

        for (var i = 0; i < value.length; i++) {
            this$1.addCharacter(value.charAt(i));
        }

        if (this.options.addCheckSum) {
            this.addCheckSum();
        }

        this.addPattern(this.STOP);
        this.setBaseUnit();
    };

    Code11.prototype.setBaseUnit = function setBaseUnit () {
        this.baseUnit = this.width / (this.totalUnits + this.quietZoneLength);
    };

    Code11.prototype.addCheckSum = function addCheckSum () {
        var value = this.value;
        var length = value.length;
        var cValue = this.getWeightedSum(value, length, this.cCheckSumTotal) % this.checkSumMod;

        this.checksum = String(cValue);
        this.addPattern(this.characterMap[cValue]);

        length++;

        if (length >= this.kCheckSumMinLength) {
            var kValue = (cValue + this.getWeightedSum(value, length, this.kCheckSumTotal)) % this.checkSumMod;
            this.checksum += kValue;
            this.addPattern(this.characterMap[kValue]);
        }
    };

    Code11.prototype.getWeightedSum = function getWeightedSum (value, length, total) {
        var this$1 = this;

        var weightedSum = 0;

        for (var i = 0; i < value.length; i++) {
            weightedSum += this$1.weightedValue(this$1.getValue(value.charAt(i)), length, i, total);
        }

        return weightedSum;
    };

    Code11.prototype.weightedValue = function weightedValue (value, length, index, total) {
        var weight = (length - index) % total || total;
        return weight * value;
    };

    Code11.prototype.getValue = function getValue (character) {
        if (!isNaN(character)) {
            return parseInt(character, 10);
        } else if (character !== this.DASH) {
            this.invalidCharacterError(character);
        }

        return this.DASH_VALUE;
    };

    Code11.prototype.addCharacter = function addCharacter (character) {
        var value = this.getValue(character);
        var pattern = this.characterMap[value];

        this.addPattern(pattern);
    };

    Code11.prototype.addPattern = function addPattern (pattern) {
        var this$1 = this;

        var value;

        for (var i = 0; i < pattern.length; i++) {
            value = parseInt(pattern.charAt(i), 10);
            this$1.pattern.push(value);
            this$1.totalUnits += value;
        }
    };

    return Code11;
}(Encoding));

setDefaultOptions(Code11, {
    addCheckSum: true
});
