import { drawing as draw } from '@progress/kendo-drawing';
import ShapeElement from "../../core/shape-element";
import { setDefaultOptions } from '../../common';
import { BOTTOM, RIGHT } from '../../common/constants';

var MarkerLineArea = (function (ShapeElement) {
    function MarkerLineArea () {
        ShapeElement.apply(this, arguments);
    }

    if ( ShapeElement ) MarkerLineArea.__proto__ = ShapeElement;
    MarkerLineArea.prototype = Object.create( ShapeElement && ShapeElement.prototype );
    MarkerLineArea.prototype.constructor = MarkerLineArea;

    MarkerLineArea.prototype.getElement = function getElement () {
        var group = new draw.Group();
        var element = new draw.MultiPath(this.visualStyle());
        var ref = this;
        var box = ref.paddingBox;
        var halfHeight = box.height() / 2;

        element
            .moveTo(box.x1, box.y1 + halfHeight)
            .lineTo(box.x1, box.y2)
            .lineTo(box.x2, box.y2);

        group.append(element);

        return group;
    };

    return MarkerLineArea;
}(ShapeElement));

setDefaultOptions(MarkerLineArea, {
    width: 15,
    height: 15,
    align: RIGHT,
    vAlign: BOTTOM,
    margin: {
        right: -2,
        bottom: 2
    }
});

export default MarkerLineArea;
