/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as a from "react";
import n from "prop-types";
import { cloneDate as u, isEqual as f } from "@progress/kendo-date-math";
import { CalendarNavigationItem as v } from "./CalendarNavigationItem.mjs";
import { dateInRange as m } from "../../utils.mjs";
import { Virtualization as g } from "../../virtualization/Virtualization.mjs";
const k = 30, p = class p extends a.Component {
  constructor(r) {
    super(r), this.virtualization = null, this.list = null, this.itemHeight = 0, this.topOffset = 0, this.maxViewHeight = 0, this.bottomOffset = 0, this.handleVirtualizationMount = (t) => {
      if (this.virtualization = t, this.virtualization && this.list) {
        this.list.style.transform = `translateY(${this.topOffset}px)`;
        const i = m(this.props.focusedDate, this.props.min, this.props.max), s = this.props.service.skip(i, this.props.min);
        this.virtualization.scrollToIndex(s);
      }
    }, this.buildNavigationItem = (t) => {
      const i = this.props.service.navigationTitle(t), s = this.props.service.isRangeStart(t), o = `kendo-react-calendar-nav-item-${t.getTime()}`, l = {
        text: i,
        value: t,
        isRangeStart: s,
        view: this.props.activeView,
        onClick: this.handleDateChange
      };
      return this.props.navigationItem ? /* @__PURE__ */ a.createElement(this.props.navigationItem, { ...l, key: o }, i) : /* @__PURE__ */ a.createElement(v, { ...l, key: o }, i);
    }, this.calculateHeights = () => {
      const t = this.props.dom.calendarHeight;
      this.itemHeight = this.props.dom.navigationItemHeight || 1, this.maxViewHeight = this.props.dom.monthViewHeight, this.topOffset = (t - this.itemHeight) / 2, this.bottomOffset = t - this.itemHeight;
    }, this.handleDateChange = (t, i) => {
      const { onChange: s } = this.props;
      if (s && i) {
        const o = {
          value: u(t),
          target: this,
          nativeEvent: i && i.nativeEvent,
          syntheticEvent: i
        };
        s.call(void 0, o);
      }
    }, this.handleScrollAction = ({ scrollAction: t, pageAction: i }) => {
      const s = i ? i.skip : this.state.skip;
      if (this.state.skip !== s && this.setState({ skip: s }), this.list && t) {
        const o = `translateY(${t.offset}px)`;
        this.list.style.transform = o;
      }
    }, this.lastView = this.props.activeView, this.lastFocus = this.props.focusedDate;
    const e = this.props.service.skip(this.props.focusedDate, this.props.min), h = this.props.service.total(this.props.min, this.props.max);
    this.state = {
      skip: e - this.getTake(e, h) > 0 ? e : 0
    };
  }
  get take() {
    return this.props.take !== void 0 ? this.props.take : p.defaultProps.take;
  }
  /**
   * @hidden
   */
  componentDidUpdate(r, e) {
    this.indexToScroll !== void 0 && this.virtualization && this.virtualization.scrollToIndex(this.indexToScroll), this.lastView = this.props.activeView, this.lastFocus = this.props.focusedDate, this.indexToScroll = void 0;
  }
  render() {
    this.calculateHeights();
    const r = this.lastView !== this.props.activeView, e = m(this.props.focusedDate, this.props.min, this.props.max), h = r ? this.props.service.skip(e, this.props.min) : this.state.skip, t = this.props.service.total(this.props.min, this.props.max), i = this.getTake(h, t), s = this.props.service.addToDate(this.props.min, h), o = this.props.service.datesList(s, i);
    (this.props.activeView !== this.lastView || !f(e, this.lastFocus)) && (this.indexToScroll = this.props.service.skip(e, this.props.min));
    const l = /* @__PURE__ */ a.createElement(
      g,
      {
        skip: h,
        take: this.take,
        total: t,
        itemHeight: this.itemHeight,
        topOffset: this.topOffset,
        bottomOffset: this.bottomOffset,
        onScroll: this.props.onScroll,
        maxScrollDifference: this.maxViewHeight,
        onScrollAction: this.handleScrollAction,
        onMount: this.handleVirtualizationMount,
        tabIndex: this.props.tabIndex
      },
      /* @__PURE__ */ a.createElement("ul", { ref: (c) => {
        this.list = c;
      }, className: "k-reset" }, o.map((c) => this.buildNavigationItem(c)))
    );
    return /* @__PURE__ */ a.createElement(
      "div",
      {
        className: "k-calendar-navigation"
      },
      /* @__PURE__ */ a.createElement("span", { className: "k-calendar-navigation-highlight" }),
      this.props.dom.didCalculate ? l : null
    );
  }
  getTake(r, e) {
    return Math.min(e - r, this.take);
  }
};
p.propTypes = {
  activeView: n.number.isRequired,
  focusedDate: n.instanceOf(Date).isRequired,
  max: n.instanceOf(Date).isRequired,
  min: n.instanceOf(Date).isRequired,
  onChange: n.func,
  take: n.number,
  tabIndex: n.number
}, p.defaultProps = {
  take: k
};
let d = p;
export {
  d as Navigation
};
