/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { classNames as n } from "@progress/kendo-react-common";
import * as e from "react";
import { GridColumnMenuWrapper as p } from "../columnMenu/GridColumnMenuWrapper.mjs";
class c extends e.Component {
  /**
   * @hidden
   */
  render() {
    const { columnMenuWrapperProps: r } = this.props, t = this.props.onClick ? {
      onClick: this.props.onClick
    } : {}, s = /* @__PURE__ */ e.createElement("span", { className: "k-cell-inner" }, /* @__PURE__ */ e.createElement(
      "span",
      {
        className: n(
          "k-link",
          {
            "!k-cursor-default": !this.props.columnMenuWrapperProps.sortable
          }
        ),
        ...t
      },
      /* @__PURE__ */ e.createElement("span", { className: "k-column-title" }, this.props.title || this.props.field || " "),
      this.props.children
    ), r.columnMenu && /* @__PURE__ */ e.createElement(p, { ...r }));
    return this.props.render ? this.props.render.call(void 0, s, this.props) : s;
  }
}
export {
  c as GridHeaderCell
};
