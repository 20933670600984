/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { classNames as l } from "@progress/kendo-react-common";
import * as e from "react";
const u = e.forwardRef(({ orientation: r = "horizontal", ...t }, a) => {
  const n = e.useRef(null), s = e.useRef(null);
  return e.useImperativeHandle(
    n,
    () => ({ element: s.current })
  ), e.useImperativeHandle(
    a,
    () => n.current
  ), /* @__PURE__ */ e.createElement("span", { ...t, className: l("k-input-prefix", t.className, `k-input-prefix-${r}`) });
});
u.displayName = "KendoReactInputPrefix";
export {
  u as InputPrefix
};
