import { Ean13 } from './ean13';

var extend = Object.assign;

export var Ean8 = (function (Ean13) {
    function Ean8 () {
        Ean13.apply(this, arguments);
    }

    if ( Ean13 ) Ean8.__proto__ = Ean13;
    Ean8.prototype = Object.create( Ean13 && Ean13.prototype );
    Ean8.prototype.constructor = Ean8;

    Ean8.prototype.initProperties = function initProperties () {
        Ean13.prototype.initProperties.call(this);

        extend(this, {
            name: "EAN 8"
        });
    };

    Ean8.prototype.initValue = function initValue (value, width, height) {
        if (value.length !== 7 || /\D/.test(value)) {
            throw new Error('Invalid value provided');
        }

        this.value = value;
        this.options.height = height;
        this.checksum = this.calculateChecksum(this.value);
        this.leftPart = this.value.substr(0, 4);
        this.rightPart = this.value.substr(4) + this.checksum;
        this.pattern = [];
        this.baseUnit = width / (67 + this.quietZoneLength);
    };

    return Ean8;
}(Ean13));
