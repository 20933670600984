/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import { InstanceObserver as u } from "@progress/kendo-charts";
import { ChartContext as m } from "./ChartContext.mjs";
const c = class c extends o.Component {
  constructor(e, t) {
    super(e, t), this.context = null, this.state = {
      donutCenterStyles: null
    }, this.chartObserver = new u(this, {
      render: "onRender"
    }), t.observersStore.dispatch({
      type: "add",
      payload: this.chartObserver
    });
  }
  render() {
    const { render: e } = this.props, { donutCenterStyles: t } = this.state;
    let r = null;
    return e && t && (r = /* @__PURE__ */ o.createElement("div", { className: "k-chart-donut-center", style: t }, /* @__PURE__ */ o.createElement(e, null))), r;
  }
  onRender(e) {
    var l;
    const t = (l = this.context) == null ? void 0 : l.optionsStore.getState().series, r = Array.isArray(t) ? t[0] : null, i = e.sender._plotArea.charts;
    if (!r || r.type !== "donut" || i[0].points.length === 0)
      return;
    const s = i[0].points[0].sector, n = s.innerRadius, h = s.center.y - n, p = s.center.x - n, a = n * 2;
    this.setState({
      donutCenterStyles: {
        height: a,
        left: p,
        top: h,
        width: a
      }
    });
  }
};
c.contextType = m;
let d = c;
export {
  d as DonutCenter
};
