/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const o = "dropdowns.nodata", a = "dropdowns.clear", r = "dropdowns.comboArrowBtnAriaLabelExpand", n = "dropdowns.comboArrowBtnAriaLabelCollapse", e = "dropdowns.dropDownListArrowBtnAriaLabel", d = "dropdowns.apply", s = "dropdowns.cancel", t = {
  [a]: "clear",
  [o]: "NO DATA FOUND.",
  [r]: "expand combobox",
  [n]: "collapse combobox",
  [e]: "select",
  [d]: "Apply",
  [s]: "Cancel"
};
export {
  d as adaptiveModeFooterApply,
  s as adaptiveModeFooterCancel,
  a as clear,
  n as comboArrowBtnAriaLabelCollapse,
  r as comboArrowBtnAriaLabelExpand,
  e as dropDownListArrowBtnAriaLabel,
  t as messages,
  o as nodata
};
