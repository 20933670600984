/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { Button as r } from "@progress/kendo-react-buttons";
import * as e from "react";
class s extends e.PureComponent {
  /**
   * @return
   * Returns a `<span />` element with the [`value`]({% slug api_dateinputs_calendarheadertitleprops %}#toc-value) of the title as a child.
   */
  render() {
    const { view: n, ...t } = this.props;
    return /* @__PURE__ */ e.createElement(r, { type: "button", fillMode: "flat", ...t }, this.props.children);
  }
}
export {
  s as CalendarHeaderTitle
};
