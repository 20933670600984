/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { messages as l } from "./messages/index.mjs";
const f = [
  { text: "grid.filterAndLogic", operator: "and" },
  { text: "grid.filterOrLogic", operator: "or" }
], g = {
  text: [
    { text: "grid.filterContainsOperator", operator: "contains" },
    { text: "grid.filterNotContainsOperator", operator: "doesnotcontain" },
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterNotEqOperator", operator: "neq" },
    { text: "grid.filterStartsWithOperator", operator: "startswith" },
    { text: "grid.filterEndsWithOperator", operator: "endswith" },
    { text: "grid.filterIsNullOperator", operator: "isnull" },
    { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    { text: "grid.filterIsEmptyOperator", operator: "isempty" },
    { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" }
  ],
  numeric: [
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterNotEqOperator", operator: "neq" },
    { text: "grid.filterGteOperator", operator: "gte" },
    { text: "grid.filterGtOperator", operator: "gt" },
    { text: "grid.filterLteOperator", operator: "lte" },
    { text: "grid.filterLtOperator", operator: "lt" },
    { text: "grid.filterIsNullOperator", operator: "isnull" },
    { text: "grid.filterIsNotNullOperator", operator: "isnotnull" }
  ],
  date: [
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterNotEqOperator", operator: "neq" },
    { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
    { text: "grid.filterAfterOperator", operator: "gt" },
    { text: "grid.filterBeforeOperator", operator: "lt" },
    { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
    { text: "grid.filterIsNullOperator", operator: "isnull" },
    { text: "grid.filterIsNotNullOperator", operator: "isnotnull" }
  ],
  boolean: [
    { text: "grid.filterEqOperator", operator: "eq" }
  ]
}, i = (t) => t === "isnull" || t === "isnotnull" || t === "isempty" || t === "isnotempty", d = (t, o) => t.map((e) => ({
  text: o.toLanguageString(e.text, l[e.text]),
  operator: e.operator
})), n = "eq", u = [
  { text: "grid.filterBooleanAll", operator: "" },
  { text: "grid.filterIsTrue", operator: !0 },
  { text: "grid.filterIsFalse", operator: !1 }
], x = {
  text: !1,
  numeric: !1,
  date: !1,
  boolean: !0
}, c = (t, o, e) => {
  const a = p(e.operators);
  let r = e.operator;
  switch (e.filterType) {
    case "numeric":
      (!r || i(r)) && (r = a), t === null && r === a && (r = "");
      break;
    case "date":
      (!r || i(r)) && (r = a), t === null && r === a && (r = "");
      break;
    case "text":
      (!r || i(r)) && (r = a), !t && r === a && (r = "");
      break;
    default:
      return;
  }
  e.onChange({ value: t, operator: r, syntheticEvent: o });
}, p = (t, o) => o ? t[o][0].operator : t[0].operator, O = (t) => t || "text", q = (t, o) => {
  const e = t.target.value;
  o({
    value: e.operator,
    operator: e.operator === "" ? "" : n,
    syntheticEvent: t.syntheticEvent
  });
}, E = (t, o, e) => {
  let a = o;
  const r = t.target.value;
  t.target.state.opened && (i(r.operator) && (a = null), o === null && !i(r.operator) && (a = void 0), e({ value: a, operator: r.operator, syntheticEvent: t.syntheticEvent }));
};
export {
  i as IsUnaryFilter,
  u as booleanFilterValues,
  q as cellBoolDropdownChange,
  c as cellInputChange,
  E as cellOperatorChange,
  n as defaultBooleanOperator,
  x as defaultHideSecondFilter,
  f as filterLogicList,
  p as getDefaultOperator,
  O as getFilterType,
  d as operatorMap,
  g as operators
};
