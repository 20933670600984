/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import e from "prop-types";
import { provideLocalizationService as p, registerForLocalization as m } from "@progress/kendo-react-intl";
import { classNames as c } from "@progress/kendo-react-common";
import { Button as d } from "@progress/kendo-react-buttons";
import { getDate as r } from "@progress/kendo-date-math";
import { today as n, messages as h } from "../../messages/index.mjs";
import { dateInRange as u, getToday as l, MIN_DATE as f, MAX_DATE as g, isInRange as x } from "../../utils.mjs";
const t = class t extends o.Component {
  constructor() {
    super(...arguments), this.localization = null, this.handleClick = (i) => {
      if (this.todayIsInRange && this.props.onClick) {
        const s = {
          syntheticEvent: i,
          nativeEvent: i.nativeEvent,
          value: u(l(), this.min, this.max),
          target: this,
          isTodayClick: !0
        };
        this.props.onClick.call(void 0, s);
      }
    };
  }
  get min() {
    return this.props.min !== void 0 ? this.props.min : t.defaultProps.min;
  }
  get max() {
    return this.props.max !== void 0 ? this.props.max : t.defaultProps.max;
  }
  get todayIsInRange() {
    return x(l(), r(this.min), r(this.max));
  }
  render() {
    this.localization = p(this);
    const i = this.localization.toLanguageString(n, h[n]), s = c(
      "k-calendar-nav-today",
      {
        "k-disabled": this.props.disabled
      }
    );
    return /* @__PURE__ */ o.createElement(
      d,
      {
        className: s,
        onClick: this.handleClick,
        tabIndex: this.props.tabIndex,
        fillMode: "flat"
      },
      i
    );
  }
};
t.propTypes = {
  max: e.instanceOf(Date).isRequired,
  min: e.instanceOf(Date).isRequired,
  onClick: e.func,
  disabled: e.bool
}, t.defaultProps = {
  min: f,
  max: g
};
let a = t;
m(a);
export {
  a as TodayCommand
};
