/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import t from "prop-types";
import { validatePackage as N, svgIconPropType as T, classNames as f, kendoThemeMaps as h, IconWrap as E } from "@progress/kendo-react-common";
import { packageMetadata as y } from "./package-metadata.mjs";
function I({ imageUrl: d, name: e, iconClass: s, svgIcon: i, imageAlt: r }) {
  return d ? /* @__PURE__ */ o.createElement("img", { role: "presentation", className: "k-button-icon", alt: r, src: d }) : e || i ? /* @__PURE__ */ o.createElement(E, { className: "k-button-icon", name: e, icon: i }) : s ? /* @__PURE__ */ o.createElement("span", { role: "presentation", className: f("k-button-icon", s) }) : null;
}
const l = class l extends o.Component {
  constructor(e) {
    super(e), this._element = null, this.handleClick = (s) => {
      this.toggleIfApplicable(), this.props.onClick && this.props.onClick.call(void 0, s);
    }, N(y), this.state = { selected: this.props.togglable === !0 && this.props.selected === !0 };
  }
  /**
   * Gets the DOM element of the Button component.
   */
  get element() {
    return this._element;
  }
  /**
   * Returns `true` when the component is togglable and selected ([see example]({% slug toggleable_button %})).
   * Otherwise, returns `false`.
   */
  get selected() {
    return this._selectedTemp !== void 0 ? this._selectedTemp : this.state.selected;
  }
  /**
   * @hidden
   */
  static getDerivedStateFromProps(e, s) {
    return e.togglable && e.selected !== void 0 && e.selected !== s.selected ? { selected: e.selected } : null;
  }
  /**
   * @hidden
   */
  render() {
    const {
      children: e,
      togglable: s,
      icon: i,
      iconClass: r,
      svgIcon: m,
      imageUrl: p,
      imageAlt: b,
      className: z,
      onClick: A,
      size: a = l.defaultProps.size,
      rounded: c = l.defaultProps.rounded,
      fillMode: n = l.defaultProps.fillMode,
      themeColor: u = l.defaultProps.themeColor,
      ...k
    } = this.props, v = m !== void 0 || i !== void 0 || r !== void 0 || p !== void 0, C = e !== void 0, P = I({
      name: i,
      svgIcon: m,
      iconClass: r,
      imageUrl: p,
      imageAlt: b
    });
    return /* @__PURE__ */ o.createElement(
      "button",
      {
        ref: (M) => this._element = M,
        "aria-pressed": s ? this.state.selected : void 0,
        ...k,
        onClick: this.handleClick,
        className: f(
          "k-button",
          {
            [`k-button-${h.sizeMap[a] || a}`]: a,
            [`k-button-${n}`]: n,
            [`k-button-${n}-${u}`]: !!(n && u),
            [`k-rounded-${h.roundedMap[c] || c}`]: c,
            "k-icon-button": !C && v,
            "k-disabled": this.props.disabled,
            "k-selected": this.state.selected,
            "k-rtl": this.props.dir === "rtl"
          },
          this.props.className
        )
      },
      P,
      e && /* @__PURE__ */ o.createElement("span", { className: "k-button-text" }, e)
    );
  }
  toggleIfApplicable() {
    if (this.props.togglable && this.props.selected === void 0) {
      const e = !this.state.selected;
      this._selectedTemp = e, this.setState({ selected: e }, () => this._selectedTemp = void 0);
    }
  }
};
l.propTypes = {
  children: t.node,
  selected: t.bool,
  togglable: t.bool,
  icon: t.string,
  svgIcon: T,
  iconClass: t.string,
  imageUrl: t.string,
  imageAlt: t.string,
  size: t.oneOf([null, "small", "medium", "large"]),
  rounded: t.oneOf([null, "small", "medium", "large", "full"]),
  fillMode: t.oneOf([null, "flat", "link", "outline", "solid"]),
  // eslint-disable-next-line max-len
  themeColor: t.oneOf([null, "base", "primary", "secondary", "tertiary", "info", "success", "warning", "error", "dark", "light", "inverse"])
}, l.defaultProps = {
  togglable: !1,
  size: "medium",
  rounded: "medium",
  fillMode: "solid",
  themeColor: "base"
};
let g = l;
export {
  g as Button
};
