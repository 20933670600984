import {
    geometry as g,
    drawing as d
} from '@progress/kendo-drawing';

import {
    Class,
    defined,
    isFunction,
    getter,
    setDefaultOptions
} from '../../common';

import {
    ShapeLayer
} from './shape';

import {
    Location
} from '../location';

export var BubbleLayer = (function (ShapeLayer) {
    function BubbleLayer () {
        ShapeLayer.apply(this, arguments);
    }

    if ( ShapeLayer ) BubbleLayer.__proto__ = ShapeLayer;
    BubbleLayer.prototype = Object.create( ShapeLayer && ShapeLayer.prototype );
    BubbleLayer.prototype.constructor = BubbleLayer;

    BubbleLayer.prototype._readData = function _readData () {
        var data = this.options.data || [];
        return data;
    };

    BubbleLayer.prototype._load = function _load (data) {
        var this$1 = this;

        this._data = data;
        this.surface.clear();

        if (data.length === 0) {
            return;
        }

        var options = this.options;
        var getValue = getter(options.valueField);

        var newData = data.slice(0);
        newData.sort(function(a, b) {
            return getValue(b) - getValue(a);
        });

        var scaleType = this._scaleType();
        var scale;
        var getLocation = getter(this.options.locationField);

        for (var i = 0; i < newData.length; i++) {
            var dataItem = newData[i];
            var location = getLocation(dataItem);
            var value = getValue(dataItem);

            if (defined(location) && defined(value)) {
                if (!scale) {
                    scale = new scaleType([
                        0,
                        value
                    ], [
                        options.minSize,
                        options.maxSize
                    ]);
                }

                location = Location.create(location);

                var center = this$1.map.locationToView(location);
                var size = scale.map(value);
                var symbol = this$1._createSymbol({
                    center: center,
                    size: size,
                    style: options.style,
                    dataItem: dataItem,
                    location: location
                });

                symbol.dataItem = dataItem;
                symbol.location = location;
                symbol.value = value;

                this$1._drawSymbol(symbol);
            }
        }
    };

    BubbleLayer.prototype._scaleType = function _scaleType () {
        var scale = this.options.scale;

        if (isFunction(scale)) {
            return scale;
        }

        return Scales[scale];
    };

    BubbleLayer.prototype._createSymbol = function _createSymbol (args) {
        var symbol = this.options.symbol;

        if (!isFunction(symbol)) {
            symbol = Symbols[symbol];
        }

        return symbol(args);
    };

    BubbleLayer.prototype._drawSymbol = function _drawSymbol (shape) {
        var args = {
            layer: this,
            shape: shape
        };

        var cancelled = this.map.trigger('shapeCreated', args);

        if (!cancelled) {
            this.surface.draw(shape);
        }
    };

    BubbleLayer.prototype._tooltipContext = function _tooltipContext (shape) {
        return {
            type: 'bubble',
            layerIndex: this._layerIndex(),
            className: 'k-map-bubble-tooltip',
            dataItem: shape.dataItem,
            location: shape.location,
            value: shape.value
        };
    };

    BubbleLayer.prototype._tooltipAnchor = function _tooltipAnchor (e) {
        var shape = e.element;
        var center = shape.bbox().center();

        return {
            top: center.y,
            left: center.x
        };
    };

    return BubbleLayer;
}(ShapeLayer));

setDefaultOptions(BubbleLayer, {
    // autoBind: true,
    locationField: 'location',
    valueField: 'value',
    minSize: 0,
    maxSize: 100,
    scale: 'sqrt',
    symbol: 'circle',
    // ensure bubble layers are displayed over tile and shape layers
    zIndex: 200
});

var SqrtScale = (function (Class) {
    function SqrtScale(domain, range) {
        Class.call(this);

        this._domain = domain;
        this._range = range;

        var domainRange = Math.sqrt(domain[1]) - Math.sqrt(domain[0]);
        var outputRange = range[1] - range[0];

        this._ratio = outputRange / domainRange;
    }

    if ( Class ) SqrtScale.__proto__ = Class;
    SqrtScale.prototype = Object.create( Class && Class.prototype );
    SqrtScale.prototype.constructor = SqrtScale;

    SqrtScale.prototype.map = function map (value) {
        var rel = (Math.sqrt(value) - Math.sqrt(this._domain[0])) * this._ratio;
        return this._range[0] + rel;
    };

    return SqrtScale;
}(Class));

var Scales = {
    sqrt: SqrtScale
};

var Symbols = {
    circle: function(args) {
        var geo = new g.Circle(args.center, args.size / 2);
        return new d.Circle(geo, args.style);
    },

    square: function(args) {
        var path = new d.Path(args.style);
        var halfSize = args.size / 2;
        var center = args.center;
        path.moveTo(center.x - halfSize, center.y - halfSize).lineTo(center.x + halfSize, center.y - halfSize).lineTo(center.x + halfSize, center.y + halfSize).lineTo(center.x - halfSize, center.y + halfSize).close();
        return path;
    }
};
