import { deepExtend, defined, isFunction, setDefaultOptions } from '../../common';
import { MAX_VALUE, MIN_VALUE } from '../../common/constants';
import { Box, ChartElement } from '../../core';
import evalOptions from '../utils/eval-options';
import colorScale from './color-scale';
import HeatmapPoint from './heatmap-point';

var HeatmapChart = (function (ChartElement) {
    function HeatmapChart(plotArea, options) {
        ChartElement.call(this, options);

        this.plotArea = plotArea;
        this.chartService = plotArea.chartService;
        this._initFields();

        this.render();
    }

    if ( ChartElement ) HeatmapChart.__proto__ = ChartElement;
    HeatmapChart.prototype = Object.create( ChartElement && ChartElement.prototype );
    HeatmapChart.prototype.constructor = HeatmapChart;

    HeatmapChart.prototype._initFields = function _initFields () {
        this.points = [];
        this.seriesOptions = [];
        this.valueRange = { min: MAX_VALUE, max: MIN_VALUE };
        this._evalSeries = [];
    };

    HeatmapChart.prototype.render = function render () {
        this.setRange();
        this.traverseDataPoints(this.addValue.bind(this));
    };

    HeatmapChart.prototype.setRange = function setRange () {
        var this$1 = this;

        var ref = this;
        var series = ref.options.series;

        for (var seriesIx = 0; seriesIx < series.length; seriesIx++) {
            var currentSeries = series[seriesIx];

            for (var pointIx = 0; pointIx < currentSeries.data.length; pointIx++) {
                var ref$1 = this$1.plotArea.bindPoint(currentSeries, pointIx);
                var valueFields = ref$1.valueFields;
                if (defined(valueFields.value) && valueFields.value !== null) {
                    this$1.valueRange.min = Math.min(this$1.valueRange.min, valueFields.value);
                    this$1.valueRange.max = Math.max(this$1.valueRange.max, valueFields.value);
                }
            }
        }
    };

    HeatmapChart.prototype.addValue = function addValue (value, fields) {
        var point;
        if (value && defined(value.value) && value.value !== null) {
            point = this.createPoint(value, fields);
            if (point) {
                Object.assign(point, fields);
            }
        }

        this.points.push(point);
    };

    HeatmapChart.prototype.evalPointOptions = function evalPointOptions (options, value, fields) {
        var series = fields.series;
        var seriesIx = fields.seriesIx;
        var state = {
            defaults: series._defaults,
            excluded: [
                "data", "tooltip", "content", "template",
                "visual", "toggle", "drilldownSeriesFactory",
                "ariaTemplate", "ariaContent"
            ]
        };

        var doEval = this._evalSeries[seriesIx];
        if (!defined(doEval)) {
            this._evalSeries[seriesIx] = doEval = evalOptions(options, {}, state, true);
        }

        var pointOptions = options;
        if (doEval) {
            pointOptions = deepExtend({}, options);
            evalOptions(pointOptions, {
                value: value,
                series: series,
                dataItem: fields.dataItem,
                min: this.valueRange.min,
                max: this.valueRange.max
            }, state);
        }

        return pointOptions;
    };

    HeatmapChart.prototype.pointType = function pointType () {
        return HeatmapPoint;
    };

    HeatmapChart.prototype.pointOptions = function pointOptions (series, seriesIx) {
        var options = this.seriesOptions[seriesIx];
        if (!options) {
            var defaults = this.pointType().prototype.defaults;
            this.seriesOptions[seriesIx] = options = deepExtend({}, defaults, {
                markers: {
                    opacity: series.opacity
                },
                tooltip: {
                    format: this.options.tooltip.format
                },
                labels: {
                    format: this.options.labels.format
                }
            }, series);
        }

        return options;
    };

    HeatmapChart.prototype.createPoint = function createPoint (value, fields) {
        var series = fields.series;
        var pointOptions = this.pointOptions(series, fields.seriesIx);
        var color = fields.color || series.color;

        pointOptions = this.evalPointOptions(pointOptions, value, fields);

        if (isFunction(series.color)) {
            color = pointOptions.color;
        } else if (this.valueRange.max !== 0) {
            var scale = colorScale(color);
            color = scale(value.value / this.valueRange.max);
        }

        var point = new HeatmapPoint(value, pointOptions);
        point.color = color;

        this.append(point);

        return point;
    };

    HeatmapChart.prototype.seriesAxes = function seriesAxes (series) {
        var xAxisName = series.xAxis;
        var yAxisName = series.yAxis;
        var plotArea = this.plotArea;
        var xAxis = xAxisName ? plotArea.namedXAxes[xAxisName] : plotArea.axisX;
        var yAxis = yAxisName ? plotArea.namedYAxes[yAxisName] : plotArea.axisY;

        if (!xAxis) {
            throw new Error("Unable to locate X axis with name " + xAxisName);
        }

        if (!yAxis) {
            throw new Error("Unable to locate Y axis with name " + yAxisName);
        }

        return { xAxis: xAxis, yAxis: yAxis };
    };

    HeatmapChart.prototype.reflow = function reflow (targetBox) {
        var this$1 = this;

        var chartPoints = this.points;
        var limit = !this.options.clip;
        var pointIx = 0;


        this.traverseDataPoints(function (value, fields) {
            var point = chartPoints[pointIx++];
            var ref = this$1.seriesAxes(fields.series);
            var xAxis = ref.xAxis;
            var yAxis = ref.yAxis;
            var indexX = xAxis.categoryIndex(value.x);
            var indexY = yAxis.categoryIndex(value.y);
            var slotX = xAxis.getSlot(indexX, indexX, limit);
            var slotY = yAxis.getSlot(indexY, indexY, limit);

            if (point) {
                if (slotX && slotY) {
                    var pointSlot = this$1.pointSlot(slotX, slotY);
                    point.reflow(pointSlot);
                } else {
                    point.visible = false;
                }
            }
        });

        this.box = targetBox;
    };

    HeatmapChart.prototype.pointSlot = function pointSlot (slotX, slotY) {
        return new Box(slotX.x1, slotY.y1, slotX.x2, slotY.y2);
    };

    HeatmapChart.prototype.traverseDataPoints = function traverseDataPoints (callback) {
        var this$1 = this;

        var ref = this;
        var series = ref.options.series;

        for (var seriesIx = 0; seriesIx < series.length; seriesIx++) {
            var currentSeries = series[seriesIx];
            var ref$1 = this$1.seriesAxes(currentSeries);
            var xAxis = ref$1.xAxis;
            var yAxis = ref$1.yAxis;
            var xRange = xAxis.currentRangeIndices();
            var yRange = yAxis.currentRangeIndices();

            for (var pointIx = 0; pointIx < currentSeries.data.length; pointIx++) {
                var ref$2 = this$1.plotArea.bindPoint(currentSeries, pointIx);
                var value = ref$2.valueFields;
                var fields = ref$2.fields;
                var xIndex = xAxis.totalIndex(value.x);
                var yIndex = yAxis.totalIndex(value.y);
                var xIn = xRange.min <= xIndex && xIndex <= xRange.max;
                var yIn = yRange.min <= yIndex && yIndex <= yRange.max;

                if (xIn && yIn) {
                    callback(value, deepExtend({
                        pointIx: pointIx,
                        series: currentSeries,
                        seriesIx: seriesIx,
                        dataItem: currentSeries.data[pointIx],
                        owner: this$1
                    }, fields));
                }
            }
        }
    };

    HeatmapChart.prototype.formatPointValue = function formatPointValue (point, format) {
        var value = point.value;
        return this.chartService.format.auto(format, value.x, value.y, value.value);
    };

    HeatmapChart.prototype.animationPoints = function animationPoints () {
        var points = this.points;
        var result = [];
        for (var idx = 0; idx < points.length; idx++) {
            result.push((points[idx] || {}).marker);
        }
        return result;
    };

    return HeatmapChart;
}(ChartElement));
setDefaultOptions(HeatmapChart, {
    series: [],
    tooltip: {
        format: "{0}, {1}: {2}"
    },
    labels: {
        format: "{2}"
    },
    clip: true
});

export default HeatmapChart;
