/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { PreventableEvent as n } from "./preventable-event.mjs";
class i extends n {
  /**
   * @hidden
   */
  constructor(e, t) {
    super(t), this.axisRanges = e.axisRanges, this.nativeEvent = e.originalEvent;
  }
}
export {
  i as DragEvent
};
