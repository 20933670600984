/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
var w = /* @__PURE__ */ ((e) => (e[e.Left = 0] = "Left", e[e.Right = 1] = "Right", e[e.Up = 2] = "Up", e[e.Down = 3] = "Down", e[e.PrevView = 4] = "PrevView", e[e.NextView = 5] = "NextView", e[e.FirstInView = 6] = "FirstInView", e[e.LastInView = 7] = "LastInView", e[e.LowerView = 8] = "LowerView", e[e.UpperView = 9] = "UpperView", e))(w || {});
export {
  w as Action
};
