/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
function f(t, n, r) {
  return r ? { ...t, [n]: r.length ? [...r] : void 0 } : t && t[n] ? [...t[n]] : [];
}
function s(t, n, r) {
  if (t[n]) {
    let o = f(t, n).map((e) => s(e, n, r));
    return r(f(t, n, o));
  }
  return r(t);
}
const i = (t, n, r) => [...t.map((o) => s(o, n, r))], u = (t, n, r, o) => {
  let e = t;
  const h = [];
  for (let c = 0; c < n.length; c++)
    e = e[n[c]], e = Array.isArray(e) ? e : e[r], h.push(e);
  const p = h.length > 1 ? h[h.length - 2] : t, g = n[n.length - 1], a = o(p[g]);
  p.splice(g, 1, a);
}, w = (t, n, r) => Object.assign(
  {},
  t,
  t[n] ? { [n]: t[n].slice() } : {},
  r || {}
), x = (t, n, r) => {
  const o = [], e = [...n];
  if (e.length) {
    let h = t[e.shift() || 0];
    for (o.push(h); e.length && r; )
      h = h[r][e.shift() || 0], o.push(h);
  }
  return o;
};
export {
  w as extendDataItem,
  x as getItemPath,
  i as mapTree,
  u as mapTreeItem
};
