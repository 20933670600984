import { Class } from '../../common';

export var EncodingResult = (function (Class) {
    function EncodingResult(dataString, version) {
        Class.call(this);

        this.dataString = dataString;
        this.version = version;
    }

    if ( Class ) EncodingResult.__proto__ = Class;
    EncodingResult.prototype = Object.create( Class && Class.prototype );
    EncodingResult.prototype.constructor = EncodingResult;

    return EncodingResult;
}(Class));
