import {
    Class,
    addClass,
    deepExtend,
    defined
} from '../../common';

import {
    Extent
} from './../extent';

import {
    getSupportedFeatures
} from '../utils';

export var Layer = (function (Class) {
    function Layer(map, options) {
        Class.call(this);

        this.support = getSupportedFeatures();

        this._initOptions(options);
        this.map = map;

        var element = document.createElement("div");
        addClass(element, "k-layer");
        element.style.zIndex = this.options.zIndex;
        element.style.opacity = this.options.opacity;

        this.element = element;

        map.scrollElement.appendChild(this.element);

        this._beforeReset = this._beforeReset.bind(this);
        this._reset = this._reset.bind(this);
        this._resize = this._resize.bind(this);
        this._panEnd = this._panEnd.bind(this);

        this._activate();
        this._updateAttribution();
    }

    if ( Class ) Layer.__proto__ = Class;
    Layer.prototype = Object.create( Class && Class.prototype );
    Layer.prototype.constructor = Layer;

    Layer.prototype.destroy = function destroy () {
        this._deactivate();
    };

    Layer.prototype._initOptions = function _initOptions (options) {
        this.options = deepExtend({}, this.options, options);
    };

    Layer.prototype.show = function show () {
        this.reset();
        this._activate();
        this._applyExtent(true);
    };

    Layer.prototype.hide = function hide () {
        this._deactivate();
        this._setVisibility(false);
    };

    Layer.prototype.reset = function reset () {
        this._beforeReset();
        this._reset();
    };

    Layer.prototype._reset = function _reset () {
        this._applyExtent();
    };

    Layer.prototype._beforeReset = function _beforeReset () {

    };

    Layer.prototype._resize = function _resize () {

    };

    Layer.prototype._panEnd = function _panEnd () {
        this._applyExtent();
    };

    Layer.prototype._applyExtent = function _applyExtent () {
        var options = this.options;
        var zoom = this.map.zoom();
        var matchMinZoom = !defined(options.minZoom) || zoom >= options.minZoom;
        var matchMaxZoom = !defined(options.maxZoom) || zoom <= options.maxZoom;
        var extent = Extent.create(options.extent);
        var inside = !extent || extent.overlaps(this.map.extent());

        this._setVisibility(matchMinZoom && matchMaxZoom && inside);
    };

    Layer.prototype._setVisibility = function _setVisibility (visible) {
        this.element.style.display = visible ? '' : 'none';
    };

    Layer.prototype._activate = function _activate () {
        var map = this.map;

        this._deactivate();

        map.bind('beforeReset', this._beforeReset);
        map.bind('reset', this._reset);
        map.bind('resize', this._resize);
        map.bind('panEnd', this._panEnd);
    };

    Layer.prototype._deactivate = function _deactivate () {
        var map = this.map;

        map.unbind('beforeReset', this._beforeReset);
        map.unbind('reset', this._reset);
        map.unbind('resize', this._resize);
        map.unbind('panEnd', this._panEnd);
    };

    Layer.prototype._updateAttribution = function _updateAttribution () {
        var attribution = this.map.attribution;

        if (attribution) {
            attribution.add(this.options.attribution);
        }
    };

    Layer.prototype._readData = function _readData () {
        var data = this.options.data || [];
        return data;
    };

    Layer.prototype._hasData = function _hasData () {
        return this._data && this._data.length > 0;
    };

    Layer.prototype._layerIndex = function _layerIndex () {
        var layers = this.map.layers || [];
        return layers.indexOf(this);
    };

    return Layer;
}(Class));
