/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const l = "d", y = "wide", _ = (h, e, g, u) => {
  const b = h[u + h.length - g.length], p = h.substring(0, u + h.length - g.length), n = g.substring(0, u), t = [];
  if (p === n && u > 0)
    return t.push([e[u - 1], n[u - 1]]), t;
  if (p.indexOf(n) === 0 && (n.length === 0 || e[n.length - 1] !== e[n.length])) {
    let s = "";
    n.length === 1 && t.push([e[0], n[0]]);
    for (let f = n.length; f < p.length; f++)
      e[f] !== s && e[f] !== "_" && (s = e[f], t.push([s, ""]));
    return t;
  }
  if (n.indexOf(p) === 0 || e[u - 1] === "_") {
    let s = e[0];
    for (let f = Math.max(0, p.length - 1); f < e.length; f++)
      if (e[f] !== "_") {
        s = e[f];
        break;
      }
    return [[s, n[u - 1]]];
  }
  return n[n.length - 1] === " " || n[n.length - 1] === b ? [[e[u - 1], "_"]] : [[e[u - 1], n[u - 1]]];
}, k = (h, e) => (h[e.pattern[0]] = e.type, h), M = (h, e, g) => h === null || !(e && e > h || g && g < h), w = ["k-widget", "k-dateinput"];
export {
  _ as approximateStringMatching,
  k as dateSymbolMap,
  l as defaultFormat,
  y as defaultFormatPlaceholder,
  M as isInRange,
  w as wrapperClasses
};
