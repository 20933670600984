/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import { ConfigurationComponent as m } from "./base/ConfigurationComponent.mjs";
import { ChartContext as d } from "../ChartContext.mjs";
const c = (t) => {
  let l = (e) => /* @__PURE__ */ r.createElement(r.Fragment, null);
  t.drilldownSeriesFactory && (l = t.drilldownSeriesFactory);
  const n = (e) => {
    var a, o;
    return e && ((o = (a = e.drilldownState) == null ? void 0 : a.steps) == null ? void 0 : o.find(({ seriesName: i }) => i === t.name));
  };
  return /* @__PURE__ */ r.createElement(d.Consumer, null, (e) => n(e) ? /* @__PURE__ */ r.createElement(
    l,
    {
      _chartCollectionIdxKey: t._chartCollectionIdxKey,
      drilldownValue: n(e).drilldownValue
    }
  ) : /* @__PURE__ */ r.createElement(m, { ...t }));
};
c.displayName = "ChartSeriesItem";
export {
  c as ChartSeriesItem
};
