/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { PreventableEvent as s } from "./preventable-event.mjs";
class i extends s {
  // tslint:enable:max-line-length
  /**
   * @hidden
   */
  constructor(t, e) {
    super(e), this.axisRanges = t.axisRanges, this.delta = t.delta, this.nativeEvent = t.originalEvent;
  }
}
export {
  i as ZoomEvent
};
