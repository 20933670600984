/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { ConfigurationComponent as r } from "../base/ConfigurationComponent.mjs";
const a = (e) => /* @__PURE__ */ t.createElement(r, { visible: !0, ...e, _chartKey: "labels" });
export {
  a as ChartSeriesLabels
};
