/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import s from "prop-types";
import { dispatchEvent as Ae, canUseDOM as U, getActiveElement as Ge, memoizeOne as S, validatePackage as Ke, shouldShowValidationUI as Oe, setScrollbarWidth as re, getter as Pe, classNames as L, kendoThemeMaps as G, WatermarkOverlay as Ue } from "@progress/kendo-react-common";
import { tableKeyboardNavigation as x, getSelectionOptions as ae, mapColumns as Ve, ClipboardService as We, TableKeyboardNavigationContext as V, isRtl as Be, tableKeyboardNavigationTools as F, tableColumnsVirtualization as $e, Pager as je, tableKeyboardNavigationScopeAttributes as le, TableSelection as ne, tableKeyboardNavigationBodyAttributes as he, DropClue as pe, DragClue as de, updateLeft as qe, updateRight as Je } from "@progress/kendo-react-data-tools";
import { GridSelectionCell as Qe } from "./cells/GridSelectionCell.mjs";
import { GridHierarchyCell as Xe } from "./cells/GridHierarchyCell.mjs";
import { GridEditCell as Ye } from "./cells/GridEditCell.mjs";
import { Header as Ze } from "./header/Header.mjs";
import { HeaderRow as et } from "./header/HeaderRow.mjs";
import { FilterRow as tt } from "./header/FilterRow.mjs";
import { GroupPanel as st } from "./header/GroupPanel.mjs";
import { Footer as it } from "./footer/Footer.mjs";
import { provideLocalizationService as ot, registerForLocalization as rt } from "@progress/kendo-react-intl";
import { pagerMessagesMap as at, gridAriaLabel as ce, messages as lt } from "./messages/index.mjs";
import { VirtualScroll as nt } from "./VirtualScroll.mjs";
import { VirtualScrollFixed as ue, RowHeightService as ge } from "./VirtualScrollFixed.mjs";
import { ColumnResize as ht } from "./drag/ColumnResize.mjs";
import { CommonDragLogic as pt } from "./drag/CommonDragLogic.mjs";
import { firefox as me, firefoxMaxHeight as fe, flatData as dt, readColumns as ct, sanitizeColumns as ut, getColumnWidth as W, autoGenerateColumns as gt, getNestedValue as ve, resolveCells as mt } from "./utils/index.mjs";
import { GridCell as ft } from "./cells/GridCell.mjs";
import { GridGroupCell as be } from "./cells/GridGroupCell.mjs";
import { GridRow as vt } from "./rows/GridRow.mjs";
import { GridHeaderSelectionCell as bt } from "./header/GridHeaderSelectionCell.mjs";
import { GridNoRecords as Ct } from "./GridNoRecords.mjs";
import { operators as Ce } from "./filterCommon.mjs";
import { FooterRow as Rt } from "./footer/FooterRow.mjs";
import { normalize as yt } from "./paging/GridPagerSettings.mjs";
import { packageMetadata as Re } from "./package-metadata.mjs";
import { GridDetailCell as wt } from "./cells/GridDetailCell.mjs";
import { GridDetailHierarchyCell as St } from "./cells/GridDetailHierarchyCell.mjs";
const _ = class _ extends r.Component {
  constructor(a) {
    super(a), this._columns = [], this._columnsMap = [[]], this._columnsMutations = 0, this._resized = !1, this._shouldUpdateLeftRight = !0, this.contextStateRef = { current: void 0 }, this.navigationStateRef = { current: void 0 }, this._data = [], this._slicedData = void 0, this.wrapperScrollTop = 0, this.showLicenseWatermark = !1, this.headerRef = r.createRef(), this.headerElementRef = r.createRef(), this._element = null, this.tableElement = null, this.containerRef = r.createRef(), this.tableBodyRef = r.createRef(), this._footer = null, this.forceUpdateTimeout = void 0, this.isRtl = !1, this.observer = null, this.handleIntersection = (e) => {
      const t = { rowIndex: this.rowIndex };
      e.forEach((i) => {
        i.isIntersecting || this.setCurrentOnTop(t);
      });
    }, this.setCurrentOnTop = (e) => {
      if (!this.vs.container || this.props.scrollable === "none")
        return;
      this.observer && this.observer.disconnect();
      const { rowIndex: t } = e;
      if (this.props.scrollable === "virtual")
        this.vs.askedSkip = t, this.vs.container.scroll(0, Math.round(this.vs.askedSkip / this.vs.total * this.vs.container.scrollHeight));
      else if (this.element) {
        const i = t < 1 ? this.element.querySelector("tbody > tr:nth-child(1)") : this.element.querySelector(`tbody > tr:nth-child(${t + 1})`);
        i && this.containerRef.current && (this.containerRef.current.scrollTop = i.offsetTop);
      }
    }, this.scrollIntoView = (e) => {
      if (!this.vs.container || this.props.scrollable === "none")
        return;
      this.rowIndex = e.rowIndex;
      const { rowIndex: t } = e, i = this.element;
      if (this.observer && i) {
        this.observer.disconnect();
        const n = i.querySelector(`[absolute-row-index="${t}"]`);
        n ? this.observer.observe(n) : this.setCurrentOnTop(e);
      }
    }, this.fitColumns = (e) => {
      this.columnResize.dblClickHandler(null, e);
    }, this.onContextMenu = (e, t, i) => {
      if (this.props.onContextMenu) {
        const n = {
          target: this,
          syntheticEvent: e,
          nativeEvent: e.nativeEvent,
          dataItem: t,
          field: i
        };
        this.props.onContextMenu.call(void 0, n);
      }
    }, this.getVirtualScroll = (e) => {
      const { rowHeight: t, detailRowHeight: i, detail: n, expandField: h, groupable: l } = e, d = this.isAllData(), v = t !== void 0 && t > 0, R = i !== void 0 && i > 0, E = l === !0 || typeof l == "object" && l.enabled !== !1;
      return !v || E && !d || !!(n && h) && (!R || !d) ? nt : ue;
    }, this.isAllData = () => {
      const { data: e, total: t } = this.props;
      return Array.isArray(e) ? e.length === t : e ? t === e.total : !1;
    }, this.initializeVirtualization = (e, t) => {
      if (this.reInitVirtualization(
        this.props.scrollable === "virtual",
        this.props.total,
        this.props.filter,
        this.props.group,
        this.props.sort,
        t || this.props.rowHeight === void 0 || this.props.rowHeight === 0
      ), this.vs.fixedScroll = this.props.fixedScroll || !1, this.vs.PageChange = this.pageChange, this.vs.realSkip = this.props.skip || 0, this.vs.pageSize = (this.props.take !== void 0 ? this.props.take : this.props.pageSize) || 0, this.vs.scrollableVirtual = this.props.scrollable === "virtual", this.vs.total = e, this.vs.propsSkip = (this.props.skip || 0) + (this.props.scrollable === "virtual" ? this.vs.topCacheCount + (this.vs.attendedSkip - (this.props.skip || 0)) : 0), this.props.rowHeight !== void 0 && this.props.rowHeight > 0 && !t) {
        const i = this.props.rowHeight * e;
        this.vs.containerHeight = me ? Math.min(fe, i) : i;
      } else
        this.vs.containerHeight = 1533915;
      if (this.vs.containerRef = this.containerRef, this.vs.tableBodyRef = this.tableBodyRef, this.vs.table = this.tableElement, this._slicedData = void 0, this.vs instanceof ue) {
        const { rowHeight: i = 0, detail: n, expandField: h } = this.props;
        let { detailRowHeight: l = 0 } = this.props;
        l = n && h ? l : i, this.isAllData() ? (this.vs.total = this._data.length, this._slicedData = this._data.slice(this.vs.realSkip, this.vs.realSkip + this.vs.pageSize), this.vs.rowHeightService = this.rowHeightService(this.vs, this._data.length, i, l, this._data)) : this.vs.rowHeightService = new ge(e, i, l);
        const d = this.vs.rowHeightService.totalHeight();
        this.vs.containerHeight = me ? Math.min(fe, d) : d;
      }
    }, this.scrollHandler = (e) => {
      if (e.target !== e.currentTarget)
        return;
      clearTimeout(this.forceUpdateTimeout), this.vs.table = this.tableElement;
      const t = e.currentTarget.scrollLeft, i = e.currentTarget.scrollTop;
      this.props.columnVirtualization && (!this.vs.scrollableVirtual || i === this.wrapperScrollTop) && (this.forceUpdateTimeout = window.setTimeout(() => {
        this.forceUpdate();
      }, 0)), this._header && this._header.setScrollLeft(t), this._footer && this._footer.setScrollLeft(t), this.vs && i !== this.wrapperScrollTop && this.vs.scrollHandler(e), Ae(this.props.onScroll, e, this, void 0), this.wrapperScrollTop = i;
    }, this.onKeyDown = (e) => {
      if (x.onKeyDown(e, {
        contextStateRef: this.contextStateRef,
        navigationStateRef: this.navigationStateRef,
        onNavigationAction: this.onNavigationAction
      }), this.props.onKeyDown) {
        const { mode: t, cell: i } = ae(this.props.selectable), n = {
          dataItems: this.getLeafDataItems(),
          mode: t,
          cell: i,
          componentId: this._gridId,
          selectedField: this.props.selectedField,
          ...this.getArguments(e)
        };
        this.props.onKeyDown.call(void 0, n);
      }
    }, this.onFocus = (e) => {
      x.onFocus(e, {
        contextStateRef: this.contextStateRef
      });
    }, this.onNavigationAction = (e) => {
      if (this.props.onNavigationAction) {
        const t = {
          focusElement: e.focusElement,
          ...this.getArguments(e.event)
        };
        this.props.onNavigationAction.call(void 0, t);
      }
    }, this.rowClick = (e, t) => {
      this.props.onRowClick && e.target.nodeName === "TD" && this.props.onRowClick.call(
        void 0,
        {
          dataItem: t.dataItem,
          ...this.getArguments(e)
        }
      );
    }, this.rowDblClick = (e, t) => {
      this.props.onRowDoubleClick && e.target.nodeName === "TD" && this.props.onRowDoubleClick.call(
        void 0,
        {
          dataItem: t.dataItem,
          ...this.getArguments(e)
        }
      );
    }, this.itemChange = (e) => {
      const t = this.props.onItemChange;
      if (e.field === this.props.expandField || this.props.group && e.field === void 0) {
        const i = this.props.onExpandChange;
        i && i.call(
          void 0,
          {
            ...this.getArguments(e.syntheticEvent),
            dataItem: e.dataItem,
            dataIndex: e.dataIndex,
            value: e.value
          }
        );
        return;
      }
      t && t.call(
        void 0,
        {
          ...this.getArguments(e.syntheticEvent),
          dataItem: e.dataItem,
          field: e.field,
          value: e.value
        }
      );
    }, this.onHeaderSelectionChange = (e) => {
      this.props.onHeaderSelectionChange && this.props.onHeaderSelectionChange.call(
        void 0,
        {
          field: e.field,
          nativeEvent: e.syntheticEvent && e.syntheticEvent.nativeEvent,
          syntheticEvent: e.syntheticEvent,
          target: this,
          dataItems: this.getLeafDataItems(),
          selectedField: this.props.selectedField
        }
      );
    }, this.selectionRelease = (e) => {
      if (this.props.onSelectionChange) {
        const t = {
          syntheticEvent: void 0,
          target: this,
          selectedField: this.props.selectedField || "",
          componentId: this._gridId,
          dataItems: this.getLeafDataItems(),
          dataItem: null,
          ...e
        };
        this.props.onSelectionChange.call(void 0, t);
      }
    }, this.pageChange = (e, t, i) => {
      this.raiseDataEvent(
        this.props.onPageChange,
        { page: e },
        { skip: e.skip, take: e.take },
        t,
        i
      );
    }, this.pagerPageChange = (e) => {
      this.pageChange({ skip: e.skip, take: e.take }, e.syntheticEvent, e.targetEvent);
    }, this.sortChange = (e, t) => {
      this.raiseDataEvent(
        this.props.onSortChange,
        { sort: e },
        { sort: e, ...this.props.scrollable === "virtual" ? { skip: 0 } : {} },
        t
      );
    }, this.filterChange = (e, t) => {
      this.raiseDataEvent(
        this.props.onFilterChange,
        { filter: e },
        { filter: e || void 0, skip: 0 },
        t
      );
    }, this.groupChange = (e, t) => {
      const i = t.nativeEvent ? t : { nativeEvent: t.nativeEvent || t.originalEvent };
      this.raiseDataEvent(
        this.props.onGroupChange,
        { group: e },
        { group: e, skip: 0 },
        i
      );
    }, this.onClipboard = (e, t) => {
      if (this.props.onClipboard) {
        if (!this.inActiveElement() || !e)
          return;
        this.props.onClipboard.call(
          void 0,
          {
            type: e,
            nativeEvent: t,
            columns: this._columns,
            dataItemKey: this.props.dataItemKey || "",
            ...typeof this.props.clipboard != "boolean" ? this.props.clipboard : {}
          }
        );
      }
    }, this.inActiveElement = () => {
      var h, l, d;
      if (!U)
        return !1;
      const e = Ge(this.document), t = e ? e.matches(".k-table-td") ? e : (h = this.document) == null ? void 0 : h.body : (l = this.document) == null ? void 0 : l.body, i = t.closest(".k-grid-container"), n = t && ((d = this._element) == null ? void 0 : d.contains(t));
      return !!(t && n && i);
    }, this.onResize = (e, t, i, n, h, l) => {
      this.resetTableWidth(), this._shouldUpdateLeftRight = !0, this._resized = !0, this._columnsMutations++, this.props.onColumnResize && this.props.onColumnResize.call(
        void 0,
        {
          columns: this.columns,
          nativeEvent: n,
          targetColumnId: l,
          index: e,
          newWidth: t,
          oldWidth: i,
          end: h,
          target: this
        }
      );
    }, this.reInitVirtualization = S((e, t, i, n, h, l) => {
      this.vs.reset();
      const d = this.getVirtualScroll(this.props);
      this.vs = new d(l);
    }), this.flatData = S((e, t, i, n, h, l, d) => {
      const v = [], R = dt(
        v,
        e,
        t,
        { index: i },
        n !== void 0,
        h
      );
      return { flattedData: v, resolvedGroupsCount: R };
    }), this.rowHeightService = S((e, t, i, n, h) => new ge(t, i, n, h)), this.childrenToArray = S((e) => r.Children.toArray(e)), this.readColumns = S(
      (e, t, i) => {
        const n = e.filter((h) => h && h.type && h.type.displayName === "KendoReactGridColumn");
        return ct(n, this.columns, { prevId: 0, idPrefix: t });
      }
    ), this.mapColumns = S(
      (e) => Ve(e)
    ), this.configureColumns = S((e, t, i) => {
      this.props.selectedField && e.filter((l) => l.field === this.props.selectedField).forEach((l) => {
        l.width = l.width || "50px", l.cell = l.cell || Qe, l.headerCell = l.headerCell || bt;
      });
      const n = {
        id: "",
        resizable: !0,
        width: "32px",
        title: " ",
        declarationIndex: -1,
        orderIndex: -1,
        children: [],
        parentIndex: -1,
        depth: 0,
        colSpan: 0,
        rowSpan: 0,
        left: 0,
        right: 0,
        index: 0,
        rightBorder: !1,
        ariaColumnIndex: 0,
        isAccessible: !0
      };
      let h = 0;
      this.props.expandField && this.props.onExpandChange && this.props.detail && (e.unshift({
        ...n,
        cell: Xe,
        field: this.props.expandField,
        headerClassName: "k-hierarchy-cell k-header"
      }), h++);
      for (let l = 0; l < t; l++)
        e.unshift({
          ...n,
          isAccessible: !1,
          cell: be,
          field: "value",
          locked: this.props.lockGroups
        }), h++;
      e.slice(h).forEach((l) => l.parentIndex >= 0 && (l.parentIndex += h));
    }), this.getHeaderRow = S((e, t, i, n, h, l, d, v, R, E, b, N) => /* @__PURE__ */ r.createElement(
      et,
      {
        cells: this.props.cells,
        sort: e,
        sortable: t,
        sortChange: this.sortChange,
        group: i || [],
        groupable: n,
        groupChange: this.groupChange,
        filter: h,
        filterable: l,
        filterOperators: d,
        filterChange: this.filterChange,
        columnMenu: R,
        selectionChange: this.onHeaderSelectionChange,
        columns: E,
        columnResize: this.columnResize,
        pressHandler: this.dragLogic.pressHandler,
        dragHandler: this.dragLogic.dragHandler,
        releaseHandler: this.dragLogic.releaseHandler,
        columnsMap: b,
        cellRender: v,
        isRtl: N,
        dragClue: this.dragLogic.dragClueRef,
        headerRef: this.headerElementRef,
        containerRef: this.containerRef,
        columnGroupChange: this.columnGroupChange
      }
    )), this.resolveTitle = (e) => {
      const t = this._columns.find((n) => n.field === e), i = t && (t.title || t.field);
      return i === void 0 ? e : i;
    }, this.getLeafDataItems = () => this._data.filter((e) => e.rowType === "data").map((e) => e.dataItem), Ke(Re), this.showLicenseWatermark = Oe(Re);
    const p = this.props.groupable === !0 || typeof this.props.groupable == "object" && this.props.groupable.enabled !== !1, c = this.getVirtualScroll(a);
    this.vs = new c(p || a.rowHeight === void 0 || a.rowHeight === 0), this.dragLogic = new pt(
      this.columnReorder.bind(this),
      this.groupReorder.bind(this),
      this.columnToGroup.bind(this)
    ), this.columnGroupChange = this.columnGroupChange.bind(this), this.columnResize = new ht(this.onResize), this.props.clipboard && (this.clipboardService = new We(this.onClipboard)), x.onConstructor({
      navigatable: !!a.navigatable,
      contextStateRef: this.contextStateRef,
      navigationStateRef: this.navigationStateRef,
      idPrefix: this._gridId
    });
  }
  /* eslint-disable max-len */
  /**
   * A getter of the current columns. Gets the current column width or current columns, or any other [`GridColumnProps`]({% slug api_grid_gridcolumnprops %}) for each defined column. Can be used on each Grid instance. To obtain the instance of the rendered Grid, use the `ref` callback. The following example demonstrates how to reorder the columns by dragging their handlers and check the properties afterwards. You can check the result in the browser console.
   *
   * @example
   * ```jsx
   * class App extends React.Component {
   *     constructor(props) {
   *         super(props);
   *         this.state = {
   *             data: [
   *                 { 'foo': 'A1', 'bar': 'B1' },
   *                 { 'foo': 'A2', 'bar': 'B2' },
   *                 { 'foo': 'A3', 'bar': 'B2' }
   *             ]
   *         };
   *         this.grid = null;
   *     }
   *     render() {
   *         return (
   *             <div>
   *                 <Grid
   *                     data={this.state.data}
   *                     reorderable={true}
   *                     ref={(g) => { this.grid = g; }}
   *                 >
   *                     <GridColumn field="foo" />
   *                     <GridColumn field="bar" />
   *                 </Grid>
   *                 <button onClick={() => console.log(JSON.stringify(this.grid.columns))}>
   *                     log current properties into browser console.
   *                 </button>
   *             </div>
   *         );
   *     }
   * }
   * ReactDOM.render(<App />, document.querySelector('my-app'));
   * ```
   */
  // tslint:enable:max-line-length
  get columns() {
    const a = this._columns.filter((p) => p.declarationIndex >= 0 && p.parentIndex === -1);
    return ut(a);
  }
  /**
   * Returns the HTML element of the Grid component.
   */
  get element() {
    return this._element;
  }
  get document() {
    if (U)
      return this.element && this.element.ownerDocument || document;
  }
  get _header() {
    return this.headerRef.current;
  }
  get _gridId() {
    return this.props.id + "-role-element-id";
  }
  /**
   * @hidden
   */
  componentDidMount() {
    this.resetTableWidth(), re();
    const a = Be(this.element);
    this.isRtl = a, x.onComponentDidMount({
      scope: this.element || void 0,
      contextStateRef: this.contextStateRef,
      navigationStateRef: this.navigationStateRef
    }), this.isRtl && this.forceUpdate();
    const p = {
      rootMargin: "0px",
      threshold: 0.9
    };
    this.clipboardService && this.clipboardService.addEventListeners(this.document), this.observer = U && window.IntersectionObserver && new window.IntersectionObserver(this.handleIntersection, p) || null;
  }
  /**
   * @hidden
   */
  getSnapshotBeforeUpdate() {
    return x.onGetSnapshotBeforeUpdate({
      document: this.document,
      contextStateRef: this.contextStateRef,
      navigationStateRef: this.navigationStateRef
    }), null;
  }
  /**
   * @hidden
   */
  componentDidUpdate() {
    this.resetTableWidth(), re(), this.vs.tableTransform && this.vs.table && (this.vs.table.style.transform = this.vs.tableTransform, this.vs.tableTransform = ""), x.onComponentDidUpdate({
      scope: this.element || void 0,
      contextStateRef: this.contextStateRef,
      navigationStateRef: this.navigationStateRef
    });
  }
  /**
   * @hidden
   */
  componentWillUnmount() {
    clearTimeout(this.forceUpdateTimeout), this.clipboardService && this.clipboardService.removeEventListeners(this.document);
  }
  /**
   * @hidden
   */
  render() {
    var ie;
    let a = this.props.total || 0;
    const p = F.getIdPrefix(this.navigationStateRef), c = ot(this);
    let e = [];
    Array.isArray(this.props.data) ? (e = this.props.data, a = a || this.props.data.length) : this.props.data && (e = this.props.data.data, a = a || this.props.data.total);
    const t = typeof this.props.groupable == "object" && this.props.groupable.footer || "none", { resolvedGroupsCount: i, flattedData: n } = this.flatData(
      e,
      t,
      this.props.skip || 0,
      this.props.group,
      this.props.expandField,
      this.props.filter,
      this.props.sort
    );
    this._data = n;
    const h = this.props.groupable === !0 || typeof this.props.groupable == "object" && this.props.groupable.enabled !== !1;
    this.columnResize.resizable = this.props.resizable || !1, this.dragLogic.reorderable = this.props.reorderable || !1, this.dragLogic.groupable = h;
    const l = this.props.selectable && this.props.selectable.drag ? "none" : void 0;
    this.initializeVirtualization(a, h);
    const d = this.childrenToArray(this.props.children);
    this.initColumns(d, i);
    const v = d.map((o) => o && o.type && o.type.displayName === "KendoReactGridToolbar" ? r.cloneElement(o, { ...o.props, ariaControls: this._gridId }) : null), R = d.filter((o) => o && o.type && o.type.displayName === "KendoReactGridNoRecords"), E = d.filter((o) => o && o.type && o.type.displayName === "KendoReactGridStatusBar"), b = this._columns.filter((o) => o.children.length === 0), N = h && /* @__PURE__ */ r.createElement(
      st,
      {
        group: this.props.group || [],
        groupChange: this.groupChange,
        pressHandler: this.dragLogic.pressHandler,
        dragHandler: this.dragLogic.dragHandler,
        releaseHandler: this.dragLogic.releaseHandler,
        refCallback: this.dragLogic.refGroupPanelDiv,
        resolveTitle: this.resolveTitle,
        ariaControls: this._gridId,
        onContextMenu: this.onContextMenu
      }
    ), { sort: ye, sortable: we, group: Se, filter: ke, filterable: Ee, filterOperators: Ie = Ce, headerCellRender: xe, columnMenu: _e } = this.props, $ = /* @__PURE__ */ r.createElement(
      Ze,
      {
        size: this.props.size,
        columnResize: this.columnResize,
        staticHeaders: this.props.scrollable !== "none",
        scrollableDataElement: () => this.vs.container,
        draggable: this.props.reorderable || h,
        ref: this.headerRef,
        elemRef: this.headerElementRef,
        headerRow: this.getHeaderRow(
          ye,
          we,
          Se,
          h,
          ke,
          Ee,
          Ie,
          xe,
          _e,
          this._columns,
          this._columnsMap,
          this.isRtl
        ),
        filterRow: this.props.filterable && /* @__PURE__ */ r.createElement(
          tt,
          {
            cells: this.props.cells,
            size: this.props.size,
            columns: this._columns,
            filter: this.props.filter,
            filterOperators: this.props.filterOperators || Ce,
            filterChange: this.filterChange,
            sort: this.props.sort,
            cellRender: this.props.filterCellRender,
            isRtl: this.isRtl,
            ariaRowIndex: this._columnsMap.length + 1
          }
        ) || void 0,
        cols: b.map((o, g) => /* @__PURE__ */ r.createElement(
          "col",
          {
            key: g.toString(),
            width: W(o)
          }
        ))
      }
    ), ze = this.vs && this.vs.container && this.vs.container.scrollLeft || 0, De = parseFloat(((this.props.style || {}).width || "").toString()), { colSpans: He, hiddenColumns: Te } = $e({
      enabled: this.props.columnVirtualization,
      columns: b,
      tableViewPortWidth: De,
      scrollLeft: ze
    }), Le = (o, g, m) => {
      let I = !1;
      const k = this.props.selectedField ? ve(this.props.selectedField, o.dataItem) : void 0;
      return {
        row: b.map((u, f) => {
          if (Te[f])
            return null;
          const D = u.id ? u.id : f, H = `${u.className ? u.className + " " : ""}${u.locked ? "k-grid-content-sticky" : ""}`, y = u.left !== void 0 ? this.isRtl ? { left: u.right, right: u.left } : { left: u.left, right: u.right } : {};
          let w = !1;
          if (u.editable && this.props.editField) {
            const C = ve(this.props.editField, o.dataItem);
            C && (C === !0 || C === u.field) && (I = !0, w = !0);
          }
          const P = u.cell || w && Ye || ft, T = this.props.expandField && this.props.detail && u.field === this.props.expandField;
          return /* @__PURE__ */ r.createElement(
            P,
            {
              onContextMenu: this.onContextMenu,
              key: D,
              locked: this.props.lockGroups,
              id: F.generateNavigatableId(
                `${g}-${String(f)}`,
                p,
                T ? "nodata" : "cell"
              ),
              colSpan: He[f],
              dataItem: o.dataItem,
              field: u.field,
              editor: u.editor,
              format: u.format,
              className: H,
              render: this.props.cellRender,
              cells: mt(this.props.cells, u.cells),
              onChange: this.itemChange,
              selectionChange: this.props.onSelectionChange ? (C) => {
                this.selectionChange({ event: C, dataItem: o.dataItem, dataIndex: m, columnIndex: f });
              } : void 0,
              columnIndex: f,
              columnsCount: b.length,
              rowType: o.rowType,
              level: o.level,
              expanded: o.expanded,
              dataIndex: o.dataIndex,
              style: y,
              ariaColumnIndex: u.ariaColumnIndex,
              isSelected: Array.isArray(k) && k.indexOf(f) > -1
            }
          );
        }),
        isInEdit: I,
        isSelected: typeof k == "boolean" && k
      };
    };
    let j = 0;
    if (this.props.scrollable === "virtual")
      for (let o = 0; o < this.vs.topCacheCount + this.vs.attendedSkip - (this.props.skip || 0); o++) {
        const g = this._data.shift();
        if (g)
          this._data.push(g), j++, g.rowType === "groupHeader" && o--;
        else
          break;
      }
    const q = (o) => o >= this._data.length - j;
    let J = this.vs.realSkip || 0;
    const M = [];
    let K = !1;
    const O = this._columnsMap.length + (this.props.filterable ? 1 : 0) + 1, Q = /* @__PURE__ */ r.createElement("div", { key: "no-records", className: "k-grid-norecords", "aria-rowindex": O }, /* @__PURE__ */ r.createElement("div", { className: "k-grid-norecords-template" }, R.length ? R : /* @__PURE__ */ r.createElement(Ct, null)));
    let z = 0;
    if (this._data.length) {
      let o = -1, g = 0;
      (this._slicedData || this._data).forEach((m, I) => {
        m.rowType === "data" && (J++, o++);
        const k = J % 2 === 0, u = this.props.dataItemKey && Pe(this.props.dataItemKey)(m.dataItem), f = I + (this.vs.realSkip || 0), D = u || "ai" + f, H = D + "_1", y = Le(m, D, o);
        if (z = f + O + g, M.push(/* @__PURE__ */ r.createElement(
          vt,
          {
            key: D,
            dataItem: m.dataItem,
            isAltRow: k,
            isInEdit: y.isInEdit,
            rowType: m.rowType,
            isHidden: q(I),
            onClick: (w) => this.rowClick(w, m),
            onDoubleClick: (w) => this.rowDblClick(w, m),
            selectedField: this.props.selectedField,
            rowHeight: this.props.rowHeight,
            render: this.props.rowRender,
            ariaRowIndex: z,
            absoluteRowIndex: f,
            dataIndex: o,
            isSelected: y.isSelected
          },
          y.row
        )), this.props.detail && m.rowType === "data" && m.expanded) {
          const w = b.length - (this.props.expandField ? 1 : 0) - (this.props.group ? this.props.group.length : 0) || 1;
          g++, z = f + O + g, M.push(/* @__PURE__ */ r.createElement(
            "tr",
            {
              key: H,
              className: L(
                "k-table-row",
                k ? "k-detail-row k-table-alt-row k-alt" : "k-detail-row"
              ),
              style: { visibility: q(I) ? "hidden" : "", height: this.props.detailRowHeight },
              role: "row",
              "aria-rowindex": z
            },
            this.props.group && this.props.group.map((P, T) => {
              var oe;
              const C = (oe = y == null ? void 0 : y.row[T]) == null ? void 0 : oe.props.style, Me = C ? this.isRtl ? { left: C.right, right: C.left } : { left: C.left, right: C.right } : {};
              return /* @__PURE__ */ r.createElement(
                be,
                {
                  id: "",
                  dataIndex: m.dataIndex,
                  field: P.field,
                  dataItem: m.dataItem,
                  key: T,
                  style: Me,
                  ariaColumnIndex: 1 + T,
                  isSelected: !1,
                  locked: this.props.lockGroups,
                  isRtl: this.isRtl,
                  cells: this.props.cells
                }
              );
            }),
            this.props.expandField && /* @__PURE__ */ r.createElement(
              St,
              {
                id: F.generateNavigatableId(`${H}-dhcell`, p)
              }
            ),
            /* @__PURE__ */ r.createElement(
              wt,
              {
                onContextMenu: this.onContextMenu,
                dataItem: m.dataItem,
                dataIndex: m.dataIndex,
                colSpan: w,
                ariaColIndex: 2 + (this.props.group ? this.props.group.length : 0),
                detail: this.props.detail,
                id: F.generateNavigatableId(`${H}-dcell`, p)
              }
            )
          ));
        }
      });
    } else
      K = !0;
    const X = {
      size: this.props.size,
      onPageChange: this.pagerPageChange,
      total: a,
      dir: this.isRtl ? "rtl" : void 0,
      skip: this.vs.propsSkip || 0,
      take: (this.props.take !== void 0 ? this.props.take : this.props.pageSize) || 10,
      messagesMap: at,
      ...yt(this.props.pageable || {})
    }, Y = this.props.pager ? /* @__PURE__ */ r.createElement(this.props.pager, { ...X }) : /* @__PURE__ */ r.createElement(je, { className: "k-grid-pager", ...X }), Fe = (o) => this.props.sort && this.props.sort.filter((g) => g.field === o).length > 0, Ne = (o, g) => /* @__PURE__ */ r.createElement(
      "col",
      {
        key: g.toString(),
        width: W(o)
      }
    ), Z = (ie = this.props.cells) != null && ie.footerCell || this._columns.some(
      (o) => {
        var g;
        return !!(o.footerCell || (g = o.cells) != null && g.footerCell);
      }
    ) ? /* @__PURE__ */ r.createElement(
      it,
      {
        size: this.props.size,
        columnResize: this.columnResize,
        staticHeaders: this.props.scrollable !== "none",
        ref: (o) => this._footer = o,
        row: /* @__PURE__ */ r.createElement(
          Rt,
          {
            cells: this.props.cells,
            columns: this._columns,
            isRtl: this.isRtl,
            ariaRowIndex: z + 1
          }
        ),
        cols: b.map(Ne)
      }
    ) : null, ee = /* @__PURE__ */ r.createElement(
      "colgroup",
      {
        ref: (o) => {
          this.columnResize.colGroupMain = o;
        }
      },
      b.map(
        (o, g) => /* @__PURE__ */ r.createElement(
          "col",
          {
            key: g.toString(),
            className: Fe(o.field) ? "k-sorted" : void 0,
            width: W(o)
          }
        )
      )
    ), te = this.dragLogic.reorderable || this.dragLogic.groupable, se = this.props.columnVirtualization ? "block" : "table";
    if (this.props.scrollable === "none")
      return /* @__PURE__ */ r.createElement(V.Provider, { value: this.contextStateRef.current }, /* @__PURE__ */ r.createElement(
        "div",
        {
          id: this.props.id,
          style: this.props.style,
          className: L(
            "k-grid",
            {
              "k-grid-md": !this.props.size,
              [`k-grid-${G.sizeMap[this.props.size] || this.props.size}`]: this.props.size
            },
            this.props.className
          ),
          ref: (o) => {
            this._element = o;
          },
          "aria-label": this.props.ariaLabel,
          onKeyDown: this.onKeyDown,
          onFocus: this.onFocus,
          ...le
        },
        v,
        N,
        /* @__PURE__ */ r.createElement(
          ne,
          {
            selectable: this.props.selectable,
            onRelease: this.selectionRelease,
            childRef: (o) => {
              this.tableElement = o;
            }
          },
          /* @__PURE__ */ r.createElement(
            "table",
            {
              className: L(
                "k-table k-grid-table",
                { [`k-table-${G.sizeMap[this.props.size] || this.props.size}`]: this.props.size }
              ),
              style: { userSelect: l, display: se }
            },
            ee,
            $,
            /* @__PURE__ */ r.createElement("tbody", { role: "rowgroup", className: "k-table-tbody", ...he }, M),
            Z
          )
        ),
        K && Q,
        te && /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement(pe, { ref: this.dragLogic.refDropElementClue }), /* @__PURE__ */ r.createElement(de, { ref: this.dragLogic.refDragElementClue }))
      ), E, this.props.pageable && Y);
    let A = this.props.style || {};
    return this.props.scrollable === "virtual" && (A.height || (A = Object.assign({}, A, { height: "450px" }))), /* @__PURE__ */ r.createElement(V.Provider, { value: this.contextStateRef.current }, /* @__PURE__ */ r.createElement(
      "div",
      {
        id: this.props.id,
        style: A,
        className: L(
          "k-grid",
          {
            "k-grid-md": !this.props.size,
            [`k-grid-${G.sizeMap[this.props.size] || this.props.size}`]: this.props.size,
            "k-grid-virtual": this.props.scrollable === "virtual"
          },
          this.props.className
        ),
        ref: (o) => {
          this._element = o;
        },
        "aria-label": this.props.ariaLabel,
        onKeyDown: this.onKeyDown,
        onFocus: this.onFocus,
        ...le
      },
      v,
      N,
      /* @__PURE__ */ r.createElement(
        "div",
        {
          className: "k-grid-aria-root",
          role: "grid",
          "aria-colcount": b.length,
          "aria-rowcount": a,
          id: this._gridId,
          "aria-label": c.toLanguageString(ce, lt[ce])
        },
        $,
        /* @__PURE__ */ r.createElement("div", { className: "k-grid-container", role: "presentation" }, /* @__PURE__ */ r.createElement(
          "div",
          {
            ref: this.containerRef,
            className: "k-grid-content k-virtual-content",
            onScroll: this.scrollHandler,
            role: "presentation"
          },
          /* @__PURE__ */ r.createElement("div", { className: "k-grid-table-wrap", role: "presentation" }, /* @__PURE__ */ r.createElement(
            ne,
            {
              selectable: this.props.selectable,
              onRelease: this.selectionRelease,
              childRef: (o) => {
                this.tableElement = o;
              }
            },
            /* @__PURE__ */ r.createElement(
              "table",
              {
                className: L(
                  "k-table k-grid-table",
                  {
                    "k-table-md": !this.props.size,
                    [`k-table-${G.sizeMap[this.props.size] || this.props.size}`]: this.props.size
                  }
                ),
                role: "presentation",
                style: { userSelect: l, display: se }
              },
              ee,
              /* @__PURE__ */ r.createElement(
                "tbody",
                {
                  className: "k-table-tbody",
                  ref: this.tableBodyRef,
                  role: "rowgroup",
                  ...he
                },
                M
              )
            )
          ), K && Q),
          /* @__PURE__ */ r.createElement("div", { className: "k-height-container", role: "presentation" }, /* @__PURE__ */ r.createElement(
            "div",
            {
              style: this.props.scrollable === "virtual" ? { height: this.vs.containerHeight + "px" } : {}
            }
          ))
        )),
        Z,
        te && /* @__PURE__ */ r.createElement(r.Fragment, null, /* @__PURE__ */ r.createElement(pe, { ref: this.dragLogic.refDropElementClue }), /* @__PURE__ */ r.createElement(de, { ref: this.dragLogic.refDragElementClue })),
        this.showLicenseWatermark && /* @__PURE__ */ r.createElement(Ue, null)
      ),
      E,
      this.props.pageable && Y
    ));
  }
  selectionChange(a) {
    if (this.props.onSelectionChange) {
      const { event: p, dataItem: c, dataIndex: e, columnIndex: t } = a, { mode: i, cell: n } = ae(this.props.selectable), h = {
        ...this.getArguments(p.syntheticEvent),
        dataItem: c,
        startColIndex: t,
        endColIndex: t,
        startRowIndex: e,
        endRowIndex: e,
        dataItems: this.getLeafDataItems(),
        altKey: !1,
        ctrlKey: !1,
        shiftKey: !1,
        metaKey: !1,
        mode: i,
        cell: n,
        isDrag: !1,
        componentId: this._gridId,
        selectedField: this.props.selectedField || ""
      };
      this.props.onSelectionChange.call(void 0, h);
    }
  }
  raiseDataEvent(a, p, c, e, t) {
    const i = this.props.onDataStateChange;
    if (a) {
      const n = { ...this.getArguments(e), ...p, targetEvent: t };
      a.call(void 0, n);
    } else
      i && i.call(
        void 0,
        {
          ...this.getArguments(e),
          targetEvent: t || {},
          dataState: {
            ...this.getDataState(),
            ...c
          }
        }
      );
  }
  columnReorder(a, p, c) {
    const e = this._columns[a], t = e.depth, i = (d) => {
      do
        d++;
      while (d < this._columns.length && this._columns[d].depth > t);
      return d;
    }, n = this._columns.splice(a, i(a) - a);
    this._columns.splice(a < p ? i(p - n.length) : p, 0, ...n), this._columns.filter((d) => d.declarationIndex >= 0).forEach((d, v) => d.orderIndex = v), this._columnsMutations++;
    const h = this._columns[a].locked && this._columns[p].locked;
    qe(this._columnsMap, this._columns, h || this._shouldUpdateLeftRight), Je(this._columnsMap, this._columns, h || this._shouldUpdateLeftRight), this._resized && (this._shouldUpdateLeftRight = !1, this._resized = !1);
    const l = this.columns;
    if (this.forceUpdate(), this.props.onColumnReorder) {
      const d = {
        target: this,
        columns: l,
        columnId: e.id,
        nativeEvent: c
      };
      this.props.onColumnReorder.call(void 0, d);
    }
  }
  groupReorder(a, p, c) {
    if (this.props.group === void 0)
      return;
    const e = this.props.group.slice();
    e.splice(p, 0, ...e.splice(a, 1)), this.groupChange(e, c);
  }
  columnToGroup(a, p, c) {
    const e = this._columns[a].field;
    if (!e)
      return;
    const t = (this.props.group || []).slice();
    t.splice(p, 0, { field: e }), this.groupChange(t, c);
  }
  columnGroupChange(a, p) {
    const c = this.dragLogic.getCurrentGroupsLength;
    this.columnToGroup(a, c, p);
  }
  resetTableWidth() {
    let a = 0;
    if (!this.columnResize.colGroupMain)
      return;
    const p = this.columnResize.colGroupMain.children;
    for (let c = 0; c < p.length; c++) {
      const e = p[c].width;
      if (!e)
        return;
      a += parseFloat(e.toString());
    }
    a = Math.round(a), this._header && this._header.setWidth(a), this._footer && this._footer.setWidth(a), this.tableElement && (this.tableElement.style.width = a + "px");
  }
  initColumns(a, p) {
    const c = F.getIdPrefix(this.navigationStateRef);
    this._columns = this.readColumns(a, c, this._columnsMutations), this._columns.length === 0 && (this._columns = gt(this.props.data, this.props.group, this.props.expandField, { prevId: 0, idPrefix: c })), this.configureColumns(this._columns, p, c), this._columnsMap = this.mapColumns(this._columns), this.columnResize.columns = this._columns, this.dragLogic.columns = this._columns;
  }
  getDataState() {
    return {
      filter: this.props.filter,
      sort: this.props.sort,
      skip: this.props.skip,
      take: this.props.take !== void 0 ? this.props.take : this.props.pageSize,
      group: this.props.group
    };
  }
  getArguments(a) {
    return {
      nativeEvent: a && a.nativeEvent,
      syntheticEvent: a,
      target: this
    };
  }
};
_.displayName = "KendoReactGrid", _.defaultProps = {
  // never use this
}, _.propTypes = {
  data: s.oneOfType([
    s.array,
    s.shape({
      data: s.array,
      total: s.number
    })
  ]),
  sortable: s.oneOfType([
    s.bool,
    s.shape({
      mode: s.oneOf(["single", "multiple"]),
      allowUnsort: s.bool
    })
  ]),
  onSortChange: s.func,
  sort: s.array,
  filterable: s.bool,
  filter: s.any,
  onFilterChange: s.func,
  pageable: s.oneOfType([
    s.bool,
    s.shape({
      buttonCount: s.number,
      responsive: s.bool,
      info: s.bool,
      type: s.oneOf(["numeric", "input"]),
      pageSizes: s.oneOfType(
        [
          s.bool,
          s.arrayOf(s.oneOfType([
            s.string,
            s.number
          ]))
        ]
      ),
      previousNext: s.bool
    })
  ]),
  pageSize: s.number,
  onPageChange: s.func,
  total: s.number,
  skip: s.number,
  take: s.number,
  fixedScroll: s.bool,
  onExpandChange: s.func,
  expandField: s.string,
  selectedField: s.string,
  onSelectionChange: s.func,
  onHeaderSelectionChange: s.func,
  resizable: s.bool,
  reorderable: s.bool,
  group: s.any,
  groupable: s.oneOfType([
    s.bool,
    s.shape({
      enabled: s.bool,
      footer: s.oneOf(["always", "visible", "none"])
    })
  ]),
  onGroupChange: s.func,
  onRowClick: s.func,
  onRowDoubleClick: s.func,
  onItemChange: s.func,
  editField: s.string,
  scrollable: s.oneOf(["none", "scrollable", "virtual"]),
  rowHeight: s.number,
  detailRowHeight: s.number,
  detail: s.any,
  style: s.object,
  onDataStateChange: s.func,
  onColumnResize: s.func,
  onColumnReorder: s.func,
  dataItemKey: s.string,
  navigatable: s.bool,
  size: s.oneOf(["small", "medium"])
}, _.contextType = V;
let B = _;
rt(B);
export {
  B as Grid
};
