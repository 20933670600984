/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { BaseEvent as s } from "./base-event.mjs";
class n extends s {
  /**
   * @hidden
   */
  constructor(t, e) {
    super(e), this.value = t.value, this.point = t.point, this.series = t.series, this.currentState = t.currentState, this.nextState = t.nextState;
  }
}
export {
  n as DrilldownEvent
};
