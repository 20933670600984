/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const o = (e, D, n) => {
  const t = typeof e == "string" ? parseInt(e) : e;
  if (!Number.isNaN(t))
    return t !== void 0 ? t : D ? n ? void 0 : -1 : 0;
};
export {
  o as getTabIndex
};
