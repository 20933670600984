/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import { normalize as z } from "../interfaces/GridSortSettings.mjs";
import { ColumnDraggable as H } from "../drag/ColumnDraggable.mjs";
import { GridHeaderCell as I } from "./GridHeaderCell.mjs";
import { Keys as m, classNames as w, IconWrap as D } from "@progress/kendo-react-common";
import { ColumnResizer as E, HeaderThElement as v } from "@progress/kendo-react-data-tools";
import { provideLocalizationService as M, registerForLocalization as N } from "@progress/kendo-react-intl";
import { sortAriaLabel as g, messages as K } from "../messages/index.mjs";
import { sortAscSmallIcon as L, sortDescSmallIcon as A } from "@progress/kendo-svg-icons";
import { resolveCells as G } from "../utils/index.mjs";
const F = {
  true: { asc: "desc", desc: "", "": "asc" },
  false: { asc: "desc", desc: "asc", "": "asc" }
}, P = {
  none: "none",
  asc: "ascending",
  desc: "descending"
};
class O extends o.Component {
  constructor(t) {
    super(t), this.serviceIndex = 0, this.index = -1, this.cellKeyDown = (s, l) => {
      if (s.isDefaultPrevented())
        return;
      const { keyCode: e, metaKey: i, ctrlKey: a } = s;
      if (this.props.sortable && l.sortable && s.keyCode === m.enter && this.cellClick(s, l), i && e === m.enter || a && e === m.space) {
        const n = this.props.columns.findIndex((c) => c.field === l.field);
        this.props.columnGroupChange && this.props.columnGroupChange(n, s);
      }
    }, this.cells = (s) => s.map((l) => {
      const e = this.props.columns[l], i = this.props.sortable && e.sortable, a = this.props.sort ? this.props.sort.findIndex((r) => r.field === e.field) : -1, p = a >= 0 && this.props.sort[a].dir || "none", n = e.columnMenu === null ? null : e.columnMenu || this.props.columnMenu, c = w({
        "k-first": e.kFirst,
        "k-filterable": !!n,
        "k-table-th": !0,
        "k-header": !0,
        "k-grid-header-sticky": e.locked,
        "k-sorted": this.props.sort && this.props.sort.some((r) => r.field === e.field)
      }, e.headerClassName);
      e.locked === !1 && (e.left = 0);
      const C = e.left !== void 0 ? this.props.isRtl ? { left: e.right, right: e.left } : { left: e.left, right: e.right } : {}, k = M(this).toLanguageString(g, K[g]), b = e.isAccessible ? {
        ariaSort: P[p],
        role: "columnheader",
        ariaColumnIndex: e.ariaColumnIndex,
        ariaSelected: !1,
        ariaDescription: i ? k : ""
      } : {
        role: "presentation"
      }, d = e.declarationIndex >= 0 ? ++this.index : --this.serviceIndex, y = e.headerCell ? e.headerCell : I, R = /* @__PURE__ */ o.createElement(
        y,
        {
          key: 1,
          field: e.field,
          onClick: i && ((r) => this.cellClick(r, e)) || void 0,
          selectionChange: this.props.selectionChange,
          title: e.title,
          selectionValue: e.headerSelectionValue,
          render: this.props.cellRender,
          children: this.sortIcon(a),
          columnMenuWrapperProps: {
            column: {
              field: e.field,
              title: e.title,
              locked: e.locked,
              filter: e.filter,
              id: e.id
            },
            sortable: i && this.props.sortable,
            sort: this.props.sort,
            onSortChange: this.props.sortChange,
            filter: this.props.filter,
            filterable: this.props.filterable && e.filterable,
            filterOperators: this.props.filterOperators,
            onFilterChange: this.props.filterChange,
            group: this.props.group,
            groupable: this.props.groupable,
            onGroupChange: this.props.groupChange,
            columnMenu: n
          }
        }
      ), f = {
        ...b,
        key: d,
        colSpan: e.colSpan,
        rowSpan: e.rowSpan,
        className: c,
        style: C,
        columnId: e.id,
        navigatable: e.navigatable,
        onKeyDown: (r) => this.cellKeyDown(r, e),
        role: "columnheader"
      }, u = [
        R,
        this.props.columnResize && this.props.columnResize.resizable && e.resizable && /* @__PURE__ */ o.createElement(
          E,
          {
            key: 2,
            resize: (r, S, x) => this.props.columnResize && this.props.columnResize.dragHandler(r, e, S, x),
            autofit: (r) => this.props.columnResize && this.props.columnResize.dblClickHandler(r, [e.id])
          }
        )
      ], h = G(this.props.cells, e.cells);
      if (h && h.headerCell) {
        const r = h.headerCell;
        return /* @__PURE__ */ o.createElement(r, { key: d, thProps: f, index: l }, u);
      }
      return /* @__PURE__ */ o.createElement(
        v,
        {
          ...f,
          key: d
        },
        u
      );
    }), this.cellClick = this.cellClick.bind(this);
  }
  cellClick(t, s) {
    if (t.preventDefault(), !this.props.sortChange)
      return;
    const { allowUnsort: l, mode: e } = z(this.props.sortable || !1, s.sortable || !1), i = (this.props.sort || []).filter((n) => n.field === s.field)[0], a = F[l][i && i.dir || ""], p = e === "single" ? [] : (this.props.sort || []).filter((n) => n.field !== s.field);
    a !== "" && s.field && p.push({ field: s.field, dir: a }), this.props.sortChange(p, t);
  }
  sortIcon(t) {
    return this.props.sort ? t >= 0 && [
      /* @__PURE__ */ o.createElement("span", { key: 1, className: "k-sort-icon" }, /* @__PURE__ */ o.createElement(
        D,
        {
          name: "sort-" + this.props.sort[t].dir + "-small",
          icon: this.props.sort[t].dir === "asc" ? L : A
        }
      )),
      this.props.sort.length > 1 && /* @__PURE__ */ o.createElement("span", { key: 2, className: "k-sort-icon" }, /* @__PURE__ */ o.createElement("span", { className: "k-sort-order" }, t + 1))
    ] : null;
  }
  render() {
    return this.serviceIndex = 0, this.index = -1, this.props.columnsMap.map((t, s) => this.props.pressHandler && /* @__PURE__ */ o.createElement(
      H,
      {
        key: s,
        pressHandler: this.props.pressHandler,
        dragHandler: this.props.dragHandler,
        releaseHandler: this.props.releaseHandler,
        ariaRowIndex: s + 1,
        dragClue: this.props.dragClue,
        headerRef: this.props.headerRef,
        containerRef: this.props.containerRef
      },
      this.cells(t)
    ) || /* @__PURE__ */ o.createElement("tr", { key: s, className: "k-table-row", role: "row", "aria-rowindex": s + 1 }, this.cells(t)));
  }
}
N(O);
export {
  O as HeaderRow
};
