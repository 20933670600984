import { Color } from '@progress/kendo-drawing';
import { WHITE, BLACK } from './constants';

function autoTextColor(color) {
    var isDark = new Color(color).isDark();
    if (isDark) {
        return WHITE;
    }

    return BLACK;
}

export default autoTextColor;
