import LineChart from '../line-chart/line-chart';
import SplineSegment from '../line-chart/spline-segment';
import LineSegment from '../line-chart/line-segment';

import { Point, Box } from '../../core';

import { SMOOTH, INTERPOLATE } from '../constants';

import { setDefaultOptions, last } from '../../common';

function groupBySeriesIx(segments) {
    var seriesSegments = [];
    for (var idx = 0; idx < segments.length; idx++) {
        var segment = segments[idx];
        seriesSegments[segment.seriesIx] = seriesSegments[segment.seriesIx] || [];
        seriesSegments[segment.seriesIx].push(segment);
    }

    return seriesSegments;
}

var RadarLineChart = (function (LineChart) {
    function RadarLineChart () {
        LineChart.apply(this, arguments);
    }

    if ( LineChart ) RadarLineChart.__proto__ = LineChart;
    RadarLineChart.prototype = Object.create( LineChart && LineChart.prototype );
    RadarLineChart.prototype.constructor = RadarLineChart;

    RadarLineChart.prototype.pointSlot = function pointSlot (categorySlot, valueSlot) {
        var valueRadius = categorySlot.center.y - valueSlot.y1;
        var slot = Point.onCircle(categorySlot.center, categorySlot.middle(), valueRadius);

        return new Box(slot.x, slot.y, slot.x, slot.y);
    };

    RadarLineChart.prototype.renderSegments = function renderSegments () {
        LineChart.prototype.renderSegments.call(this);

        if (this._segments && this._segments.length > 1) {
            var seriesSegments = groupBySeriesIx(this._segments);

            for (var idx = 0; idx < seriesSegments.length; idx++) {
                var segments = seriesSegments[idx];
                if (segments && segments.length > 1) {
                    var firstPoint = segments[0].linePoints[0];
                    var lastSegment = last(segments);
                    var lastPoint = last(lastSegment.linePoints);
                    var isFirstDataPoint = firstPoint.categoryIx === 0;
                    var isLastDataPoint = lastPoint.categoryIx === lastPoint.categoriesCount - 1;
                    if (isFirstDataPoint && isLastDataPoint) {
                        last(segments).linePoints.push(firstPoint);
                    }
                }
            }
        }
    };

    RadarLineChart.prototype.createSegment = function createSegment (linePoints, currentSeries, seriesIx) {
        var style = currentSeries.style;
        var pointType;

        if (style === SMOOTH) {
            pointType = SplineSegment;
        } else {
            pointType = LineSegment;
        }

        var segment = new pointType(linePoints, currentSeries, seriesIx);
        var missingValues = this.seriesMissingValues(currentSeries);

        if (linePoints.length === currentSeries.data.length || missingValues === INTERPOLATE) {
            segment.options.closed = true;
        }

        return segment;
    };

    return RadarLineChart;
}(LineChart));

setDefaultOptions(RadarLineChart, {
    clip: false,
    limitPoints: false
});

export default RadarLineChart;
