/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
function a(r, t) {
  let e = r;
  for (; e && e !== t; )
    e = e.parentNode;
  return !!e;
}
function i(r, t, e, o) {
  const n = r[t];
  if (n && Array.isArray(n)) {
    for (let l of n)
      if (!l.type || l.type !== o)
        return new Error(
          `${e} children should be Array of type ${o.displayName}.`
        );
  }
  return null;
}
const u = (r) => r === void 0 ? !1 : !r;
export {
  a as hasParent,
  u as toggle,
  i as validateChildren
};
