var DELETED = {};

var LegacySet = function LegacySet(values) {
    var this$1 = this;

    this._index = {};
    this._values = values ? values.slice(0) : [];

    for (var i = 0; i < this._values.length; i++) {
        this$1._index[this$1._values[i]] = i;
    }
};

var prototypeAccessors = { size: { configurable: true } };

LegacySet.prototype.values = function values () {
    return this._values.filter(function (item) { return item !== DELETED; });
};

LegacySet.prototype.has = function has (value) {
    return this._index[value] !== undefined;
};

LegacySet.prototype.add = function add (value) {
    if (!this.has(value)) {
        this._index[value] = this._values.length;
        this._values.push(value);
    }
};

LegacySet.prototype.delete = function delete$1 (value) {
    var index = this._index[value];
    if (index !== undefined) {
        this._values[index] = DELETED;
        delete this._index[value];
    }
};

LegacySet.prototype.clear = function clear () {
    this._index = {};
    this._values = [];
};

prototypeAccessors.size.get = function () {
    return this._values.length;
};

Object.defineProperties( LegacySet.prototype, prototypeAccessors );

var SetWrapper = function SetWrapper(values) {
    this._set = new Set(values);
};

var prototypeAccessors$1 = { size: { configurable: true } };

SetWrapper.prototype.values = function values () {
    return Array.from(this._set);
};

SetWrapper.prototype.has = function has (value) {
    return this._set.has(value);
};

SetWrapper.prototype.add = function add (value) {
    this._set.add(value);
};

SetWrapper.prototype.delete = function delete$2 (value) {
    this._set.delete(value);
};

SetWrapper.prototype.clear = function clear () {
    this._set.clear();
};

prototypeAccessors$1.size.get = function () {
    return this._set.size;
};

Object.defineProperties( SetWrapper.prototype, prototypeAccessors$1 );

// TODO: Drop LegacySet when removing support for IE10
var supportsSet = function () {
    var supported = false;

    if (typeof Set === 'function') {
        var set = new Set([1]);
        supported = set.has(1);
    }

    return supported;
};

export default function createHashSet(values) {
    if (supportsSet()) {
        return new SetWrapper(values);
    }

    return new LegacySet(values);
}
