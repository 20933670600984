/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import t from "prop-types";
import { classNames as s } from "@progress/kendo-react-common";
const c = "ActionSheetHeader", e = (a) => /* @__PURE__ */ r.createElement(
  "div",
  {
    className: s(
      "k-actionsheet-titlebar",
      a.className
    )
  },
  a.children
);
e.propTypes = {
  className: t.string
};
e.displayName = c;
e.propTypes = {
  children: t.any
};
export {
  e as ActionSheetHeader,
  c as headerDisplayName
};
