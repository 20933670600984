/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
class c {
  constructor(t) {
    this.rovingTabIndex = !0, this.update = () => {
    }, this.focusNextIndex = (e, r) => {
      const s = this.elements;
      let n = s.indexOf(e) + r;
      n = n < 0 ? s.length - 1 : n;
      const i = s[n % s.length];
      return this.focusElement(i, e), i;
    }, this.tabIndex = t.tabIndex || 0, this.root = t.root, this.selectors = t.selectors, this.focusOptions = t.focusOptions || { preventScroll: !0 }, this.rovingTabIndex = t.rovingTabIndex !== void 0 ? t.rovingTabIndex : !0, this.mouseEvents = t.mouseEvents || {}, this.keyboardEvents = t.keyboardEvents || {};
  }
  /**
   * Returns the collection of DOM elements which the module will navigate in.
   */
  get elements() {
    return this.root.current ? Array.from(this.root.current.querySelectorAll(this.selectors.join(","))) : [];
  }
  /**
   * Returns the first navigation DOM element.
   */
  get first() {
    return this.root.current && this.root.current.querySelector(this.selectors.join(",")) || null;
  }
  /**
   * Returns the last navigation DOM element.
   */
  get last() {
    const t = this.elements;
    return t[t.length - 1] || null;
  }
  /**
   * Returns the focused DOM element from the navigation collection of DOM elements.
   */
  get current() {
    return this.elements.find((t) => t.matches(":focus")) || null;
  }
  /**
   * Focuses the next element from the navigation collection of DOM elements.
   */
  focusNext(t) {
    return this.focusNextIndex(t, 1);
  }
  /**
   * Focuses the previous element from the navigation collection of DOM elements.
   */
  focusPrevious(t) {
    return this.focusNextIndex(t, -1);
  }
  /**
   * The keyboard events handler.
   */
  triggerKeyboardEvent(t, e) {
    const r = t.target instanceof Element && t.target.closest(this.selectors.join(",")), s = t.key === " " ? "Space" : t.key, n = t.nativeEvent.type;
    r && this.keyboardEvents[n][s] && this.keyboardEvents[n][s].call(void 0, r, this, t, e);
  }
  /**
   * The mouse events handler.
   */
  triggerMouseEvent(t) {
    const e = t.target instanceof Element && t.target.closest(this.selectors.join(",")), r = t.nativeEvent.type;
    e && this.mouseEvents[r].call(void 0, e, this, t);
  }
  /**
   * Focuses the passed element from the navigation collection of DOM elements.
   */
  focusElement(t, e) {
    t && (e && (this.rovingTabIndex && e.removeAttribute("tabindex"), e.classList.remove("k-focus")), this.rovingTabIndex && t.setAttribute("tabindex", String(this.tabIndex)), t.focus(this.focusOptions));
  }
}
export {
  c as Navigation
};
