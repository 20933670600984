/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const s = ({
  buttonCount: e = 10,
  info: r = !0,
  type: i = "numeric",
  pageSizes: o = void 0,
  previousNext: t = !0,
  responsive: n = void 0,
  pageSizeValue: u = void 0
}) => ({
  buttonCount: e,
  info: r,
  pageSizes: o === !0 ? [5, 10, 20] : o || void 0,
  previousNext: t,
  responsive: n !== !1,
  type: i,
  pageSizeValue: u
}), l = (e) => s(e === !0 ? {} : e);
export {
  l as normalize
};
