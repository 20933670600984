/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
const n = () => null, r = ({ children: e }) => e, s = (e) => {
  if (e) {
    if (e === t.Fragment)
      return [r, {}];
    if (t.isValidElement(e))
      return [e.type, e.props];
  } else
    return [n, {}];
  return [e, {}];
};
export {
  s as useCustomComponent
};
