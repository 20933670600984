/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import o from "prop-types";
import { classNames as g } from "@progress/kendo-react-common";
import { cloneDate as x, getDate as d, isEqualDate as D } from "@progress/kendo-date-math";
import { provideIntlService as y, registerForIntl as V } from "@progress/kendo-react-intl";
import { Virtualization as S } from "../../virtualization/Virtualization.mjs";
import { View as I } from "./View.mjs";
import { Header as T } from "./Header.mjs";
import { dateInRange as u, shiftWeekNames as C, isInRange as E, getToday as N } from "../../utils.mjs";
import { CalendarViewEnum as l } from "../models/CalendarViewEnum.mjs";
import { TodayCommand as O } from "./TodayCommand.mjs";
const H = 5, h = class h extends s.Component {
  constructor(r) {
    super(r), this.virtualization = null, this.calendarView = null, this.table = null, this.intl = null, this.bottomOffset = 0, this.viewOffset = 0, this.viewHeight = 0, this._element = null, this.isActive = !1, this.animateToIndex = !0, this.shouldScroll = !1, this.focusActiveDate = () => {
      if (!this._element)
        return;
      const e = this._element.querySelector("td.k-focus"), t = this._element.querySelector(".k-state-pending-focus");
      e && e[0] && e[0].classList.remove("k-focus"), t && t.classList.add("k-focus"), this.isActive = !0;
    }, this.blurActiveDate = () => {
      if (!this._element)
        return;
      const e = this._element.querySelector("td.k-focus");
      e && e.classList.remove("k-focus"), this.isActive = !1;
    }, this.handleVirtualizationMount = (e) => {
      if (this.virtualization = e, this.virtualization && this.table) {
        this.table.style.transform = `translateY(${this.viewOffset}px)`;
        const t = u(this.props.focusedDate, this.props.min, this.props.max), i = this.props.service.skip(t, this.props.min);
        this.virtualization.scrollToIndex(i);
      }
    }, this.buildMonthView = (e, t) => /* @__PURE__ */ s.createElement(
      "table",
      {
        key: "calendar-view-list-weekdays",
        className: "k-calendar-table k-calendar-weekdays",
        role: "grid",
        tabIndex: this.props.tabIndex
      },
      /* @__PURE__ */ s.createElement("colgroup", null, e.map((i, a) => /* @__PURE__ */ s.createElement("col", { key: a }))),
      /* @__PURE__ */ s.createElement("thead", { className: "k-calendar-thead" }, /* @__PURE__ */ s.createElement("tr", { className: "k-calendar-tr" }, t.map((i, a) => /* @__PURE__ */ s.createElement("th", { key: a, className: "k-calendar-th" }, i))))
    ), this.buildDates = (e, t) => {
      const i = this.props.cellUID;
      return /* @__PURE__ */ s.createElement(
        "table",
        {
          className: "k-calendar-table",
          ref: (a) => this.table = a,
          role: "grid",
          tabIndex: this.props.tabIndex,
          "aria-activedescendant": i + this.props.focusedDate.getTime()
        },
        /* @__PURE__ */ s.createElement("colgroup", null, e.map((a, n) => /* @__PURE__ */ s.createElement("col", { key: n }))),
        t.map((a) => /* @__PURE__ */ s.createElement(
          I,
          {
            ref: (n) => {
              this.calendarView || (this.calendarView = n);
            },
            key: a.getTime(),
            activeView: this.props.activeView,
            viewDate: a,
            min: this.props.min,
            max: this.props.max,
            cellUID: i,
            focusedDate: this.props.focusedDate,
            cell: this.props.cell,
            selectedDate: this.props.value,
            showWeekNumbers: this.weekNumber,
            onChange: this.handleDateChange,
            bus: this.props.bus,
            service: this.props.service,
            weekCell: this.props.weekCell
          }
        ))
      );
    }, this.calculateHeights = () => {
      if (!this.props.dom)
        return;
      const e = this.props.activeView === l.month ? this.props.dom.scrollableContentHeight : this.props.dom.scrollableYearContentHeight;
      this.bottomOffset = e - this.props.dom.viewHeight(this.props.activeView), this.viewOffset = -1 * this.props.dom.headerHeight, this.viewHeight = this.props.dom.viewHeight(this.props.activeView) || 1;
    }, this.getTake = (e, t) => Math.min(t - e, this.take), this.handleScrollAction = ({ index: e, scrollAction: t, pageAction: i }) => {
      const a = i ? i.skip : this.state.skip;
      if ((this.state.index !== e || this.state.skip !== a) && this.setState({ index: e, skip: a }), this.table && t) {
        const n = `translateY(${t.offset}px)`;
        this.table.style.transform = n;
      }
    }, this.handleTodayClick = (e) => {
      this.shouldScroll = !0, this.handleDateChange.call(void 0, e, !0);
    }, this.handleDateChange = (e, t = !1) => {
      const { onChange: i } = this.props;
      if (i) {
        const a = {
          syntheticEvent: e.syntheticEvent,
          nativeEvent: e.nativeEvent,
          value: x(e.value),
          target: this,
          isTodayClick: t
        };
        i.call(void 0, a);
      }
    }, this.lastView = this.props.activeView, this.lastFocus = this.props.focusedDate, this.state = {
      skip: this.props.service.skip(this.props.focusedDate, this.props.min),
      index: this.props.service.skip(this.props.focusedDate, this.props.min)
    };
  }
  get element() {
    return this._element;
  }
  get weekNames() {
    this.intl = y(this);
    const r = C(
      this.intl.dateFormatNames({ nameType: "short", type: "days" }),
      this.intl.firstDay()
    );
    return this.weekNumber ? [""].concat(r) : r;
  }
  get weekNumber() {
    return !!(this.props.showWeekNumbers && this.props.activeView === l.month);
  }
  get take() {
    return this.props.take !== void 0 ? this.props.take : h.defaultProps.take;
  }
  get animate() {
    return !!(this.props.smoothScroll && this.animateToIndex);
  }
  get todayIsInRange() {
    return E(N(), d(this.props.min), d(this.props.max));
  }
  componentDidUpdate(r, e) {
    this.shouldScroll = !1, this.indexToScroll !== void 0 && this.virtualization && this.virtualization[this.animate ? "animateToIndex" : "scrollToIndex"](this.indexToScroll), this.isActive && this.focusActiveDate(), this.lastView = this.props.activeView, this.indexToScroll = void 0;
  }
  render() {
    this.calculateHeights();
    const r = this.lastView !== this.props.activeView, e = u(this.props.focusedDate, this.props.min, this.props.max), t = r ? this.props.service.skip(e, this.props.min) : this.state.skip, i = this.props.service.total(this.props.min, this.props.max), a = this.getTake(t, i), n = this.props.service.addToDate(this.props.min, t), f = this.props.service.addToDate(this.props.min, this.state.index), p = new Array(this.props.service.rowLength(this.weekNumber)).fill(""), m = this.props.activeView !== this.lastView;
    this.animateToIndex = !m, (m || !D(this.lastFocus, e) || this.shouldScroll || !this.props.shouldScroll || this.props.shouldScroll()) && (this.indexToScroll = this.props.service.skip(e, this.props.min)), this.lastFocus = e;
    const v = g(
      "k-calendar-view k-vstack",
      {
        "k-calendar-monthview": this.props.activeView === l.month,
        "k-calendar-yearview": this.props.activeView === l.year,
        "k-calendar-decadeview": this.props.activeView === l.decade,
        "k-calendar-centuryview": this.props.activeView === l.century
      }
    ), w = this.buildDates(p, this.props.service.datesList(n, a)), k = /* @__PURE__ */ s.createElement(s.Fragment, null, /* @__PURE__ */ s.createElement(
      T,
      {
        key: "calendar-view-list-header",
        currentDate: f,
        min: this.props.min,
        max: this.props.max,
        activeView: this.props.activeView,
        bus: this.props.bus,
        service: this.props.service,
        headerTitle: this.props.headerTitle,
        commands: /* @__PURE__ */ s.createElement(s.Fragment, null, /* @__PURE__ */ s.createElement(
          O,
          {
            min: this.props.min,
            max: this.props.max,
            onClick: this.handleTodayClick,
            disabled: !this.todayIsInRange,
            tabIndex: this.props.tabIndex
          }
        ))
      }
    ), this.props.activeView === l.month && this.buildMonthView(p, this.weekNames), /* @__PURE__ */ s.createElement(
      S,
      {
        key: "calendar-view-list-content",
        skip: t,
        take: this.take,
        total: i,
        itemHeight: this.viewHeight,
        topOffset: this.viewOffset,
        bottomOffset: this.bottomOffset,
        scrollOffsetSize: this.viewOffset,
        maxScrollDifference: this.viewHeight,
        onScroll: this.props.onScroll,
        onScrollAction: this.handleScrollAction,
        onMount: this.handleVirtualizationMount,
        children: w,
        tabIndex: this.props.tabIndex
      }
    ));
    return /* @__PURE__ */ s.createElement(
      "div",
      {
        ref: (b) => {
          this._element = b;
        },
        className: v
      },
      this.props.dom.didCalculate ? k : null
    );
  }
};
h.propTypes = {
  activeView: o.number.isRequired,
  bottomOffset: o.number,
  cellUID: o.string.isRequired,
  focusedDate: o.instanceOf(Date).isRequired,
  max: o.instanceOf(Date).isRequired,
  min: o.instanceOf(Date).isRequired,
  onChange: o.func,
  showWeekNumbers: o.bool,
  smoothScroll: o.bool,
  take: o.number,
  value: o.instanceOf(Date),
  viewHeight: o.number,
  viewOffset: o.number,
  tabIndex: o.number
}, h.defaultProps = {
  take: H,
  showWeekNumbers: !1,
  smoothScroll: !0
};
let c = h;
V(c);
export {
  c as ViewList
};
