import {
    addClass,
    defined,
    valueOrDefault,
    deepExtend,
    setDefaultOptions
} from '../common';

import {
    appendHtml,
    removeChildren
} from './utils';

import { Observable } from '../common/observable';

export var Attribution = (function (Observable) {
    function Attribution(element, options) {
        Observable.call(this);
        this.element = element;

        this._initOptions(options);
        this.items = [];

        addClass(this.element, 'k-widget k-attribution');
    }

    if ( Observable ) Attribution.__proto__ = Observable;
    Attribution.prototype = Object.create( Observable && Observable.prototype );
    Attribution.prototype.constructor = Attribution;

    Attribution.prototype._initOptions = function _initOptions (options) {
        this.options = deepExtend({}, this.options, options);
    };

    Attribution.prototype.filter = function filter (extent, zoom) {
        this._extent = extent;
        this._zoom = zoom;
        this._render();
    };

    Attribution.prototype.add = function add (item) {
        var newItem = item;

        if (defined(item)) {
            if (typeof item === 'string') {
                newItem = {
                    text: item
                };
            }

            this.items.push(newItem);
            this._render();
        }
    };

    Attribution.prototype.remove = function remove (text) {
        var this$1 = this;

        var result = [];

        for (var i = 0; i < this.items.length; i++) {
            var item = this$1.items[i];

            if (item.text !== text) {
                result.push(item);
            }
        }

        this.items = result;
        this._render();
    };

    Attribution.prototype.clear = function clear () {
        this.items = [];
        removeChildren(this.element);
    };

    Attribution.prototype._render = function _render () {
        var this$1 = this;

        var result = [];

        for (var i = 0; i < this.items.length; i++) {
            var item = this$1.items[i];
            var text = this$1._itemText(item);

            if (text !== '') {
                result.push(text);
            }
        }

        if (result.length > 0) {
            removeChildren(this.element);

            var html = result.join(this.options.separator);
            appendHtml(html, this.element);

            this.showElement();
        } else {
            this.hideElement();
        }
    };

    Attribution.prototype.hideElement = function hideElement () {
        this.element.style.display = "none";
    };

    Attribution.prototype.showElement = function showElement () {
        this.element.style.display = "";
    };

    Attribution.prototype._itemText = function _itemText (item) {
        var text = '';
        var inZoomLevel = this._inZoomLevel(item.minZoom, item.maxZoom);
        var inArea = this._inArea(item.extent);

        if (inZoomLevel && inArea) {
            text += item.text;
        }

        return text;
    };

    Attribution.prototype._inZoomLevel = function _inZoomLevel (min, max) {
        var result = true;
        var newMin = valueOrDefault(min, -Number.MAX_VALUE);
        var newMax = valueOrDefault(max, Number.MAX_VALUE);

        result = this._zoom > newMin && this._zoom < newMax;
        return result;
    };

    Attribution.prototype._inArea = function _inArea (area) {
        var result = true;

        if (area) {
            result = area.contains(this._extent);
        }

        return result;
    };

    return Attribution;
}(Observable));

setDefaultOptions(Attribution, {
    name: 'Attribution',
    separator: '&nbsp;|&nbsp;'
});
