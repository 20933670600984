/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import e from "prop-types";
import { validatePackage as C, classNames as N } from "@progress/kendo-react-common";
import { AnimationChild as R } from "./AnimationChild.mjs";
import { TransitionGroup as g } from "react-transition-group";
import { packageMetadata as q } from "./package-metadata.mjs";
import b from "./util.mjs";
const i = b.styles, r = class r extends t.Component {
  constructor(o) {
    super(o), C(q);
  }
  /**
   * @hidden
   */
  render() {
    const {
      id: o,
      style: s,
      children: a,
      component: c,
      className: l,
      childFactory: m,
      stackChildren: x,
      componentChildStyle: p,
      componentChildClassName: d,
      ...u
    } = this.props, y = {
      id: o,
      style: s,
      component: c,
      childFactory: m,
      className: N(
        i["animation-container"],
        i["animation-container-relative"],
        l
      )
    }, h = t.Children.map(a || null, (f) => /* @__PURE__ */ t.createElement(
      R,
      {
        ...u,
        style: p,
        className: d
      },
      f
    ));
    return /* @__PURE__ */ t.createElement(g, { ...y }, h);
  }
};
r.propTypes = {
  children: e.oneOfType([
    e.arrayOf(e.node),
    e.node
  ]),
  childFactory: e.any,
  className: e.string,
  component: e.string,
  id: e.string,
  style: e.any,
  transitionName: e.string.isRequired,
  appear: e.bool.isRequired,
  enter: e.bool.isRequired,
  exit: e.bool.isRequired,
  transitionEnterDuration: e.number.isRequired,
  transitionExitDuration: e.number.isRequired
}, r.defaultProps = {
  component: "div"
};
let n = r;
export {
  n as Animation
};
