import ChartElement from './chart-element';
import TextBox from './text-box';

import { X, BLACK, TOP, CENTER, BOTTOM } from '../common/constants';
import { getSpacing, setDefaultOptions } from '../common';

var Title = (function (ChartElement) {
    function Title(options) {
        ChartElement.call(this, options);

        this._textBox = new TextBox(this.options.text, Object.assign({}, this.options, {
            vAlign: this.options.position
        }));

        this.append(this._textBox);
    }

    if ( ChartElement ) Title.__proto__ = ChartElement;
    Title.prototype = Object.create( ChartElement && ChartElement.prototype );
    Title.prototype.constructor = Title;

    Title.prototype.reflow = function reflow (targetBox) {
        ChartElement.prototype.reflow.call(this, targetBox);
        this.box.snapTo(targetBox, X);
    };

    Title.buildTitle = function buildTitle (options, defaultOptions) {
        var titleOptions = options;

        if (typeof options === "string") {
            titleOptions = { text: options };
        }

        titleOptions = Object.assign({ visible: true }, defaultOptions, titleOptions);

        var title;
        if (titleOptions && titleOptions.visible && titleOptions.text) {
            title = new Title(titleOptions);
        }

        return title;
    };

    Title.orderTitles = function orderTitles (titles) {
        var items = [].concat(titles);
        var top = items.filter(function (item) { return item && item.options.position !== BOTTOM; });
        var bottom = items.filter(function (item) { return item && item.options.position === BOTTOM; });

        collapseVerticalMargins(top);
        collapseVerticalMargins(bottom);

        bottom.reverse();
        return top.concat(bottom);
    };

    return Title;
}(ChartElement));

function collapseVerticalMargins(items) {
    for (var i = 1; i < items.length; i++) {
        var box = items[i]._textBox;
        var prevBox = items[i - 1]._textBox;
        prevBox.options.margin = Object.assign(getSpacing(prevBox.options.margin), { bottom: 0 });
        box.options.margin = Object.assign(getSpacing(box.options.margin), { top: 0 });
    }
}

setDefaultOptions(Title, {
    color: BLACK,
    position: TOP,
    align: CENTER,
    margin: getSpacing(5),
    padding: getSpacing(5)
});

export default Title;
