/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { CollectionConfigurationComponent as i } from "./base/CollectionConfigurationComponent.mjs";
import { ChartCategoryAxisItem as n } from "./CategoryAxisItem.mjs";
import { validateChildren as a } from "../utils/index.mjs";
const m = (t) => /* @__PURE__ */ e.createElement(i, { ...t, _chartKey: "categoryAxis" });
m.propTypes = {
  children: function(t, r, o) {
    return a(t, r, o, n);
  }
};
export {
  m as ChartCategoryAxis
};
