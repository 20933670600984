/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import o from "prop-types";
import { classNames as C } from "../classNames.mjs";
import { SIZE_CLASSES as I } from "./constants.mjs";
const g = t.forwardRef((e, v) => {
  const {
    children: w,
    className: r,
    svgClassName: x,
    icon: i,
    flip: h,
    id: y,
    tabIndex: N,
    size: c,
    style: l,
    svgStyle: S,
    themeColor: a,
    viewBox: m,
    name: P,
    onClick: k,
    ...b
  } = e, d = t.useRef(null);
  t.useImperativeHandle(
    v,
    () => ({
      element: d.current
    })
  );
  const f = t.useMemo(
    () => i ? i.name : s.icon,
    [i]
  ), u = t.useMemo(
    () => c || s.size,
    [c]
  ), n = t.useMemo(
    () => h || s.flip,
    [h]
  ), z = t.useMemo(
    () => m || s.viewBox,
    [m]
  ), M = t.useMemo(
    () => C(
      "k-icon",
      "k-svg-icon",
      "k-svg-i-" + f,
      {
        [`k-color-${a}`]: a,
        "k-flip-h": n === "horizontal" || n === "both",
        "k-flip-v": n === "vertical" || n === "both"
      },
      I[u],
      r
    ),
    [f, a, u, n, r]
  ), B = t.useMemo(
    () => e.width && e.height ? { width: e.width, height: e.height, ...l } : e.width ? { width: e.width, height: e.width, ...l } : e.height ? { width: e.height, height: e.height, ...l } : { ...l },
    [e.width, e.height, l]
  );
  return /* @__PURE__ */ t.createElement(
    "span",
    {
      className: M,
      style: B,
      ref: d,
      onClick: k,
      "aria-hidden": !0
    },
    /* @__PURE__ */ t.createElement(
      "svg",
      {
        id: y,
        className: x,
        style: S,
        "aria-hidden": !0,
        tabIndex: N,
        focusable: !1,
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: i ? i.viewBox : z,
        dangerouslySetInnerHTML: i ? { __html: i.content } : void 0,
        ...b
      },
      i ? void 0 : w
    )
  );
});
g.propTypes = {
  style: o.object,
  classNames: o.string,
  children: o.any,
  icon: o.object,
  themeColor: o.oneOf([
    "inherit",
    "primary",
    "secondary",
    "tertiary",
    "info",
    "success",
    "error",
    "warning",
    "dark",
    "light",
    "inverse"
  ]),
  size: o.oneOf(["default", "xsmall", "small", "medium", "large", "xlarge", "xxlarge", "xxxlarge"]),
  flip: o.oneOf(["default", "horizontal", "vertical", "both"])
};
const s = {
  size: "default",
  flip: "default",
  icon: "",
  viewBox: "0 0 24 24"
};
g.displayName = "KendoSvgIcon";
export {
  g as SvgIcon
};
