/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { getter as A, mapTree as B, extendDataItem as N, Keys as O, hasRelativeStackingContext as F } from "@progress/kendo-react-common";
import { TABLE_ROW_INDEX_ATTRIBUTE as M, TABLE_COL_INDEX_ATTRIBUTE as G } from "./constants.mjs";
const U = "items", x = {}, X = (e, t) => !e || Array.isArray(e) && e.length === 0 ? [t] : e.findIndex((o) => o === t) > -1 ? e.filter((o) => o !== t) : [...e, t], v = (e, t) => {
  let n = e;
  for (; n; ) {
    if (n.tagName === t)
      return n;
    if (n.tagName === "TABLE")
      return null;
    n = n.parentElement;
  }
  return null;
}, k = (e) => {
  if (!e)
    return;
  const t = e.getAttribute(M);
  return t ? parseInt(t, 10) : void 0;
}, H = (e) => {
  if (!e)
    return;
  const t = e.getAttribute(G);
  return t ? parseInt(t, 10) : void 0;
}, z = (e) => {
  const t = e.subItemsField || U, n = A(e.dataItemKey);
  return B(
    e.data,
    t,
    (o) => N(o, t, {
      [e.selectedField]: e.selectedState[n(o)]
    })
  );
}, J = (e) => {
  const { event: t, dataItemKey: n, selectedState: o } = e, { syntheticEvent: a, nativeEvent: s, dataItems: f, componentId: l, mode: g, cell: E, selectedField: S } = t, { target: T, ctrlKey: p, metaKey: y, altKey: I, shiftKey: D } = s;
  if (a.isDefaultPrevented() || s.keyCode !== O.enter)
    return o;
  const C = v(T, "TD"), i = v(T, "TR"), r = H(C), m = k(i);
  let d = {};
  return C && i && m !== void 0 && r !== void 0 && (d = V({
    selectedState: o,
    dataItemKey: n,
    event: {
      ctrlKey: p,
      metaKey: y,
      altKey: I,
      shiftKey: D,
      dataItems: f,
      cell: E,
      mode: g,
      selectedField: S,
      componentId: l,
      startColIndex: r,
      endColIndex: r,
      startRowIndex: m,
      endRowIndex: m,
      isDrag: !1,
      syntheticEvent: a,
      nativeEvent: s,
      target: t.target,
      dataItem: null
    }
  })), d;
}, V = (e) => {
  const { event: t, dataItemKey: n, selectedState: o } = e, {
    dataItems: a,
    startRowIndex: s,
    endRowIndex: f,
    startColIndex: l,
    endColIndex: g,
    cell: E,
    isDrag: S,
    ctrlKey: T,
    shiftKey: p,
    metaKey: y,
    componentId: I,
    mode: D,
    dataItem: C
  } = t, i = A(n);
  let r = {};
  const m = C !== null;
  if (D === "single") {
    const d = a.slice(s, s + 1)[0], u = i(d);
    x[I] = {
      rowIndex: s,
      colIndex: l
    }, r[u] = E ? [l] : !0;
  } else if (S || !p && !T && !y && !m) {
    S || (x[I] = {
      rowIndex: s,
      colIndex: l
    });
    const d = [];
    for (let c = l; c <= g; c++)
      d.push(c);
    a.slice(s, f + 1).forEach((c) => {
      const R = i(c);
      r[R] = E ? [...d] : !0;
    });
  } else if (T || y || m) {
    x[I] = {
      rowIndex: s,
      colIndex: l
    };
    const u = a.slice(s, f + 1)[0], c = i(u);
    r = { ...o }, r[c] = E && !m ? X(r[c], l) : !r[c];
  } else if (p) {
    const d = x[I] && x[I].rowIndex || 0, u = x[I] && x[I].colIndex || 0, c = Math.min(d, s, f), R = Math.max(d, s, f), K = Math.min(u, l, g), _ = Math.max(u, l, g), h = [];
    for (let w = K; w <= _; w++)
      h.push(w);
    a.slice(c, R + 1).forEach((w) => {
      const L = i(w);
      r[L] = E ? [...h] : !0;
    });
  }
  return r;
}, W = F(), Q = (e) => {
  if (!e || !W)
    return null;
  let t = e.parentElement;
  for (; t; ) {
    if (window.getComputedStyle(t).transform !== "none")
      return t;
    t = t.parentElement;
  }
}, Y = (e) => {
  if (e && e.ownerDocument && e !== e.ownerDocument.body) {
    const t = e.getBoundingClientRect();
    return {
      left: t.left - e.scrollLeft,
      top: t.top - e.scrollTop
    };
  }
  return { left: 0, top: 0 };
}, Z = (e) => {
  const t = e ? typeof e.enabled == "boolean" ? e.enabled : !0 : !1, n = e ? !!e.drag : !1, o = e && e.mode ? e.mode : "multiple", a = !!(e && e.cell);
  return { enabled: t, drag: n, mode: o, cell: a };
};
export {
  v as closestTagName,
  H as getColumnIndex,
  Y as getOffset,
  k as getRowIndex,
  V as getSelectedState,
  J as getSelectedStateFromKeyDown,
  Z as getSelectionOptions,
  Q as relativeContextElement,
  z as setSelectedState
};
