/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { isPresent as i } from "./utils.mjs";
import { Keys as n } from "@progress/kendo-react-common";
class o {
  navigate(e) {
    const t = e.keyCode;
    if (t === n.up || t === n.left)
      return this.next({ current: e.current, min: e.min, max: e.max, step: e.skipItems ? e.skipItems : -1 });
    if (t === n.down || t === n.right)
      return this.next({ current: e.current, min: e.min, max: e.max, step: e.skipItems ? e.skipItems : 1 });
    if (t === n.home)
      return 0;
    if (t === n.end)
      return e.max;
  }
  next(e) {
    return i(e.current) ? Math.min(e.max, Math.max(e.current + e.step, e.min)) : e.min;
  }
}
export {
  o as Navigation
};
