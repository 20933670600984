import { Matrix } from '../../common';

var MIN_ORDER = 1;
var MAX_ORDER = 6;

function calculatePolynomial(sourceValues, valueGetter, order) {
    var k = Math.min(Math.max(order || MIN_ORDER, MIN_ORDER), MAX_ORDER) + 1;
    var X = new Matrix();
    var Y = new Matrix();
    var count = 0;
    var xMin = Number.MAX_VALUE;
    var xMax = Number.MIN_VALUE;
    var valueMapper = function (x) { return x; };
    var coefficients = [];

    for (var i = 0; i < sourceValues.length; i++) {
        var value = sourceValues[i];
        var ref = valueGetter(value);
        var xValue = ref.xValue;
        var yValue = ref.yValue;

        if (isFinite(xValue) && xValue !== null && isFinite(yValue) && yValue !== null) {
            xMin = Math.min(xValue, xMin);
            xMax = Math.max(xValue, xMax);
            count++;

            // Set Y value in matrix
            Y.set(i, 0, yValue);

            // Set indicator column to 1 for valid values
            X.set(i, 0, 1);
            X.set(i, 1, xValue);
            for (var pow = 2; pow <= k; pow++) {
                X.set(i, pow, Math.pow(X.get(i, 1), pow));
            }
        } else {
            // Set indicator column to 0 for missing values
            X.set(i, 0, 0);
        }
    }

    // Limit order to number of values.
    X.width = Math.min(k, count);

    if (count > 0) {
        // Polynomial trendline equation:
        // y = aN * x^N + ... + a2 * x^2 + a1 * x + a0
        coefficients = linearRegression(X, Y);
        valueMapper = function (x) { return coefficients.reduce(function (y, a, n) { return y + a * Math.pow(x, n); }, 0); };
    }

    return {
        coefficients: coefficients,
        count: count,
        valueMapper: valueMapper,
        xMin: xMin,
        xMax: xMax
    };
}

function linearRegression(X, Y) {
    var Xt = X.transpose();
    var B = Xt.multiply(X).inverse().multiply(Xt).multiply(Y); // the last square estimate of the coefficients

    var coefficients = [];
    for (var i = 0; i < B.height; i++) {
        coefficients.push(B.get(i, 0));
    }

    // y_intercept and regression coefficients ('slopes')
    return coefficients;

    // It's possible to calculate statistics for the regression based on
    // the LINEST function implementation in kendo-spreadsheet-common/src/calc.js
    //
    // * The standard errors (of coefficients and y-intercept)
    // * The coefficient of determination (R^2)
    // * The standard error for the y estimate
    // * The F statistic
    // * The degrees of freedom
    // * The regression sum of squares (SSR)
    // * The residual sum of squares (SSE)
}

export default calculatePolynomial;
