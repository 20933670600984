/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { Reveal as t } from "@progress/kendo-react-animation";
import { classNames as s } from "@progress/kendo-react-common";
class l extends e.Component {
  /**
   * @hidden
   */
  render() {
    return /* @__PURE__ */ e.createElement(
      "div",
      {
        id: this.props.id,
        className: s("k-columnmenu-item-content", this.props.className),
        style: this.props.style
      },
      /* @__PURE__ */ e.createElement(t, { style: { position: "relative", display: "block" } }, this.props.show ? this.props.children : null)
    );
  }
}
export {
  l as GridColumnMenuItemContent
};
