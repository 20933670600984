/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const h = (e) => e != null, d = (e, t) => {
  for (let r = 0; r < e.length; r++)
    if (e.charAt(r) !== t)
      return !1;
  return !0;
}, x = (e, t, r) => {
  let n = e;
  return r && (n = [r].concat(n)), n.slice(t).concat(n.slice(0, t));
}, C = (e, t, r) => {
  if (!h(e))
    return !1;
  let n = String(e);
  return r && (n = n.toLowerCase()), n.indexOf(t) === 0;
}, a = (e, t, r, n, s) => {
  const o = e.offsetHeight, l = t.children.item(r), f = l.offsetTop + (s ? n - e.scrollTop : 0), c = l.offsetHeight;
  if (s) {
    let u = 0;
    f + c > o ? u = f + c - o : f < 0 && (u = f), u !== 0 ? e.scrollTop += u : e.scrollTop === 0 && n !== 0 && (e.scrollTop = n);
  } else
    f + c > o + e.scrollTop ? e.scrollTop = f + c - o : f < e.scrollTop && (e.scrollTop -= e.scrollTop - f);
}, g = (e, t, r) => {
  let n = -1;
  if (t) {
    t = t.toLowerCase();
    for (let s = 0; s < e.length; s++) {
      const o = (i(e[s], r) || "") + "";
      if (o && o.toLowerCase().startsWith(t)) {
        n = s;
        break;
      }
    }
  }
  return n;
}, T = (e, t, r, n = !1) => {
  const s = (o) => n ? o : o.toLowerCase();
  return e.findIndex((o) => r ? s(i(o, r)) === s(t) : s(t) === s(o.toString()));
}, i = (e, t) => {
  if (t && h(e)) {
    const r = t.split(".");
    let n = e;
    return r.forEach((s) => {
      n = n ? n[s] : void 0;
    }), n;
  }
  return e;
}, w = (e, t, r) => {
  if (!e)
    return;
  const n = e.findIndex((s) => i(s, t) === r);
  return e[n];
}, I = (e = [], t = [], r) => {
  if (e === t)
    return !0;
  if (e.length !== t.length)
    return !1;
  for (let n = 0; n < e.length; n++)
    if (!p(e[n], t[n], r))
      return !1;
  return !0;
}, L = (e, t, r) => {
  t.forEach((n) => {
    const s = e.findIndex((o) => p(o, n, r));
    s !== -1 && e.splice(s, 1);
  });
}, p = (e, t, r) => e === t || h(e) === h(t) && i(e, r) === i(t, r), D = (e, t, r) => {
  if (t) {
    const n = T(e, t, r, !0);
    return n !== -1 ? e[n] : e[g(e, t, r)];
  }
  return e[0];
}, H = (e, t = [], r) => {
  let n = "";
  if (e) {
    const s = t[g(t, e, r)];
    if (s) {
      const o = i(s, r);
      e.toLowerCase() !== o.toLowerCase() && (n = o.substring(e.length));
    }
  }
  return n;
}, N = (e) => {
  e.target.nodeName !== "INPUT" && e.preventDefault();
}, O = (e, t, r) => !!e != !!t || e.text !== t.text ? !1 : e === t || I(e.data, t.data, r);
export {
  p as areSame,
  w as findByFieldValue,
  D as getFocusedItem,
  T as getItemIndexByText,
  i as getItemValue,
  h as isPresent,
  g as itemIndexStartsWith,
  I as matchDataCollections,
  O as matchTags,
  C as matchText,
  N as preventDefaultNonInputs,
  L as removeDataItems,
  d as sameCharsOnly,
  a as scrollToItem,
  x as shuffleData,
  H as suggestValue
};
