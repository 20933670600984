/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as a from "react";
import { provideLocalizationService as k, registerForLocalization as u } from "@progress/kendo-react-intl";
import { GridFilterCell as I } from "../cells/GridFilterCell.mjs";
import { getFilterType as y, operatorMap as F, booleanFilterValues as L } from "../filterCommon.mjs";
import { tableKeyboardNavigationTools as R, HeaderThElement as w } from "@progress/kendo-react-data-tools";
import { filterAriaLabel as b, messages as N } from "../messages/index.mjs";
import { classNames as T } from "@progress/kendo-react-common";
import { resolveCells as z } from "../utils/index.mjs";
const A = "k-table-row k-filter-row";
class S extends a.Component {
  headerCellClassName(r, o) {
    let l = `${o ? "k-grid-header-sticky" : ""}`;
    return this.props.sort && this.props.sort.filter((n) => n.field === r).length > 0 && (l += " k-sorted"), l;
  }
  setFilter(r, o, l, n) {
    if (!this.props.filterChange)
      return;
    const s = [];
    (r !== "" && r !== null || o !== "") && s.push({
      field: l,
      operator: o,
      value: r
    }), this.props.filter && this.props.filter.filters && (this.props.filter.filters || []).forEach((i) => {
      const t = i;
      t && t.field !== l && s.push(t);
    });
    const h = this.props.filter && this.props.filter.logic ? this.props.filter.logic : "and";
    this.props.filterChange(s.length > 0 ? { logic: h, filters: s } : null, n);
  }
  render() {
    const r = k(this), o = this.props.filter && this.props.filter.filters || [], l = (e) => {
      if (e === void 0)
        return;
      const i = o.filter((t) => t.field === e);
      return i.length ? i[0] : void 0;
    };
    let n = 0, s = -1;
    const h = this.props.columns.filter((e) => e.children.length === 0).map(
      (e) => {
        const i = y(e.filter), t = l(e.field), g = e.filterable ? r.toLanguageString(b, N[b]) : void 0;
        let d = t && t.value;
        d === void 0 && (d = i === "text" ? "" : null);
        const f = e.filterable && {
          render: this.props.cellRender,
          field: e.field,
          title: e.filterTitle,
          value: d,
          operator: t && t.operator,
          operators: F(this.props.filterOperators[i] || [], r),
          booleanValues: F(L, r),
          filterType: i,
          ariaLabel: g,
          onChange: (p) => {
            this.setFilter(p.value, p.operator, e.field, p.syntheticEvent);
          }
        }, C = e.declarationIndex >= 0 ? ++s : --n, x = {
          ariaLabel: g,
          ariaColumnIndex: e.ariaColumnIndex
        }, E = e.left !== void 0 ? this.props.isRtl ? { left: e.right, right: e.left } : { left: e.left, right: e.right } : {}, m = {
          columnId: R.getFilterColumnId(e.id),
          navigatable: e.navigatable,
          style: E,
          className: T("k-table-th", this.headerCellClassName(e.field, e.locked) || void 0),
          role: "columnheader",
          ...x
        }, v = f && (e.filterCell ? /* @__PURE__ */ a.createElement(e.filterCell, { ...f }) : /* @__PURE__ */ a.createElement(I, { size: this.props.size, ...f })), c = z(this.props.cells, e.cells);
        if (c && c.filterCell) {
          const p = c.filterCell;
          return /* @__PURE__ */ a.createElement(p, { key: C, ...f, thProps: m, index: s }, v);
        }
        return /* @__PURE__ */ a.createElement(
          w,
          {
            key: C,
            ...m
          },
          v
        );
      }
    );
    return /* @__PURE__ */ a.createElement("tr", { className: A, "aria-rowindex": this.props.ariaRowIndex, role: "row" }, h);
  }
}
u(S);
export {
  A as FILTER_ROW_CLASS,
  S as FilterRow
};
