/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { dispatchDragAndDrop as we, getScrollableParent as he, autoScroll as xe } from "@progress/kendo-draggable-common";
import { noop as i } from "../noop.mjs";
import { useInheritedState as Ce } from "./useInheritedState.mjs";
import { DropsContext as Pe, DragsContext as Ie, DropContext as Me, DragContext as Te } from "../drag-n-drop/context/index.mjs";
import { useIsomorphicLayoutEffect as Ae } from "./use-isomorphic-layout-effect.mjs";
const je = 2e3;
function _e(l, ge, ve = {}) {
  const {
    onPress: B = i,
    onRelease: H = i,
    onDragStart: W = i,
    onDrag: _ = i,
    onDragEnd: q = i
  } = ge, {
    hint: a = null,
    mouseOnly: E = !1,
    autoScroll: o = !0,
    scrollContainer: u = null
  } = ve, [f, pe] = t.useState(!1), [C, be] = t.useState(!1), [k] = Ce(Me), [y, g] = Ce(Te), [z] = t.useContext(Pe), [F, G, N] = t.useContext(Ie), v = t.useRef({ x: 0, y: 0 }), D = t.useRef(), S = t.useRef(!1), $ = t.useRef(), m = t.useRef(null), L = t.useRef(!1), J = t.useRef({ x: 0, y: 0 }), K = t.useRef({ x: 0, y: 0 }), p = t.useRef({ x: 0, y: 0 }), Q = t.useRef({ x: 0, y: 0 }), X = t.useRef({ x: 0, y: 0 }), Y = t.useRef({ x: 0, y: 0 }), Ee = !!(typeof window != "undefined" && window.PointerEvent), Z = !E && Ee, c = t.useCallback(
    () => l.current && l.current.element ? l.current.element : l.current,
    [l]
  ), ke = t.useCallback(
    () => a && a.current && a.current.element ? a.current.element : a ? a.current : null,
    [a]
  ), ee = t.useCallback(
    () => u && u.current && u.current.element ? u.current.element : u ? u.current : null,
    [u]
  ), te = t.useCallback(
    () => typeof o == "object" && o.boundaryElementRef && o.boundaryElementRef.current && o.boundaryElementRef.current.element ? o.boundaryElementRef.current.element : typeof o == "object" && o.boundaryElementRef && o.boundaryElementRef.current ? o.boundaryElementRef.current : null,
    [o]
  ), h = t.useRef(null);
  t.useImperativeHandle(h, () => ({
    element: c(),
    hint: ke(),
    onPress: ye,
    onDragStart: Se,
    onDrag: Le,
    onDragEnd: Oe,
    onRelease: De,
    data: l.current
  }));
  const b = t.useCallback(
    () => {
      const e = c();
      return e && e.ownerDocument || document;
    },
    [c]
  ), d = t.useCallback(
    () => {
      const e = b();
      return e && e.defaultView || window;
    },
    [b]
  ), ne = t.useCallback(
    () => ({
      drag: y ? y.current : null,
      drop: k ? k.current : null,
      drags: F.map((e) => e.current),
      drops: z.map((e) => e.current),
      pressed: f,
      ignoreMouse: S.current,
      scrollOffset: X.current,
      offset: J.current,
      pageOffset: K.current,
      initialScrollOffset: Y.current,
      clientOffset: p.current,
      initialClientOffset: Q.current,
      velocity: v.current,
      autoScroll: !!(typeof o == "object" ? o.enabled !== !1 : o),
      scrollableParent: te(),
      autoScrollDirection: typeof o == "object" ? o.direction : { horizontal: !0, vertical: !0 },
      isScrolling: C
    }),
    [y, k, F, z, f, o, te, C]
  ), re = t.useCallback(
    (e) => {
      pe(e);
    },
    []
  ), oe = t.useCallback(
    (e) => {
      be(e);
    },
    []
  ), se = t.useCallback(
    (e) => {
      v.current = e;
    },
    []
  ), le = t.useCallback(
    (e) => {
      J.current = e;
    },
    []
  ), ce = t.useCallback(
    (e) => {
      p.current = e;
    },
    []
  ), ae = t.useCallback(
    (e) => {
      K.current = e;
    },
    []
  ), ue = t.useCallback(
    (e) => {
      Q.current = e;
    },
    []
  ), ie = t.useCallback(
    (e) => {
      X.current = e;
    },
    []
  ), fe = t.useCallback(
    (e) => {
      Y.current = e;
    },
    []
  ), ye = t.useCallback(
    (e) => {
      B(e);
    },
    [B]
  ), De = t.useCallback(
    (e) => {
      H(e);
    },
    [H]
  ), Se = t.useCallback(
    (e) => {
      g(h, { target: l.current, event: e }), W(e);
    },
    [g, l, W]
  ), Le = t.useCallback(
    (e) => {
      _(e);
    },
    [_]
  ), Oe = t.useCallback(
    (e) => {
      L.current || (g(null, { target: l.current, event: e }), q(e));
    },
    [q, g, l]
  ), n = t.useCallback(
    (e) => {
      we(
        ne(),
        { event: e, payload: h.current },
        {
          onVelocityChange: se,
          onOffsetChange: le,
          onClientOffsetChange: ce,
          onPageOffsetChange: ae,
          onInitialClientOffsetChange: ue,
          onScrollOffsetChange: ie,
          onInitialScrollOffsetChange: fe,
          onIsPressedChange: re,
          onIsScrollingChange: oe
        }
      );
    },
    [
      ne,
      se,
      le,
      ae,
      ce,
      ue,
      fe,
      re,
      ie,
      oe
    ]
  ), O = t.useCallback(
    (e) => {
      n(e);
    },
    [n]
  ), R = t.useCallback(
    (e) => {
      n(e);
    },
    [n]
  ), w = t.useCallback(
    (e) => {
      n(e);
    },
    [n]
  ), x = t.useCallback(
    (e) => {
      n(e);
    },
    [n]
  ), P = t.useCallback(
    (e) => {
      n(e);
    },
    [n]
  ), I = t.useCallback(
    (e) => {
      n(e);
    },
    [n]
  ), M = t.useCallback(
    (e) => {
      n(e);
    },
    [n]
  ), T = t.useCallback(
    (e) => {
      e.preventDefault(), n(e);
    },
    [n]
  ), A = t.useCallback(
    (e) => {
      e.preventDefault(), n(e);
    },
    [n]
  ), j = t.useCallback(
    (e) => {
      e.preventDefault(), n(e);
    },
    [n]
  ), U = t.useCallback(
    (e) => {
      if (e.touches.length === 0 && e.changedTouches.length === 1) {
        const r = d();
        S.current = !0, $.current = r.setTimeout(() => {
          S.current = !1;
        }, je);
      }
      n(e);
    },
    [n, d]
  ), V = t.useCallback(
    (e) => {
      n(e);
    },
    [n]
  ), de = t.useCallback(
    () => {
      const e = c();
      if (e) {
        const r = e.style.touchAction;
        return e.style.touchAction = "none", () => {
          e.style.touchAction = r;
        };
      }
    },
    [c]
  ), me = t.useCallback(
    () => (G(h), () => {
      N(h);
    }),
    [N, G]
  ), Re = () => {
    const e = d(), r = c(), s = b();
    return Z ? (r && (m.current = he(r), m.current && m.current.addEventListener("scroll", V, { passive: !0 }), r.addEventListener("pointerdown", O, { passive: !0 })), f && (s.addEventListener("pointermove", R), s.addEventListener("pointerup", x, !0), s.addEventListener("contextmenu", T), s.addEventListener("pointercancel", w, { passive: !0 }))) : (e.addEventListener("touchmove", i, { capture: !1, passive: !1 }), r && (r.addEventListener("mousedown", P, { passive: !0 }), E || (r.addEventListener("touchstart", A, { passive: !0 }), f && (r.addEventListener("touchmove", j, { passive: !0 }), r.addEventListener("touchend", U, { passive: !0 })))), f && (s.addEventListener("mousemove", I, { passive: !0 }), s.addEventListener("mouseup", M, { passive: !0 }))), () => {
      m.current && m.current.removeEventListener("scroll", V), r && (r.removeEventListener("pointerdown", O), r.removeEventListener("mousedown", P), r.removeEventListener("touchstart", A), r.removeEventListener("touchmove", j), r.removeEventListener("touchend", U)), s.removeEventListener("pointermove", R), s.removeEventListener("pointerup", x, !0), s.removeEventListener("contextmenu", T), s.removeEventListener("pointercancel", w), s.removeEventListener("mousemove", I), s.removeEventListener("mouseup", M), e.removeEventListener("touchmove", i), e.clearTimeout($.current);
    };
  };
  t.useEffect(() => {
    const e = d();
    if (C) {
      const r = ee() || he(document.elementFromPoint(p.current.x, p.current.y));
      e.clearInterval(D.current), D.current = e.setInterval(() => {
        xe(r, { x: v.current.x, y: v.current.y });
      }, 50);
    }
    return () => {
      e.clearInterval(D.current);
    };
  }, [c, ee, d, C]), t.useEffect(de, [de]), t.useEffect(Re, [
    f,
    d,
    c,
    b,
    E,
    Z,
    T,
    P,
    I,
    M,
    w,
    O,
    R,
    x,
    U,
    j,
    A,
    V
  ]), t.useEffect(() => (L.current = !1, () => {
    L.current = !0;
  }), []), Ae(me, [me]);
}
export {
  _e as useDraggable
};
