/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import * as b from "react-dom";
import e from "prop-types";
import { DialogTitleBar as E } from "./DialogTitleBar.mjs";
import { DialogActionsBar as c } from "./DialogActionsBar.mjs";
import { dispatchEvent as v, Keys as A, keepFocusInContainer as N, validatePackage as O, shouldShowValidationUI as P, ZIndexContext as d, classNames as Z, WatermarkOverlay as L, canUseDOM as S, createPropsContext as _, withIdHOC as B, withPropsContext as F } from "@progress/kendo-react-common";
import { packageMetadata as p } from "./package-metadata.mjs";
import { getMaxZIndex as K } from "./utils.mjs";
import { DEFAULT_DIALOGS_ZINDEX as M, ZINDEX_DIALOGS_STEP as h, DATA_DIALOGS_ID as z } from "./constants.mjs";
const i = class i extends o.Component {
  constructor(s) {
    super(s), this.context = 0, this.titleId = this.generateTitleId(), this.contentId = this.generateContentId(), this.showLicenseWatermark = !1, this.onCloseDialog = (t) => {
      t.preventDefault(), v(this.props.onClose, t, this, void 0);
    }, this.onKeyDown = (t) => {
      t.keyCode === A.esc && this.props.onClose && (t.preventDefault(), this.onCloseDialog(t)), N(t, this.element);
    }, this.getCurrentZIndex = () => !this.state || this.context === void 0 ? this.context ? this.context : M : this.state.zIndex > (this.context ? this.context + h : 0) ? this.state.zIndex : this.context + h, this.getDocument = () => this.props.appendTo ? this.props.appendTo.ownerDocument : document, O(p), this.showLicenseWatermark = P(p);
  }
  get _id() {
    return this.props.id + "-accessibility-id";
  }
  /**
   * @hidden
   */
  componentDidMount() {
    this.element && (this.props.autoFocus && this.element.focus(), this.setState({
      zIndex: K(this.getCurrentZIndex(), this.getDocument(), this._id)
    }));
  }
  /**
   * @hidden
   */
  render() {
    const s = this.props.id !== void 0 ? this.props.id : this.titleId, { title: t, width: m, height: u, children: g, minWidth: I, dir: y, style: f, contentStyle: D } = this.props, r = o.Children.toArray(g), x = this.getContent(r), C = this.getActionBar(r), w = t ? {
      "aria-labelledby": s
    } : null, k = this.props.closeIcon !== void 0 ? this.props.closeIcon : !0, a = this.getCurrentZIndex(), l = /* @__PURE__ */ o.createElement(d.Provider, { value: a }, /* @__PURE__ */ o.createElement(
      "div",
      {
        [z]: this._id,
        className: "k-dialog-wrapper" + (this.props.className ? " " + this.props.className : ""),
        onKeyDown: this.onKeyDown,
        tabIndex: 0,
        dir: y,
        style: {
          zIndex: a,
          ...f
        },
        ref: (T) => this.element = T
      },
      /* @__PURE__ */ o.createElement("div", { className: "k-overlay" }),
      /* @__PURE__ */ o.createElement(
        "div",
        {
          ...w,
          className: Z(
            "k-window k-dialog",
            {
              [`k-window-${this.props.themeColor}`]: this.props.themeColor
            }
          ),
          role: "dialog",
          "aria-labelledby": s,
          "aria-modal": !0,
          "aria-describedby": this.contentId,
          style: { width: m, height: u, minWidth: I }
        },
        this.props.title && /* @__PURE__ */ o.createElement(
          E,
          {
            closeIcon: k,
            onCloseButtonClick: this.onCloseDialog,
            id: s
          },
          t
        ),
        /* @__PURE__ */ o.createElement("div", { className: "k-window-content k-dialog-content", style: D, id: this.contentId }, x),
        C,
        this.showLicenseWatermark && /* @__PURE__ */ o.createElement(L, null)
      )
    ));
    return S ? this.props.appendTo !== null ? b.createPortal(l, this.props.appendTo || document.body) : l : null;
  }
  getActionBar(s) {
    return s.filter((t) => t && t.type === c);
  }
  getContent(s) {
    return s.filter((t) => t && t.type !== c);
  }
  generateTitleId() {
    return "dialog-title-" + this._id;
  }
  generateContentId() {
    return "dialog-content-" + this._id;
  }
};
i.displayName = "Dialog", i.propTypes = {
  title: e.any,
  id: e.string,
  dir: e.string,
  style: e.object,
  closeIcon: e.bool,
  width: e.oneOfType([e.number, e.string]),
  height: e.oneOfType([e.number, e.string]),
  minWidth: e.oneOfType([e.number, e.string]),
  autoFocus: e.bool
}, i.defaultProps = {
  autoFocus: !1
}, i.contextType = d;
let n = i;
const G = _(), R = B(F(G, n));
R.displayName = "KendoReactDialog";
export {
  R as Dialog,
  G as DialogPropsContext
};
