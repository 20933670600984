/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { classNames as r } from "@progress/kendo-react-common";
import * as e from "react";
class p extends e.Component {
  /**
   * @hidden
   */
  render() {
    const { children: s } = this.props;
    return /* @__PURE__ */ e.createElement(
      "div",
      {
        id: this.props.id,
        className: r("k-columnmenu-item-wrapper", this.props.className),
        style: this.props.style
      },
      s
    );
  }
}
export {
  p as GridColumnMenuItemGroup
};
