/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import a from "prop-types";
import { createPropsContext as ge, validatePackage as Ce, usePropsContext as ye, useId as xe, dispatchEvent as E, Keys as $, useDir as Ve, classNames as P, kendoThemeMaps as A, getTabIndex as Ee } from "@progress/kendo-react-common";
import { packageMetadata as Pe } from "../package-metadata.mjs";
import { useLocalization as Be } from "@progress/kendo-react-intl";
import { messages as Ie, checkboxValidation as Me, checkboxOptionalText as Ne } from "../messages/index.mjs";
const Fe = ge(), B = e.forwardRef((W, j) => {
  Ce(Pe);
  const I = ye(Fe, W), {
    ariaDescribedBy: G,
    ariaLabelledBy: J,
    checked: M,
    className: Q,
    labelClassName: X,
    children: Y,
    defaultChecked: Z,
    disabled: l,
    defaultValue: _,
    id: N,
    size: m,
    rounded: b,
    label: F,
    labelPlacement: S,
    name: ee,
    labelOptional: ae,
    onChange: p,
    onFocus: k,
    onBlur: f,
    tabIndex: te,
    value: c,
    required: h,
    valid: z,
    validationMessage: D,
    validityStyles: K,
    // Removed to support direct use in Form Field component
    visited: ze,
    touched: De,
    modified: Ke,
    ...le
  } = I, n = e.useRef(null), T = e.useCallback(
    () => {
      n.current && n.current.focus();
    },
    []
  ), s = e.useCallback(
    () => ({
      element: n.current,
      focus: T,
      get name() {
        return n.current && n.current.name;
      }
    }),
    [T]
  );
  e.useImperativeHandle(j, s);
  const [ne, oe] = e.useState(Z), [ce, se] = e.useState(_), w = typeof c == "boolean" || c === null, v = M !== void 0, r = w, g = r ? c : ce, i = v ? M : r ? void 0 : ne, L = i === void 0 && g, C = L ? g : i, d = L || r ? c === null ? c : void 0 : c || g, O = C === null || d === null, R = xe(), re = Be(), q = (t) => re.toLanguageString(t, Ie[t]), H = q(Me), ie = q(Ne), y = z !== void 0 ? z : h ? !!i : !0;
  e.useEffect(
    () => {
      n.current && n.current.setCustomValidity && n.current.setCustomValidity(
        y ? "" : D || H
      );
    },
    [y, D, H]
  );
  const u = e.useCallback(
    (t, o) => {
      !r && !l && (se(o), !v && !l && oe(o)), p && !l && E(
        p,
        t,
        {
          ...s(),
          value: o
        },
        { value: o }
      );
    },
    [v, l, p, r, s]
  ), de = e.useCallback(
    (t) => {
      const o = t.target.checked;
      u(t, o);
    },
    [u, c]
  ), ue = e.useCallback(
    (t) => {
      if (l)
        return;
      const { keyCode: o } = t, ve = t.currentTarget.checked;
      o === $.space && (t.preventDefault(), t.stopPropagation(), u(t, !ve));
    },
    [l, u]
  ), me = e.useCallback(
    (t) => {
      if (l)
        return;
      const { keyCode: o } = t;
      o === $.space && t.preventDefault();
    },
    [l]
  ), be = e.useCallback(
    (t) => {
      k && !l && E(
        k,
        t,
        s(),
        void 0
      );
    },
    [k, l, s]
  ), pe = e.useCallback(
    (t) => {
      f && !l && E(
        f,
        t,
        s(),
        void 0
      );
    },
    [f, l, s]
  ), ke = Ve(n, I.dir), fe = P(
    "k-checkbox-wrap",
    Q
  ), U = {
    type: "checkbox",
    className: P(
      "k-checkbox",
      {
        [`k-checkbox-${A.sizeMap[m] || m}`]: m,
        [`k-rounded-${A.roundedMap[b] || b}`]: b,
        "k-indeterminate": O,
        "k-disabled": l,
        "k-invalid k-invalid": !(y || K !== void 0 || K === !0)
      }
    ),
    ref: n,
    name: ee,
    id: N || R,
    "aria-labelledby": J,
    "aria-describedby": G,
    checked: !!C,
    disabled: l,
    tabIndex: Ee(te, l),
    role: "checkbox",
    required: h !== void 0 ? h : !1,
    "aria-checked": i || C ? !0 : O ? "mixed" : !1,
    "aria-disabled": l || void 0,
    ...le,
    onChange: de,
    onKeyDown: ue,
    onKeyUp: me,
    onFocus: be,
    onBlur: pe
  }, he = /* @__PURE__ */ e.createElement(
    e.Fragment,
    null,
    // removing value prop is required due to bug in react where
    // value set to undefined override default submit value
    d === void 0 ? /* @__PURE__ */ e.createElement("input", { ...U }) : /* @__PURE__ */ e.createElement("input", { ...U, value: w ? void 0 : d === null ? "" : d })
  ), x = /* @__PURE__ */ e.createElement(e.Fragment, null, F !== void 0 ? /* @__PURE__ */ e.createElement(
    "label",
    {
      className: P("k-checkbox-label", X),
      htmlFor: N || R,
      style: { userSelect: "none" }
    },
    F,
    ae && /* @__PURE__ */ e.createElement("span", { className: "k-label-optional" }, ie)
  ) : null), V = /* @__PURE__ */ e.createElement("span", { className: fe }, he, Y);
  return /* @__PURE__ */ e.createElement(e.Fragment, null, S === "before" ? /* @__PURE__ */ e.createElement("div", { dir: "rtl" }, V, x) : S === "after" ? /* @__PURE__ */ e.createElement("div", { dir: "ltr" }, V, x) : /* @__PURE__ */ e.createElement("div", { dir: ke }, V, x));
});
B.propTypes = {
  checked: a.bool,
  className: a.string,
  defaultChecked: a.bool,
  defaultValue: a.any,
  dir: a.string,
  disabled: a.bool,
  id: a.string,
  size: a.oneOf([null, "small", "medium", "large"]),
  rounded: a.oneOf([null, "small", "medium", "large"]),
  ariaLabelledBy: a.string,
  ariaDescribedBy: a.string,
  label: a.any,
  labelPlacement: a.string,
  labelOptional: a.bool,
  name: a.string,
  tabIndex: a.number,
  value: a.any,
  validationMessage: a.string,
  required: a.bool,
  validate: a.bool,
  valid: a.bool,
  onChange: a.func,
  onFocus: a.func,
  onBlur: a.func
};
const Se = {
  size: "medium",
  rounded: "medium"
};
B.defaultProps = Se;
B.displayName = "KendoCheckbox";
export {
  B as Checkbox,
  Fe as CheckboxPropsContext
};
