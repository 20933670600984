/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import e from "prop-types";
const i = (o) => o.replace(/^k-i-/, ""), n = (o) => "k-i-" + o, t = e.shape({
  name: e.string.isRequired,
  content: e.string.isRequired,
  viewBox: e.string.isRequired
});
export {
  t as svgIconPropType,
  n as toIconClass,
  i as toIconName
};
