/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import { provideIntlService as u, provideLocalizationService as c, registerForLocalization as h } from "@progress/kendo-react-intl";
import { pagerPage as r, messages as t, pagerOf as p, pagerTotalPages as g, pagerPageNumberLabel as m } from "../messages/index.mjs";
import { NumericTextBox as M } from "@progress/kendo-react-inputs";
class f extends s.Component {
  constructor() {
    super(...arguments), this.state = { page: this.props.currentPage }, this.handleOnChange = (e) => {
      this.setState({ page: e.target.value }), e.target.value && this.props.pageChange(e.target.value, e);
    };
  }
  render() {
    const e = u(this), a = c(this), n = this.props.messagesMap ? this.props.messagesMap(r) : { messageKey: r, defaultMessage: t[r] }, o = this.props.messagesMap ? this.props.messagesMap(p) : { messageKey: p, defaultMessage: t[p] }, i = this.props.messagesMap ? this.props.messagesMap(g) : { messageKey: g, defaultMessage: t[g] }, l = this.props.messagesMap ? this.props.messagesMap(g) : { messageKey: m, defaultMessage: t[m] };
    return /* @__PURE__ */ s.createElement("span", { className: "k-pager-input" }, /* @__PURE__ */ s.createElement("span", null, a.toLanguageString(n.messageKey, n.defaultMessage)), /* @__PURE__ */ s.createElement(
      M,
      {
        value: this.props.currentPage !== void 0 ? this.props.currentPage : this.state.page,
        onChange: this.handleOnChange,
        min: 1,
        spinners: !1,
        ariaLabel: a.toLanguageString(l.messageKey, l.defaultMessage)
      }
    ), /* @__PURE__ */ s.createElement("span", null, `${a.toLanguageString(o.messageKey, o.defaultMessage)} ${e.format(a.toLanguageString(i.messageKey, i.defaultMessage), [
      this.props.totalPages
    ])}`));
  }
}
h(f);
export {
  f as PagerInput
};
