/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const l = "labels.optional", s = {
  [l]: "(Optional)"
};
export {
  l as labelsOptional,
  s as messages
};
