/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { shiftWeekNames as s } from "../../utils.mjs";
class r {
  constructor(e) {
    this.intl = e;
  }
  getWeekNames(e = !1) {
    const t = s(
      this.intl.dateFormatNames({ nameType: "short", type: "days" }),
      this.intl.firstDay()
    );
    return e ? [""].concat(t) : t;
  }
}
export {
  r as WeekNamesService
};
