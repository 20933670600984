/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as n from "react";
import t from "prop-types";
import { classNames as c } from "@progress/kendo-react-common";
const i = (e) => {
  const s = {
    layout: "stretched",
    ...e
  }, { layout: o, children: a } = s, r = c(
    "k-actions",
    "k-actions-horizontal",
    "k-window-actions k-dialog-actions",
    {
      [`k-actions-${o}`]: o
    }
  );
  return /* @__PURE__ */ n.createElement("div", { className: r }, a);
};
i.propTypes = {
  children: t.any,
  layout: t.oneOf(["start", "center", "end", "stretched"])
};
export {
  i as DialogActionsBar
};
