/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as h from "react";
import e from "prop-types";
import { cloneDate as m, isEqual as M } from "@progress/kendo-date-math";
import { Button as k } from "@progress/kendo-react-buttons";
import { caretAltUpIcon as T, caretAltDownIcon as V } from "@progress/kendo-svg-icons";
import { provideIntlService as w, provideLocalizationService as B, registerForIntl as _, registerForLocalization as L } from "@progress/kendo-react-intl";
import { KendoDate as N } from "./models/kendo-date.mjs";
import { getActiveElement as c, validatePackage as A, AsyncFocusBlur as q, classNames as z, kendoThemeMaps as P, noop as R, createPropsContext as K, withIdHOC as U, withPropsContext as H } from "@progress/kendo-react-common";
import { FloatingLabel as $ } from "@progress/kendo-react-labels";
import { packageMetadata as j } from "../package-metadata.mjs";
import { approximateStringMatching as X, defaultFormat as Y, defaultFormatPlaceholder as G, isInRange as W, wrapperClasses as Z } from "./utils.mjs";
import { MAX_DATE as J, MIN_DATE as Q, MIN_TIME as ee, MAX_TIME as te } from "../utils.mjs";
import { increaseValue as f, messages as g, decreaseValue as v } from "../messages/index.mjs";
import { isInTimeRange as ie } from "../timepicker/utils.mjs";
const se = "Please enter a valid value!", o = class o extends h.Component {
  constructor(i) {
    super(i), this.kendoDate = null, this.currentFormat = "", this.paste = !1, this._element = null, this._wrapper = null, this._lastSelectedSymbol = "", this.focus = () => {
      this._element && this._element.focus();
    }, this.setValidity = () => {
      this.element && this.element.setCustomValidity && this.element.setCustomValidity(
        this.validity.valid ? "" : this.props.validationMessage || o.defaultProps.validationMessage
      );
    }, this.updateOnPaste = (t) => {
      if (!this.element || !this.kendoDate)
        return;
      const s = w(this).parseDate(this.element.value, this.props.format) || this.value, r = this.value;
      this.kendoDate.setValue(s), this.triggerChange(t, r);
    }, this.spinnersMouseDown = (t) => {
      t.preventDefault();
      const s = c(document);
      this.element && s !== this.element && this.element.focus({ preventScroll: !0 });
    }, this.handlePaste = () => {
      this.paste = !0;
    }, this.elementChange = (t) => {
      if (!this.element || !this.kendoDate)
        return;
      if (this.paste) {
        this.updateOnPaste(t), this.paste = !1;
        return;
      }
      const { text: s, format: r } = this.kendoDate.getTextAndFormat();
      this.currentFormat = r;
      const n = this.value, a = X(
        s,
        this.currentFormat,
        this.element.value,
        this.selection.start
      ), l = a.length === 1 && a[0][1] === "_";
      if (!l)
        for (let d = 0; d < a.length; d++)
          this.kendoDate.parsePart(a[d][0], a[d][1]);
      a.length && a[0][0] !== "_" && this.setSelection(this.selectionBySymbol(a[0][0])), l && this.switchDateSegment(1), this.triggerChange(t, n);
    }, this.elementClick = (t) => {
      this.setSelection(this.selectionByIndex(this.selection.start));
    }, this.nativeWheel = (t) => {
      c(document) === this.element && t.preventDefault();
    }, this.wheel = (t) => {
      c(document) === this.element && (t.nativeEvent.deltaY < 0 && this.increasePart(t), t.nativeEvent.deltaY > 0 && this.decreasePart(t));
    }, this.increasePart = (t) => {
      this.modifyDateSegmentValue(1, t);
    }, this.decreasePart = (t) => {
      this.modifyDateSegmentValue(-1, t);
    }, this.elementKeyDown = (t) => {
      if (!t.altKey) {
        switch (t.keyCode) {
          case 37:
            this.switchDateSegment(-1);
            break;
          case 38:
            this.modifyDateSegmentValue(1, t);
            break;
          case 39:
            this.switchDateSegment(1);
            break;
          case 40:
            this.modifyDateSegmentValue(-1, t);
            break;
          default:
            return;
        }
        t.preventDefault();
      }
    }, this.elementOnFocus = (t) => {
      this.wrapper && this.wrapper.classList.add("k-focus"), this.setState({ focused: !0 });
    }, this.elementOnBlur = (t) => {
      this.wrapper && this.wrapper.classList.remove("k-focus"), this.setState({ focused: !1 });
    }, A(j), this.state = {
      focused: !1
    };
  }
  get _inputId() {
    return this.props.id + "-accessibility-id";
  }
  /**
   * @hidden
   */
  componentDidMount() {
    this.setValidity(), this.wrapper && this.wrapper.addEventListener("wheel", this.nativeWheel, { passive: !1 });
  }
  /**
   * @hidden
   */
  componentDidUpdate(i, t) {
    this._lastSelectedSymbol && t.focused === this.state.focused ? this.setSelection(this.selectionBySymbol(this._lastSelectedSymbol)) : this.props.placeholder !== void 0 && this.selection.start === this.selection.end && this.setSelection({ start: 0, end: this.currentFormat.length }), this.setValidity();
  }
  /**
   * @hidden
   */
  componentWillUnmount() {
    this.wrapper && this.wrapper.removeEventListener("wheel", this.nativeWheel);
  }
  /**
   * @hidden
   */
  render() {
    const {
      size: i = o.defaultProps.size,
      fillMode: t = o.defaultProps.fillMode,
      rounded: s = o.defaultProps.rounded
    } = this.props;
    this.props._ref && (this.props._ref.current = this);
    const r = B(this), n = { ...o.defaultProps, ...this.props }, { name: a, label: l, id: d } = n, p = this.text, O = p === this.props.placeholder ? "" : p, E = p === this.props.placeholder ? p : void 0, b = d || this._inputId, D = !this.validityStyles || this.validity.valid;
    let x = [...Z];
    this.props.className && x.push(this.props.className);
    const S = /* @__PURE__ */ h.createElement(q, { onFocus: this.elementOnFocus, onBlur: this.elementOnBlur }, ({ onFocus: C, onBlur: I }) => /* @__PURE__ */ h.createElement(
      "span",
      {
        ref: (y) => {
          this._wrapper = y;
        },
        style: l ? void 0 : { width: this.props.width },
        dir: this.props.dir,
        className: z(
          "k-dateinput",
          "k-input",
          {
            [`k-input-${P.sizeMap[i] || i}`]: i,
            [`k-input-${t}`]: t,
            [`k-rounded-${P.roundedMap[s] || s}`]: s,
            "k-invalid": !D,
            "k-required": this.required,
            "k-disabled": this.props.disabled
          },
          this.props.className
        ),
        onFocus: C,
        onBlur: I
      },
      /* @__PURE__ */ h.createElement(
        "input",
        {
          role: this.props.ariaRole || "textbox",
          readOnly: this.props.readonly,
          tabIndex: this.props.tabIndex,
          disabled: this.props.disabled,
          title: this.props.title !== void 0 ? this.props.title : p,
          type: "text",
          spellCheck: !1,
          autoComplete: "off",
          autoCorrect: "off",
          className: "k-input-inner",
          id: b,
          "aria-label": this.props.ariaLabel,
          "aria-labelledby": this.props.ariaLabelledBy,
          "aria-describedby": this.props.ariaDescribedBy,
          "aria-haspopup": this.props.ariaHasPopup,
          "aria-disabled": this.props.disabled,
          "aria-expanded": this.props.ariaExpanded,
          "aria-controls": this.props.ariaControls,
          onWheel: this.wheel,
          onClick: this.elementClick,
          onInput: this.elementChange,
          onPaste: this.handlePaste,
          onKeyDown: this.elementKeyDown,
          onChange: R,
          value: O,
          placeholder: E,
          name: a,
          ref: (y) => this._element = y
        }
      ),
      this.props.children,
      this.props.spinners && /* @__PURE__ */ h.createElement("span", { className: "k-input-spinner k-spin-button", onMouseDown: this.spinnersMouseDown }, /* @__PURE__ */ h.createElement(
        k,
        {
          tabIndex: -1,
          type: "button",
          rounded: null,
          className: "k-spinner-increase",
          icon: "caret-alt-up",
          svgIcon: T,
          "aria-label": r.toLanguageString(f, g[f]),
          title: r.toLanguageString(f, g[f]),
          onClick: this.increasePart
        }
      ), /* @__PURE__ */ h.createElement(
        k,
        {
          tabIndex: -1,
          type: "button",
          rounded: null,
          className: "k-spinner-decrease",
          icon: "caret-alt-down",
          svgIcon: V,
          "aria-label": r.toLanguageString(v, g[v]),
          title: r.toLanguageString(v, g[v]),
          onClick: this.decreasePart
        }
      ))
    ));
    return l ? /* @__PURE__ */ h.createElement(
      $,
      {
        label: l,
        editorId: b,
        editorValue: p,
        editorValid: D,
        editorDisabled: this.props.disabled,
        children: S,
        style: { width: this.props.width }
      }
    ) : S;
  }
  /* Public Getters */
  /**
   * Gets the value of the DateInput.
   */
  get value() {
    return this.valueDuringOnChange !== void 0 ? this.valueDuringOnChange : this.kendoDate && this.kendoDate.getDateObject();
  }
  /**
   * Gets the `name` property of the DateInput.
   */
  get name() {
    return this.props.name;
  }
  get min() {
    return this.props.min !== void 0 ? this.props.min : o.defaultProps.min;
  }
  get max() {
    return this.props.max !== void 0 ? this.props.max : o.defaultProps.max;
  }
  /**
   * @hidden
   */
  get text() {
    const i = { ...F.defaultProps, ...this.props }, {
      formatPlaceholder: t = o.defaultProps.formatPlaceholder,
      format: s = o.defaultProps.format,
      value: r,
      defaultValue: n
    } = i;
    this.kendoDate === null ? (this.kendoDate = new N(this.intl.bind(this), t, s), this.kendoDate.setValue(r || n || null)) : (this.kendoDate.format = s, this.kendoDate.formatPlaceholder = t), r !== void 0 && this.value !== r && this.kendoDate.setValue(r);
    const { text: a, format: l } = this.kendoDate.getTextAndFormat();
    return this.currentFormat = l, i.placeholder !== null && i.placeholder !== void 0 && !this.state.focused && !this.kendoDate.hasValue() ? i.placeholder : a;
  }
  /**
   * Represents the validity state into which the DateInput is set.
   */
  get validity() {
    const i = W(this.value, this.min, this.max) && ie(this.value, this.props.minTime, this.props.maxTime), t = this.props.validationMessage !== void 0, s = (!this.required || this.value !== null) && i, r = this.props.valid !== void 0 ? this.props.valid : s;
    return {
      customError: t,
      rangeOverflow: this.value && this.max.getTime() < this.value.getTime() || !1,
      rangeUnderflow: this.value && this.value.getTime() < this.min.getTime() || !1,
      valid: r,
      valueMissing: this.value === null
    };
  }
  /**
   * Gets the element of the DateInput.
   *
   * @return - An `HTMLInputElement`.
   *
   * @example
   * ```jsx
   * class App extends React.Component {
   *     constructor(props) {
   *         super(props);
   *     }
   *     element = null;
   *     render() {
   *         return (
   *             <div>
   *                 <DateInput
   *                     ref={(dateInput) =>
   *                         this.element = dateInput ? dateInput.element : null}
   *                 />
   *                 <button onClick={() => console.log(this.element)}>console.log the element</button>
   *             </div>
   *         );
   *     }
   * }
   *
   * ReactDOM.render(
   *     <App />,
   *     document.getElementsByTagName('my-app')[0]
   * );
   * ```
   */
  get element() {
    return this._element;
  }
  /**
   * @hidden
   */
  get validityStyles() {
    return this.props.validityStyles !== void 0 ? this.props.validityStyles : o.defaultProps.validityStyles;
  }
  /**
   * @hidden
   */
  get required() {
    return this.props.required !== void 0 ? this.props.required : o.defaultProps.required;
  }
  /**
   * @hidden
   */
  get wrapper() {
    return this._wrapper;
  }
  /**
   * @hidden
   */
  intl() {
    return w(this);
  }
  /*  end handlers */
  get selection() {
    let i = { start: 0, end: 0 };
    return this.element !== null && this.element.selectionStart !== void 0 && (i = { start: this.element.selectionStart, end: this.element.selectionEnd }), i;
  }
  setSelection(i) {
    this._lastSelectedSymbol = this.currentFormat[i.start], window.requestAnimationFrame(() => {
      const t = c(document);
      this.element && t === this.element && !this.props.disableSelection && this.element.setSelectionRange(i.start, i.end);
    });
  }
  triggerChange(i, t) {
    this.valueDuringOnChange = this.value, this.forceUpdate(), this.props.onChange && !M(t, this.value) && this.props.onChange.call(void 0, {
      syntheticEvent: i,
      nativeEvent: i.nativeEvent,
      value: this.value,
      target: this
      // inRange: this.props.min && this.props.max ? isInRange(value, this.props.min, this.props.max) : true
    }), this.valueDuringOnChange = void 0;
  }
  selectionBySymbol(i) {
    let t = -1, s = 0;
    for (let r = 0; r < this.currentFormat.length; r++)
      this.currentFormat[r] === i && (s = r + 1, t === -1 && (t = r));
    return t < 0 && (t = 0), { start: t, end: s };
  }
  selectionByIndex(i) {
    let t = { start: i, end: i };
    for (let s = i, r = i - 1; s < this.currentFormat.length || r >= 0; s++, r--) {
      if (s < this.currentFormat.length && this.currentFormat[s] !== "_") {
        t = this.selectionBySymbol(this.currentFormat[s]);
        break;
      }
      if (r >= 0 && this.currentFormat[r] !== "_") {
        t = this.selectionBySymbol(this.currentFormat[r]);
        break;
      }
    }
    return t;
  }
  switchDateSegment(i) {
    const { start: t, end: s } = this.selection;
    if (t < s && this.currentFormat[t] !== this.currentFormat[s - 1]) {
      this.setSelection(this.selectionByIndex(i > 0 ? t : s - 1));
      return;
    }
    const r = this.currentFormat[t];
    let n = t + i;
    for (; n > 0 && n < this.currentFormat.length && !(this.currentFormat[n] !== r && this.currentFormat[n] !== "_"); )
      n += i;
    if (this.currentFormat[n] === "_")
      return;
    let a = n;
    for (; a >= 0 && a < this.currentFormat.length && this.currentFormat[a] === this.currentFormat[n]; )
      a += i;
    n > a && (a + 1 !== t || n + 1 !== s) ? this.setSelection({ start: a + 1, end: n + 1 }) : n < a && (n !== t || a !== s) && this.setSelection({ start: n, end: a });
  }
  modifyDateSegmentValue(i, t) {
    if (!this.kendoDate)
      return;
    const s = this.value, r = this.currentFormat[this.selection.start], n = this.kendoDate.symbolMap(r), a = ((this.props.steps || {})[n] || 1) * i;
    this.kendoDate.modifyPart(r, a), this.triggerChange(t, s);
  }
};
o.displayName = "DateInput", o.propTypes = {
  value: e.instanceOf(Date),
  format: e.oneOfType([
    e.string,
    e.shape({
      skeleton: e.string,
      pattern: e.string,
      date: e.oneOf(["short", "medium", "long", "full"]),
      time: e.oneOf(["short", "medium", "long", "full"]),
      datetime: e.oneOf(["short", "medium", "long", "full"]),
      era: e.oneOf(["narrow", "short", "long"]),
      year: e.oneOf(["numeric", "2-digit"]),
      month: e.oneOf(["numeric", "2-digit", "narrow", "short", "long"]),
      day: e.oneOf(["numeric", "2-digit"]),
      weekday: e.oneOf(["narrow", "short", "long"]),
      hour: e.oneOf(["numeric", "2-digit"]),
      hour12: e.bool,
      minute: e.oneOf(["numeric", "2-digit"]),
      second: e.oneOf(["numeric", "2-digit"]),
      timeZoneName: e.oneOf(["short", "long"])
    })
  ]),
  formatPlaceholder: e.oneOfType([
    e.oneOf(["wide", "narrow", "short", "formatPattern"]),
    e.shape({
      year: e.string,
      month: e.string,
      day: e.string,
      hour: e.string,
      minute: e.string,
      second: e.string
    })
  ]),
  width: e.oneOfType([
    e.string,
    e.number
  ]),
  tabIndex: e.number,
  title: e.string,
  steps: e.shape({
    year: e.number,
    month: e.number,
    day: e.number,
    hour: e.number,
    minute: e.number,
    second: e.number
  }),
  min: e.instanceOf(Date),
  max: e.instanceOf(Date),
  disabled: e.bool,
  spinners: e.bool,
  name: e.string,
  dir: e.string,
  label: e.string,
  id: e.string,
  ariaLabelledBy: e.string,
  ariaDescribedBy: e.string,
  ariaLabel: e.string,
  ariaRole: e.string,
  ariaHasPopup: e.oneOfType([
    e.bool,
    e.string
  ]),
  ariaExpanded: e.oneOfType([
    e.bool
  ]),
  onChange: e.func,
  validationMessage: e.string,
  required: e.bool,
  validate: e.bool,
  valid: e.bool,
  size: e.oneOf([null, "small", "medium", "large"]),
  rounded: e.oneOf([null, "small", "medium", "large", "full"]),
  fillMode: e.oneOf([null, "solid", "flat", "outline"])
}, o.defaultProps = {
  format: Y,
  size: "medium",
  rounded: "medium",
  fillMode: "solid",
  formatPlaceholder: G,
  // defaultValue: null as Date | null,
  spinners: !1,
  disabled: !1,
  max: m(J),
  min: m(Q),
  minTime: m(ee),
  maxTime: m(te),
  required: !1,
  validityStyles: !0,
  validationMessage: se,
  placeholder: null,
  // the rest of the properties are undefined by default
  ariaHasPopup: "grid"
};
let u = o;
const re = K(), F = U(H(re, u));
F.displayName = "KendoReactDateInput";
_(u);
L(u);
export {
  F as DateInput,
  re as DateInputPropsContext,
  u as DateInputWithoutContext
};
