/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const o = (...l) => {
  const s = {}, n = (e) => typeof e == "object" ? Object.keys(e).forEach((t) => {
    s[t] = e[t];
  }) : s[e] = !0, c = (e) => e.filter((t) => t !== !0 && !!t).map((t) => Array.isArray(t) ? c(t) : n(t));
  return c(l), Object.keys(s).map((e) => s[e] && e || null).filter((e) => e !== null).join(" ");
};
export {
  o as classNames
};
