/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as p from "react";
import * as r from "react-dom/server";
const n = "export.pdf", h = "KendoReact PDF Generator";
class c {
  constructor(t, o, e, i, a = {}) {
    this.drawDOM = t, this.exportPDF = o, this.saveAs = e, this.domElement = i, this.options = a, this.convertPageTemplateToHtml = (s) => `<span>${r.renderToStaticMarkup(
      p.createElement(this.options.pageTemplate, {
        pageNum: s.pageNum,
        totalPages: s.totalPages
      })
    )}</span>`;
  }
  savePDF(t) {
    const o = this.drawDOM(this.domElement, this.getDrawOptions()).then((e) => this.exportPDF(e, this.getPDFOptions())).then((e) => this.saveAs(e, this.options.fileName || n, this.getSaveOptions()));
    t && o.then(t, t);
  }
  getDrawOptions() {
    return {
      avoidLinks: this.options.avoidLinks,
      forcePageBreak: this.options.forcePageBreak,
      keepTogether: this.options.keepTogether,
      margin: this.options.margin,
      paperSize: this.options.paperSize,
      landscape: this.options.landscape,
      repeatHeaders: this.options.repeatHeaders,
      scale: this.options.scale,
      template: this.options.pageTemplate && this.convertPageTemplateToHtml
    };
  }
  getPDFOptions() {
    return {
      author: this.options.author,
      creator: this.options.creator || h,
      date: this.options.date,
      imgDPI: this.options.imageResolution,
      keywords: this.options.keywords,
      landscape: this.options.landscape,
      margin: this.options.margin,
      multiPage: !0,
      paperSize: this.options.paperSize,
      producer: this.options.producer,
      subject: this.options.subject,
      title: this.options.title
    };
  }
  getSaveOptions() {
    return {
      forceProxy: this.options.forceProxy,
      proxyData: this.options.proxyData,
      proxyTarget: this.options.proxyTarget,
      proxyURL: this.options.proxyURL
    };
  }
}
export {
  c as default
};
