/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import e from "prop-types";
const l = (r) => null;
l.displayName = "KendoReactGridColumn";
l.defaultProps = {
  filterable: !0,
  editable: !0,
  sortable: !0,
  resizable: !0,
  reorderable: !0,
  groupable: !0
};
l.propTypes = {
  field: e.string,
  title: e.string,
  editable: e.bool,
  sortable: e.oneOfType([
    e.bool,
    e.shape({
      allowUnsort: e.bool.isRequired
    })
  ]),
  cell: e.any,
  filterCell: e.any,
  filterTitle: e.string,
  filterable: e.bool,
  filter: e.oneOf(["text", "numeric", "boolean", "date"]),
  editor: e.oneOf(["text", "numeric", "boolean", "date"]),
  width: e.oneOfType([e.string, e.number]),
  minResizableWidth: e.number,
  headerCell: e.any,
  footerCell: e.any,
  headerSelectionValue: e.bool,
  format: e.string,
  headerClassName: e.string,
  className: e.string,
  reorderable: e.bool,
  resizable: e.bool,
  orderIndex: e.number,
  groupable: e.bool,
  columnMenu: e.any,
  locked: e.bool
};
export {
  l as GridColumn
};
