/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import i from "prop-types";
import { classNames as l } from "@progress/kendo-react-common";
const s = (e) => {
  const a = t.useRef(null), c = t.useCallback((n) => {
    e.onClick && e.onClick.call(void 0, {
      syntheticEvent: n,
      item: e.item,
      title: e.title
    });
  }, [e.item, e.title, e.onClick]);
  return t.useEffect(() => {
    a.current && e.focused && a.current.focus();
  }, [e.focused]), /* @__PURE__ */ t.createElement(
    "span",
    {
      style: e.style,
      tabIndex: e.tabIndex,
      className: l(
        "k-actionsheet-item",
        "k-cursor-pointer",
        e.disabled && "k-disabled",
        e.className
      ),
      ref: a,
      role: "button",
      "aria-disabled": e.disabled,
      onClick: c
    },
    /* @__PURE__ */ t.createElement("span", { className: "k-actionsheet-action" }, e.icon && /* @__PURE__ */ t.createElement("span", { className: "k-icon-wrap" }, e.icon), (e.title || e.description) && /* @__PURE__ */ t.createElement("span", { className: "k-actionsheet-item-text" }, e.title && /* @__PURE__ */ t.createElement("span", { className: "k-actionsheet-item-title" }, e.title), e.description && /* @__PURE__ */ t.createElement("span", { className: "k-actionsheet-item-description" }, e.description)))
  );
};
s.propTypes = {
  className: i.string,
  style: i.object,
  description: i.string,
  disabled: i.bool,
  group: i.oneOf(["top", "bottom"]),
  icon: i.element,
  title: i.string
};
export {
  s as ActionSheetItem
};
