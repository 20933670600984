/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import { Draggable as n, IconWrap as o } from "@progress/kendo-react-common";
import { registerForLocalization as r } from "@progress/kendo-react-intl";
import { sortAscSmallIcon as i, sortDescSmallIcon as a, xCircleIcon as l } from "@progress/kendo-svg-icons";
class p extends s.Component {
  constructor() {
    super(...arguments), this.draggable = null, this.onPress = (e) => {
      const t = this.draggable && this.draggable.element;
      t && this.props.onPress && this.props.onPress(e.event, t);
    }, this.onDrag = (e) => {
      const t = this.draggable && this.draggable.element;
      t && this.props.onDrag && this.props.onDrag(e.event, t);
    }, this.onRelease = (e) => {
      this.draggable && this.draggable.element && this.props.onRelease && this.props.onRelease(e.event);
    }, this.sortChange = (e) => {
      if (e.preventDefault(), this.props.onSortChange) {
        const t = this.props.dir === "asc" ? "desc" : "asc";
        this.props.onSortChange(e, t);
      }
    }, this.groupRemove = (e) => {
      e.preventDefault(), e.stopPropagation(), this.props.onRemove && this.props.onRemove(e);
    }, this.onContextMenu = (e) => {
      const t = {
        title: this.props.title,
        dir: this.props.dir,
        index: this.props.index
      };
      this.props.onContextMenu && this.props.onContextMenu.call(void 0, e, { dataItem: { ...t } });
    };
  }
  render() {
    const { dir: e } = this.props;
    return /* @__PURE__ */ s.createElement(
      n,
      {
        onPress: this.onPress,
        onDrag: this.onDrag,
        onRelease: this.onRelease,
        ref: (t) => {
          this.draggable = t;
        }
      },
      /* @__PURE__ */ s.createElement(
        "div",
        {
          className: "k-chip k-chip-md k-chip-solid k-chip-solid-base k-rounded-md",
          onClick: this.sortChange,
          onContextMenu: this.onContextMenu,
          role: "button",
          style: { touchAction: "none" }
        },
        /* @__PURE__ */ s.createElement("span", null, /* @__PURE__ */ s.createElement(
          o,
          {
            name: "sort-" + e + "-small",
            icon: e === "asc" ? i : a,
            size: "small"
          }
        )),
        /* @__PURE__ */ s.createElement("span", { className: "k-chip-content" }, this.props.title),
        /* @__PURE__ */ s.createElement("span", { className: "k-chip-actions" }, /* @__PURE__ */ s.createElement(
          "span",
          {
            className: "k-chip-action k-chip-remove-action",
            onClick: this.groupRemove
          },
          /* @__PURE__ */ s.createElement(
            o,
            {
              name: "x-circle",
              icon: l,
              size: "small"
            }
          )
        ))
      )
    );
  }
}
r(p);
export {
  p as GroupingIndicator
};
