import calculateSlope from './calculate-slope';
import getTrendlineData from './get-trendline-data';

function logarithmicTrendline(context) {
    var options = context.options;
    var categoryAxis = context.categoryAxis;
    var seriesValues = context.seriesValues;

    var data = getData({ seriesValues: seriesValues, categoryAxis: categoryAxis, options: options });
    if (data) {
        return Object.assign({}, options,

            {type: 'line',
            data: data,
            categoryField: 'category',
            field: 'value'});
    }

    return null;
}

var valueGetter = function (fieldName) { return function (ref) {
        var categoryIx = ref.categoryIx;
        var valueFields = ref.valueFields;

        return ({ xValue: Math.log(categoryIx + 1), yValue: valueFields[fieldName] });
; }    };

function getData(ref) {
    var seriesValues = ref.seriesValues;
    var categoryAxis = ref.categoryAxis;
    var options = ref.options;

    var ref$1 = calculateSlope(seriesValues(), valueGetter(options.field));
    var slope = ref$1.slope;
    var intercept = ref$1.intercept;
    var count = ref$1.count;
    if (count > 0) {
        // Logarithmic trendline equation:
        // y = a * ln (x) + b
        var a = slope;
        var b = intercept;
        return getTrendlineData(function (x) { return a * Math.log(x) + b; }, categoryAxis);
    }

    return null;
}

export default logarithmicTrendline;
