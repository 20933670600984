/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { BaseEvent as t } from "./base-event.mjs";
class o extends t {
  /**
   * @hidden
   */
  constructor(s, e) {
    super(e);
  }
}
export {
  o as RenderEvent
};
