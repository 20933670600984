/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as p from "react";
import a from "prop-types";
import { PanelBarItem as u } from "./PanelBarItem.mjs";
import { flatChildren as x, isArrayEqual as k, flatVisibleChildren as g, getInitialState as P, getFirstId as I, renderChildren as v } from "./util.mjs";
import { Keys as d, validatePackage as b, shouldShowValidationUI as w, classNames as S, WatermarkOverlay as K } from "@progress/kendo-react-common";
import { packageMetadata as m } from "../package-metadata.mjs";
import { NavigationAction as n } from "./interfaces/NavigationAction.mjs";
const c = class c extends p.Component {
  constructor(i) {
    super(i), this._element = null, this.showLicenseWatermark = !1, this.handleSelect = (t) => {
      this.onSelect(t), this.onFocus(t);
    }, this.onSelect = (t) => {
      const r = x(p.Children.toArray(this.children));
      let s, e;
      switch (r.forEach((l) => {
        l.props.uniquePrivateKey === (t.uniquePrivateKey || this.state.focused) && (s = l);
      }), this.expandMode) {
        case "single":
          e = [...s.props.parentUniquePrivateKey, s.props.uniquePrivateKey], k(this.expandedItems, e) && (s.props.parentUniquePrivateKey ? e = [...s.props.parentUniquePrivateKey] : e = []);
          break;
        case "multiple": {
          e = this.expandedItems.slice();
          let l = e.indexOf(s.props.uniquePrivateKey);
          l === -1 ? e.push(s.props.uniquePrivateKey) : e.splice(l, 1);
          break;
        }
        default:
          e = this.expandedItems.slice();
          break;
      }
      this.setState({ selected: s.props.uniquePrivateKey, expanded: e }), this.props.onSelect && this.props.onSelect.call(void 0, {
        target: s,
        expandedItems: e
      });
    }, this.onFocus = (t, r = 0) => {
      const s = g(p.Children.toArray(this.children));
      let e;
      s.forEach((l, h) => {
        if (l.props.uniquePrivateKey === (t.uniquePrivateKey || this.state.focused)) {
          let y = h + r < 0 ? 0 : h + r > s.length ? s.length - 1 : h + r;
          e = s[y];
        }
      }), e && (this.activeDescendant = e.props.id, this.setState({ focused: e.props.uniquePrivateKey }));
    }, this.onNavigate = (t, r) => {
      let s;
      switch (r) {
        case n.Previous:
          s = -1, this.onFocus(t, s);
          break;
        case n.Next:
          s = 1, this.onFocus(t, s);
          break;
        case n.Toggle:
          this.onSelect(t);
          break;
      }
    }, this.handleWrapperFocus = () => {
      clearTimeout(this.nextTickId), this.state.wrapperFocused || this.setState({ wrapperFocused: !0 });
    }, this.handleWrapperBlur = () => {
      this.nextTick(() => {
        this.setState({ wrapperFocused: !1 });
      });
    }, this.handleKeyDown = (t) => {
      const r = this._element && getComputedStyle(this._element).direction === "rtl" || !1;
      if (t.target === t.currentTarget) {
        const s = t.keyCode;
        let e;
        switch (s) {
          case d.left:
            e = r ? n.Next : n.Previous;
            break;
          case d.up:
            e = n.Previous;
            break;
          case d.right:
            e = r ? n.Previous : n.Next;
            break;
          case d.down:
            e = n.Next;
            break;
          case d.space:
          case d.enter:
            e = n.Toggle;
            break;
          default:
            e = null;
            break;
        }
        e !== null && (t.preventDefault(), this.onNavigate(t, e));
      }
    }, b(m), this.showLicenseWatermark = w(m);
    const o = P(i, this.expandMode);
    o.focused || (o.focused = I(i)), this.state = o;
  }
  get expandMode() {
    return this.props.expandMode || "multiple";
  }
  get selectedItem() {
    const { selected: i = this.state.selected } = this.props;
    return i;
  }
  get expandedItems() {
    return this.props.isControlled ? this.props.expanded || [] : this.state.expanded;
  }
  get children() {
    const i = { ...this.state, selected: this.selectedItem }, o = {
      animation: this.props.animation,
      keepItemsMounted: this.props.keepItemsMounted,
      state: i,
      expanded: this.expandedItems,
      handleSelect: this.handleSelect,
      children: this.props.children
    };
    return v(o);
  }
  /**
   * @hidden
   */
  render() {
    const i = {
      "aria-activedescendant": this.activeDescendant
    }, o = S(
      "k-panelbar",
      "k-pos-relative",
      this.props.className
    );
    return /* @__PURE__ */ p.createElement(
      "ul",
      {
        ref: (t) => {
          this._element = t;
        },
        dir: this.props.dir,
        role: "tree",
        tabIndex: 0,
        onKeyDown: this.handleKeyDown,
        onFocus: this.handleWrapperFocus,
        onBlur: this.handleWrapperBlur,
        className: o,
        style: this.props.style,
        ...i
      },
      this.children,
      this.showLicenseWatermark && /* @__PURE__ */ p.createElement(K, null)
    );
  }
  nextTick(i) {
    this.nextTickId = window.setTimeout(() => i());
  }
};
c.propTypes = {
  animation: a.bool,
  children: function(i, o) {
    const t = i[o];
    if (t) {
      if (Array.isArray(t)) {
        for (let r of t)
          if (!r.type || r.type !== u)
            return new Error(
              "PanelBar children should be either PanelBarItem or Array of PanelBarItem."
            );
      } else if (t.type !== u)
        return new Error(
          "PanelBar child should be either PanelBarItem or Array of PanelBarItem."
        );
      return null;
    }
    return null;
  },
  dir: a.string,
  selected: a.string,
  expanded: a.arrayOf(a.string),
  focused: a.string,
  expandMode: a.oneOf(["single", "multiple"]),
  className: a.string,
  keepItemsMounted: a.bool,
  onSelect: a.func,
  style: a.object
}, c.defaultProps = {
  expandMode: "multiple",
  animation: !0,
  keepItemsMounted: !1
};
let f = c;
export {
  f as PanelBar
};
