/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
class n {
  constructor() {
    this.container = null, this.scrollElement = null, this.list = null, this.containerHeight = 0, this.skip = 0, this.total = 0, this.enabled = !1, this.pageSize = 0, this.itemHeight = 0, this.PageChange = null, this.prevScrollPos = 0, this.listTranslate = 0, this.scrollSyncing = !1, this.scrollerRef = (t) => {
      const i = this;
      i.container = t, t && (t.setAttribute("unselectable", "on"), window.setTimeout(i.calcScrollElementHeight.bind(i), 0));
    }, this.calcScrollElementHeight = () => {
      this.scrollSyncing = !0;
      let t = !1;
      this.itemHeight = this.list ? this.list.children[0].offsetHeight : this.itemHeight, this.containerHeight = Math.min(1533915, this.itemHeight * this.total);
      const i = this.containerHeight;
      return this.scrollElement && (t = this.scrollElement.style.height !== i + "px", t && (this.scrollElement.style.height = i + "px")), this.scrollSyncing = !1, t;
    }, this.scrollHandler = this.scrollHandler.bind(this);
  }
  get translate() {
    return this.listTranslate;
  }
  changePage(t, i) {
    const l = Math.min(Math.max(0, t), this.total - this.pageSize);
    l !== this.skip && this.PageChange && this.PageChange({ skip: l, take: this.pageSize }, i);
  }
  translateTo(t) {
    this.listTranslate = t, this.list && (this.list.style.transform = "translateY(" + t + "px)");
  }
  reset() {
    this.container && (this.calcScrollElementHeight(), this.container.scrollTop = 0, this.translateTo(0));
  }
  scrollToEnd() {
    this.container && this.list && (this.calcScrollElementHeight(), this.container.scrollTop = this.container.scrollHeight - this.container.offsetHeight, this.translateTo(this.container.scrollHeight));
  }
  localScrollUp(t) {
    const i = this.itemHeight, l = this.container.scrollTop;
    let s = this.listTranslate, h, e = l - s;
    if (!(e > i)) {
      for (h = 0; h < this.skip && !(s + i + e <= l); h++)
        s -= i;
      if (s = this.validateTranslate(s), this.skip - h <= 0 && s >= l) {
        this.translateTo(0), this.changePage(0, t), this.container.scrollTop = 0;
        return;
      }
      s !== this.listTranslate && (this.translateTo(s), this.changePage(this.skip - h, t));
    }
  }
  localScrollDown(t) {
    const i = this.itemHeight;
    let l = this.container.scrollTop, s = this.listTranslate;
    const h = this.list.children.length;
    let e;
    for (e = 0; e < h && !(s + i >= l); e++)
      s += i;
    s = this.validateTranslate(s), e >= h && this.skip + e >= this.total ? (this.translateTo(s), this.changePage(this.total - 1, t)) : s !== this.listTranslate && (this.translateTo(s), this.changePage(this.skip + e, t));
  }
  scrollNonStrict(t) {
    const i = this.total * this.prevScrollPos / this.containerHeight, l = Math.min(Math.floor(i), this.total - 1);
    let s = this.containerHeight * i / this.total;
    s = this.validateTranslate(s), this.translateTo(s), this.changePage(l, t);
  }
  scrollHandler(t) {
    const i = this.container ? this.container.scrollTop : 0, l = this.prevScrollPos;
    this.prevScrollPos = i, !(!this.enabled || !this.list || !this.container || this.scrollSyncing) && (i - l <= 0 && i > this.listTranslate - this.list.scrollHeight / 10 ? this.localScrollUp(t) : i - l > 0 && i < this.listTranslate + this.list.scrollHeight * 2 / 3 ? this.localScrollDown(t) : this.scrollNonStrict(t));
  }
  validateTranslate(t) {
    return t = Math.max(0, t), t = Math.min(this.containerHeight, t), t;
  }
}
export {
  n as default
};
