/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { ConfigurationComponent as o } from "./base/ConfigurationComponent.mjs";
const n = (e) => /* @__PURE__ */ t.createElement(o, { visible: !0, ...e, _chartKey: "legend" });
export {
  n as ChartLegend
};
