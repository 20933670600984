/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as a from "react";
import o from "prop-types";
import { classNames as m } from "@progress/kendo-react-common";
import { RowHeightService as w } from "./services/RowHeightService.mjs";
import { ScrollerService as b } from "./services/ScrollerService.mjs";
const H = (s, i, t) => Math.min(Math.abs(i - s), t), P = 17, T = 10, x = {
  1: (s) => (i) => i + s,
  0: (s) => (i) => i - s
}, A = {
  1: (s) => (i) => Math.min(i, s),
  0: (s) => (i) => Math.max(i, s)
}, I = {
  1: (s) => (i) => i < s,
  0: (s) => (i) => i > s
}, c = class c extends a.Component {
  constructor(i) {
    super(i), this.rowHeightService = null, this.scrollContainer = null, this.lastDirection = null, this.lastTotal = 0, this.lastTake = 0, this.animationInProgress = !1, this.restrictScroll = !1, this.scrollTo = (t) => {
      const e = this.direction === "vertical" ? "scrollTop" : "scrollLeft";
      if (!this.scrollContainer)
        return;
      const r = this.scrollContainer[e];
      this.restrictScroll && e === "scrollTop" && (!Number.isInteger(r) || !Number.isInteger(t)) && Math.abs(r - t) < T || (this.scrollContainer[e] = t);
    }, this.scrollToIndex = (t) => {
      this.animationInProgress = !1, this.rowHeightService && this.scrollTo(this.rowHeightService.offset(t));
    }, this.animateToIndex = (t) => {
      if (!this.rowHeightService || !window)
        return;
      window.cancelAnimationFrame(this.cancelAnimation);
      const e = this.rowHeightService.offset(t), r = this.getContainerScrollDirection(e);
      let { start: n, end: l } = this.scrollRange(e, r);
      if (n === l)
        return;
      const f = this.scrollStep(n, l), h = x[r](f), p = A[r](l), u = I[r](h(l)), d = (v) => {
        this.animationInProgress = !0;
        const S = h(v);
        this.scrollTo(p(S)), u(S) ? this.cancelAnimation = window.requestAnimationFrame(() => {
          d(S);
        }) : this.animationInProgress = !1;
      };
      this.cancelAnimation = window.requestAnimationFrame(() => {
        d(n);
      });
    }, this.scrollToBottom = () => {
      this.rowHeightService && this.scrollTo(this.rowHeightService.totalHeight() + this.props.bottomOffset);
    }, this.scrollStep = (t, e) => {
      const r = this.props.scrollDuration || c.defaultProps.scrollDuration;
      return Math.abs(e - t) / (r / P);
    }, this.scrollRange = (t, e) => {
      const r = this.containerScrollPosition;
      if (parseInt(`${t}`, 10) === parseInt(`${r}`, 10))
        return { start: t, end: t };
      const n = this.containerMaxScroll(), l = e === 0 ? 1 : -1, f = H(r, t, this.props.maxScrollDifference || 0), h = Math.min(t, n);
      return { start: Math.min(Math.max(h + l * f, 0), n), end: h };
    }, this.containerMaxScroll = () => this.containerScrollSize - this.containerOffsetSize, this.getContainerScrollDirection = (t) => t < this.containerScrollPosition ? 0 : 1, this.initServices = (t = this.props) => {
      const e = this.direction === "vertical" ? t.itemHeight : t.itemWidth;
      e !== void 0 && (this.rowHeightService = new w(t.total, e, 0), this.scrollerService.create(
        this.rowHeightService,
        t.skip,
        t.take,
        t.total,
        t.topOffset,
        this.scrollOffsetSize,
        this.direction
      ));
    }, this.getContainerProperty = (t) => this.scrollContainer ? this.scrollContainer[t] : 0, this.handleScroll = (t) => {
      if (!this.scrollContainer || !this.rowHeightService)
        return;
      const e = t.target;
      this.scrollerService.onScroll({
        scrollLeft: e.scrollLeft,
        scrollTop: e.scrollTop,
        offsetHeight: e.offsetHeight,
        offsetWidth: e.offsetWidth
      });
      const r = this.rowHeightService.index(this.containerScrollPosition - this.props.topOffset), { onScrollAction: n } = this.props, l = {
        index: r,
        target: e,
        scrollAction: this.scrollAction,
        pageAction: this.pageAction,
        animationInProgress: this.animationInProgress
      };
      this.props.onScroll && this.props.onScroll.call(void 0, t), n && n.call(void 0, l), this.scrollAction = void 0, this.pageAction = void 0;
    }, this.handleScrollAction = (t) => {
      this.scrollAction = t;
    }, this.handlePageAction = (t) => {
      this.pageAction = t;
    }, this.scrollerService = new b(
      this.handleScrollAction,
      this.handlePageAction
    ), this.restrictScroll = Number.parseFloat(a.version) > 17;
  }
  get element() {
    return this.scrollContainer;
  }
  get containerOffsetSize() {
    return this.getContainerProperty(this.direction === "vertical" ? "offsetHeight" : "offsetWidth");
  }
  get containerScrollSize() {
    return this.getContainerProperty(this.direction === "vertical" ? "scrollHeight" : "scrollWidth");
  }
  get containerScrollPosition() {
    return this.getContainerProperty(this.direction === "vertical" ? "scrollTop" : "scrollLeft");
  }
  get direction() {
    return this.props.direction !== void 0 ? this.props.direction : c.defaultProps.direction;
  }
  get scrollOffsetSize() {
    return this.props.scrollOffsetSize !== void 0 ? this.props.scrollOffsetSize : c.defaultProps.scrollOffsetSize;
  }
  activeIndex() {
    return this.itemIndex(Math.ceil(this.containerScrollPosition));
  }
  itemIndex(i) {
    return this.rowHeightService ? this.rowHeightService.index(i) : 0;
  }
  itemOffset(i) {
    return this.rowHeightService ? this.rowHeightService.offset(i) : 0;
  }
  isIndexVisible(i) {
    if (!this.rowHeightService)
      return !1;
    const t = this.containerScrollPosition, e = t + this.containerOffsetSize, r = this.rowHeightService.offset(i), n = r + this.rowHeightService.height(i);
    return r >= t && n <= e;
  }
  isListScrolled(i) {
    return this.rowHeightService ? this.containerScrollPosition !== this.rowHeightService.offset(i) : !1;
  }
  componentDidMount() {
    const { onMount: i } = this.props;
    i && i.call(void 0, this);
  }
  render() {
    (this.lastTotal !== this.props.total || this.lastDirection !== this.direction || this.lastTake !== this.props.take) && (this.initServices(), this.lastTotal = this.props.total, this.lastDirection = this.direction, this.lastTake = this.props.take);
    const i = `${(this.rowHeightService ? this.rowHeightService.totalHeight() : 0) + this.props.bottomOffset}`, t = this.direction === "vertical" ? { height: `${i}px` } : { width: `${i}px` }, e = m(
      "k-content k-scrollable",
      {
        "k-scrollable-horizontal": this.direction === "horizontal"
      },
      this.props.className
    ), r = m(
      "k-scrollable-placeholder",
      {
        "k-scrollable-horizontal-placeholder": this.direction === "horizontal"
      }
    );
    return /* @__PURE__ */ a.createElement(
      "div",
      {
        ref: (n) => {
          this.scrollContainer = n;
        },
        onScroll: this.handleScroll,
        className: e,
        tabIndex: this.props.tabIndex,
        role: this.props.role
      },
      this.props.children,
      /* @__PURE__ */ a.createElement(
        "div",
        {
          style: t,
          className: r
        }
      )
    );
  }
};
c.propTypes = {
  bottomOffset: o.number.isRequired,
  className: o.string,
  direction: o.oneOf(["horizontal", "vertical"]),
  forceScroll: o.bool,
  itemHeight: o.number,
  itemWidth: o.number,
  maxScrollDifference: o.number,
  onScroll: o.func,
  onScrollAction: o.func,
  scrollDuration: o.number,
  scrollOffsetSize: o.number,
  skip: o.number.isRequired,
  tabIndex: o.number,
  take: o.number.isRequired,
  topOffset: o.number.isRequired,
  total: o.number.isRequired,
  role: o.string
}, c.defaultProps = {
  direction: "vertical",
  forceScroll: !1,
  scrollOffsetSize: 0,
  maxScrollDifference: 100,
  scrollDuration: 100
};
let g = c;
export {
  g as Virtualization
};
