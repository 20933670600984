import {
    Class,
    deepExtend
} from '../common';

export var SankeyElement = (function (Class) {
    function SankeyElement(options) {
        Class.call(this);
        this.options = deepExtend({}, this.options, options);
        this.createVisual();
    }

    if ( Class ) SankeyElement.__proto__ = Class;
    SankeyElement.prototype = Object.create( Class && Class.prototype );
    SankeyElement.prototype.constructor = SankeyElement;

    SankeyElement.prototype.createVisual = function createVisual () {
        this.visual = this.createElement();
    };

    SankeyElement.prototype.exportVisual = function exportVisual () {
        return this.visual;
    };

    SankeyElement.prototype.createElement = function createElement () {
        var this$1 = this;

        var customVisual = this.options.visual;
        var visual;

        if (customVisual) {
            visual = customVisual({
                sender: this.getSender(),
                options: this.visualOptions(),
                createVisual: function () { return this$1.getElement(); }
            });
        } else {
            visual = this.getElement();
        }

        return visual;
    };

    SankeyElement.prototype.getSender = function getSender () {
        return this;
    };

    return SankeyElement;
}(Class));
