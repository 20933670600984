/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const u = (t, e) => e.chartCollectionIdxKey ? c.collectionConfigurationItem(t, e) : e.chartKey ? c.configurationItem(t, e) : {}, a = (t, e) => {
  if (e.type)
    switch (e.type) {
      case "set":
        return c.themeItem(t, e);
      case "push":
        return Object.assign(t, e.payload);
      default:
        return t;
    }
  else
    return {};
}, p = (t, e) => {
  if (e.type)
    switch (e.type) {
      case "add":
        return [...t, e.payload];
      case "remove":
        return t.filter((l) => l !== e.payload);
      default:
        return t;
    }
  else
    return [];
}, c = {
  configurationItem(t, e) {
    return Object.assign(t, {
      [e.chartKey]: e.payload
    });
  },
  collectionConfigurationItem(t, e) {
    let l = !1;
    const [o, n] = e.chartCollectionIdxKey.split("_"), i = t[o].map((s, r) => parseInt(n, 10) === r ? (l = !0, e.payload) : s);
    return l === !1 && i.splice(parseInt(n, 10), 0, e.payload), Object.assign(t, {
      [o]: i
    });
  },
  themeItem(t, e) {
    let l = {}, o = Object.assign(l, t);
    const { field: n, value: i } = e.payload, s = n.split(".");
    let r = s.shift();
    for (; s.length > 0; )
      l = l[r] = l[r] || {}, r = s.shift();
    return l[r] = i, o;
  }
};
export {
  p as observersReducer,
  u as optionsReducer,
  a as themeReducer
};
