/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as p from "react";
import { classNames as d } from "@progress/kendo-react-common";
import { InstanceObserver as m } from "@progress/kendo-charts";
import { TooltipPoint as c } from "./Point.mjs";
import { SharedTooltipContent as f } from "./SharedTooltipContent.mjs";
import { TooltipPopup as S } from "./Popup.mjs";
import { ChartContext as y } from "../ChartContext.mjs";
const T = "k-chart-shared-tooltip", x = "k-chart-tooltip-inverse", a = class a extends p.Component {
  constructor() {
    super(...arguments), this.context = null, this.state = {
      popupShown: !1
    }, this.chartObserver = null;
  }
  componentDidMount() {
    this.chartObserver = new m(this, {
      showTooltip: "onShowTooltip",
      hideTooltip: "onHideTooltip"
    }), this.context.observersStore.dispatch({
      type: "add",
      payload: this.chartObserver
    });
  }
  render() {
    const { popupContext: t, shared: e, className: n, ...r } = this.state, i = this.state.popupShown ? () => {
      const o = this.findRenderFunction();
      if (e)
        return o !== null ? o(t) : /* @__PURE__ */ p.createElement(f, { ...t });
      {
        const u = t.point, l = this.findRenderFunctionByIndex(u.series.index);
        return l !== null ? l(t) : o !== null ? o(t) : /* @__PURE__ */ p.createElement(
          "span",
          {
            dangerouslySetInnerHTML: { __html: t.point.formattedValue }
          }
        );
      }
    } : Function.prototype, s = d({
      [T]: e,
      [x]: !!n
    });
    return /* @__PURE__ */ p.createElement(
      S,
      {
        ...r,
        popupContent: i,
        className: s
      }
    );
  }
  componentWillUnmount() {
    this.context.observersStore.dispatch({
      type: "remove",
      payload: this.chartObserver
    });
  }
  onShowTooltip(t) {
    const { anchor: e, style: n, shared: r, className: i, crosshair: s } = t;
    let o;
    s || (r ? o = this.createSharedTooltipContext(t) : o = this.createTooltipContext(t), this.setState({
      popupShown: !0,
      popupAlign: e.align,
      popupOffset: e.point,
      popupContext: o,
      popupStyles: n,
      className: i,
      shared: r
    }));
  }
  onHideTooltip() {
    this.setState({
      popupShown: !1,
      popupStyles: {},
      className: void 0
    });
  }
  createSharedTooltipContext(t) {
    const { points: e, categoryText: n } = t, r = e.filter((o) => typeof o.series.name != "undefined").length > 0, i = t.series.length > 1;
    let s = 1;
    return r && s++, i && s++, {
      categoryText: n,
      colorMarker: i,
      colspan: s,
      nameColumn: r,
      points: t.points.map((o) => new c(o, t.format))
    };
  }
  createTooltipContext(t) {
    const { point: e, format: n } = t;
    return { point: new c(e, n) };
  }
  findRenderFunctionByIndex(t) {
    const e = this.context.optionsStore.getState().series;
    return e !== void 0 && Array.isArray(e) && e[t] !== void 0 && e[t].hasOwnProperty("tooltip") && e[t].tooltip.hasOwnProperty("render") ? e[t].tooltip.render : null;
  }
  findRenderFunction() {
    const t = this.context.optionsStore.getState().tooltip;
    return t !== void 0 && t.hasOwnProperty("render") ? t.render : null;
  }
};
a.contextType = y;
let h = a;
export {
  h as SeriesTooltip
};
