import {
    Class,
    defined,
    setDefaultOptions
} from '../../common';

var DEFAULT_QUIET_ZONE_LENGTH = 10;

export var Encoding = (function (Class) {
    function Encoding(options) {
        Class.call(this);
        this.setOptions(options);
        this.initProperties();
    }

    if ( Class ) Encoding.__proto__ = Class;
    Encoding.prototype = Object.create( Class && Class.prototype );
    Encoding.prototype.constructor = Encoding;

    Encoding.prototype.initProperties = function initProperties () { };

    Encoding.prototype.setOptions = function setOptions (options) {
        this.options = Object.assign({}, this.options, options);
        this.quietZoneLength = this.options.addQuietZone ? 2 * this.options.quietZoneLength : 0;
    };

    Encoding.prototype.encode = function encode (value, width, height) {
        var convertedValue = defined(value) ? String(value) : value;

        this.initValue(convertedValue, width, height);

        if (this.options.addQuietZone) {
            this.addQuietZone();
        }

        this.addData();

        if (this.options.addQuietZone) {
            this.addQuietZone();
        }

        return {
            baseUnit: this.baseUnit,
            pattern: this.pattern
        };
    };

    Encoding.prototype.initValue = function initValue () { };

    Encoding.prototype.addQuietZone = function addQuietZone () {
        this.pattern.push(this.options.quietZoneLength || DEFAULT_QUIET_ZONE_LENGTH);
    };

    Encoding.prototype.addData = function addData () { };

    Encoding.prototype.invalidCharacterError = function invalidCharacterError (character) {
        throw new Error(("Character \"" + character + "\" is not valid for symbology " + (this.name) + "."));
    };

    return Encoding;
}(Class));

setDefaultOptions(Encoding, {
    quietZoneLength: DEFAULT_QUIET_ZONE_LENGTH,
    addQuietZone: true,
    addCheckSum: true
});
