import { MIN_MOVING_AVERAGE_PERIOD } from '../constants';

function calculateMovingAverage(sourceValues, valueGetter, period) {
    var averagePoints = [];
    var values = [];
    var start = Math.max(MIN_MOVING_AVERAGE_PERIOD, period) - 1;

    var end = 0;
    var sum = 0;

    for (var i = 0; i < sourceValues.length; i++) {
        var value = sourceValues[i];
        var ref = valueGetter(value);
        var xValue = ref.xValue;
        var yValue = ref.yValue;

        if (isFinite(yValue) && yValue !== null) {
            values.push(yValue);
            sum += yValue;
            end = Math.max(i, end);
        } else {
            values.push(null);
        }

        if (i >= start) {
            var count = values.filter(function (value) { return value !== null; }).length;
            var lastValue = values.shift() || 0;

            if (count > 0) {
                var average = sum / count;
                averagePoints.push([xValue, average]);

                sum -= lastValue;
                continue;
            }
        }

        averagePoints.push([xValue, null]);
    }

    return averagePoints.slice(0, end + 1);
}

export default calculateMovingAverage;
