/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import n from "prop-types";
import { useInternationalization as ue, useLocalization as ce } from "@progress/kendo-react-intl";
import { createPropsContext as de, validatePackage as me, usePropsContext as fe, useId as be, useCustomComponent as _, dispatchEvent as B, getActiveElement as H, useIsomorphicLayoutEffect as pe, classNames as ge, kendoThemeMaps as K, getTabIndex as ve } from "@progress/kendo-react-common";
import { caretAltUpIcon as ye, caretAltDownIcon as Ce } from "@progress/kendo-svg-icons";
import { FloatingLabel as Ve } from "@progress/kendo-react-labels";
import { packageMetadata as ke } from "../package-metadata.mjs";
import { numericIncreaseValue as h, messages as S, numericDecreaseValue as O } from "../messages/index.mjs";
import { getInitialState as U, formatValue as I, getStateOrPropsValue as F, rangeValue as L, sanitizeNumber as $, decreaseValue as W, increaseValue as Y } from "./utils/index.mjs";
import { Button as J } from "@progress/kendo-react-buttons";
const xe = "Please enter a valid value!", Ee = de(), T = r.forwardRef((b, Q) => {
  me(ke);
  const e = fe(Ee, b), X = be(), z = e.id || X, s = ue(), y = ce(), l = r.useRef(null), C = r.useRef(), [Z, p] = r.useState(!1), o = r.useRef(U()), V = r.useRef(!1), P = r.useRef(), g = r.useRef(e.defaultValue), v = I(
    o.current.focused && !e.disabled ? o.current.currentLooseValue : F(e.value, g.current),
    e.format,
    s
  );
  P.current = v;
  const [ee] = _(e.prefix), [te] = _(e.suffix);
  r.useEffect(
    () => {
      l.current && l.current.setCustomValidity && l.current.setCustomValidity(
        E().valid ? "" : e.validationMessage || xe
      );
    }
  );
  const q = r.useCallback(
    () => {
      l.current && l.current.focus();
    },
    []
  ), k = r.useCallback(
    () => C.current !== void 0 ? C.current : F(e.value, g.current),
    [e.value]
  ), G = r.useCallback(
    () => e.name,
    [e.name]
  ), x = r.useCallback(
    () => e.required,
    [e.required]
  ), E = r.useCallback(
    () => {
      const a = e.validationMessage !== void 0, t = k(), m = e.valid !== void 0 ? e.valid : !o.current.valueIsOutOfRange && (!x() || t != null);
      return {
        customError: a,
        valid: m,
        valueMissing: t == null
      };
    },
    [e.validationMessage, e.valid, k, x]
  ), N = r.useCallback(
    () => e.validityStyles,
    [e.validityStyles]
  ), j = r.useCallback(
    () => e,
    [e]
  ), d = r.useCallback(
    () => {
      const a = {
        element: l.current,
        focus: q
      };
      return Object.defineProperty(a, "name", { get: G }), Object.defineProperty(a, "value", { get: k }), Object.defineProperty(a, "validity", { get: E }), Object.defineProperty(a, "validityStyles", { get: N }), Object.defineProperty(a, "required", { get: x }), Object.defineProperty(a, "props", { get: j }), a;
    },
    [G, k, E, N, x, q, j]
  );
  r.useImperativeHandle(Q, d);
  const u = r.useCallback(
    () => ({
      eventValue: F(e.value, g.current),
      prevLooseValue: P.current,
      currentLooseValue: l.current.value,
      selectionStart: l.current.selectionStart,
      selectionEnd: l.current.selectionEnd,
      decimalSelect: !1,
      valueIsCorrected: !1,
      valueIsOutOfRange: !1,
      isPaste: V.current,
      focused: o.current.focused
    }),
    [e.value]
  ), c = r.useCallback(
    (a, t) => {
      if (e.disabled)
        return;
      C.current = t.eventValue, g.current = t.eventValue;
      const m = I(L(t.eventValue, e.min, e.max), e.format, s), i = L(s.parseNumber(m, e.format), e.min, e.max);
      i !== t.eventValue && (t.valueIsOutOfRange = !0, t.eventValue = i, g.current = i), e.value !== t.eventValue && B(
        e.onChange,
        a,
        d(),
        { value: t.eventValue }
      ), C.current = void 0, o.current = t, p((M) => !M);
    },
    [e.value, e.onChange, e.disabled, p, d]
  ), ne = r.useCallback(
    (a) => {
      const t = u();
      V.current = !1, c(a, $(t, e.format, s));
    },
    [e.format, e.onChange, s, c, u]
  ), re = r.useCallback(
    (a) => {
      let t = u();
      const m = s.parseNumber(String(t.currentLooseValue), e.format);
      if (t.selectionEnd > t.selectionStart && t.selectionEnd - t.selectionStart === String(t.currentLooseValue).length) {
        const i = s.numberSymbols(), f = i && a.key === i.minusSign, M = i && a.key === i.decimal;
        V.current = !f && !M;
        return;
      }
      switch (a.keyCode) {
        case 38:
          Y(
            m,
            t,
            e.step,
            e.min,
            e.max,
            e.format,
            s
          );
          break;
        case 40:
          W(
            m,
            t,
            e.step,
            e.min,
            e.max,
            e.format,
            s
          );
          break;
        case 13: {
          if (e.rangeOnEnter === !1)
            return;
          const i = I(L(m, e.min, e.max), e.format, s), f = L(s.parseNumber(i, e.format), e.min, e.max);
          t.eventValue = f, t.currentLooseValue = I(f, e.format, s), t.selectionStart = t.selectionEnd = t.currentLooseValue.length;
          break;
        }
        case 110: {
          const i = l.current, f = s.numberSymbols();
          i && (t.currentLooseValue = t.currentLooseValue.slice(0, t.selectionStart) + f.decimal + t.currentLooseValue.slice(t.selectionEnd), t.selectionStart = t.selectionEnd = t.selectionStart + 1, t = $(t, e.format, s));
          break;
        }
        default:
          return;
      }
      a.preventDefault(), c(a, t);
    },
    [e.format, e.min, e.max, e.step, e.onChange, e.rangeOnEnter, c, u]
  ), ae = r.useCallback(
    () => {
      V.current = !0;
    },
    []
  ), D = r.useCallback(
    (a) => {
      if (e.readOnly || e.disabled)
        return;
      const t = u();
      Y(
        s.parseNumber(String(t.currentLooseValue), e.format),
        t,
        e.step,
        e.min,
        e.max,
        e.format,
        s
      ), c(a, t);
    },
    [e.format, e.min, e.max, e.step, e.onChange, e.readOnly, e.disabled, c, u]
  ), R = r.useCallback(
    (a) => {
      if (e.readOnly || e.disabled)
        return;
      const t = u();
      W(
        s.parseNumber(String(t.currentLooseValue), e.format),
        t,
        e.step,
        e.min,
        e.max,
        e.format,
        s
      ), c(a, t);
    },
    [e.format, e.min, e.max, e.step, e.onChange, e.readOnly, e.disabled, c, u]
  ), le = r.useCallback(
    (a) => {
      const t = H(document);
      !document || t !== l.current || !l.current || e.readOnly || e.disabled || (a.nativeEvent.deltaY < 0 && D(a), a.nativeEvent.deltaY > 0 && R(a));
    },
    [D, R, e.disabled, e.readOnly]
  ), se = r.useCallback(
    (a) => {
      o.current.currentLooseValue = P.current, o.current.focused = !0, B(
        e.onFocus,
        a,
        d(),
        {}
      ), p((t) => !t);
    },
    [e.onFocus, p, d]
  ), oe = r.useCallback(
    (a) => {
      o.current = U(), B(
        e.onBlur,
        a,
        d(),
        {}
      ), p((t) => !t);
    },
    [e.onBlur, p, d]
  ), ie = r.useCallback(
    (a) => {
      if (document && l.current) {
        const t = H(document);
        a.preventDefault(), t !== l.current && l.current.focus();
      }
    },
    []
  );
  pe(
    () => {
      l.current && l.current.type !== "number" && o.current.selectionStart !== void 0 && o.current.selectionEnd !== void 0 && (l.current.selectionStart = o.current.selectionStart, l.current.selectionEnd = o.current.selectionEnd, o.current.selectionStart = void 0, o.current.selectionEnd = void 0);
    },
    [Z]
  );
  const w = !N() || E().valid, A = /* @__PURE__ */ r.createElement(
    "span",
    {
      dir: e.dir,
      style: e.label ? e.style : { width: e.width, ...e.style },
      className: ge(
        "k-input",
        "k-numerictextbox",
        {
          [`k-input-${K.sizeMap[e.size] || e.size}`]: e.size,
          [`k-input-${e.fillMode}`]: e.fillMode,
          [`k-rounded-${K.roundedMap[e.rounded] || e.rounded}`]: e.rounded,
          "k-invalid": !w,
          "k-required": e.required,
          "k-disabled": e.disabled
        },
        e.className
      ),
      "aria-disabled": e.disabled ? "true" : void 0
    },
    /* @__PURE__ */ r.createElement(ee, null),
    /* @__PURE__ */ r.createElement(
      "input",
      {
        role: "spinbutton",
        value: v === null ? "" : v,
        tabIndex: ve(e.tabIndex, e.disabled),
        accessKey: e.accessKey,
        disabled: e.disabled,
        title: e.title,
        "aria-disabled": e.disabled ? "true" : void 0,
        "aria-valuemin": e.min,
        "aria-valuemax": e.max,
        "aria-label": e.ariaLabel,
        "aria-labelledby": e.ariaLabelledBy,
        "aria-describedby": e.ariaDescribedBy,
        placeholder: e.placeholder,
        spellCheck: !1,
        autoComplete: "off",
        autoCorrect: "off",
        type: e.inputType || "tel",
        className: "k-input-inner",
        id: z,
        name: e.name,
        readOnly: e.readOnly,
        style: e.inputStyle,
        onChange: ne,
        onFocus: se,
        onBlur: oe,
        onKeyDown: re,
        onPaste: ae,
        onWheel: le,
        ref: l
      }
    ),
    /* @__PURE__ */ r.createElement(te, null),
    e.children,
    e.spinners && /* @__PURE__ */ r.createElement(
      "span",
      {
        className: "k-input-spinner k-spin-button",
        onMouseDown: ie
      },
      /* @__PURE__ */ r.createElement(
        J,
        {
          tabIndex: -1,
          type: "button",
          icon: "caret-alt-up",
          svgIcon: ye,
          rounded: null,
          className: "k-spinner-increase",
          "aria-label": y.toLanguageString(h, S[h]),
          title: y.toLanguageString(h, S[h]),
          onClick: D
        }
      ),
      /* @__PURE__ */ r.createElement(
        J,
        {
          tabIndex: -1,
          type: "button",
          icon: "caret-alt-down",
          svgIcon: Ce,
          rounded: null,
          className: "k-spinner-decrease",
          "aria-label": y.toLanguageString(O, S[O]),
          title: y.toLanguageString(O, S[O]),
          onClick: R
        }
      )
    )
  );
  return e.label ? /* @__PURE__ */ r.createElement(
    Ve,
    {
      label: e.label,
      editorId: z,
      editorValue: v === null ? "" : v,
      editorValid: w,
      editorDisabled: e.disabled,
      editorPlaceholder: e.placeholder,
      children: A,
      style: { width: e.width },
      dir: e.dir
    }
  ) : A;
});
T.propTypes = {
  value: n.number,
  defaultValue: n.number,
  step: n.number,
  format: n.oneOfType([
    n.string,
    n.shape({
      style: n.oneOf(["decimal", "currency", "percent", "scientific", "accounting"]),
      currency: n.string,
      currencyDisplay: n.oneOf(["symbol", "code", "name"]),
      useGrouping: n.bool,
      minimumIntegerDigits: n.number,
      minimumFractionDigits: n.number,
      maximumFractionDigits: n.number
    })
  ]),
  width: n.oneOfType([
    n.string,
    n.number
  ]),
  tabIndex: n.number,
  accessKey: n.string,
  title: n.string,
  placeholder: n.string,
  min: n.number,
  max: n.number,
  spinners: n.bool,
  disabled: n.bool,
  readOnly: n.bool,
  dir: n.string,
  name: n.string,
  label: n.string,
  validationMessage: n.string,
  required: n.bool,
  id: n.string,
  rangeOnEnter: n.bool,
  ariaLabelledBy: n.string,
  ariaDescribedBy: n.string,
  ariaLabel: n.string,
  onChange: n.func,
  onFocus: n.func,
  onBlur: n.func,
  size: n.oneOf([null, "small", "medium", "large"]),
  rounded: n.oneOf([null, "small", "medium", "large", "full"]),
  fillMode: n.oneOf([null, "solid", "flat", "outline"])
};
T.displayName = "KendoNumericTextBox";
T.defaultProps = {
  prefix: (b) => null,
  suffix: (b) => null,
  step: 1,
  spinners: !0,
  disabled: !1,
  required: !1,
  validityStyles: !0,
  rangeOnEnter: !0,
  onChange: (b) => {
  },
  onFocus: (b) => {
  },
  onBlur: (b) => {
  },
  size: "medium",
  rounded: "medium",
  fillMode: "solid"
};
export {
  T as NumericTextBox,
  Ee as NumericTextBoxPropsContext
};
