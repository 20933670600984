/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
function x(p) {
  const { enabled: m, columns: l = [], tableViewPortWidth: b, scrollLeft: u } = p, c = l.map(() => 1), i = l.map(() => !1);
  if (!m)
    return { colSpans: c, hiddenColumns: i };
  const n = l.map((e) => parseFloat((e.width || "").toString()) || 10);
  let o = 0;
  for (let e = 0; e < l.length; e++) {
    if (l[e].locked)
      continue;
    const f = e < n.length - 1 ? n[e + 1] : 0;
    if (o + n[e] + f < u)
      i[e] = !0, o += n[e];
    else
      break;
  }
  const w = n.reduce((e, f) => e + f, 0);
  o = 0;
  for (let e = l.length - 1; e >= 0; e--)
    if (!l[e].locked)
      if (o + 2 * n[e] < w - b - u)
        i[e] = !0, o += n[e];
      else
        break;
  const t = [...i], h = (e) => e;
  let s = t.lastIndexOf(!1), g = t.some(h), r = t.every(h), d, a;
  for (; g && t.length && (s !== -1 || r); ) {
    for (s < t.length - 1 && (d = r ? t.length : t.length - s - 1, a = t.length - d, a === 0 && (a = d - 1), i[a] = !1, c[a] = d, t.splice(s + 1, d)); t.length && !t[t.length - 1]; )
      t.pop();
    s = t.lastIndexOf(!1), g = t.some(h), r = t.every(h);
  }
  return { colSpans: c, hiddenColumns: i };
}
export {
  x as tableColumnsVirtualization
};
