import { NumericQRDataMode } from './numeric-data-mode';
import { AlphaNumericQRDataMode } from './alpha-numeric-data-mode';
import { ByteQRDataMode } from './byte-data-mode';

var NUMERIC = "numeric";
var ALPHA_NUMERIC = "alphanumeric";
var BYTE = "byte";

export var DataModeInstances = {};
DataModeInstances[NUMERIC] = new NumericQRDataMode();
DataModeInstances[ALPHA_NUMERIC] = new AlphaNumericQRDataMode();
DataModeInstances[BYTE] = new ByteQRDataMode();
