/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as a from "react";
import { BaseChart as o } from "./BaseChart.mjs";
import { Sparkline as n } from "@progress/kendo-charts";
import { classNames as h } from "@progress/kendo-react-common";
class m extends a.Component {
  constructor() {
    super(...arguments), this._baseChart = null, this.deriveOptionsFromParent = (t) => {
      const { type: s, data: e } = this.props, r = Object.assign({}, t, { type: s, data: e });
      return n.normalizeOptions(r);
    }, this.getTarget = () => this;
  }
  /**
   * @hidden
   */
  get chartInstance() {
    return this._baseChart !== null ? this._baseChart.chartInstance : null;
  }
  /**
   * The Drawing `Surface` of the Sparkline.
   */
  get surface() {
    return this._baseChart !== null ? this._baseChart.surface : null;
  }
  /**
   * The DOM element of the Sparkline.
   */
  get element() {
    return this._baseChart !== null ? this._baseChart.element : null;
  }
  /**
   * @hidden
   */
  render() {
    const { children: t, type: s, className: e, ...r } = this.props;
    return /* @__PURE__ */ a.createElement(
      o,
      {
        ...r,
        ref: (i) => this._baseChart = i,
        chartConstructor: n,
        getTarget: this.getTarget,
        wrapper: "span",
        deriveOptionsFromParent: this.deriveOptionsFromParent,
        className: h("k-sparkline k-widget", e)
      },
      t
    );
  }
}
export {
  m as Sparkline
};
