/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const d = 16.666666666666668, g = function(u, s, t = {}) {
  let e, n, l, r, i = 0;
  t = t || {};
  const c = function() {
    i = t.leading === !1 ? 0 : (/* @__PURE__ */ new Date()).getTime(), e = void 0, r = u.apply(n, l), e || (n = l = null);
  };
  return function() {
    const o = (/* @__PURE__ */ new Date()).getTime();
    !i && t.leading === !1 && (i = o);
    const a = s - (o - i);
    return n = void 0, l = arguments, a <= 0 || a > s ? (e && (clearTimeout(e), e = void 0), i = o, r = u.apply(n, l), e || (n = l = null)) : !e && t.trailing !== !1 && (e = window.setTimeout(c, a)), r;
  };
};
export {
  d as FRAME_DURATION,
  g as throttle
};
