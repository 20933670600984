import { Encoding } from './encoding';

var extend = Object.assign;

export var MsiBase = (function (Encoding) {
    function MsiBase () {
        Encoding.apply(this, arguments);
    }

    if ( Encoding ) MsiBase.__proto__ = Encoding;
    MsiBase.prototype = Object.create( Encoding && Encoding.prototype );
    MsiBase.prototype.constructor = MsiBase;

    MsiBase.prototype.initProperties = function initProperties () {
        Encoding.prototype.initProperties.call(this);

        extend(this, {
            characterMap: [
                "12121212",
                "12121221",
                "12122112",
                "12122121",
                "12211212",
                "12211221",
                "12212112",
                "12212121",
                "21121212",
                "21121221"
            ],
            START: "21",
            STOP: "121",
            checkSumType: "",
            checkSums: checkSums
        });
    };

    MsiBase.prototype.initValue = function initValue (value, width) {
        this.pattern = [];
        this.value = value;
        this.checkSumLength = 0;
        this.width = width;
    };

    MsiBase.prototype.setBaseUnit = function setBaseUnit () {
        var startStopLength = 7;

        this.baseUnit = this.width /
            (12 * (this.value.length + this.checkSumLength) + this.quietZoneLength + startStopLength);
    };

    MsiBase.prototype.addData = function addData () {
        var this$1 = this;

        var value = this.value;

        this.addPattern(this.START);

        for (var i = 0; i < value.length; i++) {
            this$1.addCharacter(value.charAt(i));
        }

        if (this.options.addCheckSum) {
            this.addCheckSum();
        }

        this.addPattern(this.STOP);
        this.setBaseUnit();
    };

    MsiBase.prototype.addCharacter = function addCharacter (character) {
        var pattern = this.characterMap[character];

        if (!pattern) {
            this.invalidCharacterError(character);
        }

        this.addPattern(pattern);
    };

    MsiBase.prototype.addPattern = function addPattern (pattern) {
        var this$1 = this;

        for (var i = 0; i < pattern.length; i++) {
            this$1.pattern.push(parseInt(pattern.charAt(i), 10));
        }
    };

    MsiBase.prototype.addCheckSum = function addCheckSum () {
        var this$1 = this;

        var checkSumFunction = this.checkSums[this.checkSumType];
        var checkValues = checkSumFunction.call(this.checkSums, this.value);

        this.checksum = checkValues.join("");

        for (var i = 0; i < checkValues.length; i++) {
            this$1.checkSumLength++;
            this$1.addPattern(this$1.characterMap[checkValues[i]]);
        }
    };

    return MsiBase;
}(Encoding));

export var MsiMod10 = (function (MsiBase) {
    function MsiMod10 () {
        MsiBase.apply(this, arguments);
    }

    if ( MsiBase ) MsiMod10.__proto__ = MsiBase;
    MsiMod10.prototype = Object.create( MsiBase && MsiBase.prototype );
    MsiMod10.prototype.constructor = MsiMod10;

    MsiMod10.prototype.initProperties = function initProperties () {
        MsiBase.prototype.initProperties.call(this);

        extend(this, {
            name: "MSI Modulo10",
            checkSumType: "Modulo10"
        });
    };

    return MsiMod10;
}(MsiBase));

export var MsiMod11 = (function (MsiBase) {
    function MsiMod11 () {
        MsiBase.apply(this, arguments);
    }

    if ( MsiBase ) MsiMod11.__proto__ = MsiBase;
    MsiMod11.prototype = Object.create( MsiBase && MsiBase.prototype );
    MsiMod11.prototype.constructor = MsiMod11;

    MsiMod11.prototype.initProperties = function initProperties () {
        MsiBase.prototype.initProperties.call(this);

        extend(this, {
            name: "MSI Modulo11",
            checkSumType: "Modulo11"
        });
    };

    return MsiMod11;
}(MsiBase));

export var MsiMod1010 = (function (MsiBase) {
    function MsiMod1010 () {
        MsiBase.apply(this, arguments);
    }

    if ( MsiBase ) MsiMod1010.__proto__ = MsiBase;
    MsiMod1010.prototype = Object.create( MsiBase && MsiBase.prototype );
    MsiMod1010.prototype.constructor = MsiMod1010;

    MsiMod1010.prototype.initProperties = function initProperties () {
        MsiBase.prototype.initProperties.call(this);

        extend(this, {
            name: "MSI Modulo10 Modulo10",
            checkSumType: "Modulo10Modulo10"
        });
    };

    return MsiMod1010;
}(MsiBase));

export var MsiMod1110 = (function (MsiBase) {
    function MsiMod1110 () {
        MsiBase.apply(this, arguments);
    }

    if ( MsiBase ) MsiMod1110.__proto__ = MsiBase;
    MsiMod1110.prototype = Object.create( MsiBase && MsiBase.prototype );
    MsiMod1110.prototype.constructor = MsiMod1110;

    MsiMod1110.prototype.initProperties = function initProperties () {
        MsiBase.prototype.initProperties.call(this);

        extend(this, {
            name: "MSI Modulo11 Modulo10",
            checkSumType: "Modulo11Modulo10"
        });
    };

    return MsiMod1110;
}(MsiBase));

var checkSums = {
    Modulo10: function Modulo10(value) {
        var checkValues = [0, ""],
            odd = value.length % 2,
            idx,
            evenSum,
            oddSum;

        for (idx = 0; idx < value.length; idx++) {
            checkValues[(idx + odd) % 2] += parseInt(value.charAt(idx), 10);
        }

        oddSum = checkValues[0];
        evenSum = (checkValues[1] * 2).toString();

        for (idx = 0; idx < evenSum.length; idx++) {
            oddSum += parseInt(evenSum.charAt(idx), 10);
        }

        return [(10 - (oddSum % 10)) % 10];
    },
    Modulo11: function Modulo11(value) {
        var weightedSum = 0,
            mod = 11,
            length = value.length,
            weight,
            checkValue;

        for (var i = 0; i < length; i++) {
            weight = ((length - i) % 6 || 6) + 1;
            weightedSum += weight * value.charAt(i);
        }

        checkValue = (mod - weightedSum % mod) % mod;

        if (checkValue !== 10) {
            return [checkValue];
        }

        return [1, 0];
    },
    Modulo11Modulo10: function Modulo11Modulo10(value) {
        var checkValues = this.Modulo11(value),
            mod11Value;
        mod11Value = value + checkValues[0];

        return checkValues.concat(this.Modulo10(mod11Value));
    },
    Modulo10Modulo10: function Modulo10Modulo10(value) {
        var checkValues = this.Modulo10(value),
            mod10Value;
        mod10Value = value + checkValues[0];

        return checkValues.concat(this.Modulo10(mod10Value));
    }
};
