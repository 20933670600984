/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
class t extends e.Component {
  /**
   * @hidden
   */
  render() {
    return null;
  }
}
export {
  t as GridDetailRow
};
