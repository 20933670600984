/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { classNames as d } from "@progress/kendo-react-common";
import { getItemValue as n } from "./utils.mjs";
class h extends t.Component {
  constructor() {
    super(...arguments), this.handleClick = (e) => this.props.onClick(this.props.index, e);
  }
  render() {
    const {
      selected: e,
      group: s,
      dataItem: a,
      virtual: c,
      groupMode: i,
      disabled: l,
      render: r
    } = this.props, o = /* @__PURE__ */ t.createElement(
      "li",
      {
        id: this.props.id,
        role: "option",
        "aria-selected": e,
        "aria-disabled": l ? !0 : void 0,
        className: d("k-list-item", {
          "k-selected": e,
          "k-focus": this.props.focused,
          "k-first": !!s && i === "classic",
          "k-disabled": l
        }),
        onClick: this.handleClick,
        style: { position: c ? "relative" : "unset" }
      },
      /* @__PURE__ */ t.createElement("span", { className: "k-list-item-text" }, n(a, this.props.textField).toString()),
      s !== void 0 && i === "classic" ? /* @__PURE__ */ t.createElement("div", { className: "k-list-item-group-label" }, s) : null
    );
    return r !== void 0 ? r.call(void 0, o, this.props) : o;
  }
}
export {
  h as default
};
