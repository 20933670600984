/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const A = "data-keyboardnavlevel", a = "data-keyboardnavscope", t = "data-keyboardnavheader", e = "data-keyboardnavbody", o = "data-keyboardnavid", _ = "data-keyboardnavzone", n = "_filter", d = {
  [a]: !0
}, r = {
  [t]: !0
}, b = {
  [e]: !0
};
export {
  e as KEYBOARD_NAV_DATA_BODY,
  t as KEYBOARD_NAV_DATA_HEADER,
  o as KEYBOARD_NAV_DATA_ID,
  A as KEYBOARD_NAV_DATA_LEVEL,
  a as KEYBOARD_NAV_DATA_SCOPE,
  _ as KEYBOARD_NAV_DATA_ZONE,
  n as KEYBOARD_NAV_FILTER_COL_SUFFIX,
  b as tableKeyboardNavigationBodyAttributes,
  r as tableKeyboardNavigationHeaderAttributes,
  d as tableKeyboardNavigationScopeAttributes
};
