import { encodeData } from './encodings/encoding';

var ISO = 'ISO_8859_1';

function qrcodeValidator(encoding) {
    if ( encoding === void 0 ) encoding = ISO;

    return function(value) {
        try {
            encodeData(value, 'L', encoding);
        } catch (error) {
            return {
                valid: false,
                error: error
            };
        }

        return {
            valid: true
        };
    };
}

export default qrcodeValidator;
