/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { FOCUSABLE_ELEMENTS as a, TABBABLE_ELEMENTS as f } from "@progress/kendo-react-common";
import { KEYBOARD_NAV_DATA_LEVEL as u, KEYBOARD_NAV_DATA_ID as i, KEYBOARD_NAV_DATA_SCOPE as E, KEYBOARD_NAV_DATA_HEADER as g, KEYBOARD_NAV_DATA_BODY as d, KEYBOARD_NAV_FILTER_COL_SUFFIX as b } from "./constants.mjs";
const _ = (e, t, r = "cell") => `${t}_${e}_${r}`, N = (e) => {
  if (e)
    return parseInt(e.getAttribute(u) || "", 10);
}, A = (e) => {
  if (!e)
    return;
  const t = e.getAttribute(i);
  return t || void 0;
}, s = (e) => e ? !!e.getAttribute(i) : !1, v = (e, t = { level: 0 }) => e.querySelector(`[${u}='${t.level}']`), I = (e) => e.parentElement && e.parentElement.closest(`[${u}]`), m = (e, t) => e.querySelector(`[${i}='${t}']`), $ = (e) => e.parentElement && e.parentElement.closest(`[${E}]`), D = (e) => e.querySelector(`[${g}]`), B = (e) => e.querySelector(`[${d}]`), p = (e, t = { focusable: !1 }) => {
  let r = t.focusable ? a : f;
  return Array.from(e.querySelectorAll(r.join(",")));
}, x = (e, t = { level: 0 }) => {
  if (!e)
    return [];
  let r = a.map((l) => l + `[${u}='${t.level}']`).join(",");
  return Array.from(e.querySelectorAll(r));
}, y = (e = { level: 0 }) => {
  let t = a.map(
    (r) => r + `[${u}='${e.level}']`
  ).join(",");
  return (r) => r.matches(t);
}, S = (e) => {
  const { elementForFocus: t, event: r, contextStateRef: l, prevElement: o } = e;
  if (l.current && t && t.focus) {
    r.preventDefault();
    const n = t.querySelector(".k-checkbox-wrap .k-checkbox");
    n && n.focus ? n.focus() : t.focus(), s(t) && (t.setAttribute("tabIndex", "0"), l.current.activeId = A(t)), o && s(o) && o.setAttribute("tabIndex", "-1");
  }
}, h = (e) => e.current ? e.current.idPrefix : "", O = (e, t, r, l, o) => {
  if (!r)
    return [];
  let n = e + (o ? -1 : 1);
  for (; n >= 0 && n < l.length; ) {
    const c = l[n][t];
    if (c !== r)
      return [c, [n, t]];
    n = n + (o ? -1 : 1);
  }
  return [];
}, T = (e, t, r, l, o) => {
  if (!r)
    return [];
  let n = t + (o ? -1 : 1);
  for (; n >= 0 && n < l[e].length; ) {
    const c = l[e][n];
    if (c !== r)
      return [c, [e, n]];
    n = n + (o ? -1 : 1);
  }
  return [];
}, C = (e, t) => {
  if (t) {
    for (let r = 0; r < e.length; r++)
      for (let l = 0; l < e[r].length; l++)
        if (e[r][l] === t)
          return [r, l];
  }
}, L = (e) => e.current ? e.current.navigationMatrix.length : 0, F = (e) => e ? `${e}${b}` : "", R = {
  generateNavigatableId: _,
  getNavigatableId: A,
  getNavigatableLevel: N,
  getNavigatableElement: v,
  getClosestNavigatableElement: I,
  getActiveNavDataElement: m,
  getClosestScope: $,
  getHeaderElement: D,
  getBodyElement: B,
  getFocusableElements: p,
  getNavigatableElements: x,
  filterNavigatableElements: y,
  focusElement: S,
  getIdPrefix: h,
  isNavigatable: s,
  findNextIdByRowIndex: O,
  findNextIdByCellIndex: T,
  findId: C,
  getNextNavigationIndex: L,
  getFilterColumnId: F
};
export {
  y as filterNavigatableElements,
  C as findId,
  T as findNextIdByCellIndex,
  O as findNextIdByRowIndex,
  S as focusElement,
  _ as generateNavigatableId,
  m as getActiveNavDataElement,
  B as getBodyElement,
  I as getClosestNavigatableElement,
  $ as getClosestScope,
  F as getFilterColumnId,
  p as getFocusableElements,
  D as getHeaderElement,
  h as getIdPrefix,
  v as getNavigatableElement,
  x as getNavigatableElements,
  A as getNavigatableId,
  N as getNavigatableLevel,
  L as getNextNavigationIndex,
  s as isNavigatable,
  R as tableKeyboardNavigationTools
};
