/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { provideLocalizationService as u, registerForIntl as l, registerForLocalization as c } from "@progress/kendo-react-intl";
import { GridColumnMenuItemGroup as m } from "./GridColumnMenuItemGroup.mjs";
import { GridColumnMenuItem as a } from "./GridColumnMenuItem.mjs";
import { messages as f, groupColumn as g, ungroupColumn as d } from "../messages/index.mjs";
import { groupIcon as C, ungroupIcon as h } from "@progress/kendo-svg-icons";
class s extends t.Component {
  constructor() {
    super(...arguments), this.onGroupClick = (n) => {
      if (n.preventDefault(), this.props.onGroupChange) {
        const { column: o } = this.props;
        if (!o.field)
          return;
        const e = (this.props.group || []).slice(), r = e.findIndex((i) => i.field === o.field);
        r > -1 ? e.splice(r, 1) : e.push({
          field: o.field
        }), this.props.onGroupChange(e, n);
      }
      this.props.onCloseMenu && this.props.onCloseMenu();
    };
  }
  /**
   * @hidden
   */
  render() {
    const { group: n, column: o } = this.props, e = u(this), r = !!(n && o.field && n.find((p) => p.field === o.field)), i = r ? d : g;
    return /* @__PURE__ */ t.createElement(m, null, /* @__PURE__ */ t.createElement(
      a,
      {
        title: e.toLanguageString(i, f[i]),
        iconClass: r ? "k-i-ungroup" : "k-i-group",
        svgIcon: r ? h : C,
        onClick: this.onGroupClick
      }
    ));
  }
}
l(s);
c(s);
export {
  s as GridColumnMenuGroup
};
