import { Encodings } from './encodings/main';

var validate = function (encoding, size, prefix) { return function (value) {
    try {
        encoding.encode(
            prefix + value,
            size.width,
            size.height
        );
    } catch (error) {
        return {
            valid: false,
            error: error
        };
    }

    return {
        valid: true
    };
}; };

// A default size for encodings, so the validator can check only the value if no size is provided.
var fallbackSize = { width: 500, height: 100 };

function barcodeValidator(type, size) {
    if ( size === void 0 ) size = fallbackSize;

    if (!type) {
        throw new Error("Please specify barcode type to validate.");
    }

    var resolvedType = type.toLowerCase();
    var prefix = '';
    if (resolvedType === 'upca') {
        resolvedType = 'ean13';
        prefix = '0';
    } else if (resolvedType === 'upce') {
        resolvedType = 'ean8';
        prefix = '0';
    }

    if (!Encodings[resolvedType]) {
        throw new Error(("Encoding '" + type + "' is not supported."));
    }

    var encoding = new Encodings[resolvedType]();
    return validate(encoding, size, prefix);
}

export default barcodeValidator;
