/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
class u {
  constructor(f) {
    this.columns = [], this.resizable = !1, this.isRtl = !1, this.setIsRtl = (c) => {
      this.isRtl = c;
    }, this.dragHandler = (c, l, e, h) => {
      const t = c.originalEvent;
      h || (t.preventDefault(), t.stopPropagation(), t.stopImmediatePropagation());
      const o = e.closest("th");
      if (!o || !o)
        return;
      const s = o.clientWidth;
      let n = s;
      if (this.isRtl ? n += e.getBoundingClientRect().right - e.offsetWidth / 2 - c.clientX : n += c.clientX - e.getBoundingClientRect().left - e.offsetWidth / 2, !h && Math.abs(n - s) < 1)
        return;
      this.fixateInitialWidths(o.parentElement.clientWidth), this.setWidths(l, Math.floor(n) / s);
      const d = this.columns.filter((i) => !i.children.length).findIndex((i) => i.id === l.id);
      this.onResize(d, s, n, t, h, l.id);
    }, this.dblClickHandler = (c, l) => {
      const e = this.columns.filter((r) => l.indexOf(r.id) > -1);
      if (!this.colGroupMain || !e.length)
        return;
      const h = {}, t = e;
      for (; t.length > 0; ) {
        const r = t.pop();
        if (!r)
          break;
        r.children.length ? t.push(...r.children) : h[r.id] = r;
      }
      const o = this.columns.filter((r) => !r.children.length), s = [];
      o.forEach((r, a) => {
        h[r.id] && s.push(a);
      });
      const n = [this.colGroupMain.parentElement], d = [this.colGroupMain];
      this.colGroupHeader && (n.push(this.colGroupHeader.parentElement), d.push(this.colGroupHeader)), this.colGroupFooter && (n.push(this.colGroupFooter.parentElement), d.push(this.colGroupFooter)), n.forEach((r) => r.classList.add("k-autofitting"));
      let i = [];
      d.forEach((r) => {
        s.forEach((a) => {
          r.children[a] && (r.children[a].width = "", i[a] = Math.max(i[a] || 0, r.children[a].offsetWidth + 1));
        });
      }), d.forEach((r) => {
        s.forEach((a) => {
          r.children[a] && (r.children[a].width = i[a] + "px", o[a].width = i[a]);
        });
      }), n.forEach((r) => r.classList.remove("k-autofitting")), this.onResize(s[0], 0, 0, c, !0, l[0]);
    }, this.updateColElements = (c) => {
      const l = this.columns.filter((h) => !h.children.length);
      let e = 1e-10;
      for (let h = 0; h < c.length; h++) {
        const t = c[h], o = l.findIndex((i) => i.id === t.id), s = parseFloat((t.width || 0).toString()), n = Math.floor(s);
        e += s - n;
        const d = n + Math.floor(e);
        if (e -= Math.floor(e), this.colGroupMain && this.colGroupMain.children[o]) {
          const i = parseInt(this.colGroupMain.children[o].width, 10);
          this.colGroupMain.children[o].width = d + "px", this.updateNextLockedCol(
            this.colGroupMain.parentElement,
            t,
            i - d
          );
        }
        if (this.colGroupHeader && this.colGroupHeader.children[o]) {
          const i = parseInt(this.colGroupHeader.children[o].width, 10);
          this.colGroupHeader.children[o].width = d + "px", this.updateNextLockedCol(
            this.colGroupHeader.parentElement,
            t,
            i - d
          );
        }
        if (this.colGroupFooter && this.colGroupFooter.children[o]) {
          const i = parseInt(this.colGroupFooter.children[o].width, 10);
          this.colGroupFooter.children[o].width = d + "px", this.updateNextLockedCol(
            this.colGroupFooter.parentElement,
            t,
            i - d
          );
        }
      }
    }, this.onResize = f;
  }
  fixateInitialWidths(f) {
    const c = this.columns.filter((t) => !t.children.length);
    let l = 0, e = this.colGroupMain ? this.colGroupMain.children : [];
    for (let t = 0; t < e.length; t++)
      e[t].width ? f -= parseFloat(e[t].width) : l++;
    if (l === 0)
      return;
    const h = Math.floor(f / l);
    for (let t = 0; t < e.length; t++) {
      const o = e[t];
      o.width || (o.width = h, c[t].width = h.toString(), this.colGroupHeader && (this.colGroupHeader.children[t].width = h), this.colGroupFooter && (this.colGroupFooter.children[t].width = h));
    }
  }
  setWidths(f, c) {
    const l = this.columns.findIndex((t) => t.id === f.id), e = [];
    let h = f.children.length;
    for (let t = l + 1; h > 0 && t < this.columns.length; t++, h--) {
      const o = this.columns[t];
      o.children.length ? h += o.children.length : e.push(o);
    }
    return e.length === 0 && e.push(f), e.forEach((t) => {
      let o = t.width ? parseFloat(t.width.toString()) * c : 0;
      const s = t.minResizableWidth === void 0 ? 10 : t.minResizableWidth;
      o < s && (o = s), t.width = o;
    }), this.updateColElements(e), e;
  }
  updateNextLockedCol(f, c, l) {
    const e = c.index, h = this.colGroupMain.parentElement.closest(".k-grid"), t = h == null ? void 0 : h.clientWidth, o = this.columns.filter((s) => s.locked).map((s) => parseInt(s.width + "", 10)).reduce((s, n) => s + n, 0);
    !c.locked || t <= o + 20 || this.columns.forEach((s) => {
      if (s != null && s.locked && l) {
        const n = f == null ? void 0 : f.querySelectorAll('[aria-colindex="' + s.ariaColumnIndex + '"]');
        n == null || n.forEach((d) => {
          const i = d.style;
          this.isRtl ? (s.index > e && i && i.right && (i.right = parseInt(i.right, 10) - l + "px"), s.index < e && i && i.left && (i.left = parseInt(i.left, 10) - l + "px")) : (s.index > e && i && i.left && (i.left = parseInt(i.left, 10) - l + "px"), s.index < e && i && i.right && (i.right = parseInt(i.right, 10) - l + "px"));
        });
      }
    });
  }
}
export {
  u as ColumnResize
};
