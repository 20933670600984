import { default as ChartLegend } from "../chart/legend/legend";
import { SankeyElement } from "./element";
import { setDefaultOptions } from '../common';
import { nodeColor } from "./node";
import { BOTTOM, CENTER, POINTER } from "../common/constants";
import { AREA } from "../chart/constants";

export var Legend = (function (SankeyElement) {
    function Legend () {
        SankeyElement.apply(this, arguments);
    }

    if ( SankeyElement ) Legend.__proto__ = SankeyElement;
    Legend.prototype = Object.create( SankeyElement && SankeyElement.prototype );
    Legend.prototype.constructor = Legend;

    Legend.prototype.getElement = function getElement () {
        var options = this.options;
        var drawingRect = options.drawingRect;
        var nodes = options.nodes; if ( nodes === void 0 ) nodes = [];
        var colors = options.colors; if ( colors === void 0 ) colors = [];

        if (options.visible === false || !nodes.length) {
            return null;
        }

        var data = nodes.map(function (node, index) { return ({
            text: (node.label && node.label.text) || '',
            area: { background: nodeColor(node, colors, index), opacity: node.opacity },
            node: node,
        }); });

        var legend = new ChartLegend(Object.assign({}, options, {data: data}));
        legend.reflow(drawingRect);

        legend.renderVisual();
        return legend.visual;
    };

    Legend.prototype.createElement = function createElement () {
        return this.getElement();
    };

    return Legend;
}(SankeyElement));

setDefaultOptions(Legend, {
    markers: { visible: false },
    item: {
        type: AREA,
        cursor: POINTER,
        opacity: 1
    },
    position: BOTTOM,
    align: CENTER,
    border: {
        width: 0
    }
});
