/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { Popup as s } from "@progress/kendo-react-popup";
class a extends e.Component {
  render() {
    const {
      children: n,
      width: o,
      dir: r,
      itemsCount: t,
      popupSettings: i
    } = this.props;
    return /* @__PURE__ */ e.createElement(
      s,
      {
        style: { width: o, direction: r },
        contentKey: t && t.join(),
        ...i
      },
      n
    );
  }
}
export {
  a as default
};
