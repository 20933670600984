/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { BaseEvent as r } from "./base-event.mjs";
class i extends r {
  /**
   * Constructs the event arguments from a raw object.
   */
  constructor(t, o) {
    super(o), this.from = t.from, this.to = t.to;
  }
}
export {
  i as NavigatorFilterEvent
};
