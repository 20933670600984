/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import { ChartContext as i } from "../../ChartContext.mjs";
const p = class p extends s.Component {
  constructor(t, r) {
    super(t, r), this.optionsStore = r.optionsStore;
  }
  renderChildren(t, r) {
    const { children: o } = t.props, { _chartKey: c, _parentStore: e } = this.props, n = {
      ...t.props,
      _chartCollectionIdxKey: `${c}_${r}`,
      _parentStore: e
    };
    return s.cloneElement(t, n, o);
  }
  render() {
    const { _chartKey: t, _parentStore: r, children: o } = this.props;
    return (r || this.optionsStore).dispatch({
      chartKey: t,
      payload: []
    }), s.Children.map(o, (e, n) => s.isValidElement(e) ? this.renderChildren(e, n) : e);
  }
};
p.contextType = i;
let a = p;
export {
  a as CollectionConfigurationComponent
};
