/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as n from "react";
import { useDraggable as i } from "./hooks/useDraggable.mjs";
const m = n.forwardRef((e, c) => {
  const l = n.useRef(null), r = n.useRef(null), t = n.useCallback(
    () => l.current && l.current.element ? l.current.element : l.current,
    [l]
  );
  n.useImperativeHandle(r, () => ({ element: t() || null })), n.useImperativeHandle(c, () => r.current), n.useImperativeHandle(e.childRef, () => l.current);
  const u = n.useCallback(
    (a) => {
      e.onPress && e.onPress({
        element: t(),
        target: r.current,
        event: a
      });
    },
    [t, e.onPress]
  ), g = n.useCallback(
    (a) => {
      e.onRelease && e.onRelease({
        element: t(),
        target: r.current,
        event: a
      });
    },
    [t, e.onRelease]
  ), o = n.useCallback(
    (a) => {
      e.onDragStart && e.onDragStart({
        element: t(),
        target: r.current,
        event: a
      });
    },
    [e, t]
  ), s = n.useCallback(
    (a) => {
      e.onDrag && e.onDrag({
        element: t(),
        target: r.current,
        event: a
      });
    },
    [t, e.onDrag]
  ), d = n.useCallback(
    (a) => {
      e.onDragEnd && e.onDragEnd({
        element: t(),
        target: r.current,
        event: a
      });
    },
    [t, e.onDragEnd]
  );
  return i(l, {
    onPress: u,
    onRelease: g,
    onDragStart: o,
    onDrag: s,
    onDragEnd: d
  }, {
    mouseOnly: e.mouseOnly,
    autoScroll: e.autoScroll,
    hint: e.hint,
    scrollContainer: e.scrollContainer
  }), e.children ? n.cloneElement(n.Children.only(e.children), { ref: l }) : null;
});
m.displayName = "KendoReactDraggable";
export {
  m as Draggable
};
