function getTrendlineData(valueMapper, categoryAxis) {
    var data = [];
    var totalRange = categoryAxis.totalRangeIndices();
    var currentRange = categoryAxis.currentRangeIndices();
    var range = {
        min: Math.floor(Math.max(currentRange.min - 1, totalRange.min)),
        max: Math.ceil(Math.min(currentRange.max + 2, totalRange.max))
    };

    for (var i = range.min; i < range.max; i++) {
        var x = (i + 1);
        data[i] = {
            category: categoryAxis.categoryAt(i, true),
            value: valueMapper(x)
        };
    }

    return data;
}

export default getTrendlineData;
