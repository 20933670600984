/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { BaseChart as h } from "./BaseChart.mjs";
import { DonutCenter as i } from "./DonutCenter.mjs";
import { Chart as l } from "@progress/kendo-charts";
import { classNames as u } from "@progress/kendo-react-common";
class f extends t.Component {
  constructor() {
    super(...arguments), this._baseChart = null, this.getTarget = () => this;
  }
  /**
   * @hidden
   */
  get chartInstance() {
    return this._baseChart !== null ? this._baseChart.chartInstance : null;
  }
  /**
   * The Drawing `Surface` of the Chart.
   */
  get surface() {
    return this._baseChart !== null ? this._baseChart.surface : null;
  }
  /**
   * The DOM element of the Chart.
   */
  get element() {
    return this._baseChart !== null ? this._baseChart.element : null;
  }
  /**
   * @hidden
   */
  render() {
    const { donutCenterRender: e, children: r, className: a, ...s } = this.props;
    return /* @__PURE__ */ t.createElement(
      h,
      {
        ...s,
        ref: (n) => this._baseChart = n,
        chartConstructor: l,
        getTarget: this.getTarget,
        wrapper: "div",
        className: u("k-chart k-widget", a)
      },
      r,
      /* @__PURE__ */ t.createElement(i, { render: e })
    );
  }
}
export {
  f as Chart
};
