/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { classNames as n } from "@progress/kendo-react-common";
import { getItemValue as a } from "./utils.mjs";
const r = (e) => e.preventDefault();
class p extends t.Component {
  render() {
    const {
      selected: s,
      defaultItem: o,
      textField: l
    } = this.props;
    return /* @__PURE__ */ t.createElement(
      "div",
      {
        onClick: this.props.onClick,
        onMouseDown: r,
        style: { position: "unset" },
        className: n("k-list-optionlabel", {
          "k-selected": s
        })
      },
      a(o, l) || ""
    );
  }
}
export {
  p as default
};
