import { MIN_MOVING_AVERAGE_PERIOD } from '../constants';
import calculateMovingAverage from './calculate-moving-average';

function movingAverageTrendline(context) {
    var options = context.options;

    var data = getData(context);
    if (data) {
        return Object.assign({}, options,

            {type: 'line',
            data: data,
            categoryField: 'category',
            field: 'value'});
    }

    return null;
}

var valueGetter = function (fieldName) { return function (ref) {
        var categoryIx = ref.categoryIx;
        var valueFields = ref.valueFields;

        return ({ xValue: categoryIx, yValue: valueFields[fieldName] });
; }    };

function calculatePoints(ref) {
    var options = ref.options;
    var categoryAxis = ref.categoryAxis;
    var seriesValues = ref.seriesValues;

    var period = (options.trendline || {}).period || MIN_MOVING_AVERAGE_PERIOD;
    var totalRange = categoryAxis.totalRangeIndices();
    var currentRange = categoryAxis.currentRangeIndices();
    var range = {
        min: Math.floor(Math.max(currentRange.min - period, totalRange.min)),
        max: Math.ceil(Math.min(currentRange.max + period + 2, totalRange.max))
    };

    return calculateMovingAverage(seriesValues(range), valueGetter(options.field), period);
}

function getData(context) {
    var categoryAxis = context.categoryAxis;
    var points = calculatePoints(context);
    var data = [];
    points.forEach(function (ref) {
        var categoryIx = ref[0];
        var value = ref[1];

        data[categoryIx] = {
            category: categoryAxis.categoryAt(categoryIx, true),
            value: value
        };
    });

    if (data.length > 0) {
        return data;
    }

    return null;
}

export default movingAverageTrendline;
