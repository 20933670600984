import { QRDataMode } from './qr-data-mode';
import { extend } from '../../utils';
import {
    toBitsString,
    splitInto
} from '../../utils';

export var NumericQRDataMode = (function (QRDataMode) {
    function NumericQRDataMode () {
        QRDataMode.apply(this, arguments);
    }

    if ( QRDataMode ) NumericQRDataMode.__proto__ = QRDataMode;
    NumericQRDataMode.prototype = Object.create( QRDataMode && QRDataMode.prototype );
    NumericQRDataMode.prototype.constructor = NumericQRDataMode;

    NumericQRDataMode.prototype.initProperties = function initProperties () {
        QRDataMode.prototype.initProperties.call(this);

        extend(this, {
            bitsInCharacterCount: [10, 12, 14],
            modeIndicator: "0001"
        });
    };

    NumericQRDataMode.prototype.getValue = function getValue (character) {
        return parseInt(character, 10);
    };

    NumericQRDataMode.prototype.encode = function encode (str, version) {
        var mode = this,
            parts = splitInto(str, 3),
            result = mode.getModeCountString(str.length, version);
        var i;

        for (i = 0; i < parts.length - 1; i++) {
            result += toBitsString(parts[i], 10);
        }

        return result + toBitsString(parts[i], 1 + 3 * parts[i].length);
    };

    NumericQRDataMode.prototype.getStringBitsLength = function getStringBitsLength (inputLength, version) {
        var mod3 = inputLength % 3;
        return 4 + this.getBitsCharacterCount(version) + 10 * Math.floor(inputLength / 3) + 3 * mod3 + (mod3 === 0 ? 0 : 1);
    };

    return NumericQRDataMode;
}(QRDataMode));
