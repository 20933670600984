/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { generateNavigatableId as o } from "../../navigation/utils.mjs";
import { ColumnDefaultProps as g } from "../ColumnProps.mjs";
function x(t, r) {
  const d = t[r].depth;
  let i = null;
  for (let h = r + 1; h < t.length; h++)
    if (t[h].depth === d) {
      i = t[h];
      break;
    }
  return i;
}
const f = (t) => {
  let r = t.width ? parseFloat(t.width.toString()) : 0;
  return !r && t.children && t.children.length && (r = t.children.reduce((d, i) => f(i) + d, 0)), r;
};
function y(t) {
  const r = [[]];
  let d = 0;
  for (let e = t.length - 1; e >= 0; e--)
    d = Math.max(d, t[e].depth), t[e].colSpan = t[e].colSpan || 1, t[e].parentIndex !== -1 && (t[t[e].parentIndex].colSpan = (t[t[e].parentIndex].colSpan || 0) + t[e].colSpan);
  const i = [];
  let h = 1;
  return t.forEach((e, n) => {
    r[e.depth] = r[e.depth] || [];
    let l = !1;
    r[e.depth].length === 0 && (h <= 1 ? h = 1 + (e.children.length > 0 ? 0 : d - e.depth) : (h--, l = !0)), e.rowSpan = 1 + (e.children.length > 0 ? 0 : d - e.depth), e.kFirst = l, e.index = r[e.depth].length, r[e.depth].push(n), e.ariaColumnIndex = i[e.depth] ? i[e.depth] + 1 : 1;
    for (let a = e.depth; a < e.depth + e.rowSpan; a++)
      i[a] = (i[a] || 0) + e.colSpan;
  }), c(r, t), s(r, t), r;
}
function c(t, r, d) {
  let i = new Array(t.length).fill(0), h = 0;
  r.forEach((e) => {
    if (e.locked && (!e.left || d))
      if (e.left = i[e.depth], h = f(e), e.children.length === 0)
        for (let n = e.depth; n < i.length; n++)
          i[n] += h;
      else
        i[e.depth] += h;
  });
}
function s(t, r, d) {
  let i = 0, h = new Array(t.length).fill(0);
  for (let e = r.length - 1; e >= 0; e--) {
    const n = r[e];
    if (n.locked && (!n.right || d)) {
      if (n.right = h[n.depth], i = f(n), n.children.length === 0)
        for (let a = n.depth; a < h.length; a++)
          h[a] += i;
      else
        h[n.depth] += i;
      const l = x(r, e);
      n.rightBorder = !(l && l.locked);
    }
  }
}
function w(t, r, d = 0) {
  let i = [];
  if (!t || !t.length)
    return [];
  t.forEach((e) => {
    const n = w(e.children || [], r, d + 1);
    i.push(
      Object.assign(
        {},
        { depth: d },
        g,
        e,
        {
          id: e.id ? e.id : o(`${r.prevId++}`, r.idPrefix, "column"),
          declarationIndex: i.length,
          children: n,
          rowSpan: 0,
          colSpan: 0,
          isAccessible: !0
        }
      )
    );
  });
  const h = (e, n) => e.orderIndex === n.orderIndex ? e.declarationIndex - n.declarationIndex : (e.orderIndex || 0) - (n.orderIndex || 0);
  if (i.sort(h), d === 0) {
    let e = [];
    const n = (l, a) => l.forEach((p) => {
      p.parentIndex = a, n(p.children, e.push(p) - 1);
    });
    return n(i, -1), e;
  }
  return i;
}
function C(t) {
  return !!(t && getComputedStyle(t).direction === "rtl");
}
function E(t, r) {
  if (!r || !t || !t.originalEvent)
    return -1;
  const d = { x: t.clientX, y: t.originalEvent.clientY };
  let i = r.ownerDocument ? r.ownerDocument.elementFromPoint(d.x, d.y) : null;
  for (; i && i.parentElement !== r; )
    i = i.parentElement;
  if (!i && r.matches("tr")) {
    const e = (n) => {
      const l = n.getBoundingClientRect();
      return d.x > l.left && d.x < l.left + l.width && d.y > l.top && d.y < l.top + l.height;
    };
    i = Array.from(r.children).find(e) || null;
  }
  const h = r.children;
  for (let e = 0; e < h.length; e++)
    if (h[e] === i)
      return e;
  return -1;
}
export {
  E as getIndex,
  C as isRtl,
  y as mapColumns,
  x as nextColumn,
  w as readColumns,
  c as updateLeft,
  s as updateRight
};
