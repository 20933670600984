/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
class s {
  /**
   * @hidden
   */
  constructor(t, n) {
    this.sender = t, this.syntheticEvent = n;
  }
}
function o(e, t) {
  return new s(e, t);
}
export {
  s as DomEvent,
  o as toDomEvent
};
