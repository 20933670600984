/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import o from "prop-types";
import { classNames as k } from "../classNames.mjs";
import { SIZE_CLASSES as v } from "./constants.mjs";
import { toIconClass as N } from "./utils.mjs";
import { useMouse as b } from "../hooks/useMouse.mjs";
const u = e.forwardRef((a, d) => {
  const {
    className: n,
    name: s,
    themeColor: t,
    size: i,
    flip: m,
    style: h,
    id: g,
    tabIndex: x,
    ...y
  } = a, l = e.useRef(null), c = e.useRef(null);
  e.useImperativeHandle(l, () => ({
    element: c.current
  })), e.useImperativeHandle(d, () => l.current);
  const f = e.useMemo(
    () => i || p.size,
    [i]
  ), r = e.useMemo(
    () => m || p.flip,
    [m]
  ), z = e.useMemo(
    () => k(
      "k-icon",
      "k-font-icon",
      s && N(s),
      {
        [`k-color-${t}`]: t,
        "k-flip-h": r === "horizontal" || r === "both",
        "k-flip-v": r === "vertical" || r === "both"
      },
      v[f],
      n
    ),
    [s, t, f, r, n]
  ), I = b(a, l);
  return /* @__PURE__ */ e.createElement(
    "span",
    {
      ref: c,
      ...y,
      ...I,
      className: z,
      id: g,
      tabIndex: x,
      style: h,
      role: "presentation"
    }
  );
});
u.propTypes = {
  style: o.object,
  classNames: o.string,
  name: o.string,
  themeColor: o.oneOf([
    "inherit",
    "primary",
    "secondary",
    "tertiary",
    "info",
    "success",
    "error",
    "warning",
    "dark",
    "light",
    "inverse"
  ]),
  size: o.oneOf(["default", "xsmall", "small", "medium", "large", "xlarge", "xxlarge", "xxxlarge"]),
  flip: o.oneOf(["default", "horizontal", "vertical", "both"])
};
const p = {
  size: "default",
  flip: "default"
};
u.displayName = "KendoIcon";
export {
  u as Icon
};
