import FunnelChart from './funnel-chart';

var MAX_NECK_RATIO = 1e6;

var PyramidChart = (function (FunnelChart) {
    function PyramidChart(plotArea, options) {
        options.dynamicSlope = false;
        options.neckRatio = MAX_NECK_RATIO;

        FunnelChart.call(this, plotArea, options);
    }

    if ( FunnelChart ) PyramidChart.__proto__ = FunnelChart;
    PyramidChart.prototype = Object.create( FunnelChart && FunnelChart.prototype );
    PyramidChart.prototype.constructor = PyramidChart;

    return PyramidChart;
}(FunnelChart));

export default PyramidChart;
