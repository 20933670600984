/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import { Draggable as r } from "@progress/kendo-react-common";
class n extends s.Component {
  constructor() {
    super(...arguments), this.draggable = null, this.isDragged = !1, this.onDrag = (e) => {
      this.isDragged = !0;
      const t = this.draggable && this.draggable.element;
      t && this.props.resize(e.event, t, !1);
    }, this.onRelease = (e) => {
      if (!this.isDragged)
        return;
      const t = this.draggable && this.draggable.element;
      t && this.props.resize(e.event, t, !0), this.isDragged = !1;
    }, this.onDoubleClick = (e) => {
      if (this.isDragged)
        return;
      const t = this.draggable && this.draggable.element;
      t && this.props.autofit && this.props.autofit(e, t);
    };
  }
  render() {
    return /* @__PURE__ */ s.createElement(
      r,
      {
        onPress: (e) => {
          e.event.originalEvent.stopPropagation();
        },
        onDrag: this.onDrag,
        onRelease: this.onRelease,
        ref: (e) => {
          this.draggable = e;
        }
      },
      /* @__PURE__ */ s.createElement(
        "span",
        {
          className: "k-column-resizer",
          style: { touchAction: "none" },
          draggable: !1,
          onDoubleClick: this.onDoubleClick
        }
      )
    );
  }
}
export {
  n as ColumnResizer
};
