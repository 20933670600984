/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const n = (...e) => Object.assign({ allowUnsort: !0, mode: "single" }, ...e);
export {
  n as normalize
};
