/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const f = (s) => {
  s.style.transitionDuration = "", Array.from(s.classList).forEach((i) => {
    i.startsWith("k-slide-") && s.classList.remove(i);
  });
}, w = typeof window != "undefined" && /Firefox/.test(window.navigator.userAgent), v = (s, i, n, r, o) => {
  if (n === 0)
    return o();
  const a = "k-slide-" + i + "-" + r, u = a + "-active", e = (l) => {
    l.target === s && (s.removeEventListener("transitionend", e), f(s), r === "exit" ? s.style.display = "none" : s.style.display = "", o());
  };
  s.addEventListener("transitionend", e);
  const d = s.ownerDocument;
  if (!d)
    return;
  const t = d.defaultView;
  if (!t)
    return;
  const c = () => {
    f(s), s.classList.add(a), t.requestAnimationFrame(() => {
      s.style.transitionDuration = n + "ms", s.classList.add(u);
    });
  };
  w ? t.requestAnimationFrame(c) : c();
};
export {
  v as slide
};
