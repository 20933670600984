/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const x = {
  default: "",
  xsmall: "k-icon-xs",
  small: "k-icon-sm",
  medium: "k-icon-md",
  large: "k-icon-lg",
  xlarge: "k-icon-xl",
  xxlarge: "k-icon-xxl",
  xxxlarge: "k-icon-xxxl"
};
export {
  x as SIZE_CLASSES
};
