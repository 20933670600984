/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const c = (t) => {
  const e = t.shadowRoot, n = t.contentDocument;
  return e && e.activeElement ? c(e.activeElement) : n && n.activeElement ? c(n.activeElement) : t;
}, o = (t) => {
  if (!(!t || !t.activeElement))
    return c(t.activeElement);
};
export {
  o as getActiveElement,
  c as getInnerActiveElement
};
