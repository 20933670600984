import { Class } from '../../../common';
import { extend } from '../../utils';
import {
    toBitsString
} from '../../utils';

export var QRDataMode = (function (Class) {
    function QRDataMode() {
        Class.call(this);

        this.initProperties();
    }

    if ( Class ) QRDataMode.__proto__ = Class;
    QRDataMode.prototype = Object.create( Class && Class.prototype );
    QRDataMode.prototype.constructor = QRDataMode;

    QRDataMode.prototype.initProperties = function initProperties () {
        extend(this, {
            modeIndicator: "",
            bitsInCharacterCount: []
        });
    };

    QRDataMode.prototype.getVersionIndex = function getVersionIndex (version) {
        if (version < 10) {
            return 0;
        } else if (version > 26) {
            return 2;
        }

        return 1;
    };

    QRDataMode.prototype.getBitsCharacterCount = function getBitsCharacterCount (version) {
        return this.bitsInCharacterCount[this.getVersionIndex(version || 40)];
    };

    QRDataMode.prototype.getModeCountString = function getModeCountString (length, version) {
        return this.modeIndicator + toBitsString(length, this.getBitsCharacterCount(version));
    };

    QRDataMode.prototype.encode = function encode () { };

    QRDataMode.prototype.getStringBitsLength = function getStringBitsLength () { };

    QRDataMode.prototype.getValue = function getValue () { };

    return QRDataMode;
}(Class));
