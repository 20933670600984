import { ShapeElement } from '../../core';
import { deepExtend, setDefaultOptions } from '../../common';
import { CROSS, LEFT, TOP, WHITE } from '../../common/constants';
import { valueOrDefault } from '../../drawing-utils';

export var DEFAULT_MARKER_SIZE = 10;
export var DEFAULT_MARKER_BORDER_WIDTH = 2;

var LegendItemMarker = (function (ShapeElement) {
    function LegendItemMarker () {
        ShapeElement.apply(this, arguments);
    }

    if ( ShapeElement ) LegendItemMarker.__proto__ = ShapeElement;
    LegendItemMarker.prototype = Object.create( ShapeElement && ShapeElement.prototype );
    LegendItemMarker.prototype.constructor = LegendItemMarker;

    LegendItemMarker.prototype.visualStyle = function visualStyle () {
        var options = this.markerOptions();
        var border = options.border;

        return {
            stroke: {
                width: border.width,
                color: border.color,
                opacity: valueOrDefault(border.opacity, options.opacity),
                dashType: border.dashType
            },
            fill: {
                color: options.background,
                opacity: options.opacity
            },
            cursor: options.cursor
        };
    };

    LegendItemMarker.prototype.markerOptions = function markerOptions () {
        return this.options;
    };

    LegendItemMarker.prototype.markerHighlightOptions = function markerHighlightOptions () {
        var options = this.markerOptions();
        var borderWidth = options.highlight.border.width;

        return deepExtend(
            {},
            options,
            { background: options.border.color },
            options.highlight,
            options.type === CROSS ? {
                background: options.highlight.border.color,
                border: { color: options.highlight.background, width: borderWidth / 2 },
                width: options.width,
                height: options.height,
                margin: { top: 0, left: 0 }
            } : {}
        );
    };

    LegendItemMarker.prototype.createHighlight = function createHighlight () {
        var highlight = new ShapeElement(this.markerHighlightOptions());
        var box = this.paddingBox.clone();
        highlight.reflow(box.pad(highlight.options.border.width));
        this.highlight = [ highlight.getElement() ];

        return this.highlight;
    };

    return LegendItemMarker;
}(ShapeElement));

setDefaultOptions(LegendItemMarker, {
    border: {
        width: DEFAULT_MARKER_BORDER_WIDTH
    },
    width: DEFAULT_MARKER_SIZE - DEFAULT_MARKER_BORDER_WIDTH,
    height: DEFAULT_MARKER_SIZE - DEFAULT_MARKER_BORDER_WIDTH,
    background: WHITE,
    margin: {
        top: -1,
        left: -1
    },
    vAlign: TOP,
    align: LEFT,
    highlight: {
        width: DEFAULT_MARKER_SIZE,
        height: DEFAULT_MARKER_SIZE,
        border: {
            color: WHITE,
            width: DEFAULT_MARKER_BORDER_WIDTH
        }
    }
});

export default LegendItemMarker;
