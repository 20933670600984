/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { getDate as M, isEqual as v, cloneDate as m, addMonths as f, lastDayOfMonth as y, createDate as P } from "@progress/kendo-date-math";
import { Mask as x } from "./mask.mjs";
import { dateSymbolMap as D } from "../utils.mjs";
class k {
  constructor(t, e, s) {
    this.year = !0, this.month = !0, this.date = !0, this.hours = !0, this.minutes = !0, this.seconds = !0, this.milliseconds = !0, this.leadingZero = null, this.typedMonthPart = "", this.knownParts = "adHhmMsEy", this.symbols = {
      E: "E",
      H: "H",
      M: "M",
      a: "a",
      d: "d",
      h: "h",
      m: "m",
      s: "s",
      y: "y"
    }, this._value = M(/* @__PURE__ */ new Date()), this.intlProvider = t, this.formatPlaceholder = e, this.format = s, this.monthNames = this.allFormatedMonths();
  }
  get intl() {
    return this.intlProvider();
  }
  get value() {
    return this._value;
  }
  setValue(t) {
    t ? v(t, this._value) || (this._value = m(t), this.modifyExisting(!0)) : (this._value = M(/* @__PURE__ */ new Date()), this.modifyExisting(!1));
  }
  hasValue() {
    const t = (e, s) => e || s.type !== "literal" && s.type !== "dayperiod" && this.getExisting(s.pattern[0]);
    return this.intl.splitDateFormat(this.format).reduce(t, !1);
  }
  getDateObject() {
    for (let t = 0; t < this.knownParts.length; t++)
      if (!this.getExisting(this.knownParts[t]))
        return null;
    return m(this.value);
  }
  getTextAndFormat() {
    return this.merge(
      this.intl.formatDate(this.value, this.format),
      this.dateFormatString(this.value, this.format)
    );
  }
  modifyExisting(t) {
    const e = this.dateFormatString(this.value, this.format).symbols;
    for (let s = 0; s < e.length; s++)
      this.setExisting(e[s], t);
  }
  getExisting(t) {
    switch (t) {
      case "y":
        return this.year;
      case "M":
      case "L":
        return this.month;
      case "d":
        return this.date;
      case "E":
        return this.date && this.month && this.year;
      case "h":
      case "H":
        return this.hours;
      case "m":
        return this.minutes;
      case "s":
        return this.seconds;
      default:
        return !0;
    }
  }
  setExisting(t, e) {
    switch (t) {
      case "y":
        this.year = e, e === !1 && this._value.setFullYear(2e3);
        break;
      case "M":
        this.month = e, e === !1 && this._value.setMonth(0);
        break;
      case "d":
        this.date = e;
        break;
      case "h":
      case "H":
        this.hours = e;
        break;
      case "m":
        this.minutes = e;
        break;
      case "s":
        this.seconds = e;
        break;
      default:
        return;
    }
  }
  modifyPart(t, e) {
    let s = m(this.value);
    switch (t) {
      case "y":
        s.setFullYear(s.getFullYear() + e);
        break;
      case "M":
        s = f(this.value, e);
        break;
      case "d":
      case "E":
        s.setDate(s.getDate() + e);
        break;
      case "h":
      case "H":
        s.setHours(s.getHours() + e);
        break;
      case "m":
        s.setMinutes(s.getMinutes() + e);
        break;
      case "s":
        s.setSeconds(s.getSeconds() + e);
        break;
      case "a":
        s.setHours(s.getHours() + 12 * e);
        break;
    }
    this.setExisting(t, !0), this._value = s;
  }
  parsePart(t, e) {
    if (this.resetLeadingZero(), !e)
      return this.setExisting(t, !1), { value: null };
    const s = this.intl.formatDate(this.value, this.format), r = this.dateFormatString(this.value, this.format), n = r.symbols;
    let i = !1, h = "", l = "", d = "";
    for (let o = 0; o < s.length; o++)
      n[o] === t ? (l += this.getExisting(t) ? s[o] : "0", i = !0) : i ? d += s[o] : h += s[o];
    let a = null;
    const g = this.matchMonth(e);
    for (; l.length > 0 && l.charAt(0) === "0"; )
      l = l.slice(1);
    l.length >= 4 && (l = "");
    for (let o = 0; o < 2; o++) {
      let c = l + e, p = parseInt(c, 10);
      if (a = this.intl.parseDate(h + c + d, this.format), !a && !isNaN(p) && !isNaN(parseInt(e, 10))) {
        if (t === "M" && !g) {
          const u = p - 1;
          u > -1 && u < 12 && (a = m(this.value), a.setMonth(u), a.getMonth() !== u && (a = y(f(a, -1))));
        }
        t === "y" && (a = P(
          parseInt(c, 10),
          this.month ? this.value.getMonth() : 0,
          this.date ? this.value.getDate() : 1,
          this.hours ? this.value.getHours() : 0,
          this.minutes ? this.value.getMinutes() : 0,
          this.seconds ? this.value.getSeconds() : 0,
          this.milliseconds ? this.value.getMilliseconds() : 0
        ), this.date && a.getDate() !== this.value.getDate() && (a = y(f(a, -1))));
      }
      if (a)
        return this._value = a, this.setExisting(t, !0), { value: this.value };
      l = "";
    }
    return g && (a = this.intl.parseDate(h + g + d, this.format), a) ? (this._value = a, this.setExisting(t, !0), { value: this.value }) : (e === "0" && (this.leadingZero = this.isAbbrMonth(r.partMap, t) ? null : { [t]: !0 }, this.setExisting(t, !1)), { value: null });
  }
  symbolMap(t) {
    return this.intl.splitDateFormat(this.format).reduce(D, {})[t];
  }
  resetLeadingZero() {
    const t = this.leadingZero !== null;
    return this.leadingZero = null, t;
  }
  isAbbrMonth(t, e) {
    const s = this.partPattern(t, e);
    return s.type === "month" && s.names;
  }
  partPattern(t, e) {
    return t.filter((s) => s.pattern.indexOf(e) !== -1)[0];
  }
  matchMonth(t) {
    if (this.typedMonthPart += t.toLowerCase(), this.monthNames.length === 0)
      return "";
    for (; this.typedMonthPart.length > 0; ) {
      for (let s = 0; s < this.monthNames.length; s++)
        if (this.monthNames[s].toLowerCase().indexOf(this.typedMonthPart) === 0)
          return this.monthNames[s];
      const e = parseInt(this.typedMonthPart, 10);
      if (e >= 1 && e <= 12 && e.toString() === this.typedMonthPart)
        return this.monthNames[e - 1];
      this.typedMonthPart = this.typedMonthPart.substring(1, this.typedMonthPart.length);
    }
    return "";
  }
  allFormatedMonths() {
    const t = this.intl.splitDateFormat(this.format);
    for (let e = 0; e < t.length; e++)
      if (t[e].type === "month" && t[e].names)
        return this.intl.dateFormatNames(t[e].names);
    return [];
  }
  dateFormatString(t, e) {
    const s = this.intl.splitDateFormat(e), r = [], n = [];
    for (let h = 0; h < s.length; h++) {
      let l = this.intl.formatDate(t, { pattern: s[h].pattern }).length;
      for (; l > 0; )
        r.push(this.symbols[s[h].pattern[0]] || "_"), n.push(s[h]), l--;
    }
    const i = new x();
    return i.symbols = r.join(""), i.partMap = n, i;
  }
  merge(t, e) {
    let s = "", r = "", n = e.symbols;
    for (let i = n.length - 1; i >= 0; i--)
      if (this.knownParts.indexOf(n[i]) === -1 || this.getExisting(n[i]))
        s = t[i] + s, r = n[i] + r;
      else {
        const h = n[i];
        for (; i >= 0 && h === n[i]; )
          i--;
        for (i++, this.leadingZero && this.leadingZero[h] ? s = "0" + s : s = this.dateFieldName(e.partMap[i]) + s; r.length < s.length; )
          r = n[i] + r;
      }
    return { text: s, format: r };
  }
  dateFieldName(t) {
    const e = this.formatPlaceholder || "wide";
    return e[t.type] ? e[t.type] : e === "formatPattern" ? t.pattern : this.intl.dateFieldName(Object.assign(t, { nameType: e }));
  }
}
export {
  k as KendoDate
};
