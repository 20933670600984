/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { TextBox as S, InputPrefix as N, Checkbox as C } from "@progress/kendo-react-inputs";
import { IconWrap as x } from "@progress/kendo-react-common";
import { searchIcon as v } from "@progress/kendo-svg-icons";
import { Button as d } from "@progress/kendo-react-buttons";
const y = (l) => {
  const [o, k] = e.useState(""), m = e.useMemo(() => new Set(l.columnsState.map((t) => t.id)), [l.columnsState]), h = e.useMemo(() => l.columns.filter((t) => {
    var n;
    return (n = t.title || t.field) == null ? void 0 : n.toLowerCase().includes(o.toLowerCase());
  }), [l.columns, o]), [a, i] = e.useState(l.columns.map((t) => m.has(t.id))), f = e.useCallback(() => {
    const t = l.columns.filter((n, c) => a[c]);
    l.onColumnsChange.call(void 0, t), l.onCloseMenu.call(void 0);
  }, [a, l.columns, l.onColumnsChange, l.onCloseMenu]), E = e.useCallback(() => {
    i(l.columns.map((t) => m.has(t.id)));
  }, [m, l.columns]), r = a.filter((t) => t).length, s = r === a.length, b = e.useCallback(() => {
    i(l.columns.map((t, n) => s && n === 0 ? !0 : !s));
  }, [l.columns, s]), g = e.useCallback((t, n) => {
    const c = a.slice();
    c[n] = t, i(c);
  }, [a]);
  return /* @__PURE__ */ e.createElement("form", { className: "k-filter-menu" }, /* @__PURE__ */ e.createElement("div", { className: "k-filter-menu-container" }, /* @__PURE__ */ e.createElement(
    S,
    {
      className: "k-searchbox",
      value: o,
      onChange: (t) => k(String(t.target.value)),
      prefix: () => /* @__PURE__ */ e.createElement(N, null, /* @__PURE__ */ e.createElement(x, { name: "search", icon: v }))
    }
  ), /* @__PURE__ */ e.createElement("ul", { className: "k-reset k-multicheck-wrap" }, !o && /* @__PURE__ */ e.createElement("li", { className: "k-item k-check-all-wrap" }, /* @__PURE__ */ e.createElement(
    C,
    {
      checked: s,
      onChange: b,
      label: "(Select all)"
    }
  )), h.map((t, n) => /* @__PURE__ */ e.createElement("li", { key: t.id, className: "k-item" }, /* @__PURE__ */ e.createElement(
    C,
    {
      disabled: a[n] && r === 1,
      checked: a[n],
      onChange: (c) => {
        var u;
        return g(!!((u = c.target.element) != null && u.checked), n);
      },
      label: t.title || t.field
    }
  )))), /* @__PURE__ */ e.createElement("div", { className: "k-filter-selected-items" }, r, " selected items"), /* @__PURE__ */ e.createElement("div", { className: "k-actions k-actions-start k-actions-horizontal" }, /* @__PURE__ */ e.createElement(d, { type: "button", themeColor: "primary", onClick: f }, "Apply"), /* @__PURE__ */ e.createElement(d, { type: "button", onClick: E }, "Reset"))));
};
y.displayName = "GridColumnMenuColumnsList";
export {
  y as GridColumnMenuColumnsList
};
