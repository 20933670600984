function xValueLimits(sourceValues, valueGetter) {
    var xMin = Number.MAX_VALUE;
    var xMax = Number.MIN_VALUE;

    for (var i = 0; i < sourceValues.length; i++) {
        var value = sourceValues[i];
        var ref = valueGetter(value);
        var xValue = ref.xValue;
        var yValue = ref.yValue;

        if (isFinite(xValue) && xValue !== null && isFinite(yValue) && yValue !== null) {
            xMin = Math.min(xValue, xMin);
            xMax = Math.max(xValue, xMax);
        }
    }

    return { xMin: xMin, xMax: xMax };
}

export default xValueLimits;
