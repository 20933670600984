/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const c = (n) => ({
  x: {
    location: n.pageX
  },
  y: {
    location: n.pageY
  }
}), g = (n, t) => {
  const e = n.pointers, s = e[0], i = s.pageX, a = s.pageY;
  let r = 0;
  if (e.length > 1) {
    const h = e[0], o = e[1];
    r = Math.sqrt(Math.pow(h.pageX - o.pageX, 2) + Math.pow(h.pageY - o.pageY, 2));
  }
  return {
    distance: r,
    event: n.srcEvent,
    preventDefault: function() {
      n.preventDefault();
    },
    target: n.target,
    touches: e.map(c),
    type: n.type,
    x: {
      delta: t ? i - t.x.location : 0,
      initialDelta: n.deltaX,
      location: i,
      startLocation: i - n.deltaX
    },
    y: {
      delta: t ? a - t.y.location : 0,
      initialDelta: n.deltaY,
      location: a,
      startLocation: a - n.deltaY
    }
  };
};
function d(n, t) {
  for (let e = 0; e < n.length; e++)
    if (t[n[e]])
      return !0;
  return !1;
}
const p = [{
  end: "panend",
  move: "panmove",
  start: "panstart"
}, {
  gesturechange: "pinchmove",
  gestureend: "pinchend",
  gesturestart: "pinchstart"
}, {
  press: "press"
}, {
  tap: "tap"
}];
class l {
  constructor(t, e) {
    this.hammerInstance = t, this.eventHandlers = {}, this.tap = this.tap.bind(this), this.press = this.press.bind(this), this.panstart = this.panstart.bind(this), this.panmove = this.panmove.bind(this), this.panend = this.panend.bind(this), this.pinchstart = this.pinchstart.bind(this), this.pinchmove = this.pinchmove.bind(this), this.pinchend = this.pinchend.bind(this), e && this.bind(e);
  }
  tap(t) {
    this.trigger("tap", t);
  }
  press(t) {
    this.trigger("press", t);
  }
  panstart(t) {
    delete this.previous, this.previous = this.trigger("start", t);
  }
  panmove(t) {
    this.previous = this.trigger("move", t);
  }
  panend(t) {
    this.trigger("end", t), delete this.previous;
  }
  pinchstart(t) {
    this.trigger("gesturestart", t);
  }
  pinchmove(t) {
    this.trigger("gesturechange", t);
  }
  pinchend(t) {
    this.trigger("gestureend", t);
  }
  trigger(t, e) {
    const s = g(e, this.previous);
    return this.eventHandlers[t] && this.eventHandlers[t](s), s;
  }
  bind(t = {}) {
    this.unbind(), this.eventHandlers = t;
    for (let e = 0; e < p.length; e++) {
      const s = p[e], i = Object.keys(s);
      if (d(i, t))
        for (let a = 0; a < i.length; a++) {
          const r = s[i[a]];
          this.hammerInstance.on(r, this[r]);
        }
    }
  }
  unbind() {
    this.hammerInstance && this.hammerInstance.off(), this.eventHandlers = {};
  }
  destroy() {
    this.hammerInstance && (this.hammerInstance.destroy(), delete this.hammerInstance), delete this.eventHandlers;
  }
  toggleDrag(t) {
    this.toggle("pan", t);
  }
  toggleZoom(t) {
    this.toggle("pinch", t);
  }
  toggle(t, e) {
    this.hammerInstance && this.hammerInstance.get(t).set({
      enable: e
    });
  }
}
export {
  l as DomEvents
};
