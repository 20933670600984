/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import p from "../../store/store.mjs";
import { optionsReducer as c } from "../../store/reducer.mjs";
import { ChartContext as h } from "../../ChartContext.mjs";
const s = class s extends r.Component {
  constructor(t, e) {
    super(t, e), this.optionsStore = e.optionsStore, this.childStore = p(c);
  }
  render() {
    const { children: t } = this.props;
    return t !== void 0 ? r.Children.map(t, (e) => r.isValidElement(e) ? this.renderChildren(e) : e) : null;
  }
  componentDidMount() {
    this.dispatch();
  }
  componentDidUpdate() {
    this.dispatch();
  }
  dispatch() {
    const { _chartKey: t, _chartCollectionIdxKey: e, _parentStore: o, children: a, ...n } = this.props;
    (o || this.optionsStore).dispatch({
      chartKey: t,
      chartCollectionIdxKey: e,
      payload: Object.assign({}, n, this.childStore.getState())
    });
  }
  renderChildren(t) {
    const { children: e } = t.props, o = {
      ...t.props,
      _parentStore: this.childStore
    };
    return r.cloneElement(t, o, e);
  }
};
s.contextType = h;
let i = s;
export {
  i as ConfigurationComponent
};
