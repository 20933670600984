/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import { Container as u } from "../Container.mjs";
import { CrosshairTooltip as a } from "./Crosshair.mjs";
import { ChartContext as h } from "../ChartContext.mjs";
const l = ["categoryAxis", "valueAxis", "xAxis", "yAxis"];
function x(n) {
  const t = {};
  for (let o = 0; o < l.length; o++) {
    const s = m(n, l[o]);
    for (let e = 0; e < s.length; e++) {
      const i = s[e];
      t[i.name + i.index] = i;
    }
  }
  return t;
}
function m(n, t) {
  const o = [];
  if (n[t]) {
    const s = [].concat(n[t]);
    for (let e = 0; e < s.length; e++) {
      const i = (s[e].crosshair || {}).tooltip;
      i && i.visible && o.push({
        index: e,
        name: t
      });
    }
  }
  return o;
}
const c = class c extends r.Component {
  constructor() {
    super(...arguments), this.context = null, this.state = {
      tooltips: {}
    }, this.storeUnsubscriber = Function.prototype, this.subscriber = () => {
      var t;
      this.setState({
        tooltips: x((t = this.context) == null ? void 0 : t.optionsStore.getState())
      });
    };
  }
  componentDidMount() {
    this.storeUnsubscriber = this.context.optionsStore.subscribe(this.subscriber);
  }
  render() {
    const { tooltips: t } = this.state, o = Object.keys(t).map((s) => /* @__PURE__ */ r.createElement(a, { ...t[s], key: s }));
    return /* @__PURE__ */ r.createElement(u, null, o);
  }
  componentWillUnmount() {
    this.storeUnsubscriber();
  }
};
c.contextType = h;
let p = c;
export {
  p as CrosshairTooltipContainer
};
