/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import s from "prop-types";
import { validatePackage as T, useCustomComponent as m, useAsyncFocusBlur as z, classNames as S, kendoThemeMaps as v } from "@progress/kendo-react-common";
import { packageMetadata as O } from "../package-metadata.mjs";
const c = e.forwardRef((n, g) => {
  T(O);
  const u = e.useRef(null), r = e.useRef(null), l = e.useRef(), k = e.useCallback(
    () => {
      var t;
      return l.current !== void 0 ? l.current : (t = u.current) == null ? void 0 : t.value;
    },
    []
  );
  e.useImperativeHandle(
    r,
    () => ({
      get element() {
        return u.current;
      },
      get name() {
        return u.current && u.current.name;
      },
      get value() {
        return k();
      }
    })
  ), e.useImperativeHandle(
    g,
    () => r.current
  );
  const {
    size: a,
    fillMode: d,
    rounded: o,
    className: x,
    dir: h,
    style: p,
    prefix: C = i.prefix,
    suffix: y = i.suffix,
    valid: $,
    // Destruct to avoid warning when used inside a form field
    modified: q,
    touched: H,
    visited: I,
    ...B
  } = n, [E] = m(C), [F] = m(y), [M, f] = e.useState(!1), R = (t) => {
    f(!0);
  }, _ = (t) => {
    f(!1);
  }, b = e.useCallback(
    (t) => {
      l.current = t.target.value, n.onChange && n.onChange.call(void 0, {
        syntheticEvent: t,
        nativeEvent: t.nativeEvent,
        value: t.target.value,
        target: r.current
      }), l.current = void 0;
    },
    [n.onChange]
  ), { onFocus: N, onBlur: P } = z({
    onFocus: R,
    onBlur: _,
    onSyncFocus: n.onFocus,
    onSyncBlur: n.onBlur
  });
  return /* @__PURE__ */ e.createElement(
    "span",
    {
      style: p,
      dir: h,
      className: S(
        "k-textbox k-input",
        {
          [`k-input-${v.sizeMap[a] || a}`]: a,
          [`k-input-${d}`]: d,
          [`k-rounded-${v.roundedMap[o] || o}`]: o
        },
        {
          "k-focus": M,
          "k-required": n.required,
          "k-disabled": n.disabled,
          "k-invalid": n.valid === !1
        },
        x
      ),
      onFocus: N,
      onBlur: P
    },
    /* @__PURE__ */ e.createElement(E, null),
    /* @__PURE__ */ e.createElement(
      "input",
      {
        ref: u,
        className: "k-input-inner",
        ...B,
        onChange: b
      }
    ),
    /* @__PURE__ */ e.createElement(F, null)
  );
}), i = {
  prefix: (n) => null,
  suffix: (n) => null,
  size: "medium",
  rounded: "medium",
  fillMode: "solid"
};
c.propTypes = {
  size: s.oneOf([null, "small", "medium", "large"]),
  rounded: s.oneOf([null, "small", "medium", "large", "full"]),
  fillMode: s.oneOf([null, "solid", "flat", "outline"])
};
c.displayName = "KendoReactTextBoxComponent";
c.defaultProps = i;
export {
  c as TextBox
};
