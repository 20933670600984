/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as n from "react";
import { InstanceObserver as h } from "@progress/kendo-charts";
import { toDomEvent as l } from "../events/dom-event.mjs";
import { hasParent as m } from "../utils/index.mjs";
import { Popup as u } from "@progress/kendo-react-popup";
import { ChartContext as f } from "../ChartContext.mjs";
const v = { horizontal: "fit", vertical: "fit" }, d = "k-chart-tooltip", C = "k-chart-tooltip-wrapper", r = class r extends n.Component {
  constructor(s, o) {
    super(s, o), this.context = null, this.element = null, this.onChartMouseLeave = (e) => {
      const { syntheticEvent: t } = e;
      return !!m(t.relatedTarget, this.element);
    }, this.onMouseLeave = (e) => {
      const t = l(this, e);
      this.context.childrenObserver.trigger("onMouseLeave", t) && e.preventDefault();
    }, this.popupRef = (e) => {
      this.element = e;
      const t = e && e.closest(".k-animation-container");
      t && (t.style.transition = "initial");
    }, this.chartObserver = new h(this, {
      onMouseLeave: "onChartMouseLeave"
    }), o.observersStore.dispatch({
      type: "add",
      payload: this.chartObserver
    });
  }
  render() {
    const { popupShown: s, popupAlign: o, popupOffset: e, popupStyles: t, popupContent: a, className: p } = this.props, c = [d, p].join(" ").trim();
    return /* @__PURE__ */ n.createElement(
      u,
      {
        animate: !1,
        popupAlign: o,
        offset: e,
        show: s,
        collision: v,
        className: C
      },
      /* @__PURE__ */ n.createElement(
        "div",
        {
          className: c,
          style: t,
          onMouseLeave: this.onMouseLeave,
          ref: this.popupRef
        },
        a()
      )
    );
  }
  componentWillUnmount() {
    this.context.observersStore.dispatch({
      type: "remove",
      payload: this.chartObserver
    });
  }
};
r.contextType = f;
let i = r;
export {
  i as TooltipPopup
};
