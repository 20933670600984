/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { BrowserSupportService as t } from "./browser-support.service.mjs";
import { canUseDOM as e } from "./canUseDOM.mjs";
const c = () => !e || !document.body ? !1 : new t().scrollbarWidth, s = () => {
  if (!e || !document.body)
    return !1;
  let r = new t().scrollbarWidth;
  document.body.style.setProperty("--kendo-scrollbar-width", `${r}px`);
};
export {
  c as getScrollbarWidth,
  s as setScrollbarWidth
};
