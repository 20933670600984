import { deepExtend } from '../common';

var max = function (array, mapFn) { return Math.max.apply(null, array.map(mapFn)); };
var min = function (array, mapFn) { return Math.min.apply(null, array.map(mapFn)); };
var sum = function (array, mapFn) { return array.map(mapFn).reduce(function (acc, curr) { return (acc + curr); }, 0); };
var sortAsc = function (a, b) { return a.y0 - b.y0; };
var sortSource = function (a, b) { return sortAsc(a.source, b.source); };
var sortTarget = function (a, b) { return sortAsc(a.target, b.target); };
var value = function (node) { return node.value; };

function sortLinks(nodes) {
    nodes.forEach(function (node) {
        var sourceLinks = node.sourceLinks;
        var targetLinks = node.targetLinks;
        sourceLinks.sort(sortTarget);
        targetLinks.sort(sortSource);
    });
}

var Sankey = function Sankey(options) {
    var offset = options.nodesOptions.offset || {};
    this.data = {
        nodes: options.nodes.map(function (node) { return deepExtend({}, { offset: offset }, node); }),
        links: options.links.map(function (link) { return deepExtend({}, link); })
    };

    this.width = options.width;
    this.height = options.height;
    this.offsetX = options.offsetX || 0;
    this.offsetY = options.offsetY || 0;
    this.nodeWidth = options.nodesOptions.width;
    this.nodePadding = options.nodesOptions.padding;
};

Sankey.prototype.calculate = function calculate () {
    var ref = this.data;
        var nodes = ref.nodes;
        var links = ref.links;
    this.connectLinksToNodes(nodes, links);
    this.calculateNodeValues(nodes);
    this.calculateNodeDepths(nodes);

    var columns = this.calculateNodeColumns(nodes);
    this.calculateNodeBreadths(columns);
    this.applyNodesOffset(nodes);
    this.calculateLinkBreadths(nodes);

    return this.data;
};

Sankey.prototype.connectLinksToNodes = function connectLinksToNodes (nodes, links) {
    var nodesMap = new Map();

    nodes.forEach(function (node) {
        node.sourceLinks = [];
        node.targetLinks = [];
        node.id = node.id !== undefined ? node.id : node.label.text;
        nodesMap.set(node.id, node);
    });

    links.forEach(function (link) {
        link.source = nodesMap.get(link.sourceId);
        link.target = nodesMap.get(link.targetId);
        link.source.sourceLinks.push(link);
        link.target.targetLinks.push(link);
    });
};

Sankey.prototype.calculateNodeValues = function calculateNodeValues (nodes) {
    nodes.forEach(function (node) {
        node.value = Math.max(
            sum(node.sourceLinks, value),
            sum(node.targetLinks, value)
        );
    });
};

Sankey.prototype.calculateNodeDepths = function calculateNodeDepths (nodes) {
    var current = new Set(nodes);
    var next = new Set();
    var currDepth = 0;
    while (current.size) {
        var currentNodes = Array.from(current);
        for (var n = 0; n < currentNodes.length; n++) {
            var node = currentNodes[n];
            node.depth = currDepth;
            for (var l = 0; l < node.sourceLinks.length; l++) {
                var link = node.sourceLinks[l];
                next.add(link.target);
            }
        }
        currDepth++;
        current = next;
        next = new Set();
    }
};

Sankey.prototype.calculateNodeColumns = function calculateNodeColumns (nodes) {
        var this$1 = this;

    var maxDepth = max(nodes, function (d) { return d.depth; });
    var columnWidth = (this.width - this.offsetX - this.nodeWidth) / maxDepth;
    var columns = new Array(maxDepth + 1);
    for (var i = 0; i < nodes.length; i++) {
        var node = nodes[i];
        var depth = Math.max(0, Math.min(maxDepth, node.sourceLinks.length ? node.depth : maxDepth));
        node.x0 = this$1.offsetX + depth * columnWidth;
        node.x1 = node.x0 + this$1.nodeWidth;
        columns[depth] = columns[depth] || [];
        columns[depth].push(node);
    }

    return columns;
};

Sankey.prototype.calculateNodeBreadths = function calculateNodeBreadths (columns) {
        var this$1 = this;

    var kSize = min(columns, function (c) { return (this$1.height - this$1.offsetY - (c.length - 1) * this$1.nodePadding) / sum(c, value); });

    for (var c = 0; c < columns.length; c++) {
        var nodes = columns[c];
        var y = this$1.offsetY;
        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            node.y0 = y;
            node.y1 = y + node.value * kSize;
            y = node.y1 + this$1.nodePadding;
            for (var l = 0; l < node.sourceLinks.length; l++) {
                var link = node.sourceLinks[l];
                link.width = link.value * kSize;
            }
        }

        y = (this$1.height - y + this$1.nodePadding) / (nodes.length + 1);
        for (var i$1 = 0; i$1 < nodes.length; ++i$1) {
            var node$1 = nodes[i$1];
            node$1.y0 += y * (i$1 + 1);
            node$1.y1 += y * (i$1 + 1);
        }
    }

    for (var c$1 = 0; c$1 < columns.length; c$1++) {
        sortLinks(columns[c$1]);
    }
};

Sankey.prototype.applyNodesOffset = function applyNodesOffset (nodes) {
    nodes.forEach(function (node) {
        var offsetX = (node.offset ? node.offset.left : 0) || 0;
        var offsetY = (node.offset ? node.offset.top : 0) || 0;
        node.x0 += offsetX;
        node.x1 += offsetX;
        node.y0 += offsetY;
        node.y1 += offsetY;
    });
};

Sankey.prototype.calculateLinkBreadths = function calculateLinkBreadths (nodes) {
    nodes.forEach(function (node) {
        var sourceLinks = node.sourceLinks;
            var targetLinks = node.targetLinks;
        var y = node.y0;
        var y1 = y;
        sourceLinks.forEach(function (link) {
            link.y0 = y + link.width / 2;
            y += link.width;
        });
        targetLinks.forEach(function (link) {
            link.y1 = y1 + link.width / 2;
            y1 += link.width;
        });
    });
};

export var calculateSankey = function (options) { return new Sankey(options).calculate(); };
