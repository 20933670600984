/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { getScrollbarWidth as o, classNames as s, kendoThemeMaps as i } from "@progress/kendo-react-common";
import { isRtl as h, tableKeyboardNavigationHeaderAttributes as a } from "@progress/kendo-react-data-tools";
class d extends t.Component {
  constructor() {
    super(...arguments), this.headerWrap = null, this.table = null, this.syncScroll = !1, this.scrollbarWidth = o(), this.onScroll = (e) => {
      if (this.syncScroll) {
        this.syncScroll = !1;
        return;
      }
      if (!this.headerWrap)
        return;
      const l = this.headerWrap.scrollLeft, r = this.props.scrollableDataElement();
      r && r.scrollLeft !== l && (r.scrollLeft = l);
    };
  }
  get element() {
    return this.props.elemRef.current;
  }
  componentDidMount() {
    const e = h(this.element);
    this.props.columnResize && this.props.columnResize.setIsRtl(e), this.forceUpdate();
  }
  /**
   *
   * @param scrollLeft - Scrolls to the left (in pixels).
   */
  setScrollLeft(e) {
    this.headerWrap && this.headerWrap.scrollLeft !== e && (this.syncScroll = !0, this.headerWrap.scrollLeft = e);
  }
  /**
   *
   * @param width - Scrolls the width (in pixels).
   */
  setWidth(e) {
    this.table && (this.table.style.width = e + "px");
  }
  render() {
    return this.props.staticHeaders ? /* @__PURE__ */ t.createElement(
      "div",
      {
        ref: this.props.elemRef,
        className: s(
          "k-grid-header",
          { "k-grid-draggable-header": this.props.draggable }
        ),
        role: "presentation"
      },
      /* @__PURE__ */ t.createElement(
        "div",
        {
          ref: (e) => {
            this.headerWrap = e;
          },
          className: "k-grid-header-wrap",
          style: this.scrollbarWidth ? {} : { borderWidth: 0 },
          onScroll: this.onScroll,
          role: "presentation"
        },
        /* @__PURE__ */ t.createElement(
          "table",
          {
            ref: (e) => {
              this.table = e;
            },
            className: s(
              "k-table k-grid-header-table",
              {
                "k-table-md": !this.props.size,
                [`k-table-${i.sizeMap[this.props.size] || this.props.size}`]: this.props.size
              },
              this.props.className
            ),
            role: "presentation"
          },
          /* @__PURE__ */ t.createElement("colgroup", { ref: (e) => {
            this.props.columnResize.colGroupHeader = e;
          } }, this.props.cols),
          /* @__PURE__ */ t.createElement("thead", { className: "k-table-thead", role: "rowgroup", ...a }, this.props.headerRow, this.props.filterRow)
        )
      )
    ) : /* @__PURE__ */ t.createElement(
      "thead",
      {
        role: "presentation",
        className: s(
          "k-table-thead",
          { "k-grid-draggable-header": this.props.draggable }
        ),
        ...a
      },
      this.props.headerRow,
      this.props.filterRow
    );
  }
}
export {
  d as Header
};
