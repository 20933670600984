/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as a from "react";
import { getNestedValue as f } from "../utils/index.mjs";
import { NumericTextBox as b } from "@progress/kendo-react-inputs";
import { DatePicker as r } from "@progress/kendo-react-dateinputs";
import { classNames as s, useId as N } from "@progress/kendo-react-common";
import { useTableKeyboardNavigation as S } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE as x } from "../constants/index.mjs";
const R = (e) => {
  const c = f(e.field, e.dataItem);
  let d = null;
  const i = s("k-table-td", "k-grid-edit-cell", {
    "k-selected": e.isSelected
  }, e.className), C = N(), m = S(e.id);
  let l = null, n = null;
  const I = a.useCallback(
    (t) => {
      if (e.onChange) {
        const h = t.target.type === "checkbox" ? t.target.checked : t.target.value;
        e.onChange({
          dataItem: e.dataItem,
          dataIndex: e.dataIndex,
          field: e.field,
          syntheticEvent: t,
          value: h
        });
      }
    },
    [e.onChange, e.dataItem, e.dataIndex, e.field]
  ), u = a.useCallback((t) => {
    e.onContextMenu && e.onContextMenu.call(void 0, t, e.dataItem, e.field);
  }, [e.onContextMenu, e.dataItem, e.field]), k = a.useCallback(
    (t) => {
      e.onChange && e.onChange({
        dataItem: e.dataItem,
        dataIndex: e.dataIndex,
        field: e.field,
        syntheticEvent: t.syntheticEvent,
        value: t.value
      });
    },
    [e.onChange, e.dataItem, e.dataIndex, e.field]
  );
  switch (e.editor) {
    case "numeric": {
      l = {
        onContextMenu: u,
        colSpan: e.colSpan,
        style: e.style,
        className: i,
        "aria-colindex": e.ariaColumnIndex,
        "aria-selected": e.isSelected,
        [x]: e.columnIndex,
        role: "gridcell",
        ...m
      }, n = /* @__PURE__ */ a.createElement(
        b,
        {
          width: "100%",
          value: c === void 0 ? null : c,
          onChange: k
        }
      ), d = /* @__PURE__ */ a.createElement(
        "td",
        {
          ...l
        },
        n
      );
      break;
    }
    case "date":
      l = {
        onContextMenu: u,
        colSpan: e.colSpan,
        style: e.style,
        className: i,
        "aria-colindex": e.ariaColumnIndex,
        "aria-selected": e.isSelected,
        [x]: e.columnIndex,
        role: "gridcell",
        ...m
      }, n = /* @__PURE__ */ a.createElement(
        r,
        {
          width: "100%",
          value: c,
          onChange: k
        }
      ), d = /* @__PURE__ */ a.createElement(
        "td",
        {
          ...l
        },
        n
      );
      break;
    case "boolean":
      l = {
        onContextMenu: u,
        colSpan: e.colSpan,
        style: e.style,
        className: i,
        "aria-colindex": e.ariaColumnIndex,
        "aria-selected": e.isSelected,
        [x]: e.columnIndex,
        role: "gridcell",
        ...m
      }, n = [
        /* @__PURE__ */ a.createElement("span", { key: 1, className: "k-checkbox-wrap" }, /* @__PURE__ */ a.createElement(
          "input",
          {
            checked: c || !1,
            id: C,
            type: "checkbox",
            className: "k-checkbox k-checkbox-md k-rounded-md",
            onChange: I
          }
        )),
        /* @__PURE__ */ a.createElement("label", { className: "k-checkbox-label", htmlFor: C, key: 2 })
      ], d = /* @__PURE__ */ a.createElement(
        "td",
        {
          ...l
        },
        n
      );
      break;
    default:
      l = {
        onContextMenu: u,
        colSpan: e.colSpan,
        style: e.style,
        className: i,
        "aria-colindex": e.ariaColumnIndex,
        "aria-selected": e.isSelected,
        [x]: e.columnIndex,
        role: "gridcell",
        ...m
      }, n = /* @__PURE__ */ a.createElement(
        "input",
        {
          style: { width: "100%" },
          className: "k-input",
          value: c || "",
          onChange: I
        }
      ), d = /* @__PURE__ */ a.createElement(
        "td",
        {
          ...l
        },
        n
      );
  }
  const g = e.editor || "text", o = e.cells;
  if (o && o.edit && o.edit[g]) {
    const t = o.edit[g];
    return /* @__PURE__ */ a.createElement(t, { ...e, tdProps: l }, n);
  }
  return e.render ? e.render.call(void 0, d, e) : d;
};
export {
  R as GridEditCell
};
