/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
const l = (r) => /* @__PURE__ */ e.createElement("td", { className: "k-table-td k-hierarchy-cell", role: "gridcell" });
export {
  l as GridDetailHierarchyCell
};
