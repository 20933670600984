import {
    Class
} from '../../common';

import {
    proxy,
    now
} from '../utils';

var extend = Object.assign;

function animationFrame(callback) {
    window.requestAnimationFrame(callback);
}

export var Animation = (function (Class) {
    function Animation() {
        Class.call(this);
        var that = this;

        that._tickProxy = proxy(that._tick, that);
        that._started = false;
    }

    if ( Class ) Animation.__proto__ = Class;
    Animation.prototype = Object.create( Class && Class.prototype );
    Animation.prototype.constructor = Animation;

    Animation.prototype.tick = function tick () { };
    Animation.prototype.done = function done () { };
    Animation.prototype.onEnd = function onEnd () { };
    Animation.prototype.onCancel = function onCancel () { };

    Animation.prototype.start = function start () {
        if (!this.enabled()) {
            return;
        }

        if (!this.done()) {
            this._started = true;
            animationFrame(this._tickProxy);
        } else {
            this.onEnd();
        }
    };

    Animation.prototype.enabled = function enabled () {
        return true;
    };

    Animation.prototype.cancel = function cancel () {
        this._started = false;
        this.onCancel();
    };

    Animation.prototype._tick = function _tick () {
        var that = this;

        if (!that._started) {
            return;
        }

        that.tick();

        if (!that.done()) {
            animationFrame(that._tickProxy);
        } else {
            that._started = false;
            that.onEnd();
        }
    };

    return Animation;
}(Class));

export var Transition = (function (Animation) {
    function Transition(options) {
        Animation.call(this);
        extend(this, options);
    }

    if ( Animation ) Transition.__proto__ = Animation;
    Transition.prototype = Object.create( Animation && Animation.prototype );
    Transition.prototype.constructor = Transition;

    Transition.prototype.done = function done () {
        return this.timePassed() >= this.duration;
    };

    Transition.prototype.timePassed = function timePassed () {
        return Math.min(this.duration, now() - this.startDate);
    };

    Transition.prototype.moveTo = function moveTo (options) {
        var that = this,
            movable = that.movable;

        that.initial = movable[that.axis];
        that.delta = options.location - that.initial;
        that.duration = typeof options.duration === 'number' ? options.duration : 300;
        that.tick = that._easeProxy(options.ease);
        that.startDate = now();
        that.start();
    };

    Transition.prototype._easeProxy = function _easeProxy (ease) {
        var that = this;

        return function() {
            that.movable.moveAxis(that.axis, ease(that.timePassed(), that.initial, that.delta, that.duration));
        };
    };

    Transition.easeOutExpo = function easeOutExpo (t, b, c, d) {
        return t === d ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b;
    };

    return Transition;
}(Animation));
