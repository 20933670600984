/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as i from "react";
function u(t, n, o) {
  const [e, c] = i.useState(n);
  return i.useEffect(
    () => {
      if (!e && window && t.current) {
        const r = window.getComputedStyle(t.current).direction;
        r && c(r);
      }
    },
    o
  ), e;
}
export {
  u as useDir
};
