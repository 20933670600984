/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
const t = e.createContext(0), n = () => e.useContext(t);
t.displayName = "KendoReactZIndexContext";
export {
  t as ZIndexContext,
  n as useZIndexContext
};
