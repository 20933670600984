/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import { classNames as m } from "@progress/kendo-react-common";
import { InstanceObserver as S } from "@progress/kendo-charts";
import { TooltipPopup as d } from "./Popup.mjs";
import { ChartContext as f } from "../ChartContext.mjs";
const v = "k-chart-crosshair-tooltip", C = "k-chart-tooltip-inverse", n = class n extends r.Component {
  constructor(t, o) {
    super(t, o), this.context = null, this.state = {
      popupShown: !1
    }, this.chartObserver = new S(this, {
      showTooltip: "onShowTooltip",
      hideTooltip: "onHideTooltip"
    }), o.observersStore.dispatch({
      type: "add",
      payload: this.chartObserver
    });
  }
  render() {
    const { popupContend: t, className: o, ...e } = this.state, p = this.state.popupShown ? () => t : Function.prototype, s = m({
      [v]: !0,
      [C]: !!o
    });
    return /* @__PURE__ */ r.createElement(
      d,
      {
        ...e,
        popupContent: p,
        className: s
      }
    );
  }
  componentWillUnmount() {
    var t;
    (t = this.context) == null || t.observersStore.dispatch({
      type: "remove",
      payload: this.chartObserver
    });
  }
  onShowTooltip(t) {
    const { anchor: o, style: e, className: p, crosshair: s, axisName: a, axisIndex: c, value: l } = t, { name: h, index: u } = this.props;
    s && a === h && c === u && this.setState({
      popupShown: !0,
      popupAlign: o.align,
      popupOffset: o.point,
      popupContend: l,
      popupStyles: e,
      className: p
    });
  }
  onHideTooltip() {
    this.setState({
      popupShown: !1,
      popupStyles: {},
      className: void 0
    });
  }
};
n.contextType = f;
let i = n;
export {
  i as CrosshairTooltip
};
