import {
    Class
} from '../common';

var STRING = "string";
var FUNCTION = "function";

var preventDefault = function() {
    this._defaultPrevented = true;
};

var isDefaultPrevented = function() {
    return this._defaultPrevented === true;
};

export var Observable = (function (Class) {
    function Observable() {
        Class.call(this);
        this._events = {};
    }

    if ( Class ) Observable.__proto__ = Class;
    Observable.prototype = Object.create( Class && Class.prototype );
    Observable.prototype.constructor = Observable;

    Observable.prototype.destroy = function destroy () {
        this.unbind();
    };

    Observable.prototype.bind = function bind (event, handlers, one) {
        var that = this,
            idx,
            eventNames = typeof event === STRING ? [event] : event || [],
            length,
            original,
            handler,
            handlersIsFunction = typeof handlers === FUNCTION,
            events;

        if (handlers === undefined) {
            for (idx in event) {
                that.bind(idx, event[idx]);
            }

            return that;
        }

        /* eslint-disable no-loop-func */
        var loop = function (  ) {
            var eventName = eventNames[idx];

            handler = handlersIsFunction ? handlers : handlers[eventName];

            if (handler) {
                if (one) {
                    original = handler;
                    handler = function() {
                        that.unbind(eventName, handler);
                        original.apply(that, arguments);
                    };

                    handler.original = original;
                }

                events = that._events[eventName] = that._events[eventName] || [];
                events.push(handler);
            }
        };

        for (idx = 0, length = eventNames.length; idx < length; idx++) loop(  );
        /* eslint-enable no-loop-func */

        return that;
    };

    Observable.prototype.one = function one (eventNames, handlers) {
        return this.bind(eventNames, handlers, true);
    };

    Observable.prototype.first = function first (eventName, handlers) {
        var that = this,
            idx,
            eventNames = typeof eventName === STRING ? [eventName] : eventName,
            length,
            handler,
            handlersIsFunction = typeof handlers === FUNCTION,
            events;

        for (idx = 0, length = eventNames.length; idx < length; idx++) {
            var eventName$1 = eventNames[idx];

            handler = handlersIsFunction ? handlers : handlers[eventName$1];

            if (handler) {
                events = that._events[eventName$1] = that._events[eventName$1] || [];
                events.unshift(handler);
            }
        }

        return that;
    };

    Observable.prototype.trigger = function trigger (eventName, eventArgs) {
        var that = this,
            events = that._events[eventName],
            idx,
            length;

        if (events) {
            var e = eventArgs || {};

            e.sender = that;

            e._defaultPrevented = false;

            e.preventDefault = preventDefault;

            e.isDefaultPrevented = isDefaultPrevented;

            events = events.slice();

            for (idx = 0, length = events.length; idx < length; idx++) {
                events[idx].call(that, e);
            }

            return e._defaultPrevented === true;
        }

        return false;
    };

    Observable.prototype.unbind = function unbind (eventName, handler) {
        var that = this,
            events = that._events[eventName],
            idx;

        if (eventName === undefined) {
            that._events = {};
        } else if (events) {
            if (handler) {
                for (idx = events.length - 1; idx >= 0; idx--) {
                    if (events[idx] === handler || events[idx].original === handler) {
                        events.splice(idx, 1);
                    }
                }
            } else {
                that._events[eventName] = [];
            }
        }

        return that;
    };

    Observable.prototype._setEvents = function _setEvents (options) {
        var this$1 = this;

        var length = (this.events || []).length;

        for (var idx = 0; idx < length; idx ++) {
            var e = this$1.events[idx];

            if (this$1.options[e] && options[e]) {
                this$1.unbind(e, this$1.options[e]);

                if (this$1._events && this$1._events[e]) {
                    delete this$1._events[e];
                }
            }
        }

        this.bind(this.events, options);
    };

    return Observable;
}(Class));
