import { Code93 } from './code93';
import { Code39ExtendedBase } from './code39-extended';
import deepExtend from '../../common/deep-extend';

export var Code93Extended = (function (Code93) {
    function Code93Extended () {
        Code93.apply(this, arguments);
    }

    if ( Code93 ) Code93Extended.__proto__ = Code93;
    Code93Extended.prototype = Object.create( Code93 && Code93.prototype );
    Code93Extended.prototype.constructor = Code93Extended;

    Code93Extended.prototype.initProperties = function initProperties () {
        Code93.prototype.initProperties.call(this);

        deepExtend(this, Code39ExtendedBase, {
            name: "Code 93 extended",
            pushCheckSum: function() {
                var this$1 = this;

                var checkValues = this._getCheckValues();
                var value;

                this.checksum = checkValues.join("");

                for (var i = 0; i < checkValues.length; i++) {
                    value = checkValues[i];

                    if (this$1.shiftValuesAsciiCodes[value]) {
                        this$1.addExtended(this$1.shiftValuesAsciiCodes[value]);
                    } else {
                        var characterByValue = this$1._findCharacterByValue(value);
                        this$1.addPattern(this$1.characterMap[characterByValue].pattern);
                    }
                }
            }
        });
    };

    return Code93Extended;
}(Code93));
