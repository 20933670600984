/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as i from "react";
class a extends i.Component {
  render() {
    const {
      group: s,
      virtual: o,
      render: e,
      isMultiColumn: t = !1
    } = this.props, r = /* @__PURE__ */ i.createElement(
      "li",
      {
        id: this.props.id,
        role: "group",
        className: t ? "k-table-group-row" : "k-list-group-item",
        style: t ? { boxSizing: "inherit" } : { position: o ? "relative" : "unset" }
      },
      /* @__PURE__ */ i.createElement("span", { className: e ? void 0 : t ? "k-table-th" : "k-list-item-text" }, s)
    );
    return e !== void 0 ? e.call(void 0, r, this.props) : r;
  }
}
export {
  a as default
};
