/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
var c = /* @__PURE__ */ ((t) => (t[t.month = 0] = "month", t[t.year = 1] = "year", t[t.decade = 2] = "decade", t[t.century = 3] = "century", t))(c || {});
export {
  c as CalendarViewEnum
};
