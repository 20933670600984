/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { Popup as h } from "@progress/kendo-react-popup";
import { Keys as u, IconWrap as a } from "@progress/kendo-react-common";
import { filterIcon as m } from "@progress/kendo-svg-icons";
import { columnMenu as l, messages as p } from "../messages/index.mjs";
import { provideLocalizationService as f } from "@progress/kendo-react-intl";
class k extends t.Component {
  constructor() {
    super(...arguments), this.state = {
      show: !1
    }, this._anchor = null, this._content = null, this.blurTimeout = void 0, this.willBlur = !1, this.blur = () => {
      if (this.willBlur) {
        this.willBlur = !1;
        return;
      }
      clearTimeout(this.blurTimeout), this.blurTimeout = window.setTimeout(() => {
        this.closeMenu();
      });
    }, this.focus = () => {
      clearTimeout(this.blurTimeout);
    }, this.anchorClick = (e) => {
      e.preventDefault(), this.state.show && this.props.onCloseMenu && this.props.onCloseMenu(), this.setState(
        {
          show: !this.state.show
        },
        () => this.state.show && this._content && this._content.focus()
      );
    }, this.closeMenu = () => {
      this.props.onCloseMenu && this.props.onCloseMenu(), this.setState({ show: !1 });
    }, this.onAnchorMouseDown = (e) => {
      this.willBlur = this.state.show && e.currentTarget === this._anchor;
    }, this.onAnchorKeyDown = (e) => {
      var n;
      if (e.keyCode === u.tab) {
        const o = e.target, s = o && ((n = o.closest(".k-grid")) == null ? void 0 : n.getElementsByClassName("k-grid-content")[0]);
        s && s.scrollWidth > s.clientWidth && o.scrollIntoView({ inline: "center" });
      }
    };
  }
  render() {
    const { columnMenu: e, ...n } = this.props, { column: o } = this.props, s = f(this), i = o.title || o.field, c = i ? `${i} ` : "";
    return /* @__PURE__ */ t.createElement(t.Fragment, null, /* @__PURE__ */ t.createElement(
      "a",
      {
        className: "k-grid-header-menu k-grid-column-menu",
        ref: (r) => this._anchor = r,
        onClick: this.anchorClick,
        onMouseDown: this.onAnchorMouseDown,
        onKeyDown: this.onAnchorKeyDown,
        href: "#",
        "aria-label": `${c}${s.toLanguageString(l, p[l])}`
      },
      /* @__PURE__ */ t.createElement(a, { name: "filter", icon: m })
    ), /* @__PURE__ */ t.createElement(
      h,
      {
        anchor: this._anchor,
        show: this.state.show,
        popupClass: "k-column-menu k-column-menu-popup k-grid-columnmenu-popup"
      },
      /* @__PURE__ */ t.createElement(
        "div",
        {
          ref: (r) => this._content = r,
          tabIndex: 0,
          onBlur: this.blur,
          onFocus: this.focus,
          style: { outline: "none" }
        },
        e && /* @__PURE__ */ t.createElement(
          e,
          {
            ...n,
            onCloseMenu: this.closeMenu
          }
        )
      )
    ));
  }
}
export {
  k as GridColumnMenuWrapper
};
