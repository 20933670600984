import { Encoding } from './encoding';

var extend = Object.assign;

export var Ean13 = (function (Encoding) {
    function Ean13 () {
        Encoding.apply(this, arguments);
    }

    if ( Encoding ) Ean13.__proto__ = Encoding;
    Ean13.prototype = Object.create( Encoding && Encoding.prototype );
    Ean13.prototype.constructor = Ean13;

    Ean13.prototype.initProperties = function initProperties () {
        Encoding.prototype.initProperties.call(this);

        extend(this, {
            name: "EAN 13",
            keyTable: [
                '000000',
                '001011',
                '001101',
                '001110',
                '010011',
                '011001',
                '011100',
                '010101',
                '010110',
                '011010'
            ],
            characterMap: {
                digits: [
                    [3, 2, 1, 1],
                    [2, 2, 2, 1],
                    [2, 1, 2, 2],
                    [1, 4, 1, 1],
                    [1, 1, 3, 2],
                    [1, 2, 3, 1],
                    [1, 1, 1, 4],
                    [1, 3, 1, 2],
                    [1, 2, 1, 3],
                    [3, 1, 1, 2]
                ],
                start: [1, 1, 1],
                middle: [1, 1, 1, 1, 1]
            }
        });
    };

    Ean13.prototype.initValue = function initValue (value, width, height) {
        var valueAsString = String(value);

        if (valueAsString.length !== 12 || /\D/.test(valueAsString)) {
            throw new Error('The value of the "EAN13" encoding should be 12 symbols');
        }

        this.pattern = [];
        this.options.height = height;
        this.baseUnit = width / (95 + this.quietZoneLength);
        this.value = valueAsString;
        this.checksum = this.calculateChecksum();
        this.leftKey = valueAsString[0];
        this.leftPart = valueAsString.substr(1, 6);
        this.rightPart = valueAsString.substr(7) + this.checksum;
    };

    Ean13.prototype.addData = function addData () {
        this.addPieces(this.characterMap.start);
        this.addSide(this.leftPart, this.leftKey);
        this.addPieces(this.characterMap.middle);
        this.addSide(this.rightPart);
        this.addPieces(this.characterMap.start);
    };

    Ean13.prototype.addSide = function addSide (leftPart, key) {
        var this$1 = this;

        for (var i = 0; i < leftPart.length; i++) {
            if (key && parseInt(this$1.keyTable[key].charAt(i), 10)) {
                this$1.addPieces(Array.prototype.slice.call(this$1.characterMap.digits[leftPart.charAt(i)]).reverse(), true);
            } else {
                this$1.addPieces(this$1.characterMap.digits[leftPart.charAt(i)], true);
            }
        }
    };

    Ean13.prototype.addPieces = function addPieces (arrToAdd, limitedHeight) {
        var this$1 = this;

        for (var i = 0; i < arrToAdd.length; i++) {
            if (limitedHeight) {
                this$1.pattern.push({
                    y1: 0,
                    y2: this$1.options.height * 0.95,
                    width: arrToAdd[i]
                });
            } else {
                this$1.pattern.push(arrToAdd[i]);
            }
        }
    };

    Ean13.prototype.calculateChecksum = function calculateChecksum () {
        var odd = 0,
            even = 0,
            value = this.value.split("").reverse().join("");

        for (var i = 0; i < value.length; i++) {
            if (i % 2) {
                even += parseInt(value.charAt(i), 10);
            } else {
                odd += parseInt(value.charAt(i), 10);
            }
        }

        var checksum = (10 - ((3 * odd + even) % 10)) % 10;

        return checksum;
    };

    return Ean13;
}(Encoding));
