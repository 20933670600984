/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { GridColumnMenuWrapper as c } from "../columnMenu/GridColumnMenuWrapper.mjs";
import { selectRow as o, messages as a } from "../messages/index.mjs";
import { registerForLocalization as p, provideLocalizationService as l } from "@progress/kendo-react-intl";
import { withIdHOC as d } from "@progress/kendo-react-common";
class i extends e.Component {
  get _inputId() {
    return this.props.id;
  }
  render() {
    const n = l(this), { columnMenuWrapperProps: t } = this.props, r = [
      /* @__PURE__ */ e.createElement("span", { key: 0, className: "k-checkbox-wrap" }, /* @__PURE__ */ e.createElement(
        "input",
        {
          id: this._inputId,
          type: "checkbox",
          tabIndex: -1,
          className: "k-checkbox k-checkbox-md k-rounded-md",
          "aria-label": n.toLanguageString(o, a[o]),
          "aria-checked": this.props.selectionValue,
          checked: this.props.selectionValue,
          onChange: (s) => this.props.selectionChange({
            field: this.props.field,
            syntheticEvent: s
          })
        }
      )),
      t.columnMenu && /* @__PURE__ */ e.createElement(c, { ...t })
    ];
    return this.props.render ? this.props.render.call(void 0, r, this.props) : r;
  }
}
p(i);
const g = d(i);
export {
  g as GridHeaderSelectionCell
};
