/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import a from "prop-types";
import { validatePackage as f, classNames as b, createPropsContext as _, withIdHOC as C, withPropsContext as M } from "@progress/kendo-react-common";
import { FloatingLabel as S } from "@progress/kendo-react-labels";
import { packageMetadata as L } from "../package-metadata.mjs";
const e = class e extends r.Component {
  constructor(s) {
    super(s), this._input = null, this.focus = () => {
      this._input && this._input.focus();
    }, this.isInvalid = (i) => {
      let t = !1;
      for (let l in i)
        i.hasOwnProperty(l) && (t = t || !!i[l]);
      return t;
    }, this.setValidity = () => {
      this._input && this._input.setCustomValidity && (this.validity.valid || !this.validityStyles ? this._input.classList.remove("k-invalid") : this._input.classList.add("k-invalid"), this._input.setCustomValidity(
        this.validity.valid ? "" : this.props.validationMessage || ""
      ));
    }, this.handleChange = (i) => {
      this.setState({
        value: i.target.value
      }), this.valueDuringOnChange = i.target.value, this.props.onChange && this.props.onChange.call(void 0, {
        syntheticEvent: i,
        nativeEvent: i.nativeEvent,
        value: i.target.value,
        target: this
      }), this.valueDuringOnChange = void 0;
    }, this.handleAutoFill = (i) => {
      if (i.animationName === "autoFillStart") {
        let t = i.target.parentNode;
        t && t.classList.contains("k-empty") && t.classList.remove("k-empty");
      }
    }, f(L), this.state = {
      value: this.props.defaultValue || e.defaultProps.defaultValue
    };
  }
  get _inputId() {
    return this.props.id;
  }
  /**
   * Gets the native input element of the Input component.
   */
  get element() {
    return this._input;
  }
  /**
   * Gets the value of the Input.
   */
  get value() {
    return this.valueDuringOnChange !== void 0 ? this.valueDuringOnChange : this.props.value !== void 0 ? this.props.value : this.state.value;
  }
  /**
   * Gets the `name` property of the Input.
   */
  get name() {
    return this.props.name;
  }
  /**
   * Represents the validity state into which the Input is set.
   */
  get validity() {
    const s = {
      badInput: this._input ? this._input.validity.badInput : !1,
      patternMismatch: this._input ? this._input.validity.patternMismatch : !1,
      rangeOverflow: this._input ? this._input.validity.rangeOverflow : !1,
      rangeUnderflow: this._input ? this._input.validity.rangeUnderflow : !1,
      stepMismatch: this._input ? this._input.validity.stepMismatch : !1,
      tooLong: this._input ? this._input.validity.tooLong : !1,
      typeMismatch: this._input ? this._input.validity.typeMismatch : !1,
      valueMissing: this._input ? this._input.validity.valueMissing : !1
    };
    return {
      ...s,
      customError: this.props.validationMessage !== void 0,
      valid: this.props.valid !== void 0 ? this.props.valid : this._input ? !this.isInvalid(s) : !0
    };
  }
  /**
   * @hidden
   */
  get validityStyles() {
    return this.props.validityStyles !== void 0 ? this.props.validityStyles : e.defaultProps.validityStyles;
  }
  /**
   * @hidden
   */
  componentDidMount() {
    this.forceUpdate();
  }
  /**
   * @hidden
   */
  componentDidUpdate() {
    this.setValidity();
  }
  /**
   * @hidden
   */
  render() {
    const {
      className: s,
      label: i,
      labelClassName: t,
      id: l,
      validationMessage: N,
      defaultValue: P,
      valid: D,
      // Removed to support direct use in Form Field component
      visited: I,
      touched: O,
      modified: w,
      ariaLabelledBy: v,
      ariaDescribedBy: c,
      validityStyles: E,
      style: o,
      ariaLabel: y,
      ...n
    } = this.props, p = l || this._inputId, u = !this.validityStyles || this.validity.valid, g = b(s, "k-input k-input-md k-rounded-md k-input-solid"), h = /* @__PURE__ */ r.createElement(
      "input",
      {
        "aria-labelledby": v,
        "aria-describedby": c,
        "aria-disabled": this.props.disabled || void 0,
        "aria-invalid": !u || void 0,
        "aria-label": y || void 0,
        ...n,
        style: i ? void 0 : o,
        value: this.value,
        id: p,
        className: g,
        onChange: this.handleChange,
        onAnimationStart: this.handleAutoFill,
        ref: (m) => {
          this._input = m;
        }
      }
    );
    return i ? /* @__PURE__ */ r.createElement(
      S,
      {
        label: i,
        labelClassName: t,
        editorId: p,
        editorValue: String(this.value),
        editorValid: u,
        editorDisabled: n.disabled,
        editorPlaceholder: n.placeholder,
        children: h,
        style: o,
        dir: n.dir
      }
    ) : h;
  }
};
e.displayName = "Input", e.propTypes = {
  label: a.string,
  labelClassName: a.string,
  validationMessage: a.string,
  required: a.bool,
  validate: a.bool,
  id: a.string,
  ariaLabelledBy: a.string,
  ariaDescribedBy: a.string,
  ariaLabel: a.string
}, e.defaultProps = {
  defaultValue: "",
  required: !1,
  validityStyles: !0
};
let d = e;
const V = _(), k = C(M(V, d));
k.displayName = "KendoReactInput";
export {
  k as Input,
  V as InputPropsContext,
  d as InputWithoutContext
};
