import { drawing } from '@progress/kendo-drawing';
import { deepExtend, addClass, setDefaultOptions } from '../common';
import { calculateSankey } from './calculation';
import { Node, resolveNodeOptions } from './node';
import { Link, resolveLinkOptions } from './link';
import { Label, resolveLabelOptions } from './label';
import { Title } from './title';
import { BOTTOM, LEFT, RIGHT, TOP } from '../common/constants';
import Box from '../core/box';
import rectToBox from '../core/utils/rect-to-box';
import { Observable } from '../common/observable';
import { Legend } from './legend';

var LINK = 'link';
var NODE = 'node';

export var Sankey = (function (Observable) {
    function Sankey(element, options, theme) {
        Observable.call(this);

        this._initElement(element);
        this._initTheme(theme);
        this._setOptions(options);
        this._initSurface();

        if (options && options.data) {
            this._redraw();
        }

        this._initResizeObserver();
    }

    if ( Observable ) Sankey.__proto__ = Observable;
    Sankey.prototype = Object.create( Observable && Observable.prototype );
    Sankey.prototype.constructor = Sankey;

    Sankey.prototype.destroy = function destroy () {
        this.unbind();
        this._destroySurface();
        this._destroyResizeObserver();
    };

    Sankey.prototype._initElement = function _initElement (element) {
        this.element = element;
        addClass(this.element, [ "k-chart", "k-sankey" ]);
    };

    Sankey.prototype._initSurface = function _initSurface () {
        if (!this.surface) {
            this._destroySurface();
            this._initSurfaceElement();
            this.surface = this._createSurface();
        }
    };

    Sankey.prototype._initResizeObserver = function _initResizeObserver () {
        var this$1 = this;

        var observer = new ResizeObserver(function (entries) {
            entries.forEach(function (entry) {
                var ref = entry.contentRect;
                var width = ref.width;
                var height = ref.height;
                if (entry.target !== this$1.element ||
                    (this$1.size.width === width && this$1.size.height === height)) {
                    return;
                }
                this$1.size = { width: width, height: height };
                this$1.surface.setSize(this$1.size);
                this$1._redraw();
            });
        });
        this._resizeObserver = observer;
        observer.observe(this.element);
    };

    Sankey.prototype._createSurface = function _createSurface () {
        return drawing.Surface.create(this.surfaceElement, {
            mouseenter: this._mouseenter.bind(this),
            mouseleave: this._mouseleave.bind(this)
        });
    };

    Sankey.prototype._initTheme = function _initTheme (theme) {
        var currentTheme = theme || this.theme || {};
        this.theme = currentTheme;
        this.options = deepExtend({}, currentTheme, this.options);
    };

    Sankey.prototype.setLinksOpacity = function setLinksOpacity (opacity) {
        var this$1 = this;

        this.linksVisuals.forEach(function (link) {
            this$1.setOpacity(link, opacity);
        });
    };

    Sankey.prototype.setOpacity = function setOpacity (link, opacity) {
        link.options.set('stroke', Object.assign({}, link.options.stroke, {opacity: opacity}));
    };

    Sankey.prototype.trigger = function trigger (name, ev) {
        ev.type = name;
        return Observable.prototype.trigger.call(this, name, ev);
    };

    Sankey.prototype._mouseenter = function _mouseenter (ev) {
        var element = ev.element;
        var isLink = element.type === LINK;
        var isNode = element.type === NODE;
        var isLegendItem = Boolean(element.chartElement && element.chartElement.options.node);

        if ((isLink && this.trigger('linkEnter', ev)) ||
            (isNode && this.trigger('nodeEnter', ev))) {
            return;
        }

        var ref = this.options.links;
        var highlight = ref.highlight;
        if (isLink) {
            this.setLinksOpacity(highlight.inactiveOpacity);
            this.setOpacity(element, highlight.opacity);
        } else if (isNode) {
            this.highlightLinks(element, highlight);
        } else if (isLegendItem) {
            var nodeVisual = this.nodesVisuals.get(element.chartElement.options.node.id);
            this.highlightLinks(nodeVisual, highlight);
        }
    };

    Sankey.prototype._mouseleave = function _mouseleave (ev) {
        var element = ev.element;
        var isLink = element.type === LINK;
        var isNode = element.type === NODE;
        var isLegendItem = Boolean(element.chartElement && element.chartElement.options.node);
        var target = ev.originalEvent.relatedTarget;

        if (isLink && target && target.nodeName === 'text') {
            return;
        }

        if ((isLink && this.trigger('linkLeave', ev)) ||
            (isNode && this.trigger('nodeLeave', ev))) {
            return;
        }

        if (isLink || isNode || isLegendItem) {
            this.setLinksOpacity(this.options.links.opacity);
        }
    };

    Sankey.prototype.highlightLinks = function highlightLinks (node, highlight) {
        var this$1 = this;

        if (node) {
            this.setLinksOpacity(highlight.inactiveOpacity);
            node.links.forEach(function (link) {
                this$1.setOpacity(link, highlight.opacity);
            });
        }
    };

    Sankey.prototype._destroySurface = function _destroySurface () {
        if (this.surface) {
            this.surface.destroy();
            this.surface = null;
            this._destroySurfaceElement();
        }
    };

    Sankey.prototype._destroyResizeObserver = function _destroyResizeObserver () {
        if (this._resizeObserver) {
            this._resizeObserver.disconnect();
            this._resizeObserver = null;
        }
    };

    Sankey.prototype._initSurfaceElement = function _initSurfaceElement () {
        if (!this.surfaceElement) {
            this.surfaceElement = document.createElement('div');
            this.element.appendChild(this.surfaceElement);
        }
    };

    Sankey.prototype._destroySurfaceElement = function _destroySurfaceElement () {
        if (this.surfaceElement && this.surfaceElement.parentNode) {
            this.surfaceElement.parentNode.removeChild(this.surfaceElement);
            this.surfaceElement = null;
        }
    };

    Sankey.prototype.setOptions = function setOptions (options, theme) {
        this._setOptions(options);
        this._initTheme(theme);
        this._initSurface();
        this._redraw();
    };

    Sankey.prototype._redraw = function _redraw () {
        this.surface.clear();

        var ref = this._getSize();
        var width = ref.width;
        var height = ref.height;
        this.size = { width: width, height: height };
        this.surface.setSize(this.size);

        this.createVisual();

        this.surface.draw(this.visual);
    };

    Sankey.prototype._getSize = function _getSize () {
        return this.element.getBoundingClientRect();
    };

    Sankey.prototype.createVisual = function createVisual () {
        this.visual = this._render();
    };

    Sankey.prototype.titleBox = function titleBox (title, drawingRect) {
        if (!title || title.visible === false || !title.text) {
            return null;
        }

        var titleElement = new Title(Object.assign({}, {drawingRect: drawingRect}, title));
        var titleVisual = titleElement.exportVisual();
        return titleVisual.chartElement.box;
    };

    Sankey.prototype.legendBox = function legendBox (options, nodes, drawingRect) {
        if (!options || options.visible === false) {
            return null;
        }

        var legend = new Legend(Object.assign({}, {nodes: nodes}, options, {drawingRect: drawingRect}));
        var legendVisual = legend.exportVisual();

        return legendVisual.chartElement.box;
    };

    Sankey.prototype.calculateSankey = function calculateSankey$1 (options) {
        var ref = this.options;
        var title = ref.title;
        var legend = ref.legend;
        var data = ref.data;
        var ref$1 = this.options;
        var nodes = ref$1.nodes;
        var labels = ref$1.labels;
        var nodesColors = ref$1.nodesColors;

        var sankeyBox = new Box(0, 0, options.width, options.height);
        var titleBox = this.titleBox(title, sankeyBox);

        var legendArea = sankeyBox.clone();

        if (titleBox) {
            var titleHeight = titleBox.height();
            if (title.position === TOP) {
                sankeyBox.unpad({ top: titleHeight });
                legendArea = new Box(0, titleHeight, options.width, options.height);
            } else {
                sankeyBox.shrink(0, titleHeight);
                legendArea = new Box(0, 0, options.width, options.height - titleHeight);
            }
        }

        var legendBox = this.legendBox(legend, data.nodes, legendArea);

        if (legendBox) {
            if (legend.position === LEFT) {
                sankeyBox.unpad({ left: legendBox.width() });
            }

            if (legend.position === RIGHT) {
                sankeyBox.shrink(legendBox.width(), 0);
            }

            if (legend.position === TOP) {
                sankeyBox.unpad({ top: legendBox.height() });
            }

            if (legend.position === BOTTOM) {
                sankeyBox.shrink(0, legendBox.height());
            }
        }

        var calculatedNodes = calculateSankey(Object.assign({}, options, {offsetX: sankeyBox.x1, offsetY: sankeyBox.y1, width: sankeyBox.x2, height: sankeyBox.y2})).nodes;
        var box = new Box();

        calculatedNodes.forEach(function (nodeEl, i) {
            var nodeOps = resolveNodeOptions(nodeEl, nodes, nodesColors, i);
            var nodeInstance = new Node(nodeOps);
            box.wrap(rectToBox(nodeInstance.exportVisual().rawBBox()));

            var labelInstance = new Label(deepExtend({ node: nodeEl, totalWidth: options.width }, labels));
            var labelVisual = labelInstance.exportVisual();
            if (labelVisual) {
                box.wrap(rectToBox(labelVisual.rawBBox()));
            }
        });

        var offsetX = (box.x1 < 0 ? -box.x1 : 0) + sankeyBox.x1;
        var offsetY = (box.y1 < 0 ? -box.y1 : 0) + sankeyBox.y1;

        var width = box.width() > sankeyBox.x2 ? offsetX + sankeyBox.x2 - (box.width() - sankeyBox.x2) : sankeyBox.x2;
        var height = box.height() > sankeyBox.y2 ? offsetY + sankeyBox.y2 - (box.height() - sankeyBox.y2) : sankeyBox.y2;

        return {
            sankey: calculateSankey(Object.assign({}, options, {offsetX: offsetX, offsetY: offsetY, width: width, height: height})),
            legendBox: legendBox,
            titleBox: titleBox
        };
    };

    Sankey.prototype._render = function _render () {
        var ref = this.options;
        var data = ref.data;
        var labelOptions = ref.labels;
        var nodesOptions = ref.nodes;
        var linkOptions = ref.links;
        var nodesColors = ref.nodesColors;
        var title = ref.title;
        var legend = ref.legend;
        var ref$1 = this.size;
        var width = ref$1.width;
        var height = ref$1.height;
        var calcOptions = Object.assign({}, data, {width: width, height: height, nodesOptions: nodesOptions, title: title, legend: legend});
        var ref$2 = this.calculateSankey(calcOptions);
        var sankey = ref$2.sankey;
        var titleBox = ref$2.titleBox;
        var legendBox = ref$2.legendBox;
        var nodes = sankey.nodes;
        var links = sankey.links;

        var visual = new drawing.Group();

        if (titleBox) {
            var titleElement = new Title(Object.assign({}, title, {drawingRect: titleBox}));
            var titleVisual = titleElement.exportVisual();
            visual.append(titleVisual);
        }

        if (legendBox) {
            var legendElement = new Legend(Object.assign({}, legend, {drawingRect: legendBox, nodes: nodes, colors: nodesColors}));
            var legendVisual = legendElement.exportVisual();
            visual.append(legendVisual);
        }

        var visualNodes = new Map();

        nodes.forEach(function (node, i) {
            var nodeOps = resolveNodeOptions(node, nodesOptions, nodesColors, i);

            var nodeInstance = new Node(nodeOps);
            var nodeVisual = nodeInstance.exportVisual();
            nodeVisual.links = [];
            nodeVisual.type = NODE;
            visualNodes.set(node.id, nodeVisual);

            visual.append(nodeVisual);
        });

        var sortedLinks = links.slice().sort(function (a, b) { return b.value - a.value; });

        var linksVisuals = [];

        sortedLinks.forEach(function (link) {
            var source = link.source;
            var target = link.target;
            var sourceNode = visualNodes.get(source.id);
            var targetNode = visualNodes.get(target.id);
            var linkOps = resolveLinkOptions(link, linkOptions, sourceNode, targetNode);
            var linkInstance = new Link(linkOps);
            var linkVisual = linkInstance.exportVisual();

            linkVisual.type = LINK;
            linksVisuals.push(linkVisual);

            sourceNode.links.push(linkVisual);
            targetNode.links.push(linkVisual);

            visual.append(linkVisual);
        });

        this.linksVisuals = linksVisuals;
        this.nodesVisuals = visualNodes;

        nodes.forEach(function (node) {
            var textOps = resolveLabelOptions(node, labelOptions, width);
            var labelInstance = new Label(textOps);
            var labelVisual = labelInstance.exportVisual();

            if (labelVisual) {
                visual.append(labelVisual);
            }
        });

        return visual;
    };

    Sankey.prototype.exportVisual = function exportVisual () {
        return this._render();
    };

    Sankey.prototype._setOptions = function _setOptions (options) {
        this.options = deepExtend({}, this.options, options);
    };

    return Sankey;
}(Observable));

setDefaultOptions(Sankey, {
    labels: {
        visible: true,
        margin: {
            left: 8,
            right: 8
        },
        padding: 0,
        border: {
            width: 0
        },
        align: LEFT,
        opacity: 1,
        offset: { left: 0, top: 0 }
    },
    nodes: {
        width: 24,
        padding: 16,
        opacity: 1,
        offset: { left: 0, top: 0 }
    },
    links: {
        colorType: 'static', // 'source', 'target', 'static'
        opacity: 0.4,
        highlight: {
            opacity: 0.8,
            inactiveOpacity: 0.2
        }
    }
});
