/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import { classNames as s } from "@progress/kendo-react-common";
import { GRID_ROW_INDEX_ATTRIBUTE as p } from "../constants/index.mjs";
class a extends r.Component {
  /**
   * @hidden
   */
  render() {
    const e = s(
      "k-table-row",
      {
        "k-table-group-row k-grouping-row": this.props.rowType === "groupHeader",
        "k-group-footer": this.props.rowType === "groupFooter",
        "k-master-row": this.props.rowType !== "groupHeader" && this.props.rowType !== "groupFooter",
        "k-table-alt-row k-alt": this.props.rowType !== "groupHeader" && this.props.rowType !== "groupFooter" && this.props.isAltRow,
        "k-selected": this.props.isSelected,
        "k-grid-edit-row": this.props.isInEdit
      }
    ), o = /* @__PURE__ */ r.createElement(
      "tr",
      {
        id: this.props.id,
        onClick: this.props.onClick,
        onDoubleClick: this.props.onDoubleClick,
        className: e,
        style: {
          height: this.props.rowHeight ? this.props.rowHeight + "px" : "",
          visibility: this.props.isHidden ? "hidden" : ""
        },
        role: "row",
        "aria-rowindex": this.props.ariaRowIndex,
        "absolute-row-index": this.props.absoluteRowIndex,
        key: this.props.absoluteRowIndex,
        [p]: this.props.rowType === "data" ? this.props.dataIndex : void 0
      },
      this.props.children
    );
    return this.props.render ? this.props.render.call(void 0, o, this.props) : o;
  }
}
export {
  a as GridRow
};
