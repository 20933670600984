/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { addDecades as c, addCenturies as g, firstDecadeOfCentury as E, lastDecadeOfCentury as I, firstYearOfDecade as o, durationInCenturies as d, cloneDate as p } from "@progress/kendo-date-math";
import { Action as l } from "../models/NavigationAction.mjs";
import { EMPTY_SELECTIONRANGE as M } from "../models/SelectionRange.mjs";
import { range as m, getToday as N, isInRange as w, intersects as O, isInSelectionRange as _ } from "../../utils.mjs";
const $ = [[]], T = 4, b = 3, G = {
  [l.Left]: (i) => c(i, -1),
  [l.Up]: (i) => c(i, -5),
  [l.Right]: (i) => c(i, 1),
  [l.Down]: (i) => c(i, 5),
  [l.PrevView]: (i) => g(i, -1),
  [l.NextView]: (i) => g(i, 1),
  [l.FirstInView]: (i) => E(i),
  [l.LastInView]: (i) => I(i)
};
class W {
  addToDate(e, t) {
    return g(e, t);
  }
  datesList(e, t) {
    return m(0, t).map((r) => g(e, r));
  }
  data(e) {
    const {
      cellUID: t,
      focusedDate: r,
      isActiveView: u,
      max: s,
      min: a,
      selectedDate: f,
      selectionRange: h = M,
      viewDate: F
    } = e;
    if (!F)
      return $;
    const q = m(0, T), S = E(F), R = I(F), A = N();
    return m(0, b).map((V) => {
      const L = c(S, V * T);
      return q.map((C) => {
        const n = this.normalize(c(L, C), a, s), y = this.isEqual(n, h.start), D = this.isEqual(n, h.end), Y = !y && !D && _(n, h), v = u && (Array.isArray(f) ? this.isSelectedFromArray(n, f, a, s) : w(f, a, s) && this.isEqual(n, f));
        return O(n, S, R) ? {
          formattedValue: this.value(n),
          id: `${t}${n.getTime()}`,
          isFocused: this.isEqual(n, r),
          isSelected: v,
          isWeekend: !1,
          isInRange: w(n, a, s),
          isRangeStart: y,
          isRangeMid: Y,
          isRangeEnd: D,
          isRangeSplitEnd: Y && this.isEqual(n, R),
          isRangeSplitStart: Y && this.isEqual(n, S),
          isToday: this.isEqual(n, A),
          title: this.cellTitle(n),
          value: n
        } : null;
      });
    });
  }
  isSelectedFromArray(e, t, r, u) {
    let s = !1;
    return t.forEach((a) => {
      w(e, r, u) && this.isEqual(e, a) && (s = !0);
    }), s;
  }
  isEqual(e, t) {
    return !e || !t ? !1 : o(e).getFullYear() === o(t).getFullYear();
  }
  isInArray(e, t) {
    if (!t.length)
      return !1;
    const r = e.getFullYear();
    return t[0].getFullYear() <= r && r <= t[t.length - 1].getFullYear() + 99;
  }
  isInRange(e, t, r) {
    const u = o(e).getFullYear(), s = !t || o(t).getFullYear() <= u, a = !r || u <= o(r).getFullYear();
    return s && a;
  }
  isInSameView(e, t) {
    return d(e, t) === 0;
  }
  isRangeStart(e) {
    return e.getFullYear() % 1e3 === 0;
  }
  move(e, t) {
    const r = G[t];
    return r ? r(e) : e;
  }
  cellTitle(e) {
    return o(e).getFullYear().toString();
  }
  navigationTitle(e) {
    return e ? E(e).getFullYear().toString() : "";
  }
  title(e) {
    return e ? `${E(e).getFullYear()} - ${I(e).getFullYear()}` : "";
  }
  rowLength(e) {
    return T;
  }
  skip(e, t) {
    return d(t, e);
  }
  total(e, t) {
    return d(e, t) + 1;
  }
  value(e) {
    return e ? o(e).getFullYear().toString() : "";
  }
  viewDate(e, t, r = 1) {
    return d(e, t) < r ? g(e, -1) : e;
  }
  normalize(e, t, r) {
    return e < t && this.isEqual(e, t) ? p(t) : e > r && this.isEqual(e, r) ? p(r) : e;
  }
}
export {
  W as CenturyViewService
};
