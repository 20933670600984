/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import * as V from "react-dom";
import { useDocument as j, Draggable as z } from "@progress/kendo-react-common";
import { getSelectionOptions as G, relativeContextElement as J, getOffset as Q, closestTagName as s, getColumnIndex as K, getRowIndex as T } from "./utils.mjs";
import { TABLE_PREVENT_SELECTION_ELEMENT as U } from "./constants.mjs";
const ee = (f) => {
  var X;
  const { onRelease: h, childRef: D } = f, {
    enabled: F,
    drag: I,
    mode: i,
    cell: b
  } = G(f.selectable), o = t.useRef(null), x = t.useRef(null), d = t.useRef({ clientX: 0, clientY: 0 }), [M, E] = t.useState(null), C = t.useRef(), g = t.useRef(!1), y = t.useRef(!1), H = t.useCallback(
    (a) => {
      const { event: e } = a;
      y.current = e.originalEvent.target.hasAttribute(U), !(y.current || !o.current) && (C.current = J(o.current.element), d.current = {
        clientY: e.clientY,
        clientX: e.clientX
      });
    },
    []
  ), L = t.useCallback(
    (a) => {
      const { event: e } = a, { clientX: n, clientY: r } = d.current;
      if (!y.current && !(!I || i === "single") && ((Math.abs(r - e.clientY) > 5 || Math.abs(n - e.clientX) > 5) && (g.current = !0), g.current)) {
        const l = Q(C.current);
        E({
          top: Math.min(r, e.clientY) - l.top,
          left: Math.min(n, e.clientX) - l.left,
          width: Math.abs(e.clientX - n),
          height: Math.abs(e.clientY - r)
        });
      }
    },
    [E, I, i]
  ), _ = t.useCallback(
    (a) => {
      const { event: e } = a, { clientX: n, clientY: r } = d.current;
      if (y.current || !o.current)
        return;
      const l = o.current.element && o.current.element.ownerDocument;
      if (l) {
        if (g.current) {
          const m = Math.min(r, e.clientY), R = Math.min(n, e.clientX), v = Math.max(r, e.clientY), u = Math.max(n, e.clientX), c = x.current;
          if (!c)
            return;
          c.style.visibility = "hidden";
          const Y = l.elementFromPoint(R, m), P = l.elementFromPoint(u, v);
          if (c.style.visibility = "", !Y || !P)
            return;
          const w = s(Y, "TD"), A = s(w, "TR"), S = s(P, "TD"), B = s(S, "TR"), k = K(w), p = T(A), N = K(S), O = T(B);
          k !== void 0 && p !== void 0 && N !== void 0 && O !== void 0 && h({
            nativeEvent: e.originalEvent,
            startRowIndex: p,
            startColIndex: k,
            endRowIndex: O,
            endColIndex: N,
            altKey: e.altKey,
            shiftKey: e.shiftKey,
            ctrlKey: e.ctrlKey,
            metaKey: e.metaKey,
            mode: i,
            cell: b,
            isDrag: !0
          });
        } else {
          const m = l.elementFromPoint(n, r);
          if (!m)
            return;
          const R = s(m, "TD"), v = s(m, "TR"), u = K(R), c = T(v);
          R && v && c !== void 0 && u !== void 0 && h({
            nativeEvent: e.originalEvent,
            startRowIndex: c,
            startColIndex: u,
            endRowIndex: c,
            endColIndex: u,
            altKey: e.altKey,
            shiftKey: e.shiftKey,
            ctrlKey: e.ctrlKey,
            metaKey: e.metaKey,
            mode: i,
            cell: b,
            isDrag: !1
          });
        }
        E(null), g.current = !1, d.current = { clientX: 0, clientY: 0 };
      }
    },
    [E, i, b, h]
  ), q = j(x);
  return F ? /* @__PURE__ */ t.createElement(t.Fragment, null, /* @__PURE__ */ t.createElement(
    z,
    {
      onPress: H,
      onDrag: L,
      onRelease: _,
      ref: o,
      childRef: D
    },
    f.children
  ), M && V.createPortal(
    /* @__PURE__ */ t.createElement(
      "div",
      {
        ref: x,
        style: { ...M, position: "fixed" },
        className: "k-marquee k-marquee-color"
      }
    ),
    (X = q()) == null ? void 0 : X.body
  )) : t.cloneElement(t.Children.only(f.children), { ref: D });
};
export {
  ee as TableSelection
};
