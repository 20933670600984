/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const o = "grid.noRecords", a = "grid.pagerInfo", i = "grid.pagerFirstPage", l = "grid.pagerPreviousPage", g = "grid.pagerNextPage", s = "grid.pagerLastPage", n = "grid.pagerItemsPerPage", p = "grid.pagerPage", d = "grid.pagerPageSizeAriaLabel", c = "grid.pagerOf", u = "grid.pagerTotalPages", f = "grid.groupPanelEmpty", m = "grid.groupColumn", I = "grid.ungroupColumn", P = "grid.columnMenu", h = "grid.filterClearButton", O = "grid.filterSubmitButton", C = "grid.filterTitle", A = "grid.sortAscending", b = "grid.sortDescending", E = "grid.searchPlaceholder", L = "grid.filterCheckAll", S = "grid.filterChooseOperator", q = "grid.filterSelectedItems", x = "grid.sortAriaLabel", G = "grid.filterAriaLabel", N = "grid.groupPanelAriaLabel", y = "grid.groupExpand", w = "grid.groupCollapse", B = "grid.detailExpand", F = "grid.detailCollapse", T = "grid.selectRow", D = "grid.gridAriaLabel", M = {
  [B]: "Expand detail row",
  [F]: "Collapse detail row",
  [y]: "Expand group",
  [w]: "Collapse Group",
  [o]: "No records available",
  [f]: "Drag a column header and drop it here to group by that column",
  [h]: "Clear",
  [O]: "Filter",
  [C]: "Filter",
  [A]: "Sort Ascending",
  [b]: "Sort Descending",
  [x]: "Sortable",
  [m]: "Group Column",
  [I]: "Ungroup Column",
  [P]: "Column menu",
  [n]: "items per page",
  [a]: "{0} - {1} of {2} items",
  [i]: "Go to the first page",
  [l]: "Go to the previous page",
  [g]: "Go to the next page",
  [s]: "Go to the last page",
  [p]: "Page",
  [d]: "Page size",
  [c]: "of",
  [u]: "{0}",
  [E]: "Search",
  [L]: "Check All",
  [S]: "Choose Operator",
  [q]: "selected items",
  [G]: "Filter",
  [N]: "Group panel",
  [D]: "Table",
  [T]: "Select Row",
  "grid.filterEqOperator": "Is equal to",
  "grid.filterNotEqOperator": "Is not equal to",
  "grid.filterIsNullOperator": "Is null",
  "grid.filterIsNotNullOperator": "Is not null",
  "grid.filterIsEmptyOperator": "Is empty",
  "grid.filterIsNotEmptyOperator": "Is not empty",
  "grid.filterStartsWithOperator": "Starts with",
  "grid.filterContainsOperator": "Contains",
  "grid.filterNotContainsOperator": "Does not contain",
  "grid.filterEndsWithOperator": "Ends with",
  "grid.filterGteOperator": "Is greater than or equal to",
  "grid.filterGtOperator": "Is greater than",
  "grid.filterLteOperator": "Is less than or equal to",
  "grid.filterLtOperator": "Is less than",
  "grid.filterIsTrue": "Is true",
  "grid.filterIsFalse": "Is false",
  "grid.filterBooleanAll": "(All)",
  "grid.filterAfterOrEqualOperator": "Is after or equal to",
  "grid.filterAfterOperator": "Is after",
  "grid.filterBeforeOperator": "Is before",
  "grid.filterBeforeOrEqualOperator": "Is before or equal to",
  "grid.filterAndLogic": "And",
  "grid.filterOrLogic": "Or"
};
function v(r) {
  const e = r.replace(/^pager\.([a-z])/, (R, t) => "grid.pager" + t.toUpperCase());
  return { messageKey: e, defaultMessage: M[e] };
}
export {
  P as columnMenu,
  F as detailCollapse,
  B as detailExpand,
  G as filterAriaLabel,
  L as filterCheckAll,
  S as filterChooseOperator,
  h as filterClearButton,
  q as filterSelectedItems,
  O as filterSubmitButton,
  C as filterTitle,
  D as gridAriaLabel,
  w as groupCollapse,
  m as groupColumn,
  y as groupExpand,
  N as groupPanelAriaLabel,
  f as groupPanelEmpty,
  M as messages,
  o as noRecords,
  i as pagerFirstPage,
  a as pagerInfo,
  n as pagerItemPerPage,
  s as pagerLastPage,
  v as pagerMessagesMap,
  g as pagerNextPage,
  c as pagerOf,
  p as pagerPage,
  d as pagerPageSizeAriaLabel,
  l as pagerPreviousPage,
  u as pagerTotalPages,
  E as searchPlaceholder,
  T as selectRow,
  x as sortAriaLabel,
  A as sortAscending,
  b as sortDescending,
  I as ungroupColumn
};
