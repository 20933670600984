/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { resolveCells as i, footerColumns as n } from "../utils/index.mjs";
import { classNames as p } from "@progress/kendo-react-common";
class c extends t.Component {
  constructor() {
    super(...arguments), this._element = null, this.renderCell = (e, r) => {
      const l = {
        colSpan: e.colSpan !== 1 ? e.colSpan : void 0,
        style: e.left !== void 0 ? this.props.isRtl ? { left: e.right, right: e.left } : { left: e.left, right: e.right } : {},
        className: p(
          "k-table-td",
          e.locked && e.left !== void 0 ? "k-grid-footer-sticky" : ""
        ),
        key: r,
        role: "gridcell",
        "aria-colindex": e.ariaColumnIndex
      }, o = {
        field: e.field,
        ariaColumnIndex: e.ariaColumnIndex,
        ...l
      }, s = i(this.props.cells, e.cells);
      if (s && s.footerCell) {
        const a = s.footerCell;
        return /* @__PURE__ */ t.createElement(a, { ...o, tdProps: l, index: r });
      }
      return e.footerCell && /* @__PURE__ */ t.createElement(e.footerCell, { ...o, key: r }) || /* @__PURE__ */ t.createElement("td", { ...l });
    };
  }
  get element() {
    return this._element;
  }
  render() {
    return /* @__PURE__ */ t.createElement("tr", { className: "k-table-row", role: "row", "aria-rowindex": this.props.ariaRowIndex }, n(this.props.columns).map(this.renderCell));
  }
}
export {
  c as FooterRow
};
