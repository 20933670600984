/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import { Draggable as t } from "@progress/kendo-react-common";
class o extends s.PureComponent {
  constructor() {
    super(...arguments), this.draggable = null, this.onPress = (e) => {
      const r = this.draggable && this.draggable.element;
      r && this.props.pressHandler && this.props.pressHandler(e.event, r);
    }, this.onDrag = (e) => {
      const r = this.draggable && this.draggable.element;
      r && this.props.dragHandler && this.props.dragHandler(e.event, r);
    }, this.onRelease = (e) => {
      this.draggable && this.draggable.element && this.props.releaseHandler && this.props.releaseHandler(e.event);
    };
  }
  render() {
    return /* @__PURE__ */ s.createElement(
      t,
      {
        onPress: this.onPress,
        onDrag: this.onDrag,
        onRelease: this.onRelease,
        hint: this.props.dragClue,
        autoScroll: { boundaryElementRef: this.props.headerRef, direction: { horizontal: !0, vertical: !1 } },
        scrollContainer: this.props.containerRef || void 0,
        ref: (e) => {
          this.draggable = e;
        }
      },
      /* @__PURE__ */ s.createElement("tr", { className: "k-table-row", style: { touchAction: "none" }, role: "row", "aria-rowindex": this.props.ariaRowIndex }, this.props.children)
    );
  }
}
export {
  o as ColumnDraggable
};
