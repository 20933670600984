/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as l from "react";
import { classNames as f } from "@progress/kendo-react-common";
const C = (o, e) => {
  const s = Object.getOwnPropertyNames(o), n = Object.getOwnPropertyNames(e);
  if (s.length !== n.length)
    return !1;
  for (let t = 0; t < s.length; t++) {
    const a = s[t];
    if (o[a] !== e[a])
      return !1;
  }
  return !0;
};
class w extends l.Component {
  constructor() {
    super(...arguments), this.handleClick = (e) => {
      const { onClick: s, value: n } = this.props;
      s && s.call(void 0, n, e);
    }, this.handleMouseEnter = () => {
      const { onMouseEnter: e, value: s } = this.props;
      e && e.call(void 0, s);
    }, this.handleMouseLeave = () => {
      const { onMouseLeave: e, value: s } = this.props;
      e && e.call(void 0, s);
    };
  }
  // Manually checking if the component needs an update
  // due to date object being compared by instance
  // and new Date object is created
  // every time and fails the shallow compare of the React.PureComponent.
  /**
   * @hidden
   */
  shouldComponentUpdate(e) {
    const { value: s, ...n } = this.props, { value: t, ...a } = e;
    return !((!(s && t) || s.getTime() === t.getTime()) && C(n, a));
  }
  /* eslint-disable max-len */
  /**
   * @return
   * Returns a `<td />` element with a `<span className="k-link" />` inside to apply the styles. The text inside is the [`formattedValue`]({% slug api_dateinputs_calendarcellprops %}#toc-formattedvalue) of the `cell`.
   */
  render() {
    const {
      className: e,
      formattedValue: s,
      isWeekend: n,
      isFocused: t,
      isInRange: a,
      isSelected: c,
      isRangeStart: r,
      isRangeMid: d,
      isRangeEnd: i,
      isRangeSplitStart: p,
      isRangeSplitEnd: u,
      isToday: h,
      isDisabled: g,
      view: M,
      value: R,
      ...m
    } = this.props, v = this.props.activeRangeEnd === "end" && i, k = this.props.activeRangeEnd === "start" && r, E = f(
      "k-calendar-td",
      {
        "k-range-end": i,
        "k-range-mid": d,
        "k-range-split-end": u,
        "k-range-split-start": p,
        "k-range-start": r,
        "k-active": k || v,
        "k-state-pending-focus": t,
        "k-selected": c || r || i,
        "k-today": h,
        "k-weekend": n,
        "k-disabled": g
      },
      e
    );
    return /* @__PURE__ */ l.createElement(
      "td",
      {
        ...m,
        className: E,
        onClick: this.handleClick,
        onMouseEnter: this.handleMouseEnter,
        onMouseLeave: this.handleMouseLeave
      },
      /* @__PURE__ */ l.createElement("span", { className: "k-link" }, this.props.children)
    );
  }
}
export {
  w as CalendarCell
};
