/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { PreventableEvent as n } from "./preventable-event.mjs";
class r extends n {
  /**
   * @hidden
   */
  constructor(t, e) {
    super(e), this.axisRanges = t.axisRanges, this.nativeEvent = t.originalEvent;
  }
}
export {
  r as DragStartEvent
};
