/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import { CollectionConfigurationComponent as i } from "./base/CollectionConfigurationComponent.mjs";
import { ChartValueAxisItem as n } from "./ValueAxisItem.mjs";
import { validateChildren as a } from "../utils/index.mjs";
const m = (e) => /* @__PURE__ */ o.createElement(i, { ...e, _chartKey: "valueAxis" });
m.propTypes = {
  children: function(e, t, r) {
    return a(e, t, r, n);
  }
};
export {
  m as ChartValueAxis
};
