/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { GroupingIndicator as c } from "../drag/GroupingIndicator.mjs";
import { provideLocalizationService as a, registerForLocalization as u } from "@progress/kendo-react-intl";
import { groupPanelAriaLabel as i, messages as l, groupPanelEmpty as g } from "../messages/index.mjs";
class h extends t.Component {
  constructor() {
    super(...arguments), this.onGroupRemove = (p, r) => {
      const o = this.props.group.slice();
      o.splice(r, 1), this.props.groupChange(o, p);
    }, this.onGroupSortChange = (p, r, o, e) => {
      const s = Object.assign({}, o, { dir: e }), n = this.props.group.slice();
      n.splice(r, 1, s), this.props.groupChange(n, p);
    };
  }
  render() {
    const r = (this.props.group || []).map((o, e) => /* @__PURE__ */ t.createElement(
      c,
      {
        key: e,
        index: e,
        dir: o.dir || "asc",
        title: this.props.resolveTitle(o.field),
        onRemove: (s) => {
          this.onGroupRemove(s, e);
        },
        onSortChange: (s, n) => {
          this.onGroupSortChange(s, e, o, n);
        },
        onPress: this.props.pressHandler,
        onDrag: this.props.dragHandler,
        onRelease: this.props.releaseHandler,
        onContextMenu: this.props.onContextMenu
      }
    ));
    return /* @__PURE__ */ t.createElement(
      "div",
      {
        ref: this.props.refCallback,
        className: "k-grouping-header",
        role: "toolbar",
        "aria-label": a(this).toLanguageString(i, l[i]),
        "aria-controls": this.props.ariaControls || ""
      },
      /* @__PURE__ */ t.createElement("div", { className: "k-chip-list k-chip-list-md", role: "none" }, r),
      /* @__PURE__ */ t.createElement("div", { className: "k-grouping-drop-container" }, !r.length && a(this).toLanguageString(g, l[g]), " ")
    );
  }
}
u(h);
export {
  h as GroupPanel
};
