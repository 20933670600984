import calculateSlope from './calculate-slope';
import checkAllPositive from './check-all-positive';
import getScatterTrendlineData from './get-scatter-trendline-data';

function scatterExponentialTrendline(context) {
    var options = context.options;
    var seriesValues = context.seriesValues;

    var data = getData({ seriesValues: seriesValues, options: options });
    if (data) {
        return Object.assign({}, options,

            {type: 'scatterLine',
            data: data});
    }

    return null;
}

var valueGetter = function (fieldName) { return function (ref) {
        var valueFields = ref.valueFields;

        return ({ xValue: valueFields.x, yValue: Math.log(valueFields[fieldName]) });
; }    };

function getData(ref) {
    var seriesValues = ref.seriesValues;
    var options = ref.options;

    var sourceValues = seriesValues();
    if (!checkAllPositive(sourceValues, options.field)) {
        return null;
    }

    var ref$1 = calculateSlope(sourceValues, valueGetter(options.field));
    var slope = ref$1.slope;
    var intercept = ref$1.intercept;
    var count = ref$1.count;
    var xMin = ref$1.xMin;
    var xMax = ref$1.xMax;
    var range = { xMin: xMin, xMax: xMax };

    if (count > 0) {
        // Exponential trendline equation:
        // y = a * e ^ (b * x)
        var a = Math.exp(intercept);
        var b = slope;

        return getScatterTrendlineData(function (x) { return a * Math.exp(b * x); }, range, options.trendline);
    }

    return null;
}

export default scatterExponentialTrendline;
