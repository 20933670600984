import calculateSlope from './calculate-slope';
import getScatterTrendlineData from './get-scatter-trendline-data';
import scatterValueGetter from './scatter-value-getter';

function scatterLinearTrendline(context) {
    var options = context.options;
    var seriesValues = context.seriesValues;

    var data = getData({ seriesValues: seriesValues, options: options });
    if (data) {
        return Object.assign({}, options,

            {type: 'scatterLine',
            data: data});
    }

    return null;
}

function getData(ref) {
    var seriesValues = ref.seriesValues;
    var options = ref.options;

    var ref$1 = calculateSlope(seriesValues(), scatterValueGetter(options.field));
    var slope = ref$1.slope;
    var intercept = ref$1.intercept;
    var count = ref$1.count;
    var xMin = ref$1.xMin;
    var xMax = ref$1.xMax;
    var range = { xMin: xMin, xMax: xMax };

    if (count > 0) {
        return getScatterTrendlineData(function (x) { return slope * x + intercept; }, range, options.trendline);
    }

    return null;
}

export default scatterLinearTrendline;
