import calculateMovingAverage from './calculate-moving-average';
import { MIN_MOVING_AVERAGE_PERIOD } from '../constants';
import scatterValueGetter from './scatter-value-getter';

function scatterMovingAverageTrendline(context) {
    var options = context.options;

    var data = getData(context);
    if (data) {
        return Object.assign({}, options,

            {type: 'scatterLine',
            data: data});
    }

    return null;
}

function getData(ref) {
    var options = ref.options;
    var seriesValues = ref.seriesValues;

    var period = (options.trendline || {}).period || MIN_MOVING_AVERAGE_PERIOD;
    var range = { before: period, after: period };
    var data = calculateMovingAverage(seriesValues(range), scatterValueGetter(options.field), period);

    if (data.length > 0) {
        return data;
    }

    return null;
}

export default scatterMovingAverageTrendline;
