/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { drawDOM as t, exportPDF as a } from "@progress/kendo-drawing";
import { saveAs as i } from "@progress/kendo-file-saver";
import m from "./KendoDrawingAdapter.mjs";
function D(r, e = {}, o) {
  new m(
    t,
    a,
    i,
    r,
    e
  ).savePDF(o);
}
export {
  D as savePDF
};
