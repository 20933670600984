/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { addMonths as u, addYears as g, firstMonthOfYear as I, lastMonthOfYear as R, createDate as M, durationInYears as h, cloneDate as p } from "@progress/kendo-date-math";
import { Action as o } from "../models/NavigationAction.mjs";
import { range as m, getToday as _, isInRange as F, isInSelectionRange as O } from "../../utils.mjs";
import { EMPTY_SELECTIONRANGE as $ } from "../models/SelectionRange.mjs";
const C = [[]], S = 4, G = 3, P = (r) => r > 4 ? -5 : r < 2 ? -2 : -7, k = (r) => r < 7 ? 5 : r < 10 ? 7 : 2, z = {
  [o.Left]: (r) => u(r, -1),
  [o.Up]: (r) => u(r, P(r.getMonth())),
  [o.Right]: (r) => u(r, 1),
  [o.Down]: (r) => u(r, k(r.getMonth())),
  [o.PrevView]: (r) => g(r, -1),
  [o.NextView]: (r) => g(r, 1),
  [o.FirstInView]: (r) => I(r),
  [o.LastInView]: (r) => R(r)
};
class K {
  constructor(t) {
    this._intl = t;
  }
  addToDate(t, e) {
    return g(t, e);
  }
  datesList(t, e) {
    return m(0, e).map((n) => g(t, n));
  }
  data(t) {
    const {
      cellUID: e,
      focusedDate: n,
      isActiveView: l,
      max: i,
      min: a,
      selectedDate: c,
      selectionRange: f = $,
      viewDate: Y
    } = t;
    if (!Y)
      return C;
    const b = this.abbrMonthNames(), d = I(Y), q = R(Y), y = d.getFullYear(), A = m(0, S), V = _();
    return m(0, G).map((v) => {
      const D = u(d, v * S);
      return A.map((N) => {
        const s = this.normalize(u(D, N), a, i);
        if (y < s.getFullYear())
          return null;
        const w = this.isEqual(s, f.start), T = this.isEqual(s, f.end), E = !w && !T && O(s, f), L = l && (Array.isArray(c) ? this.isSelectedFromArray(s, c, a, i) : F(c, a, i) && this.isEqual(s, c));
        return {
          formattedValue: b[s.getMonth()],
          id: `${e}${s.getTime()}`,
          isFocused: this.isEqual(s, n),
          isSelected: L,
          isInRange: F(s, a, i),
          isWeekend: !1,
          isRangeStart: w,
          isRangeMid: E,
          isRangeEnd: T,
          isRangeSplitEnd: E && this.isEqual(s, q),
          isRangeSplitStart: E && this.isEqual(s, d),
          isToday: this.isEqual(s, V),
          title: this.cellTitle(s),
          value: s
        };
      });
    });
  }
  isSelectedFromArray(t, e, n, l) {
    let i = !1;
    return e.forEach((a) => {
      F(t, n, l) && this.isEqual(t, a) && (i = !0);
    }), i;
  }
  isEqual(t, e) {
    return !t || !e ? !1 : t.getFullYear() === e.getFullYear() && t.getMonth() === e.getMonth();
  }
  isInArray(t, e) {
    if (!e.length)
      return !1;
    const n = t.getFullYear();
    return e[0].getFullYear() <= n && n <= e[e.length - 1].getFullYear();
  }
  isInRange(t, e, n) {
    const l = M(t.getFullYear(), t.getMonth(), 1), i = !e || M(e.getFullYear(), e.getMonth(), 1) <= l, a = !n || l <= M(n.getFullYear(), n.getMonth(), 1);
    return i && a;
  }
  isInSameView(t, e) {
    return h(t, e) === 0;
  }
  isRangeStart(t) {
    return t.getFullYear() % 10 === 0;
  }
  move(t, e) {
    const n = z[e];
    return n ? n(t) : t;
  }
  cellTitle(t) {
    return `${t.getFullYear()} ${this.value(t)}`;
  }
  navigationTitle(t) {
    return this.title(t);
  }
  title(t) {
    return t ? t.getFullYear().toString() : "";
  }
  rowLength(t) {
    return S;
  }
  skip(t, e) {
    return h(e, t);
  }
  total(t, e) {
    return h(t, e) + 1;
  }
  value(t) {
    return t ? this.abbrMonthNames()[t.getMonth()] : "";
  }
  viewDate(t, e, n = 1) {
    return h(t, e) < n ? g(t, -1) : t;
  }
  abbrMonthNames() {
    return this._intl.dateFormatNames({ nameType: "abbreviated", type: "months" });
  }
  normalize(t, e, n) {
    return t < e && this.isEqual(t, e) ? p(e) : t > n && this.isEqual(t, n) ? p(n) : t;
  }
}
export {
  K as YearViewService
};
