/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as h from "react";
import { useTableKeyboardNavigation as p } from "../navigation/hooks.mjs";
const y = (e) => {
  const {
    ariaSort: a,
    colSpan: t,
    rowSpan: r,
    className: n,
    style: o,
    columnId: i,
    onKeyDown: l,
    navigatable: c,
    ariaColumnIndex: s,
    ariaLabel: d,
    role: m,
    ariaSelected: b
  } = e, u = p(i, c);
  return /* @__PURE__ */ h.createElement(
    "th",
    {
      "aria-sort": a,
      "aria-label": d,
      "aria-colindex": s,
      "aria-selected": b,
      colSpan: t,
      rowSpan: r,
      className: n,
      style: o,
      onKeyDown: l,
      role: m,
      ...u
    },
    e.children
  );
};
y.displayName = "KendoReactHeaderThElement";
export {
  y as HeaderThElement
};
