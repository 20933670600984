/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { DomEvents as r } from "./dom-events.mjs";
const s = "Hammerjs is not loaded.Solution: http://www.telerik.com/kendo-react-ui/components/charts/troubleshooting/#toc-installation";
class a {
  static create(n, o) {
    if (typeof window != "undefined") {
      const e = window.Hammer;
      if (!e) {
        process.env.NODE_ENV !== "production" && console.warn(s);
        return;
      }
      const t = new e(n, {
        recognizers: [
          [e.Tap],
          [e.Pan],
          [e.Pinch],
          [e.Press, { time: 0 }]
        ]
      });
      return new r(t, o);
    }
  }
}
export {
  a as DomEventsBuilder
};
