/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { fieldList as i } from "./fieldList.mjs";
const e = {};
e.undefined = () => {
};
function s(t) {
  if (e[t])
    return e[t];
  const o = i(t);
  return e[t] = function(u) {
    let n = u;
    for (let r = 0; r < o.length && n; r++)
      n = n[o[r]];
    return n;
  }, e[t];
}
export {
  s as getter
};
