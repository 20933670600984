import { QRDataMode } from './qr-data-mode';
import { extend } from '../../utils';
import {
    toBitsString
} from '../../utils';

export var ByteQRDataMode = (function (QRDataMode) {
    function ByteQRDataMode () {
        QRDataMode.apply(this, arguments);
    }

    if ( QRDataMode ) ByteQRDataMode.__proto__ = QRDataMode;
    ByteQRDataMode.prototype = Object.create( QRDataMode && QRDataMode.prototype );
    ByteQRDataMode.prototype.constructor = ByteQRDataMode;

    ByteQRDataMode.prototype.initProperties = function initProperties () {
        QRDataMode.prototype.initProperties.call(this);

        extend(this, {
            bitsInCharacterCount: [8, 16, 16],
            modeIndicator: "0100"
        });
    };

    ByteQRDataMode.prototype.getValue = function getValue (character) {
        var code = character.charCodeAt(0);

        if (code <= 127 || (160 <= code && code <= 255)) {
            return code;
        }

        throw new Error(("Unsupported character in QR Code: \"" + character + "\"."));
    };

    ByteQRDataMode.prototype.encode = function encode (str, version) {
        var mode = this,
            result = mode.getModeCountString(str.length, version);

        for (var i = 0; i < str.length; i++) {
            result += toBitsString(mode.getValue(str.charAt(i)), 8);
        }

        return result;
    };

    ByteQRDataMode.prototype.getStringBitsLength = function getStringBitsLength (inputLength, version) {
        return 4 + this.getBitsCharacterCount(version) + 8 * inputLength;
    };

    return ByteQRDataMode;
}(QRDataMode));
