/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import { useAsyncFocusBlur as l } from "../hooks/useAsyncFocusBlur.mjs";
const a = ({
  children: e,
  onFocus: t,
  onBlur: c,
  onSyncFocus: o,
  onSyncBlur: s
}) => {
  const n = l({ onFocus: t, onBlur: c, onSyncFocus: o, onSyncBlur: s });
  return /* @__PURE__ */ r.createElement(r.Fragment, null, e.call(void 0, { ...n }));
};
export {
  a as AsyncFocusBlur
};
