/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { BaseEvent as e } from "./base-event.mjs";
class n extends e {
  constructor() {
    super(...arguments), this.prevented = !1;
  }
  /* eslint-disable max-len */
  /**
   * Prevents the default action for a specified event. In this way, the source component suppresses the built-in behavior that follows the event.
   */
  preventDefault() {
    this.prevented = !0;
  }
  /**
   * Returns `true` if the event was prevented by any of its subscribers.
   *
   * @returns `true` if the default action was prevented.
   * Otherwise, returns `false`.
   */
  isDefaultPrevented() {
    return this.prevented;
  }
}
export {
  n as PreventableEvent
};
