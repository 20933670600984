import PlotAreaBase from './plotarea-base';
import FunnelChart from '../funnel-chart/funnel-chart';
import PyramidChart from '../funnel-chart/pyramid-chart';

import { append } from '../../common';
import { filterSeriesByType } from '../utils';

import { FUNNEL, PYRAMID } from '../constants';

var FunnelPlotArea = (function (PlotAreaBase) {
    function FunnelPlotArea () {
        PlotAreaBase.apply(this, arguments);
    }

    if ( PlotAreaBase ) FunnelPlotArea.__proto__ = PlotAreaBase;
    FunnelPlotArea.prototype = Object.create( PlotAreaBase && PlotAreaBase.prototype );
    FunnelPlotArea.prototype.constructor = FunnelPlotArea;

    FunnelPlotArea.prototype.render = function render () {
        this.createChart(FunnelChart, filterSeriesByType(this.series, [ FUNNEL ]));
        this.createChart(PyramidChart, filterSeriesByType(this.series, [ PYRAMID ]));
    };

    FunnelPlotArea.prototype.createChart = function createChart (chartType, series) {
        var firstSeries = series[0];
        if (!firstSeries) {
            return;
        }

        var chart = new chartType(this, {
            series: series,
            legend: this.options.legend,
            neckRatio: firstSeries.neckRatio,
            dynamicHeight: firstSeries.dynamicHeight,
            dynamicSlope: firstSeries.dynamicSlope,
            segmentSpacing: firstSeries.segmentSpacing,
            highlight: firstSeries.highlight
        });

        this.appendChart(chart);
    };

    FunnelPlotArea.prototype.appendChart = function appendChart (chart, pane) {
        PlotAreaBase.prototype.appendChart.call(this, chart, pane);
        append(this.options.legend.data, chart.legendItems);
    };

    FunnelPlotArea.prototype._pointsByVertical = function _pointsByVertical (basePoint) {
        return this.pointsBySeriesIndex(basePoint.series.index);
    };

    FunnelPlotArea.prototype.getPointToTheRight = function getPointToTheRight (point) {
        return this.getPointBelow(point);
    };

    FunnelPlotArea.prototype.getPointToTheLeft = function getPointToTheLeft (point) {
        return this.getPointAbove(point);
    };

    return FunnelPlotArea;
}(PlotAreaBase));

export default FunnelPlotArea;
