/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as o from "react";
import { CollectionConfigurationComponent as i } from "./base/CollectionConfigurationComponent.mjs";
import { ChartSeriesItem as n } from "./SeriesItem.mjs";
import { validateChildren as m } from "../utils/index.mjs";
const a = (e) => /* @__PURE__ */ o.createElement(i, { ...e, _chartKey: "series" });
a.propTypes = {
  children: function(e, r, t) {
    return m(e, r, t, n);
  }
};
export {
  a as ChartSeries
};
