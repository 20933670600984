/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { KEYBOARD_NAV_DATA_LEVEL as i, KEYBOARD_NAV_DATA_ID as r } from "./constants.mjs";
import { TableKeyboardNavigationContext as n } from "./TableKeyboardNavigationContext.mjs";
const c = (o, a) => {
  const t = e.useContext(n);
  return !t || !o || a === !1 ? {} : {
    tabIndex: t && t.activeId && t.activeId === o ? 0 : -1,
    [i]: t.level,
    [r]: o
  };
};
export {
  c as useTableKeyboardNavigation
};
