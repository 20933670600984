import ArcGauge from '../arc/arc-gauge';

var defaultStartAngle = 90;

var CircularGauge = (function (ArcGauge) {
    function CircularGauge () {
        ArcGauge.apply(this, arguments);
    }

    if ( ArcGauge ) CircularGauge.__proto__ = ArcGauge;
    CircularGauge.prototype = Object.create( ArcGauge && ArcGauge.prototype );
    CircularGauge.prototype.constructor = CircularGauge;

    CircularGauge.prototype._createModel = function _createModel () {
        var scaleOptions = this.options.scale;
        if (typeof scaleOptions.startAngle !== 'number') {
            scaleOptions.startAngle = defaultStartAngle;
        }

        scaleOptions.endAngle = scaleOptions.startAngle + 360;

        ArcGauge.prototype._createModel.call(this);
    };

    return CircularGauge;
}(ArcGauge));

export default CircularGauge;
