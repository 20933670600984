/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
const s = (e) => e ? new Date(e.getTime()) : null;
function l(e) {
  const n = {};
  return o(e, n), n;
}
function o(e, n) {
  for (let t in e)
    if (e.hasOwnProperty(t)) {
      const i = e[t];
      n[t] = c(i, n[t]);
    }
}
function c(e, n) {
  if (Array.isArray(e))
    return f(e);
  if (e instanceof Date)
    return s(e);
  if (r.isValidElement(e))
    return r.cloneElement(e, e.props);
  if (e && typeof e == "object") {
    const t = n || {};
    return o(e, t), t;
  } else
    return e;
}
function f(e) {
  return e.map((n) => c(n, void 0));
}
export {
  l as clone,
  f as cloneArray,
  s as cloneDate,
  o as cloneObject,
  c as cloneValue
};
