/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import { GridColumnMenuItem as m } from "./GridColumnMenuItem.mjs";
import { GridColumnMenuItemGroup as M } from "./GridColumnMenuItemGroup.mjs";
import { normalize as x } from "../interfaces/GridSortSettings.mjs";
import { provideLocalizationService as z, registerForIntl as E, registerForLocalization as L } from "@progress/kendo-react-intl";
import { sortAscending as f, messages as u, sortDescending as h } from "../messages/index.mjs";
import { sortAscSmallIcon as R, sortDescSmallIcon as w } from "@progress/kendo-svg-icons";
const D = "asc", I = "desc", F = {
  true: {
    asc: { asc: "", desc: "desc" },
    desc: { asc: "asc", desc: "" },
    "": { asc: "asc", desc: "desc" }
  },
  false: {
    asc: { asc: "asc", desc: "desc" },
    desc: { asc: "asc", desc: "desc" },
    "": { asc: "asc", desc: "desc" }
  }
}, g = (o, s) => s ? s.findIndex((t) => t.field === o) : -1, C = (o, s) => !!(s && o > -1 && s[o].dir === D), S = (o, s) => !!(s && o > -1 && s[o].dir === I), l = class l extends r.Component {
  constructor() {
    super(...arguments), this.onAscClick = (s) => {
      this.onSort(s, D), this.props.onCloseMenu && this.props.onCloseMenu();
    }, this.onDescClick = (s) => {
      this.onSort(s, I), this.props.onCloseMenu && this.props.onCloseMenu();
    }, this.onSort = (s, t) => {
      if (s.preventDefault(), !this.props.onSortChange)
        return;
      const { column: e, sortable: c, sort: k } = this.props, { allowUnsort: v, mode: A } = x(c || !1, !1), a = (k || []).filter((i) => i.field === e.field)[0], d = F[v][a && a.dir || ""][t], p = A === "single" ? [] : (this.props.sort || []).filter((i) => i.field !== e.field);
      d !== "" && e.field && p.push({ field: e.field, dir: d }), this.props.onSortChange(p, s);
    };
  }
  /**
   * @hidden
   */
  render() {
    const { sort: s, column: t } = this.props, e = g(t.field, s), c = z(this);
    return /* @__PURE__ */ r.createElement(M, null, /* @__PURE__ */ r.createElement(
      m,
      {
        title: c.toLanguageString(f, u[f]),
        iconClass: "k-i-sort-asc-sm",
        svgIcon: R,
        selected: C(e, s),
        onClick: this.onAscClick
      }
    ), /* @__PURE__ */ r.createElement(
      m,
      {
        title: c.toLanguageString(h, u[h]),
        iconClass: "k-i-sort-desc-sm",
        svgIcon: w,
        selected: S(e, s),
        onClick: this.onDescClick
      }
    ));
  }
};
l.active = (s, t) => {
  const e = g(s, t);
  return S(e, t) || C(e, t);
};
let n = l;
E(n);
L(n);
export {
  n as GridColumnMenuSort
};
