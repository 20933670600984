/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const o = "data-windowid", D = 10002, n = 2, t = ".k-window:not(.k-dialog), .k-dialog-wrapper";
export {
  o as DATA_DIALOGS_ID,
  D as DEFAULT_DIALOGS_ZINDEX,
  t as DIALOGS_SELECTOR,
  n as ZINDEX_DIALOGS_STEP
};
