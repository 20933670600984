/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as a from "react";
const d = (n, o) => {
  const [e, l] = a.useContext(n), [c, u] = a.useState(o);
  return [
    e !== void 0 ? e : c,
    (...t) => {
      e !== void 0 ? l(...t) : u(t[0]);
    }
  ];
};
export {
  d as useInheritedState
};
