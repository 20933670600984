/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as i from "react";
import { getter as f } from "@progress/kendo-react-common";
const x = (r) => {
  const { data: n = [] } = r;
  return /* @__PURE__ */ i.createElement("div", { className: "k-selection-aggregates k-grid-selection-aggregates" }, n.map((o, a) => /* @__PURE__ */ i.createElement("div", { key: a }, /* @__PURE__ */ i.createElement("span", { className: "k-selection-aggregates-item-text" }, o.type, ": "), /* @__PURE__ */ i.createElement("span", { className: "k-selection-aggregates-item-value" }, o.formattedValue))));
};
x.displayName = "KendoReactGridStatusBar";
const D = (r) => {
  var o;
  const n = r.slice();
  for (let a = 0; a < n.length; a++)
    for (; n[a] && ((o = n[a].children) != null && o.length); )
      n.splice(a, 1, ...n[a].children);
  return n;
}, N = (r) => {
  const { dataItems: n, target: o, selectedField: a } = r, p = D(o.columns).map((e) => e.field).filter((e) => e && typeof e == "string").map((e) => f(e)), b = f(a), t = { dates: [], numbers: [], booleans: [], others: [] }, y = (e) => {
    typeof e == "number" ? t.numbers.push(e) : typeof e == "boolean" ? t.booleans.push(e) : e instanceof Date ? t.dates.push(e) : t.others.push(e);
  };
  n.forEach((e) => {
    const s = b(e);
    s && s.forEach((l) => {
      y(p[l](e));
    });
  });
  const m = t.dates.map((e) => e.getTime()), g = t.booleans.filter((e) => e).length, d = t.booleans.filter((e) => !e).length, c = t.numbers.length ? t.numbers.reduce((e, s) => e += s, 0) : void 0, u = {
    sum: c,
    average: typeof c == "number" ? c / t.numbers.length : void 0,
    min: t.numbers.length ? Math.min(...t.numbers) : void 0,
    max: t.numbers.length ? Math.max(...t.numbers) : void 0,
    count: t.numbers.length + t.booleans.length + t.dates.length + t.others.length,
    isTrue: g > 0 ? g : void 0,
    isFalse: d > 0 ? d : void 0,
    earliest: t.dates.length ? new Date(Math.min(...m)) : void 0,
    latest: t.dates.length ? new Date(Math.max(...m)) : void 0
  }, E = (e, s) => (s === "sum" || s === "average") && typeof e == "number" ? e.toFixed(2) : (s === "earliest" || s === "latest") && e instanceof Date ? e.toLocaleDateString() : String(e), h = [];
  return Object.keys(u).forEach((e) => {
    const s = e, l = u[s];
    l !== void 0 && h.push({ type: s, value: l, formattedValue: E(l, s) });
  }), h;
};
export {
  x as StatusBar,
  N as getStatusData,
  D as leafColumns
};
