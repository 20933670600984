import { setDefaultOptions, deepExtend } from '../common';
import { SankeyElement } from './element';
import { Box, TextBox } from '../core';
var INSIDE = 'inside';
var BEFORE = 'before';
var AFTER = 'after';

export var Label = (function (SankeyElement) {
    function Label () {
        SankeyElement.apply(this, arguments);
    }

    if ( SankeyElement ) Label.__proto__ = SankeyElement;
    Label.prototype = Object.create( SankeyElement && SankeyElement.prototype );
    Label.prototype.constructor = Label;

    Label.prototype.getElement = function getElement () {
        var options = deepExtend({}, this.options, this.options.node.label);
        var node = options.node;
        var totalWidth = options.totalWidth;
        var position = options.position;
        var text = options.text;
        var offset = options.offset;

        if (!options.visible || !text) {
            return null;
        }

        var nodeBox = new Box(node.x0, node.y0, node.x1, node.y1);
        var visualOptions = this.visualOptions();
        var textbox = new TextBox(text, visualOptions);
        textbox.reflow(new Box());
        var textSizeBox = textbox.box;

        var goesOutside = node.x1 + textSizeBox.width() > totalWidth;
        var textY = nodeBox.center().y - (textSizeBox.height() / 2);
        var side = position === BEFORE || (position === INSIDE && goesOutside) ? BEFORE : AFTER;
        var textOrigin = [side === BEFORE ? node.x0 - textSizeBox.width() : node.x1, textY];

        var textRect = new Box(textOrigin[0], textOrigin[1], textOrigin[0] + textSizeBox.width(), textOrigin[1] + textSizeBox.height());
        textRect.translate(offset.left || 0, offset.top || 0);
        textbox.reflow(textRect);

        textbox.renderVisual();

        return textbox.visual;
    };

    Label.prototype.visualOptions = function visualOptions () {
        var options = deepExtend({}, this.options, this.options.node.label);
        return {
            color: options.color,
            opacity: options.opacity,
            font: options.font,
            border: options.border,
            margin: options.margin,
            padding: options.padding,
            align: options.align,
        };
    };

    return Label;
}(SankeyElement));

setDefaultOptions(Label, {
    position: INSIDE, // inside, before, after
});

export var resolveLabelOptions = function (node, options, totalWidth) { return deepExtend({},
    options,
    {
        node: node,
        totalWidth: totalWidth,
        visual: node.label.visual,
        visible: node.label.visible,
        margin: node.label.margin,
        padding: node.label.padding,
        border: node.label.border,
        align: node.label.align,
        opacity: node.label.opacity,
        offset: node.label.offset
    }
); };
