/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { canUseDOM as u } from "@progress/kendo-react-common";
const l = 1e3 / 60, f = (i) => {
  let s, r, t = [];
  const n = () => s, c = (e) => {
    s = i(s, e), u && (window.clearTimeout(r), r = window.setTimeout(() => t.forEach((o) => o()), l));
  }, a = (e) => (t.push(e), () => t = t.filter((o) => o !== e));
  return c({}), { getState: n, dispatch: c, subscribe: a };
};
export {
  l as THROTTLE_MS,
  f as default
};
