import { Class } from '../../common';

export var FreeCellVisitor = (function (Class) {
    function FreeCellVisitor(matrix) {
        Class.call(this);

        this.matrix = matrix;
        this.row = matrix.length - 1;
        this.column = matrix.length - 1;
        this.startColumn = this.column;
        this.dir = -1;
        this.c = 0;
    }

    if ( Class ) FreeCellVisitor.__proto__ = Class;
    FreeCellVisitor.prototype = Object.create( Class && Class.prototype );
    FreeCellVisitor.prototype.constructor = FreeCellVisitor;

    FreeCellVisitor.prototype.move = function move () {
        this.row += this.dir * this.c;
        this.c ^= 1;
        this.column = this.startColumn - this.c;
    };

    FreeCellVisitor.prototype.getNextCell = function getNextCell () {
        var this$1 = this;

        while (this.matrix[this.row][this.column] !== undefined) {
            this$1.move();

            if (this$1.row < 0 || this$1.row >= this$1.matrix.length) {
                this$1.dir = -this$1.dir;
                this$1.startColumn -= this$1.startColumn !== 8 ? 2 : 3;
                this$1.column = this$1.startColumn;
                this$1.row = this$1.dir < 0 ? this$1.matrix.length - 1 : 0;
            }
        }

        return {
            row: this.row,
            column: this.column
        };
    };

    FreeCellVisitor.prototype.getNextRemainderCell = function getNextRemainderCell () {
        this.move();

        if (this.matrix[this.row][this.column] === undefined) {
            return {
                row: this.row,
                column: this.column
            };
        }
    };

    return FreeCellVisitor;
}(Class));
