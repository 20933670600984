import { drawing } from '@progress/kendo-drawing';
import { SankeyElement } from './element';
import { deepExtend } from '../common';
import { defined } from '../drawing-utils';

export var Link = (function (SankeyElement) {
    function Link () {
        SankeyElement.apply(this, arguments);
    }

    if ( SankeyElement ) Link.__proto__ = SankeyElement;
    Link.prototype = Object.create( SankeyElement && SankeyElement.prototype );
    Link.prototype.constructor = Link;

    Link.prototype.getElement = function getElement () {
        var link = this.options.link;
        var source = link.source;
        var target = link.target;
        var y0 = link.y0;
        var y1 = link.y1;
        var xC = (source.x0 + target.x1) / 2;

        return new drawing.Path(this.visualOptions())
            .moveTo(source.x1, y0).curveTo([xC, y0], [xC, y1], [target.x0, y1]);
    };

    Link.prototype.visualOptions = function visualOptions () {
        var options = this.options;
        var link = this.options.link;
        return {
            stroke: {
                width: options.link.width,
                color: link.color || options.color,
                opacity: defined(link.opacity) ? link.opacity : options.opacity
            }
        };
    };

    return Link;
}(SankeyElement));

export var resolveLinkOptions = function (link, options, sourceNode, targetNode) {
    var linkOptions = deepExtend({},
        options,
        {
            link: link,
            opacity: link.opacity,
            color: link.color,
            colorType: link.colorType,
            visual: link.visual,
            highlight: link.highlight
        }
    );

    if (linkOptions.colorType === 'source') {
        linkOptions.color = sourceNode.options.fill.color;
    } else if (linkOptions.colorType === 'target') {
        linkOptions.color = targetNode.options.fill.color;
    }

    return linkOptions;
};
