import {
    Class,
    valueOrDefault
} from '../common';

import { Location } from './location';

var math = Math,
    max = math.max,
    min = math.min;

export var Extent = (function (Class) {
    function Extent(initialNw, initialSe) {
        Class.call(this);
        var nw = Location.create(initialNw);
        var se = Location.create(initialSe);

        if (nw.lng + 180 > se.lng + 180 && nw.lat + 90 < se.lat + 90) {
            this.se = nw;
            this.nw = se;
        } else {
            this.se = se;
            this.nw = nw;
        }
    }

    if ( Class ) Extent.__proto__ = Class;
    Extent.prototype = Object.create( Class && Class.prototype );
    Extent.prototype.constructor = Extent;

    var staticAccessors = { World: { configurable: true } };

    Extent.prototype.contains = function contains (loc) {
        var nw = this.nw, se = this.se, lng = valueOrDefault(loc.lng, loc[1]), lat = valueOrDefault(loc.lat, loc[0]);

        return loc &&
            lng + 180 >= nw.lng + 180 && lng + 180 <= se.lng + 180 &&
            lat + 90 >= se.lat + 90 && lat + 90 <= nw.lat + 90;
    };

    Extent.prototype.center = function center () {
        var nw = this.nw;
        var se = this.se;
        var lng = nw.lng + (se.lng - nw.lng) / 2;
        var lat = nw.lat + (se.lat - nw.lat) / 2;

        return new Location(lat, lng);
    };

    Extent.prototype.containsAny = function containsAny (locs) {
        var this$1 = this;

        var result = false;

        for (var i = 0; i < locs.length; i++) {
            result = result || this$1.contains(locs[i]);
        }

        return result;
    };

    Extent.prototype.include = function include (loc) {
        var nw = this.nw, se = this.se, lng = valueOrDefault(loc.lng, loc[1]), lat = valueOrDefault(loc.lat, loc[0]);

        nw.lng = min(nw.lng, lng);
        nw.lat = max(nw.lat, lat);
        se.lng = max(se.lng, lng);
        se.lat = min(se.lat, lat);
    };

    Extent.prototype.includeAll = function includeAll (locs) {
        var this$1 = this;

        for (var i = 0; i < locs.length; i++) {
            this$1.include(locs[i]);
        }
    };

    Extent.prototype.edges = function edges () {
        var nw = this.nw, se = this.se;

        return {
            nw: this.nw,
            ne: new Location(nw.lat, se.lng),
            se: this.se,
            sw: new Location(se.lat, nw.lng)
        };
    };

    Extent.prototype.toArray = function toArray () {
        var nw = this.nw, se = this.se;

        return [
            nw,
            new Location(nw.lat, se.lng),
            se,
            new Location(se.lat, nw.lng)
        ];
    };

    Extent.prototype.overlaps = function overlaps (extent) {
        return this.containsAny(extent.toArray()) ||
            extent.containsAny(this.toArray());
    };

    Extent.create = function create (a, b) {
        if (a instanceof Extent) {
            return a;
        } else if (a && b) {
            return new Extent(a, b);
        } else if (a && a.length === 4 && !b) {
            return new Extent([
                a[0],
                a[1]
            ], [
                a[2],
                a[3]
            ]);
        }
    };

    staticAccessors.World.get = function () {
        return new Extent([ 90, -180 ], [ -90, 180 ]);
    };

    Object.defineProperties( Extent, staticAccessors );

    return Extent;
}(Class));
