/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as h from "react";
import { getIndex as a } from "@progress/kendo-react-data-tools";
class g {
  constructor(e, i, n) {
    this.refDragElementClue = h.createRef(), this.refDropElementClue = h.createRef(), this.columns = [], this.reorderable = !1, this.groupable = !1, this.startColumn = -1, this.startGroup = -1, this.currentColumn = -1, this.currentGroup = -1, this.groupPanelDivElement = null, this.refGroupPanelDiv = (t) => {
      this.groupPanelDivElement = t && t.children ? t.children[0] : t;
    }, this.pressHandler = (t, r) => {
      const s = this.getColumnIndex(t, r);
      if (this.startGroup = this.getGroupIndex(t), s >= 0) {
        const u = this.columns[s];
        (u.reorderable && this.reorderable || u.groupable && this.groupable) && (this.startColumn = s);
      }
    }, this.dragHandler = (t, r) => {
      if (t.isTouch || t.originalEvent.preventDefault(), t.originalEvent.stopPropagation(), this.startColumn === -1 && this.startGroup === -1)
        return;
      this.currentColumn = this.getColumnIndex(t, r), this.currentGroup = this.isTargetGroupingContainer(t) ? this.getCurrentGroupsLength : this.getGroupIndex(t);
      const s = !this.isValid();
      s && (this.currentColumn = -1, this.currentGroup = -1);
      const u = this.currentColumn >= 0 ? r.children[this.columns[this.currentColumn].index] : this.isTargetGroupingContainer(t) ? t.originalEvent.target : this.groupPanelDivElement && this.groupPanelDivElement.children[this.currentGroup];
      this.updateDragElementClue(t, r, u, s), this.updateDropElementClue(t, r, u, s);
    }, this.releaseHandler = (t) => {
      const r = this.startColumn, s = this.currentColumn, u = this.startGroup, l = this.currentGroup;
      t.originalEvent.preventDefault(), this.dropElementClue && this.dropElementClue.setState({ visible: !1 }), this.dragElementClue && this.dragElementClue.setState({ visible: !1 });
      const o = this.isValid();
      this.startColumn = this.startGroup = this.currentColumn = this.currentGroup = -1, o && (r >= 0 && s >= 0 ? this.columnReorder(r, s, t.originalEvent) : u >= 0 && l >= 0 ? this.groupReorder(u, l, t.originalEvent) : r >= 0 && l >= 0 && this.columnToGroup(r, l, t.originalEvent));
    }, this.columnReorder = e, this.groupReorder = i, this.columnToGroup = n;
  }
  get dragClueRef() {
    return this.refDragElementClue;
  }
  get dropClueRef() {
    return this.refDropElementClue;
  }
  get getCurrentGroupsLength() {
    const e = this.groupPanelDivElement && this.groupPanelDivElement.children;
    return e && e.length || 0;
  }
  get dragElementClue() {
    return this.refDragElementClue.current;
  }
  get dropElementClue() {
    return this.refDropElementClue.current;
  }
  getColumnIndex(e, i) {
    if (!i || i.parentElement === this.groupPanelDivElement)
      return -1;
    const n = a(e, i);
    if (n === -1)
      return -1;
    for (let t = 0; t < i.parentNode.children.length; t++)
      if (i.parentNode.children[t] === i)
        return this.columns.findIndex((r) => r.index === n && r.depth === t);
    return -1;
  }
  isTargetGroupingContainer(e) {
    const i = e.originalEvent.target;
    return i.className.indexOf ? i.className.indexOf("k-grouping-drop-container") !== -1 : !1;
  }
  getGroupIndex(e) {
    return a(e, this.groupPanelDivElement);
  }
  isValid() {
    return this.startGroup >= 0 ? this.currentGroup >= 0 && this.currentGroup !== this.startGroup : this.startColumn === -1 ? !1 : this.currentGroup >= 0 ? this.columns[this.startColumn].groupable === !0 && this.groupable === !0 : this.reorderable === !0 && this.currentColumn >= 0 && this.currentColumn !== this.startColumn && this.columns[this.startColumn].reorderable === !0 && this.columns[this.currentColumn].parentIndex === this.columns[this.startColumn].parentIndex;
  }
  updateDragElementClue(e, i, n, t) {
    if (!this.dragElementClue)
      return;
    const r = this.startColumn >= 0 ? i.children[this.columns[this.startColumn].index].innerText : i.innerText;
    this.dragElementClue.setState({
      visible: !0,
      top: e.pageY + 10,
      left: e.pageX,
      innerText: r,
      status: t || !n ? "k-i-cancel" : "k-i-plus"
    });
  }
  updateDropElementClue(e, i, n, t) {
    if (!this.dropElementClue)
      return;
    if (t || !n) {
      this.dropElementClue.setState({ visible: !1 });
      return;
    }
    const r = n.getBoundingClientRect(), u = (n.closest(".k-grouping-header") || n).getBoundingClientRect();
    let l = r.left + e.pageX - e.clientX - 6;
    !this.isTargetGroupingContainer(e) && (this.currentColumn > this.startColumn || this.currentGroup > this.startGroup && this.startGroup !== -1) && (l += r.width);
    const o = u.top + e.pageY - e.clientY;
    this.dropElementClue.setState({
      visible: !0,
      top: o,
      left: l,
      height: this.currentColumn >= 0 ? i.clientHeight : u.height
    });
  }
}
export {
  g as CommonDragLogic
};
