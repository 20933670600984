/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as a from "react";
import { PanelBarItem as c } from "./PanelBarItem.mjs";
const C = ({
  animation: n = !0,
  keepItemsMounted: r = !1,
  state: e,
  expanded: o,
  handleSelect: f,
  children: p,
  parentExpanded: i = !0,
  level: t = 0,
  parentPrivateKey: u = []
}) => a.Children.map(p, (s, E) => {
  if (s && s.type === c) {
    let m, d = l(s, u, E);
    if (s.props.children) {
      const g = {
        animation: n,
        keepItemsMounted: r,
        state: e,
        expanded: o,
        handleSelect: f,
        children: s.props.children,
        parentExpanded: (o || []).indexOf(d) > -1,
        level: t + 1,
        parentPrivateKey: [...u, d]
      };
      m = C(g);
    }
    return a.cloneElement(
      s,
      {
        ...s.props,
        animation: s.props.animation !== void 0 ? s.props.animation : n,
        keepItemsMounted: r,
        id: s.props.id || `k-panelbar-item-default-${d}`,
        uniquePrivateKey: d,
        parentUniquePrivateKey: u,
        parentExpanded: i,
        level: t,
        expanded: (o || []).indexOf(d) > -1,
        focused: e.focused === d && e.wrapperFocused,
        selected: e.selected === d,
        children: m,
        onSelect: f
      }
    );
  } else
    return /* @__PURE__ */ a.createElement("div", { className: "k-panelbar-content k-content" }, s);
}), q = (n) => {
  const r = a.Children.toArray(n.children)[0];
  return r ? l(r, [], 0) : "";
}, b = (n, r, e = {
  expanded: n.expanded || [],
  selected: n.selected || "",
  focused: n.focused || "",
  wrapperFocused: !1
}, o = !0, f = []) => (a.Children.map(n.children, (p, i) => {
  if (p && p.type === c) {
    let t = l(p, f, i);
    !p.props.disabled && o && (p.props.selected && (e.selected = t), p.props.focused && (e.focused = t), p.props.expanded && (r === "multiple" ? e.expanded.push(t) : r === "single" && (e.expanded = [t])), p.props.children && (e = b(
      p.props,
      r,
      e,
      !!p.props.expanded,
      [...f, t]
    )));
  }
}), e), l = (n, r, e) => n && n.props && n.props.id ? n.props.id : r.length ? r[r.length - 1] + `.${e}` : `.${e}`;
function I(n, r = []) {
  return (n || []).forEach((e) => {
    e.disabled || (r.push(e), e.expanded && e.children && I(e.children, r));
  }), r;
}
function v(n, r = []) {
  return a.Children.forEach(n, (e) => {
    e && e.props && !e.props.disabled && (r.push(e), e.props.children && v(e.props.children, r));
  }), r;
}
function y(n, r = []) {
  return a.Children.forEach(n, (e) => {
    e && e.props && !e.props.disabled && (e.props.expanded || e.props.parentExpanded) && (r.push(e), e.props.children && y(e.props.children, r));
  }), r;
}
const F = (n) => n != null;
var x;
((n) => {
  function r(e) {
    return e.map((o, f) => {
      let p;
      return o.content && (p = o.content), o.children && (p = r(o.children)), /* @__PURE__ */ a.createElement(
        c,
        {
          ...o,
          children: p,
          key: o.id || f
        }
      );
    });
  }
  n.mapItemsToComponents = r;
})(x || (x = {}));
const $ = (n, r) => n.length !== r.length ? !1 : n.every((e, o) => e === r[o]);
export {
  x as PanelBarUtils,
  v as flatChildren,
  y as flatVisibleChildren,
  I as flatVisibleItems,
  q as getFirstId,
  b as getInitialState,
  $ as isArrayEqual,
  F as isPresent,
  C as renderChildren
};
