import {
    Class,
    addClass,
    isArray,
    getter,
    deepExtend,
    setDefaultOptions,
    renderIcon
} from '../../common';

import { Layer } from './layer';
import { Location } from '../location';
import {
    proxy,
    on,
    off,
    toHyphens,
    toPixels,
    convertToHtml
} from '../utils';
import TemplateService from '../../services/template-service';

var CLICK = "click";
var MOUSE_ENTER = "mouseenter";
var MOUSE_LEAVE = "mouseleave";

var extend = Object.assign;
var MARKER_CLASS_NAME = "k-marker";
var MARKER_CLASS = "." + MARKER_CLASS_NAME;

export var MarkerLayer = (function (Layer) {
    function MarkerLayer(map, options) {
        Layer.call(this, map, options);

        this._markerClickHandler = proxy(this._markerClick, this);
        on(this.element, CLICK, MARKER_CLASS, this._markerClickHandler);

        this.items = [];

        this._load(this._readData());
    }

    if ( Layer ) MarkerLayer.__proto__ = Layer;
    MarkerLayer.prototype = Object.create( Layer && Layer.prototype );
    MarkerLayer.prototype.constructor = MarkerLayer;

    MarkerLayer.prototype.destroy = function destroy () {
        Layer.prototype.destroy.call(this);
        off(this.element, CLICK, this._markerClickHandler);
        this.clear();
    };

    MarkerLayer.prototype.add = function add (args) {
        var this$1 = this;

        if (isArray(args)) {
            for (var i = 0; i < args.length; i++) {
                this$1._addOne(args[i]);
            }
        } else {
            return this._addOne(args);
        }
    };

    MarkerLayer.prototype.remove = function remove (marker) {
        marker.destroy();
        var index = (this.items || []).indexOf(marker);

        if (index > -1) {
            this.items.splice(index, 1);
        }
    };

    MarkerLayer.prototype.clear = function clear () {
        var this$1 = this;

        for (var i = 0; i < this.items.length; i++) {
            this$1.items[i].destroy();
        }

        this.items = [];
    };

    MarkerLayer.prototype.update = function update (marker) {
        var location = marker.location();

        if (location) {
            marker.showAt(this.map.locationToView(location));

            var args = {
                marker: marker,
                layer: this
            };

            this.map.trigger('markerActivate', args);
        }
    };

    MarkerLayer.prototype._reset = function _reset () {
        var this$1 = this;

        Layer.prototype._reset.call(this);

        var items = this.items;

        for (var i = 0; i < items.length; i++) {
            this$1.update(items[i]);
        }
    };

    MarkerLayer.prototype.bind = function bind (options, dataItem) {
        var marker = Marker.create(options, this.options);
        marker.dataItem = dataItem;

        var args = {
            marker: marker,
            layer: this
        };

        var cancelled = this.map.trigger('markerCreated', args);

        if (!cancelled) {
            this.add(marker);
            return marker;
        }
    };

    MarkerLayer.prototype._addOne = function _addOne (arg) {
        var marker = Marker.create(arg, this.options);
        marker.addTo(this);
        return marker;
    };

    MarkerLayer.prototype._readData = function _readData () {
        var data = this.options.data || [];
        return data;
    };

    MarkerLayer.prototype._load = function _load (data) {
        var this$1 = this;

        this._data = data;
        this.clear();

        var getLocation = getter(this.options.locationField);
        var getTitle = getter(this.options.titleField);

        for (var i = 0; i < data.length; i++) {
            var dataItem = data[i];

            this$1.bind({
                location: getLocation(dataItem),
                title: getTitle(dataItem)
            }, dataItem);
        }
    };

    MarkerLayer.prototype._markerClick = function _markerClick (e) {
        var marker = e.currentTarget._kendoNode;

        var args = {
            layer: this,
            layerIndex: this._layerIndex(),
            marker: marker,
            markerIndex: (this.items || []).indexOf(marker),
            originalEvent: e
        };

        this.map.trigger('markerClick', args);
    };

    MarkerLayer.prototype._markerMouseEnter = function _markerMouseEnter (e) {
        var args = this._createMarkerEventArgs(e);
        this.map.trigger("markerMouseEnter", args);
    };

    MarkerLayer.prototype._markerMouseLeave = function _markerMouseLeave (e) {
        var args = this._createMarkerEventArgs(e);
        this.map.trigger("markerMouseLeave", args);
    };

    MarkerLayer.prototype._createMarkerEventArgs = function _createMarkerEventArgs (e) {
        var marker = e.marker;

        var args = extend({}, {
            layer: this,
            layerIndex: this._layerIndex(),
            marker: marker,
            markerIndex: (this.items || []).indexOf(marker)
        }, e);

        return args;
    };

    return MarkerLayer;
}(Layer));

setDefaultOptions(MarkerLayer, {
    zIndex: 1000,
    autoBind: true,
    locationField: 'location',
    titleField: 'title',
    template: ""
});

export var Marker = (function (Class) {
    function Marker(options) {
        Class.call(this);
        this.options = options || {};
    }

    if ( Class ) Marker.__proto__ = Class;
    Marker.prototype = Object.create( Class && Class.prototype );
    Marker.prototype.constructor = Marker;

    Marker.prototype.destroy = function destroy () {
        this.layer = null;
        this.unbindEvents();
        this.hide();
    };

    Marker.prototype.addTo = function addTo (parent) {
        this.layer = parent.markers || parent;
        this.layer.items.push(this);
        this.layer.update(this);
    };

    Marker.prototype.location = function location (value) {
        if (value) {
            this.options.location = Location.create(value).toArray();

            if (this.layer) {
                this.layer.update(this);
            }

            return this;
        }

        return Location.create(this.options.location);
    };

    Marker.prototype.showAt = function showAt (point) {
        this.render();

        this._anchor = { left: Math.round(point.x), top: Math.round(point.y) };
        this.element.style.left = toPixels(this._anchor.left);
        this.element.style.top = toPixels(this._anchor.top);
    };

    Marker.prototype.hide = function hide () {
        if (this.element) {
            this.element.remove();
            this.element = null;
        }
    };

    Marker.prototype.bindEvents = function bindEvents () {
        if (!this.element) {
            return;
        }

        this._mouseEnterHandler = proxy(this._mouseEnter, this);
        on(this.element, MOUSE_ENTER, MARKER_CLASS, this._mouseEnterHandler);
        this._mouseLeaveHandler = proxy(this._mouseLeave, this);
        on(this.element, MOUSE_LEAVE, MARKER_CLASS, this._mouseLeaveHandler);
    };

    Marker.prototype.unbindEvents = function unbindEvents () {
        if (!this.element) {
            return;
        }

        off(this.element, MOUSE_ENTER, this._mouseEnterHandler);
        off(this.element, MOUSE_LEAVE, this._mouseLeaveHandler);
    };

    Marker.prototype.render = function render () {
        if (!this.element) {
            var options = this.options;
            var layer = this.layer;
            var element = document.createElement('span');
            addClass(element, MARKER_CLASS_NAME);

            if (this.options.template) {
                var templateFn = this._compileTemplate(this.options.template);
                var templateHtml = templateFn(this.dataItem);
                var templateElement = convertToHtml(templateHtml);
                element.appendChild(templateElement);
            } else if (options.svgIcon) {
                renderIcon(element, { icon: options.svgIcon, iconClass: "k-icon-xxl", svgIcons: this.options.icons.svgIcons, type: "svg" });
            } else {
                var iconOptions = { icon: "map-marker", iconClass: "k-icon-xxl", svgIcons: this.options.icons.svgIcons, type: this.options.icons.type };

                if (options.shape) {
                    if (options.shape === "pinTarget") {
                        iconOptions.icon = "map-marker-target";
                        renderIcon(element, iconOptions);
                    } else if (options.shape === "pin") {
                        renderIcon(element, iconOptions);
                    } else {
                        addClass(element, 'k-icon k-icon-xxl k-i-marker-' + toHyphens(options.shape || 'pin'));
                    }
                } else {
                    renderIcon(element, iconOptions);
                }
            }

            if (options.title) {
                element.setAttribute("title", options.title);
            }

            var attributes = options.attributes || {};
            Object.keys(attributes).forEach(function(key) {
                element.setAttribute(key, attributes[key]);
            });

            element._kendoNode = this;
            element.style.zIndex = options.zIndex;

            this.element = element;

            if (layer) {
                layer.element.appendChild(this.element);
            }

            this.bindEvents();
        }
    };

    Marker.prototype._mouseEnter = function _mouseEnter (e) {
        var args = this._createEventArgs(e);
        this.layer._markerMouseEnter(args);

        this.layer.map._tooltip.show({
            top: this._anchor.top - this.element.offsetHeight,
            left: this._anchor.left
        }, this._tooltipContext());
    };

    Marker.prototype._tooltipContext = function _tooltipContext () {
        return {
            type: 'marker',
            layerIndex: this.layer._layerIndex(),
            className: 'k-map-marker-tooltip',
            dataItem: this.dataItem,
            title: this.options.title,
            location: this.location()
        };
    };

    Marker.prototype._mouseLeave = function _mouseLeave (e) {
        var args = this._createEventArgs(e);
        this.layer._markerMouseLeave(args);
    };

    Marker.prototype._createEventArgs = function _createEventArgs (e) {
        var args = {
            marker: this,
            originalEvent: e
        };

        return args;
    };

    Marker.prototype._compileTemplate = function _compileTemplate (template) {
        return TemplateService.compile(template, {
            paramName: "dataItem",
            useWithBlock: false
        });
    };

    Marker.create = function create (arg, defaults) {
        if (arg instanceof Marker) {
            return arg;
        }

        return new Marker(deepExtend({}, defaults, arg));
    };

    return Marker;
}(Class));
