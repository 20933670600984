/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as t from "react";
import { Icon as g } from "./Icon.mjs";
import { SvgIcon as I } from "./SvgIcon.mjs";
import { IconsContext as l } from "./IconsContext.mjs";
const v = t.forwardRef((o, c) => {
  var r, i;
  const { type: m, icons: e } = t.useContext(l), { icon: s, ...f } = o;
  let n = m === "svg" ? ((r = o.icon) == null ? void 0 : r.name) || o.name : o.name || ((i = o.icon) == null ? void 0 : i.name);
  n = n && e && e[n] && typeof e[n] == "string" ? e[n] : n;
  const a = n && e && e[n] && typeof e[n] != "string" ? e[n] : s;
  return m === "svg" && a ? /* @__PURE__ */ t.createElement(I, { ...o, icon: a, ref: c }) : /* @__PURE__ */ t.createElement(g, { ...f, name: n, ref: c });
});
v.displayName = "KendoIconWrap";
export {
  v as IconWrap
};
