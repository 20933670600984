/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import p from "prop-types";
import { Animation as g } from "@progress/kendo-react-animation";
import { validatePackage as N, FOCUSABLE_ELEMENTS as S, Navigation as C, classNames as x } from "@progress/kendo-react-common";
import { packageMetadata as D } from "../package-metadata.mjs";
import { ActionSheetItem as E } from "./ActionSheetItem.mjs";
import { headerDisplayName as v } from "./ActionSheetHeader.mjs";
import { footerDisplayName as I } from "./ActionSheetFooter.mjs";
import { contentDisplayName as w } from "./ActionSheetContent.mjs";
const m = class m extends e.Component {
  /** @hidden */
  constructor(o) {
    super(o), this.actionSheetRef = e.createRef(), this.actionSheetTitleClass = "k-actionsheet-title", this.ariaLabeledBy = this.actionSheetTitleClass + 1, this.animationDuration = 300, this.bottomPosition = { bottom: "0", width: "100%" }, this.handleKeyDown = (t) => {
      this.props.navigatable && this.navigation.triggerKeyboardEvent(t);
    }, this.onTab = (t, a, i) => {
      i.preventDefault(), i.shiftKey ? a.focusPrevious(t) : a.focusNext(t);
    }, this.handleOverlayClick = (t) => {
      this.props.onOverlayClick && this.props.onOverlayClick.call(void 0, t), this.props.onClose && this.props.onClose.call(void 0, t), this.props.animation || this.hideActionSheet();
    }, this.handleItemClick = (t) => {
      this.props.onItemClick && this.props.onItemClick.call(void 0, t), this.props.onItemSelect && this.props.onItemSelect.call(void 0, t), this.props.animation || this.hideActionSheet();
    }, this.onEnter = (t, a, i) => {
      if (t.ariaDisabled)
        return;
      const h = t.className && t.className.indexOf ? t.className.indexOf("k-actionsheet-item") !== -1 : !1, l = a.elements.filter((s) => s.className.indexOf("k-actionsheet-item") !== -1);
      if (h && this.props.onItemClick) {
        i.preventDefault();
        const s = this.props.items[l.indexOf(t)];
        this.props.onItemClick.call(void 0, {
          syntheticEvent: i,
          item: s,
          title: s && s.title
        });
      }
      if (h && this.props.onItemSelect) {
        i.preventDefault();
        const s = this.props.items[l.indexOf(t)];
        this.props.onItemSelect.call(void 0, {
          syntheticEvent: i,
          item: s,
          title: s && s.title
        });
      }
      this.props.animation || this.hideActionSheet();
    }, this.onEscape = (t, a, i) => {
      this.props.onOverlayClick && (i.preventDefault(), this.props.onOverlayClick.call(void 0, i)), this.props.onClose && (i.preventDefault(), this.props.onClose.call(void 0, i)), this.props.animation || this.hideActionSheet();
    }, this.hideActionSheet = () => {
      this.setState({ show: !1 });
    }, this.children = (t) => {
      const a = {};
      return e.Children.forEach(t, (i) => {
        i && (a[i.type.displayName] = i);
      }), a;
    }, this.state = {
      show: !1,
      slide: !1
    }, N(D);
  }
  /** @hidden */
  componentDidMount() {
    this.props.expand && !this.state.show && this.setState({ show: !0 });
  }
  /** @hidden */
  componentDidUpdate(o, t) {
    const a = this.actionSheetRef.current;
    if (this.props.expand && !this.state.show && this.setState({ show: !0 }), this.props.expand && this.state.show && !this.state.slide && this.setState({ slide: !0 }), !this.props.expand && this.state.show && this.state.slide && this.setState({ slide: !1 }), t !== this.state && this.state.slide && a && !this.props.className && (a.style.setProperty("--kendo-actionsheet-height", "auto"), a.style.setProperty("--kendo-actionsheet-max-height", "none")), a && this.props.navigatable) {
      const h = [
        ".k-actionsheet-item",
        ...[
          ".k-actionsheet-footer",
          ".k-actionsheet-content",
          ".k-actionsheet-titlebar"
        ].map((l) => S.concat(this.props.navigatableElements).map(
          (s) => `${l} ${s}`
        )).flat()
      ];
      this.navigation = new C({
        tabIndex: this.props.tabIndex || 0,
        root: this.actionSheetRef,
        rovingTabIndex: !1,
        selectors: h,
        keyboardEvents: {
          keydown: {
            Tab: this.onTab,
            Enter: this.onEnter,
            Escape: this.onEscape
          }
        }
      }), this.navigation.focusElement(this.navigation.first, null);
    }
  }
  /** @hidden */
  render() {
    const {
      title: o,
      subTitle: t,
      animationStyles: a,
      animation: i,
      expand: h,
      tabIndex: l,
      items: s
    } = this.props, r = s == null ? void 0 : s.filter((n) => !n.group || n.group === "top"), d = s == null ? void 0 : s.filter((n) => n.group === "bottom"), y = r && r.length > 0 && d && d.length > 0, f = this.children(this.props.children), u = this.props.animationDuration || this.animationDuration, k = this.state.slide && /* @__PURE__ */ e.createElement(
      "div",
      {
        className: x("k-actionsheet", this.props.className, {
          "k-actionsheet-bottom": !this.props.className
        }),
        role: "dialog",
        "aria-modal": "true",
        "aria-hidden": !1,
        "aria-labelledby": this.ariaLabeledBy,
        ref: this.actionSheetRef,
        onKeyDown: this.handleKeyDown
      },
      f[v] && !o && !t && f[v],
      (o || t) && /* @__PURE__ */ e.createElement("div", { className: "k-actionsheet-titlebar k-text-center" }, /* @__PURE__ */ e.createElement("div", { className: "k-actionsheet-titlebar-group k-hbox" }, /* @__PURE__ */ e.createElement("div", { className: this.actionSheetTitleClass, id: this.ariaLabeledBy }, o && /* @__PURE__ */ e.createElement("div", null, o), t && /* @__PURE__ */ e.createElement("div", { className: "k-actionsheet-subtitle" }, t)))),
      f[w] || /* @__PURE__ */ e.createElement("div", { className: "k-actionsheet-content" }, /* @__PURE__ */ e.createElement("div", { className: "k-list-ul", role: "group" }, r && r.map((n, c) => /* @__PURE__ */ e.createElement(
        E,
        {
          ...n,
          id: c,
          key: c,
          item: n,
          tabIndex: l || 0,
          onClick: this.handleItemClick
        }
      ))), y && /* @__PURE__ */ e.createElement("hr", { className: "k-hr" }), /* @__PURE__ */ e.createElement("div", { className: "k-list-ul", role: "group" }, d && d.map((n, c) => /* @__PURE__ */ e.createElement(
        E,
        {
          ...n,
          id: c + ((r == null ? void 0 : r.length) || 0),
          key: c,
          item: n,
          tabIndex: l || 0,
          onClick: this.handleItemClick
        }
      )))),
      f[I]
    );
    return /* @__PURE__ */ e.createElement(e.Fragment, null, h || this.state.show ? /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("div", { className: "k-actionsheet-container" }, /* @__PURE__ */ e.createElement(
      "div",
      {
        className: "k-overlay",
        onClick: this.handleOverlayClick
      }
    ), i ? /* @__PURE__ */ e.createElement(
      g,
      {
        transitionName: this.state.slide ? "slide-up" : "slide-down",
        onExited: this.hideActionSheet,
        transitionEnterDuration: u,
        transitionExitDuration: u,
        animationEnteringStyle: a || this.bottomPosition,
        animationEnteredStyle: a || this.bottomPosition,
        animationExitingStyle: a || this.bottomPosition,
        exit: !0,
        enter: !0,
        appear: !1
      },
      k
    ) : k)) : null);
  }
};
m.displayName = "KendoReactActionSheet", m.propTypes = {
  items: p.array,
  subTitle: p.string,
  title: p.string,
  navigatable: p.bool,
  navigatableElements: p.array
}, m.defaultProps = {
  navigatable: !0,
  navigatableElements: []
};
let b = m;
export {
  b as ActionSheet
};
