import { geometry, drawing } from '@progress/kendo-drawing';
import { SankeyElement } from './element';
import { deepExtend } from '../common';

export var Node = (function (SankeyElement) {
    function Node () {
        SankeyElement.apply(this, arguments);
    }

    if ( SankeyElement ) Node.__proto__ = SankeyElement;
    Node.prototype = Object.create( SankeyElement && SankeyElement.prototype );
    Node.prototype.constructor = Node;

    Node.prototype.getElement = function getElement () {
        var options = this.options;
        var node = options.node;
        var rect = new geometry.Rect([node.x0, node.y0], [node.x1 - node.x0, node.y1 - node.y0]);

        return drawing.Path.fromRect(rect, this.visualOptions());
    };

    Node.prototype.visualOptions = function visualOptions () {
        var options = deepExtend({}, this.options, this.options.node);

        return {
            fill: {
                color: options.color,
                opacity: options.opacity
            },
            stroke: { width: 0 },
        };
    };

    return Node;
}(SankeyElement));

export var nodeColor = function (node, nodesColors, index) { return node.color || nodesColors[index % nodesColors.length]; };

export var resolveNodeOptions = function (node, options, nodesColors, index) {
    var nodeOptions = deepExtend({}, options, options.node);
    return deepExtend({},
        { color: nodeColor(node, nodesColors, index) },
        nodeOptions,
        { node: node },
        {
            visual: node.visual,
            opacity: node.opacity,
            offset: node.offset,
            color: node.color
        }
    );
};
