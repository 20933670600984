/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import { DropDownList as i } from "@progress/kendo-react-dropdowns";
import { Input as p, NumericTextBox as s } from "@progress/kendo-react-inputs";
import { DatePicker as h } from "@progress/kendo-react-dateinputs";
import { cellInputChange as l, cellOperatorChange as u, cellBoolDropdownChange as C } from "../filterCommon.mjs";
class E extends r.Component {
  constructor(t) {
    super(t), this.renderOperatorEditor = () => {
      if (this.props.filterType === "boolean")
        return;
      const n = this.props.operators.find((o) => o.operator === this.props.operator) || null;
      return /* @__PURE__ */ r.createElement(
        i,
        {
          value: n,
          onChange: this.operatorChange,
          data: this.props.operators,
          textField: "text"
        }
      );
    }, this.inputChange = this.inputChange.bind(this), this.operatorChange = this.operatorChange.bind(this), this.boolDropdownChange = this.boolDropdownChange.bind(this);
  }
  /**
   * @hidden
   */
  render() {
    return /* @__PURE__ */ r.createElement(r.Fragment, null, this.renderOperatorEditor(), this.filterComponent(this.props.filterType, this.props.value, this.props.booleanValues));
  }
  inputChange(t, n) {
    l(t, n, this.props);
  }
  operatorChange(t) {
    u(t, this.props.value, this.props.onChange);
  }
  boolDropdownChange(t) {
    C(t, this.props.onChange);
  }
  filterComponent(t, n, o) {
    switch (t) {
      case "numeric":
        return /* @__PURE__ */ r.createElement(
          s,
          {
            value: n,
            onChange: (e) => {
              this.inputChange(e.value, e.syntheticEvent);
            },
            rangeOnEnter: !1
          }
        );
      case "date":
        return /* @__PURE__ */ r.createElement(
          h,
          {
            value: n,
            onChange: (e) => {
              this.inputChange(e.value, e.syntheticEvent);
            }
          }
        );
      case "boolean": {
        const e = (a) => a == null;
        return /* @__PURE__ */ r.createElement(
          i,
          {
            onChange: this.boolDropdownChange,
            value: o.find((a) => a.operator === (e(n) ? "" : n)),
            data: o,
            textField: "text"
          }
        );
      }
      default:
        return /* @__PURE__ */ r.createElement(
          p,
          {
            value: n || "",
            onChange: (e) => {
              this.inputChange(e.target.value, e.syntheticEvent);
            }
          }
        );
    }
  }
}
export {
  E as GridColumnMenuFilterCell
};
