import { Class } from '../../common';
import { Encoding } from './encoding';
import deepExtend from '../../common/deep-extend';
import inArray from '../../common/in-array';

var numberRegex = /^\d+$/;
var alphanumericRegex = /^[a-z0-9]+$/i;
var extend = Object.assign;

export var State128 = (function (Class) {
    function State128(encoding) {
        Class.call(this);
        this.encoding = encoding;
        this.initProperties();
    }

    if ( Class ) State128.__proto__ = Class;
    State128.prototype = Object.create( Class && Class.prototype );
    State128.prototype.constructor = State128;

    State128.prototype.initProperties = function initProperties () { };

    State128.prototype.addStart = function addStart () { };

    State128.prototype.is = function is () { };

    State128.prototype.isCode = function isCode () {
        return false;
    };

    State128.prototype.move = function move () { };

    State128.prototype.pushState = function pushState () { };

    return State128;
}(Class));

export var State128AB = (function (State128) {
    function State128AB(encoding, states) {
        State128.call(this, encoding);
        this.states = states;
        this._initMoves(states);
    }

    if ( State128 ) State128AB.__proto__ = State128;
    State128AB.prototype = Object.create( State128 && State128.prototype );
    State128AB.prototype.constructor = State128AB;

    State128AB.prototype.initProperties = function initProperties () {
        State128.prototype.initProperties.call(this);

        deepExtend(this, {
            FNC4: "FNC4",
            SHIFT: 98
        });
    };

    State128AB.prototype.addStart = function addStart () {
        this.encoding.addPattern(this.START);
    };

    State128AB.prototype.is = function is (value, index) {
        var code = value.charCodeAt(index);
        return this.isCode(code);
    };

    State128AB.prototype.move = function move (encodingState) {
        var idx = 0;

        while (!this._moves[idx].call(this, encodingState) && idx < this._moves.length) {
            idx++;
        }
    };

    State128AB.prototype.pushState = function pushState (encodingState) {
        var this$1 = this;

        var states = this.states,
            value = encodingState.value,
            maxLength = value.length,
            code;

        if (inArray("C", states)) {
            var numberMatch = value.substr(encodingState.index).match(/\d{4,}/g);

            if (numberMatch) {
                maxLength = value.indexOf(numberMatch[0], encodingState.index);
            }
        }

        while ((code = encodingState.value.charCodeAt(encodingState.index)) >= 0 &&
            this.isCode(code) &&
            encodingState.index < maxLength) {
            this$1.encoding.addPattern(this$1.getValue(code));
            encodingState.index++;
        }
    };

    State128AB.prototype._initMoves = function _initMoves (states) {
        this._moves = [];

        if (inArray(this.FNC4, states)) {
            this._moves.push(this._moveFNC);
        }

        if (inArray(this.shiftKey, states)) {
            this._moves.push(this._shiftState);
        }

        this._moves.push(this._moveState);
    };

    State128AB.prototype._moveFNC = function _moveFNC (encodingState) {
        if (encodingState.fnc) {
            encodingState.fnc = false;
            return encodingState.previousState === this.key;
        }
    };

    State128AB.prototype._shiftState = function _shiftState (encodingState) {
        if (encodingState.previousState === this.shiftKey &&
            (encodingState.index + 1 >= encodingState.value.length ||
                this.encoding[this.shiftKey].is(encodingState.value, encodingState.index + 1))) {
            this.encoding.addPattern(this.SHIFT);
            encodingState.shifted = true;
            return true;
        }
    };

    State128AB.prototype._moveState = function _moveState () {
        this.encoding.addPattern(this.MOVE);
        return true;
    };

    return State128AB;
}(State128));

var states128 = {};

states128.A = (function (State128AB) {
    function State128A () {
        State128AB.apply(this, arguments);
    }

    if ( State128AB ) State128A.__proto__ = State128AB;
    State128A.prototype = Object.create( State128AB && State128AB.prototype );
    State128A.prototype.constructor = State128A;

    State128A.prototype.initProperties = function initProperties () {
        State128AB.prototype.initProperties.call(this);

        extend(this, {
            key: "A",
            shiftKey: "B",
            MOVE: 101,
            START: 103
        });
    };

    State128A.prototype.isCode = function isCode (code) {
        return 0 <= code && code < 96;
    };

    State128A.prototype.getValue = function getValue (code) {
        if (code < 32) {
            return code + 64;
        }

        return code - 32;
    };

    return State128A;
}(State128AB));

states128.B = (function (State128AB) {
    function State128B () {
        State128AB.apply(this, arguments);
    }

    if ( State128AB ) State128B.__proto__ = State128AB;
    State128B.prototype = Object.create( State128AB && State128AB.prototype );
    State128B.prototype.constructor = State128B;

    State128B.prototype.initProperties = function initProperties () {
        State128AB.prototype.initProperties.call(this);

        extend(this, {
            key: "B",
            shiftKey: "A",
            MOVE: 100,
            START: 104
        });
    };

    State128B.prototype.isCode = function isCode (code) {
        return 32 <= code && code < 128;
    };

    State128B.prototype.getValue = function getValue (code) {
        return code - 32;
    };

    return State128B;
}(State128AB));

states128.C = (function (State128) {
    function State128C () {
        State128.apply(this, arguments);
    }

    if ( State128 ) State128C.__proto__ = State128;
    State128C.prototype = Object.create( State128 && State128.prototype );
    State128C.prototype.constructor = State128C;

    State128C.prototype.initProperties = function initProperties () {
        State128.prototype.initProperties.call(this);

        extend(this, {
            key: "C",
            MOVE: 99,
            START: 105
        });
    };

    State128C.prototype.addStart = function addStart () {
        this.encoding.addPattern(this.START);
    };

    State128C.prototype.is = function is (value, index) {
        var next4 = getSubstring(value, index, 4);
        return (index + 4 <= value.length || value.length === 2) && numberRegex.test(next4);
    };

    State128C.prototype.move = function move () {
        this.encoding.addPattern(this.MOVE);
    };

    State128C.prototype.pushState = function pushState (encodingState) {
        var this$1 = this;

        var code;

        while ((code = getSubstring(encodingState.value, encodingState.index, 2)) &&
            numberRegex.test(code) && code.length === 2) {
            this$1.encoding.addPattern(parseInt(code, 10));
            encodingState.index += 2;
        }
    };

    State128C.prototype.getValue = function getValue (code) {
        return code;
    };

    return State128C;
}(State128));

states128.FNC4 = (function (State128) {
    function State128FNC4(encoding, states) {
        State128.call(this, encoding);
        this._initSubStates(states);
    }

    if ( State128 ) State128FNC4.__proto__ = State128;
    State128FNC4.prototype = Object.create( State128 && State128.prototype );
    State128FNC4.prototype.constructor = State128FNC4;

    State128FNC4.prototype.initProperties = function initProperties () {
        State128.prototype.initProperties.call(this);

        extend(this, {
            key: "FNC4",
            dependentStates: ["A", "B"]
        });
    };

    State128FNC4.prototype.addStart = function addStart (encodingState) {
        var code = encodingState.value.charCodeAt(0) - 128,
            subState = this._getSubState(code);

        this.encoding[subState].addStart();
    };

    State128FNC4.prototype.is = function is (value, index) {
        var code = value.charCodeAt(index);
        return this.isCode(code);
    };

    State128FNC4.prototype.isCode = function isCode (code) {
        return 128 <= code && code < 256;
    };

    State128FNC4.prototype.pushState = function pushState (encodingState) {
        var this$1 = this;

        var subState = this._initSubState(encodingState),
            encoding = this.encoding,
            length = subState.value.length;
        var code;

        encodingState.index += length;

        if (length < 3) {
            for (; subState.index < length; subState.index++) {
                code = subState.value.charCodeAt(subState.index);
                subState.state = this$1._getSubState(code);

                if (subState.previousState !== subState.state) {
                    subState.previousState = subState.state;
                    encoding[subState.state].move(subState);
                }

                encoding.addPattern(encoding[subState.state].MOVE);
                encoding.addPattern(encoding[subState.state].getValue(code));
            }
        } else {
            if (subState.state !== subState.previousState) {
                encoding[subState.state].move(subState);
            }

            this._pushStart(subState);
            encoding.pushData(subState, this.subStates);

            if (encodingState.index < encodingState.value.length) {
                this._pushStart(subState);
            }
        }

        encodingState.fnc = true;
        encodingState.state = subState.state;
    };

    State128FNC4.prototype._pushStart = function _pushStart (subState) {
        this.encoding.addPattern(this.encoding[subState.state].MOVE);
        this.encoding.addPattern(this.encoding[subState.state].MOVE);
    };

    State128FNC4.prototype._initSubState = function _initSubState (encodingState) {
        var subState = {
            value: this._getAll(encodingState.value, encodingState.index),
            index: 0
        };

        subState.state = this._getSubState(subState.value.charCodeAt(0));
        subState.previousState = encodingState.previousState === this.key ?
            subState.state :
            encodingState.previousState;

        return subState;
    };

    State128FNC4.prototype._initSubStates = function _initSubStates (states) {
        var this$1 = this;

        this.subStates = [];

        for (var i = 0; i < states.length; i++) {
            if (inArray(states[i], this$1.dependentStates)) {
                this$1.subStates.push(states[i]);
            }
        }
    };

    State128FNC4.prototype._getSubState = function _getSubState (code) {
        var this$1 = this;

        for (var i = 0; i < this.subStates.length; i++) {
            if (this$1.encoding[this$1.subStates[i]].isCode(code)) {
                return this$1.subStates[i];
            }
        }
    };

    State128FNC4.prototype._getAll = function _getAll (value, index) {
        var code;
        var result = "";
        var i = index;

        while ((code = value.charCodeAt(i++)) && this.isCode(code)) {
            result += String.fromCharCode(code - 128);
        }

        return result;
    };

    return State128FNC4;
}(State128));

states128.FNC1 = (function (State128) {
    function States128FNC1(encoding, states) {
        State128.call(this, encoding);
        this.states = states;
    }

    if ( State128 ) States128FNC1.__proto__ = State128;
    States128FNC1.prototype = Object.create( State128 && State128.prototype );
    States128FNC1.prototype.constructor = States128FNC1;

    States128FNC1.prototype.initProperties = function initProperties () {
        State128.prototype.initProperties.call(this);

        extend(this, {
            key: "FNC1",
            startState: "C",
            startAI: "(",
            endAI: ")",
            dependentStates: ["C", "B"],
            applicationIdentifiers: {
                "22": { max: 29, type: "alphanumeric" },
                "402": { length: 17 },
                "7004": { max: 4, type: "alphanumeric" },
                "242": { max: 6, type: "alphanumeric" },
                "8020": { max: 25, type: "alphanumeric" },
                "703": { min: 3, max: 30, type: "alphanumeric" },
                "8008": { min: 8, max: 12, type: "alphanumeric" },
                "253": { min: 13, max: 17, type: "alphanumeric" },
                "8003": { min: 14, max: 30, type: "alphanumeric" },
                multiKey: [{
                    ids: ["15", "17", "8005", "8100"],
                    ranges: [
                        [11, 13],
                        [310, 316],
                        [320, 336],
                        [340, 369]
                    ],
                    type: { length: 6 }
                }, {
                    ids: ["240", "241", "250", "251", "400", "401", "403", "7002", "8004", "8007", "8110"],
                    ranges: [[90 - 99]],
                    type: { max: 30, type: "alphanumeric" }
                }, {
                    ids: ["7001"],
                    ranges: [[410, 414]],
                    type: { length: 13 }
                }, {
                    ids: ["10", "21", "254", "420", "8002"],
                    type: { max: 20, type: "alphanumeric" }
                }, {
                    ids: ["00", "8006", "8017", "8018"],
                    type: { length: 18 }
                }, {
                    ids: ["01", "02", "8001"],
                    type: { length: 14 }
                }, {
                    ids: ["422"],
                    ranges: [
                        [424, 426]
                    ],
                    type: { length: 3 }
                }, {
                    ids: ["20", "8102"],
                    type: { length: 2 }
                }, {
                    ids: ["30", "37"],
                    type: { max: 8, type: "alphanumeric" }
                }, {
                    ids: ["390", "392"],
                    type: { max: 15, type: "alphanumeric" }
                }, {
                    ids: ["421", "423"],
                    type: { min: 3, max: 15, type: "alphanumeric" }
                }, {
                    ids: ["391", "393"],
                    type: { min: 3, max: 18, type: "alphanumeric" }
                }, {
                    ids: ["7003", "8101"],
                    type: { length: 10 }
                }]
            },
            START: 102
        });
    };


    States128FNC1.prototype.addStart = function addStart () {
        this.encoding[this.startState].addStart();
    };

    States128FNC1.prototype.is = function is () {
        return inArray(this.key, this.states);
    };

    States128FNC1.prototype.pushState = function pushState (encodingState) {
        var this$1 = this;

        var encoding = this.encoding,
            value = encodingState.value.replace(/\s/g, ""),
            regexSeparators = new RegExp("[" + this.startAI + this.endAI + "]", "g"),
            index = encodingState.index,
            subState = {
                state: this.startState
            },
            current,
            nextStart,
            separatorLength;

        encoding.addPattern(this.START);

        var trueCondition = true;

        while (trueCondition) {
            subState.index = 0;

            separatorLength = value.charAt(index) === this$1.startAI ? 2 : 0;
            current = separatorLength > 0 ? this$1.getBySeparator(value, index) : this$1.getByLength(value, index);

            if (current.ai.length) {
                nextStart = index + separatorLength + current.id.length + current.ai.length;
            } else {
                nextStart = value.indexOf(this$1.startAI, index + 1);

                if (nextStart < 0) {
                    if (index + current.ai.max + current.id.length + separatorLength < value.length) {
                        throw new Error("Separators are required after variable length identifiers");
                    }

                    nextStart = value.length;
                }
            }

            subState.value = value.substring(index, nextStart).replace(regexSeparators, "");
            this$1.validate(current, subState.value);
            encoding.pushData(subState, this$1.dependentStates);

            if (nextStart >= value.length) {
                break;
            }

            index = nextStart;

            if (subState.state !== this$1.startState) {
                encoding[this$1.startState].move(subState);
                subState.state = this$1.startState;
            }

            if (!current.ai.length) {
                encoding.addPattern(this$1.START);
            }
        }

        encodingState.index = encodingState.value.length;
    };

    States128FNC1.prototype.validate = function validate (current, value) {
        var code = value.substr(current.id.length),
            ai = current.ai;

        if (!ai.type && !numberRegex.test(code)) {
            throw new Error("Application identifier " + current.id + " is numeric only but contains non numeric character(s).");
        }

        if (ai.type === "alphanumeric" && !alphanumericRegex.test(code)) {
            throw new Error("Application identifier " + current.id + " is alphanumeric only but contains non alphanumeric character(s).");
        }

        if (ai.length && ai.length !== code.length) {
            throw new Error("Application identifier " + current.id + " must be " + ai.length + " characters long.");
        }

        if (ai.min && ai.min > code.length) {
            throw new Error("Application identifier " + current.id + " must be at least " + ai.min + " characters long.");
        }

        if (ai.max && ai.max < code.length) {
            throw new Error("Application identifier " + current.id + " must be at most " + ai.max + " characters long.");
        }
    };

    States128FNC1.prototype.getByLength = function getByLength (value, index) {
        var this$1 = this;

        var id;
        var applicationIdentifier;

        for (var i = 2; i <= 4; i++) {
            id = getSubstring(value, index, i);
            applicationIdentifier = this$1.getApplicationIdentifier(id) || this$1.getApplicationIdentifier(id.substring(0, id.length - 1));
            if (applicationIdentifier) {
                return {
                    id: id,
                    ai: applicationIdentifier
                };
            }
        }
        this.unsupportedAIError(id);
    };

    States128FNC1.prototype.unsupportedAIError = function unsupportedAIError (id) {
        throw new Error("'" + id + "' is not a supported Application Identifier");
    };

    States128FNC1.prototype.getBySeparator = function getBySeparator (value, index) {
        var start = value.indexOf(this.startAI, index),
            end = value.indexOf(this.endAI, start),
            id = value.substring(start + 1, end),
            ai = this.getApplicationIdentifier(id) || this.getApplicationIdentifier(id.substr(id.length - 1));

        if (!ai) {
            this.unsupportedAIError(id);
        }

        return {
            ai: ai,
            id: id
        };
    };

    States128FNC1.prototype.getApplicationIdentifier = function getApplicationIdentifier (id) {
        var applicationIdentifier = this.applicationIdentifiers,
            multiKey = applicationIdentifier.multiKey;

        if (applicationIdentifier[id]) {
            return applicationIdentifier[id];
        }

        for (var i = 0; i < multiKey.length; i++) {
            if (multiKey[i].ids && inArray(id, multiKey[i].ids)) {
                return multiKey[i].type;
            } else if (multiKey[i].ranges) {
                var ranges = multiKey[i].ranges;

                for (var j = 0; j < ranges.length; j++) {
                    if (ranges[j][0] <= id && id <= ranges[j][1]) {
                        return multiKey[i].type;
                    }
                }
            }
        }
    };

    return States128FNC1;
}(State128));

export var Code128Base = (function (Encoding) {
    function Code128Base(options) {
        Encoding.call(this, options);
        this._initStates();
    }

    if ( Encoding ) Code128Base.__proto__ = Encoding;
    Code128Base.prototype = Object.create( Encoding && Encoding.prototype );
    Code128Base.prototype.constructor = Code128Base;

    Code128Base.prototype.initProperties = function initProperties () {
        Encoding.prototype.initProperties.call(this);

        extend(this, {
            characterMap: [
                212222, 222122, 222221, 121223, 121322, 131222, 122213, 122312, 132212, 221213,
                221312, 231212, 112232, 122132, 122231, 113222, 123122, 123221, 223211, 221132,
                221231, 213212, 223112, 312131, 311222, 321122, 321221, 312212, 322112, 322211,
                212123, 212321, 232121, 111323, 131123, 131321, 112313, 132113, 132311, 211313,
                231113, 231311, 112133, 112331, 132131, 113123, 113321, 133121, 313121, 211331,
                231131, 213113, 213311, 213131, 311123, 311321, 331121, 312113, 312311, 332111,
                314111, 221411, 431111, 111224, 111422, 121124, 121421, 141122, 141221, 112214,
                112412, 122114, 122411, 142112, 142211, 241211, 221114, 413111, 241112, 134111,
                111242, 121142, 121241, 114212, 124112, 124211, 411212, 421112, 421211, 212141,
                214121, 412121, 111143, 111341, 131141, 114113, 114311, 411113, 411311, 113141,
                114131, 311141, 411131, 211412, 211214, 211232, 2331112
            ],
            STOP: 106
        });
    };

    Code128Base.prototype._initStates = function _initStates () {
        var this$1 = this;

        for (var i = 0; i < this.states.length; i++) {
            this$1[this$1.states[i]] = new states128[this$1.states[i]](this$1, this$1.states);
        }
    };

    Code128Base.prototype.initValue = function initValue (value, width, height) {
        this.pattern = [];
        this.value = value;
        this.width = width;
        this.height = height;
        this.checkSum = 0;
        this.totalUnits = 0;
        this.index = 0;
        this.position = 1;
    };

    Code128Base.prototype.addData = function addData () {
        var encodingState = {
            value: this.value,
            index: 0,
            state: ""
        };

        if (this.value.length === 0) {
            return;
        }

        encodingState.state =
            encodingState.previousState = this.getNextState(encodingState, this.states);

        this.addStart(encodingState);

        this.pushData(encodingState, this.states);

        this.addCheckSum();
        this.addStop();
        this.setBaseUnit();
    };

    Code128Base.prototype.pushData = function pushData (encodingState, states) {
        var this$1 = this;

        var trueCondition = true;

        while (trueCondition) {
            this$1[encodingState.state].pushState(encodingState);

            if (encodingState.index >= encodingState.value.length) {
                break;
            }

            if (!encodingState.shifted) {
                encodingState.previousState = encodingState.state;
                encodingState.state = this$1.getNextState(encodingState, states);
                this$1[encodingState.state].move(encodingState);
            } else {
                var temp = encodingState.state;
                encodingState.state = encodingState.previousState;
                encodingState.previousState = temp;
                encodingState.shifted = false;
            }
        }
    };

    Code128Base.prototype.addStart = function addStart (encodingState) {
        this[encodingState.state].addStart(encodingState);
        this.position = 1;
    };

    Code128Base.prototype.addCheckSum = function addCheckSum () {
        this.checksum = this.checkSum % 103;
        this.addPattern(this.checksum);
    };

    Code128Base.prototype.addStop = function addStop () {
        this.addPattern(this.STOP);
    };

    Code128Base.prototype.setBaseUnit = function setBaseUnit () {
        this.baseUnit = this.width / (this.totalUnits + this.quietZoneLength);
    };

    Code128Base.prototype.addPattern = function addPattern (code) {
        var this$1 = this;

        var pattern = this.characterMap[code].toString();
        var value;

        for (var i = 0; i < pattern.length; i++) {
            value = parseInt(pattern.charAt(i), 10);
            this$1.pattern.push(value);
            this$1.totalUnits += value;
        }
        this.checkSum += code * this.position++;
    };

    Code128Base.prototype.getNextState = function getNextState (encodingState, states) {
        var this$1 = this;

        for (var i = 0; i < states.length; i++) {
            if (this$1[states[i]].is(encodingState.value, encodingState.index)) {
                return states[i];
            }
        }

        this.invalidCharacterError(encodingState.value.charAt(encodingState.index));
    };

    return Code128Base;
}(Encoding));

export var Code128a = (function (Code128Base) {
    function Code128a () {
        Code128Base.apply(this, arguments);
    }

    if ( Code128Base ) Code128a.__proto__ = Code128Base;
    Code128a.prototype = Object.create( Code128Base && Code128Base.prototype );
    Code128a.prototype.constructor = Code128a;

    Code128a.prototype.initProperties = function initProperties () {
        Code128Base.prototype.initProperties.call(this);

        extend(this, {
            name: "Code 128 A",
            states: ["A"]
        });
    };

    return Code128a;
}(Code128Base));

export var Code128b = (function (Code128Base) {
    function Code128b () {
        Code128Base.apply(this, arguments);
    }

    if ( Code128Base ) Code128b.__proto__ = Code128Base;
    Code128b.prototype = Object.create( Code128Base && Code128Base.prototype );
    Code128b.prototype.constructor = Code128b;

    Code128b.prototype.initProperties = function initProperties () {
        Code128Base.prototype.initProperties.call(this);

        extend(this, {
            name: "Code 128 B",
            states: ["B"]
        });
    };

    return Code128b;
}(Code128Base));

export var Code128c = (function (Code128Base) {
    function Code128c () {
        Code128Base.apply(this, arguments);
    }

    if ( Code128Base ) Code128c.__proto__ = Code128Base;
    Code128c.prototype = Object.create( Code128Base && Code128Base.prototype );
    Code128c.prototype.constructor = Code128c;

    Code128c.prototype.initProperties = function initProperties () {
        Code128Base.prototype.initProperties.call(this);

        extend(this, {
            name: "Code 128 C",
            states: ["C"]
        });
    };

    return Code128c;
}(Code128Base));

export var Code128 = (function (Code128Base) {
    function Code128 () {
        Code128Base.apply(this, arguments);
    }

    if ( Code128Base ) Code128.__proto__ = Code128Base;
    Code128.prototype = Object.create( Code128Base && Code128Base.prototype );
    Code128.prototype.constructor = Code128;

    Code128.prototype.initProperties = function initProperties () {
        Code128Base.prototype.initProperties.call(this);

        extend(this, {
            name: "Code 128",
            states: ["C", "B", "A", "FNC4"]
        });
    };

    return Code128;
}(Code128Base));

export var CodeGS1128 = (function (Code128Base) {
    function CodeGS1128 () {
        Code128Base.apply(this, arguments);
    }

    if ( Code128Base ) CodeGS1128.__proto__ = Code128Base;
    CodeGS1128.prototype = Object.create( Code128Base && Code128Base.prototype );
    CodeGS1128.prototype.constructor = CodeGS1128;

    CodeGS1128.prototype.initProperties = function initProperties () {
        Code128Base.prototype.initProperties.call(this);

        extend(this, {
            name: "Code GS1-128",
            states: ["FNC1", "C", "B"]
        });
    };

    return CodeGS1128;
}(Code128Base));

function getSubstring(value, index, count) {
    return value.substring(index, index + count);
}
