/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as w from "react";
import * as N from "react-dom";
import e from "prop-types";
import { slide as E } from "./animation.mjs";
import { validatePackage as D, ZIndexContext as _, canUseDOM as I, classNames as P } from "@progress/kendo-react-common";
import { alignElement as C, positionElement as x, Collision as c, AlignPoint as n, domUtils as R } from "@progress/kendo-popup-common";
import { throttle as z, FRAME_DURATION as M } from "./util.mjs";
import { packageMetadata as L } from "./package-metadata.mjs";
const U = 100, H = 1;
function g(O, i) {
  if (O === i)
    return !0;
  if (!!O != !!i)
    return !1;
  const t = Object.getOwnPropertyNames(O), s = Object.getOwnPropertyNames(i);
  if (t.length !== s.length)
    return !1;
  for (let p = 0; p < t.length; p++) {
    let o = t[p];
    if (O[o] !== i[o])
      return !1;
  }
  return !0;
}
const S = {
  left: -1e3,
  top: 0
}, j = "k-animation-container", b = "k-animation-container-shown", k = "k-child-animation-container", F = "k-popup", v = class v extends w.Component {
  constructor(i) {
    super(i), this.context = 0, this.state = { current: "hidden", previous: "hidden", props: {} }, this._popup = null, this.show = (t) => {
      this.setPosition(t), this.animate(t.firstChild, "enter", this.onOpened), this.setState({ current: "shown", previous: this.state.current });
    }, this.setPosition = (t) => {
      let { anchorAlign: s, popupAlign: p, collision: o, offset: a, anchor: l, margin: h, scale: d, positionMode: u } = this.props;
      const { width: f, height: m } = t.style;
      t.style.width = t.offsetWidth + "px", t.style.height = t.offsetHeight + "px";
      const y = C({
        anchor: l,
        anchorAlign: s,
        element: t,
        elementAlign: p,
        offset: a,
        margin: h,
        positionMode: u,
        scale: d
      }), r = x({
        anchor: l,
        anchorAlign: s,
        element: t,
        elementAlign: p,
        collisions: o,
        currentLocation: y,
        margin: this.props.margin
      });
      if (t.style.top = r.offset.top + "px", t.style.left = r.offset.left + "px", t.style.width = f, t.style.height = m, this._collisions = {
        fit: r.fit,
        fitted: r.fitted,
        flip: r.flip,
        flipped: r.flipped
      }, this.props.onPosition) {
        const A = {
          target: this,
          flipped: r.flipped,
          fitted: r.fitted
        };
        this.props.onPosition.call(void 0, A);
      }
    }, this.onOpened = () => {
      const t = this._popup;
      t && (this.props.show && t.classList.add(b), this.attachRepositionHandlers(t), this.props.onOpen && this.props.onOpen.call(void 0, { target: this }));
    }, this.animate = (t, s, p) => {
      if (!this.props.popupAlign)
        return;
      let o;
      const { horizontal: a, vertical: l } = this.props.popupAlign;
      a === "left" && l === "center" ? o = "right" : a === "right" && l === "center" ? o = "left" : l === "top" ? o = "down" : o = "up";
      const h = {
        down: "up",
        up: "down",
        left: "right",
        right: "left"
      };
      this._collisions && this._collisions.flipped && (o = h[o]), E(
        t,
        o,
        this.animationDuration[s],
        s,
        p
      );
    }, this.onClosing = (t) => {
      this.props.show || t.classList.remove(b), this.detachRepositionHandlers();
    }, this.onClosed = () => {
      this.state.current === "hiding" && this.state.previous === "shown" && this.setState({ current: "hidden", previous: this.state.current }), this.props.onClose && this.props.onClose.call(void 0, { target: this });
    }, this.getCurrentZIndex = () => this.context ? this.context + H : U, D(L), this.reposition = z(this.reposition.bind(this), M);
  }
  /**
   * Represents the Popup DOM element.
   */
  get element() {
    return this._popup;
  }
  /**
   * @hidden
   */
  static getDerivedStateFromProps(i, t) {
    const { show: s, anchor: p, anchorAlign: o, appendTo: a, collision: l, popupAlign: h, className: d, popupClass: u, style: f, offset: m, contentKey: y } = i, r = {
      ...t,
      props: {
        show: s,
        anchor: p,
        anchorAlign: o,
        appendTo: a,
        collision: l,
        popupAlign: h,
        className: d,
        popupClass: u,
        style: f,
        offset: m,
        contentKey: y
      }
    };
    return i.show ? t.current === "hidden" || t.current === "hiding" ? { ...r, current: "showing", previous: t.current } : t.current === "showing" ? { ...r, current: "shown", previous: t.current } : t.current === "shown" && (!g(m, t.props.offset) || !g(o, t.props.anchorAlign) || !g(a, t.props.appendTo) || !g(l, t.props.collision) || !g(h, t.props.popupAlign) || !g(f, t.props.style) || p !== t.props.anchor || u !== t.props.popupClass || d !== t.props.className) ? { ...r, current: "reposition", previous: t.current } : r : t.current === "hiding" || t.current === "hidden" ? { ...r, current: "hidden", previous: t.current } : { ...r, current: "hiding", previous: t.current };
  }
  /**
   *
   * @hidden
   */
  componentDidUpdate(i) {
    this.state.current === "showing" && this._popup ? this.show(this._popup) : this.state.current === "hiding" && this._popup ? (this.onClosing(this._popup), this.animate(this._popup.firstChild, "exit", this.onClosed)) : this.state.current === "reposition" && this.state.previous === "shown" ? setTimeout(() => {
      this.setState({ current: "shown", previous: this.state.current });
    }, 0) : this.state.current === "shown" && i.contentKey !== this.props.contentKey && this._popup && this.setPosition(this._popup);
  }
  /**
   * @hidden
   */
  componentDidMount() {
    this.state.current === "showing" && this._popup && this.show(this._popup);
  }
  /**
   * @hidden
   */
  componentWillUnmount() {
    this.detachRepositionHandlers();
  }
  /**
   * @hidden
   */
  render() {
    const {
      children: i,
      className: t,
      popupClass: s,
      show: p,
      id: o,
      positionMode: a
    } = this.props, l = this.props.appendTo ? this.props.appendTo : I ? this.props.anchor && this.props.anchor.ownerDocument ? this.props.anchor.ownerDocument.body : document.body : void 0;
    this.state.current === "reposition" && this.state.previous === "shown" && this._popup && this.setPosition(this._popup);
    const h = Object.assign(
      {},
      { position: a, top: 0, left: -1e4 },
      this.props.style || {}
    ), d = this.state.current === "hiding";
    if ((p || d) && l) {
      const u = this.getCurrentZIndex(), f = /* @__PURE__ */ w.createElement(_.Provider, { value: u }, /* @__PURE__ */ w.createElement(
        "div",
        {
          className: P(j, t),
          id: o,
          ref: (m) => this._popup = m,
          style: {
            zIndex: u,
            ...h
          }
        },
        /* @__PURE__ */ w.createElement("div", { className: P(k), style: { transitionDelay: "0ms" } }, /* @__PURE__ */ w.createElement(
          "div",
          {
            role: this.props.role,
            className: P(F, s)
          },
          i
        ))
      ));
      return this.props.appendTo !== null ? N.createPortal(f, l) : f;
    }
    return null;
  }
  get animationDuration() {
    const i = this.props.animate;
    let t = 0, s = 0;
    return i && (i === !0 ? t = s = 300 : (t = i.openDuration || 0, s = i.closeDuration || 0)), { enter: t, exit: s };
  }
  attachRepositionHandlers(i) {
    this.detachRepositionHandlers(), this._scrollableParents = R.scrollableParents(this.props.anchor || i), this._scrollableParents && this._scrollableParents.map((t) => t.addEventListener("scroll", this.reposition)), window.addEventListener("resize", this.reposition);
  }
  detachRepositionHandlers() {
    this._scrollableParents && (this._scrollableParents.map((i) => i.removeEventListener("scroll", this.reposition)), this._scrollableParents = void 0), window.removeEventListener("resize", this.reposition);
  }
  reposition() {
    this.setState({ current: "reposition", previous: this.state.current });
  }
};
v.propTypes = {
  anchor: function(i) {
    const t = i.anchor;
    return t && typeof t.nodeType != "number" ? new Error(
      "Invalid prop `anchor` supplied to `Kendo React Popup`. Validation failed."
    ) : null;
  },
  appendTo: function(i) {
    const t = i.appendTo;
    return t && typeof t.nodeType != "number" ? new Error(
      "Invalid prop `appendTo` supplied to `Kendo React Popup`. Validation failed."
    ) : null;
  },
  className: e.oneOfType([
    e.string,
    e.arrayOf(e.string),
    e.object
  ]),
  id: e.string,
  popupClass: e.oneOfType([
    e.string,
    e.arrayOf(e.string),
    e.object
  ]),
  collision: e.shape({
    horizontal: e.oneOf([
      c.fit,
      c.flip,
      c.none
    ]),
    vertical: e.oneOf([
      c.fit,
      c.flip,
      c.none
    ])
  }),
  anchorAlign: e.shape({
    horizontal: e.oneOf([
      n.left,
      n.center,
      n.right
    ]),
    vertical: e.oneOf([
      n.top,
      n.center,
      n.bottom
    ])
  }),
  popupAlign: e.shape({
    horizontal: e.oneOf([
      n.left,
      n.center,
      n.right
    ]),
    vertical: e.oneOf([
      n.top,
      n.center,
      n.bottom
    ])
  }),
  offset: e.shape({
    left: e.number,
    top: e.number
  }),
  children: e.oneOfType([
    e.element,
    e.node
  ]),
  show: e.bool,
  animate: e.oneOfType([
    e.bool,
    e.shape({
      openDuration: e.number,
      closeDuration: e.number
    })
  ]),
  margin: e.shape({
    horizontal: e.number,
    vertical: e.number
  }),
  positionMode: e.oneOf([
    "fixed",
    "absolute"
  ]),
  scale: e.number,
  style: e.object,
  onClose: e.func,
  onPosition: e.func,
  onOpen: e.func
}, v.defaultProps = {
  collision: {
    horizontal: c.fit,
    vertical: c.flip
  },
  anchorAlign: {
    horizontal: n.left,
    vertical: n.bottom
  },
  popupAlign: {
    horizontal: n.left,
    vertical: n.top
  },
  offset: S,
  animate: !0,
  show: !1,
  margin: {
    horizontal: 0,
    vertical: 0
  },
  positionMode: "absolute"
}, v.contextType = _, v.displayName = "PopupComponent";
let T = v;
export {
  T as PopupWithoutContext
};
