/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { BaseEvent as t } from "./base-event.mjs";
class a extends t {
  // tslint:enable:max-line-length
  /**
   * @hidden
   */
  constructor(e, s) {
    super(s), this.axisRanges = e.axisRanges, this.nativeEvent = e.originalEvent;
  }
}
export {
  a as ZoomEndEvent
};
