/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { CalendarViewEnum as u } from "../models/CalendarViewEnum.mjs";
import { domContainerFactory as s } from "../../utils.mjs";
import { canUseDOM as y } from "@progress/kendo-react-common";
class P {
  constructor() {
    this.calendarHeight = 0, this.headerHeight = 0, this.monthViewHeight = 0, this.yearViewHeight = 0, this.decadeViewHeight = 0, this.centuryViewHeight = 0, this.navigationItemHeight = 0, this.scrollableContentHeight = 0, this.scrollableYearContentHeight = 0, this.calendarWidth = 0, this.monthViewWidth = 0, this.yearViewWidth = 0, this.decadeViewWidth = 0, this.centuryViewWidth = 0, this.scrollableContentWidth = 0, this.didCalculate = !1, this.hostContainer = null;
  }
  ensureHeights() {
    this.calendarHeight === void 0 && this.calculateHeights();
  }
  calculateHeights(i) {
    if (!y)
      return;
    const n = s("div"), a = s("ul"), l = s("li"), v = s("td"), D = s("th"), w = s("tr"), F = s("tbody"), S = s("thead"), m = s("table"), H = () => n(
      `
            <span class="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-button-flat k-calendar-title">
                <span className="k-button-text">March 2017</span>
            </span>
            <span class="k-spacer"></span>
            <span class="k-calendar-nav k-hstack"><button class="k-calendar-nav-today">TODAY</button></span>
        `,
      "k-calendar-header k-hstack"
    ), $ = () => m(
      [
        S([
          w([D("MO", "k-calendar-th")], "k-calendar-tr")
        ], "k-calendar-thead")
      ],
      "k-calendar-table k-calendar-weekdays"
    ), V = (t, e) => new Array(t).fill("1").map(e), z = (t, e = 1) => m([
      F(
        [w(
          [v("1", "k-calendar-td k-alt")]
        )].concat(
          V(
            t,
            () => w(V(e, (o) => v(`<span class="k-link">${o}</span>`, "k-calendar-td")), "k-calendar-tr")
          )
        ),
        "k-calendar-tbody"
      )
    ], "k-calendar-table"), k = (t) => n(t, "k-content k-scrollable"), M = (t, e, o) => n(
      o ? [
        H(),
        $(),
        k([t, t])
      ] : [
        H(),
        k([t, t])
      ],
      e,
      { left: "-10000px", position: "absolute" }
    ), x = /* @__PURE__ */ (() => {
      let t;
      return () => y ? (t || (t = n(
        [k([a([l("<span>FEB</span>")])])],
        "k-calendar-navigation",
        { left: "0px", position: "absolute" }
      )), t) : null;
    })(), g = ({ cells: t, rows: e }, o, N) => {
      let p;
      return () => y ? (p || (p = M(z(e, t), o, N)), p) : null;
    }, d = (t) => t.querySelector(".k-scrollable"), b = (t) => {
      const e = d(t);
      return e.className = `${e.className} k-scrollable-horizontal`, t;
    }, f = g({ cells: 7, rows: 6 }, "k-calendar-view k-calendar-monthview k-vstack", !0), C = g({ cells: 5, rows: 3 }, "k-calendar-view k-calendar-yearview", !1), W = g({ cells: 5, rows: 2 }, "k-calendar-view k-calendar-decadeview", !1), O = () => b(f()), Y = () => b(C()), q = () => b(W()), h = (t) => parseFloat(window.getComputedStyle(t).height) || t.offsetHeight, c = (t) => {
      const e = window.getComputedStyle(t);
      return parseFloat(e.width) + parseFloat(e.paddingLeft) + parseFloat(e.paddingRight) || t.offsetWidth;
    }, r = (t) => t.querySelector("tbody");
    this.didCalculate = !0, i && (this.hostContainer = i), this.batch(f(), (t) => {
      const e = r(t);
      this.calendarHeight = h(t), this.monthViewHeight = h(e), this.headerHeight = h(e.children[0]), this.scrollableContentHeight = h(d(t));
    }), this.batch(O(), (t) => {
      const e = r(t);
      this.calendarWidth = c(t), this.monthViewWidth = c(e), this.scrollableContentWidth = c(d(t));
    }), this.batch(C(), (t) => {
      this.yearViewHeight = h(r(t)), this.scrollableYearContentHeight = h(d(t));
    }), this.batch(Y(), (t) => {
      this.yearViewWidth = c(r(t));
    }), this.batch(W(), (t) => {
      this.decadeViewHeight = h(r(t)), this.centuryViewHeight = this.decadeViewHeight;
    }), this.batch(q(), (t) => {
      this.decadeViewWidth = c(r(t)), this.centuryViewWidth = this.decadeViewWidth;
    }), this.batch(x(), (t) => {
      this.navigationItemHeight = h(t.querySelector("li"));
    });
  }
  viewHeight(i) {
    return this.viewDimension(i, "height");
  }
  viewWidth(i) {
    return this.viewDimension(i, "width");
  }
  viewDimension(i, n) {
    const a = n === "height" ? "ViewHeight" : "ViewWidth";
    switch (i) {
      case u.month:
        return this[`month${a}`];
      case u.year:
        return this[`year${a}`];
      case u.decade:
        return this[`decade${a}`];
      case u.century:
        return this[`century${a}`];
      default:
        return 1;
    }
  }
  batch(i, n) {
    const a = this.hostContainer || document.body, l = a.appendChild(i);
    n(l), a.removeChild(l);
  }
}
export {
  P as DOMService
};
