/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as l from "react";
import e from "prop-types";
import { TextBox as i, InputPrefix as u } from "@progress/kendo-react-inputs";
import { searchIcon as m } from "@progress/kendo-svg-icons";
import { IconWrap as f } from "@progress/kendo-react-common";
const t = l.forwardRef((n, a) => {
  const o = l.useRef(null);
  return l.useImperativeHandle(
    a,
    () => o.current
  ), /* @__PURE__ */ l.createElement("div", { className: "k-list-filter" }, /* @__PURE__ */ l.createElement(
    i,
    {
      ...n,
      ref: o,
      value: n.value || "",
      onChange: n.onChange,
      onKeyDown: n.onKeyDown,
      tabIndex: n.tabIndex,
      onClick: (r) => r.stopPropagation(),
      size: n.size,
      fillMode: n.fillMode,
      rounded: n.rounded,
      prefix: () => /* @__PURE__ */ l.createElement(u, null, /* @__PURE__ */ l.createElement(f, { name: "search", icon: m }))
    }
  ));
});
t.propTypes = {
  value: e.oneOfType([e.string, e.number, e.array]),
  tabIndex: e.number,
  onChange: e.func,
  onKeyDown: e.func,
  size: e.oneOf([null, "small", "medium", "large"]),
  rounded: e.oneOf([null, "small", "medium", "large", "full"]),
  fillMode: e.oneOf([null, "solid", "flat", "outline"])
};
t.displayName = "KendoReactListFilter";
const y = t;
export {
  y as default
};
