import { Class } from '../../../common';
import { EncodingResult } from '../encoding-result';
import {
    getModes,
    getVersion,
    getDataCodewordsCount,
    getDataString
} from '../encoding';

export var IsoEncoder = (function (Class) {
    function IsoEncoder () {
        Class.apply(this, arguments);
    }

    if ( Class ) IsoEncoder.__proto__ = Class;
    IsoEncoder.prototype = Object.create( Class && Class.prototype );
    IsoEncoder.prototype.constructor = IsoEncoder;

    IsoEncoder.prototype.getEncodingResult = function getEncodingResult (inputString, errorCorrectionLevel) {
        var modes = getModes(inputString),
            dataCodewordsCount = getDataCodewordsCount(modes),
            version = getVersion(dataCodewordsCount, errorCorrectionLevel),
            dataString = getDataString(modes, version);

        return new EncodingResult(dataString, version);
    };

    return IsoEncoder;
}(Class));
