/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { Keys as i } from "./keys.mjs";
import { FOCUSABLE_ELEMENTS as r } from "./constants/main.mjs";
const l = (s) => {
  if (s) {
    const t = s.querySelectorAll(r.join(","));
    if (t.length && t[0].focus)
      return t[0];
  }
}, u = (s) => {
  if (s) {
    const t = s.querySelectorAll(r.join(","));
    if (t.length && t[t.length - 1].focus)
      return t[t.length - 1];
  }
}, c = (s) => {
  if (s) {
    const t = l(s);
    t && t.focus();
  }
}, C = (s) => {
  if (s) {
    const t = u(s);
    t && t.focus();
  }
}, e = (s, t) => {
  if (!t || s.keyCode !== i.tab)
    return;
  const f = l(t), o = u(t);
  f && o && !s.shiftKey && s.target === o && (s.preventDefault(), f.focus()), f && o && s.shiftKey && s.target === f && (s.preventDefault(), o.focus()), f || s.preventDefault();
}, a = (s, t, f) => {
  let o = !0;
  if (s.keyCode !== i.enter && s.target === t) {
    f && f(!1);
    return;
  }
  s.keyCode === i.enter && s.target === t ? (o = !0, setTimeout(
    () => {
      c(t);
    },
    1
  )) : s.keyCode === i.esc ? (o = !1, t.focus()) : e(s, t), f && f(o);
};
export {
  a as applyTrappedFocus,
  l as firstFocusableChild,
  c as focusFirstFocusableChild,
  C as focusLastFocusableChild,
  e as keepFocusInContainer,
  u as lastFocusableChild
};
