/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const t = () => typeof document != "undefined" ? document : {};
class l {
  constructor() {
    this.scrollbar = 0;
  }
  get scrollbarWidth() {
    const o = t();
    if (!this.scrollbar && o && o.createElement) {
      const e = o.createElement("div");
      e.style.cssText = "overflow:scroll;overflow-x:hidden;zoom:1;clear:both;display:block", e.innerHTML = "&nbsp;", o.body.appendChild(e), this.scrollbar = e.offsetWidth - e.scrollWidth, o.body.removeChild(e);
    }
    return this.scrollbar;
  }
}
export {
  l as BrowserSupportService
};
