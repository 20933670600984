/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const E = "data-grid-row-index", T = "data-grid-col-index", t = "data-prevent-selection";
export {
  T as TABLE_COL_INDEX_ATTRIBUTE,
  t as TABLE_PREVENT_SELECTION_ELEMENT,
  E as TABLE_ROW_INDEX_ATTRIBUTE
};
