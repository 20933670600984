/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as a from "react";
import t from "prop-types";
import { noop as n, classNames as M } from "@progress/kendo-react-common";
import { CSSTransition as k } from "react-transition-group";
import w from "./util.mjs";
const i = w.styles, o = class o extends a.Component {
  constructor() {
    super(...arguments), this.elementRef = a.createRef();
  }
  /**
   * The element that is being animated.
   */
  get element() {
    return this.elementRef.current;
  }
  /**
   * @hidden
   */
  render() {
    const {
      children: f,
      style: g,
      appear: h,
      enter: y,
      exit: v,
      transitionName: e,
      transitionEnterDuration: r,
      transitionExitDuration: s,
      className: S,
      onEnter: l,
      onEntering: m,
      onEntered: E,
      onExit: c,
      onExiting: d,
      onExited: p,
      onAfterExited: x,
      mountOnEnter: $,
      unmountOnExit: b,
      animationEnteringStyle: R,
      animationEnteredStyle: N,
      animationExitingStyle: O,
      animationExitedStyle: D,
      ...j
    } = this.props, T = M(
      S,
      i["child-animation-container"]
    ), q = {
      transitionDelay: "0ms",
      ...g
    }, A = {
      entering: { transitionDuration: `${r}ms`, ...R },
      entered: { ...N },
      exiting: { transitionDuration: `${s}ms`, ...O },
      exited: { ...D }
    }, P = {
      in: this.props.in,
      appear: h,
      enter: y,
      exit: v,
      mountOnEnter: $,
      unmountOnExit: b,
      timeout: {
        enter: r,
        exit: s
      },
      onEnter: () => {
        l && l.call(void 0, { animatedElement: this.element, target: this });
      },
      onEntering: () => {
        m && m.call(void 0, { animatedElement: this.element, target: this });
      },
      onEntered: () => {
        E && E.call(void 0, { animatedElement: this.element, target: this });
      },
      onExit: () => {
        c && c.call(void 0, { animatedElement: this.element, target: this });
      },
      onExiting: () => {
        d && d.call(void 0, { animatedElement: this.element, target: this });
      },
      onExited: () => {
        x && x.call(void 0, { animatedElement: this.element, target: this }), p && p.call(void 0, { animatedElement: this.element, target: this });
      },
      classNames: {
        appear: i[`${e}-appear`] || `${e}-appear`,
        appearActive: i[`${e}-appear-active`] || `${e}-appear-active`,
        enter: i[`${e}-enter`] || `${e}-enter`,
        enterActive: i[`${e}-enter-active`] || `${e}-enter-active`,
        exit: i[`${e}-exit`] || `${e}-exit`,
        exitActive: i[`${e}-exit-active`] || `${e}-exit-active`
      }
    };
    return /* @__PURE__ */ a.createElement(k, { ...P, ...j, nodeRef: this.elementRef }, (C) => /* @__PURE__ */ a.createElement(
      "div",
      {
        style: {
          ...q,
          ...A[C]
        },
        className: T,
        ref: this.elementRef
      },
      f
    ));
  }
};
o.propTypes = {
  in: t.bool,
  children: t.oneOfType([
    t.arrayOf(t.node),
    t.node
  ]),
  transitionName: t.string.isRequired,
  className: t.string,
  appear: t.bool,
  enter: t.bool,
  exit: t.bool,
  transitionEnterDuration: t.number.isRequired,
  transitionExitDuration: t.number.isRequired,
  mountOnEnter: t.bool,
  unmountOnExit: t.bool,
  animationEnteringStyle: t.object,
  animationEnteredStyle: t.object,
  animationExitingStyle: t.object,
  animationExitedStyle: t.object
}, o.defaultProps = {
  mountOnEnter: !0,
  unmountOnExit: !1,
  onEnter: n,
  onEntering: n,
  onEntered: n,
  onExit: n,
  onExiting: n,
  onExited: n,
  onAfterExited: n,
  animationEnteringStyle: {},
  animationEnteredStyle: {},
  animationExitingStyle: {},
  animationExitedStyle: {}
};
let u = o;
export {
  u as AnimationChild
};
