/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { FIELD_REGEX as n } from "./constants/main.mjs";
function s(i) {
  const t = [];
  return i.replace(n, function(r, e, o, c) {
    t.push(e !== void 0 ? e : o || c);
  }), t;
}
export {
  s as fieldList
};
