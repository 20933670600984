/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as s from "react";
import r from "prop-types";
import { cloneDate as p, weekInYear as v } from "@progress/kendo-date-math";
import { provideIntlService as D, registerForIntl as w } from "@progress/kendo-react-intl";
import { CalendarCell as g } from "./CalendarCell.mjs";
import { CalendarWeekCell as N } from "./CalendarWeekCell.mjs";
import { CalendarViewEnum as u } from "../models/CalendarViewEnum.mjs";
import { getToday as d, setTime as b } from "../../utils.mjs";
import { WeekNamesService as E } from "../services/WeekNamesService.mjs";
const k = (f) => /* @__PURE__ */ s.createElement("td", { key: f, className: "k-calendar-td" }, " "), c = class c extends s.Component {
  constructor() {
    super(...arguments), this.intl = null, this.weekService = null, this.buildWeekNumber = (t, e) => {
      if (!this.firstDate(t))
        return k(`week-cell-${e}`);
      const n = this.firstDate(t), a = this.getWeekNumber(n), o = `kendo-react-calendar-week-cell-${a}`, l = {
        className: "k-alt",
        value: a,
        firstDate: n
      };
      return this.props.weekCell ? /* @__PURE__ */ s.createElement(this.props.weekCell, { ...l, key: o }, a) : /* @__PURE__ */ s.createElement(N, { ...l, key: o }, a);
    }, this.buildRow = (t) => t.map((e, i) => {
      if (!e)
        return k(i);
      const n = { "aria-selected": e.isSelected }, a = `kendo-react-calendar-cell-${e.value.getTime()}`, o = {
        ...n,
        ...e,
        isDisabled: !e.isInRange,
        view: this.props.activeView,
        onClick: this.handleClick,
        onMouseEnter: this.handleMouseEnter,
        onMouseLeave: this.handleMouseLeave
      };
      return this.props.cell ? /* @__PURE__ */ s.createElement(this.props.cell, { ...o, key: a }, e.formattedValue) : /* @__PURE__ */ s.createElement(g, { ...o, key: a }, e.formattedValue);
    }), this.firstDate = (t) => {
      const e = this.firstWeekDateContext(t);
      return e ? e.value : null;
    }, this.firstWeekDateContext = (t) => {
      if (!this.weekNumber)
        return null;
      let e = 0, i = t[e];
      for (; !i && e < t.length; )
        i = t[++e];
      return i;
    }, this.handleClick = (t, e) => {
      const { onChange: i } = this.props;
      if (i && e) {
        const n = {
          value: p(t),
          target: this,
          nativeEvent: e && e.nativeEvent,
          syntheticEvent: e
        };
        i.call(void 0, n);
      }
    }, this.handleMouseEnter = (t) => {
      const { onCellEnter: e } = this.props;
      e && e.call(void 0, p(t));
    }, this.handleMouseLeave = (t) => {
      const { onCellLeave: e } = this.props;
      e && e.call(void 0, p(t));
    };
  }
  get min() {
    return this.props.min;
  }
  get max() {
    return this.props.max;
  }
  get isHorizontal() {
    return this.props.direction === "horizontal";
  }
  get isMonthView() {
    return this.props.activeView === u.month;
  }
  get weekNumber() {
    return !!(this.props.showWeekNumbers && this.props.activeView === u.month);
  }
  get selectedDate() {
    return this.props.selectedDate !== void 0 ? this.props.selectedDate : c.defaultProps.selectedDate;
  }
  render() {
    this.intl = D(this), this.weekService = new E(this.intl);
    const t = this.weekService.getWeekNames(this.weekNumber), e = this.props.service.rowLength(this.weekNumber), i = this.props.service.title(this.props.viewDate), n = d(), a = b(this.props.viewDate, n), o = this.props.service.data({
      cellUID: this.props.cellUID,
      min: this.min,
      max: this.max,
      focusedDate: this.props.focusedDate,
      isActiveView: !this.props.bus.canMoveDown(this.props.activeView),
      selectedDate: this.selectedDate,
      selectionRange: this.props.selectionRange,
      viewDate: a
    });
    return /* @__PURE__ */ s.createElement(s.Fragment, null, this.isMonthView && this.isHorizontal && /* @__PURE__ */ s.createElement(
      "thead",
      {
        role: "rowgroup",
        className: "k-calendar-thead"
      },
      /* @__PURE__ */ s.createElement("tr", { role: "row", className: "k-calendar-tr" }, t.map((l, h) => /* @__PURE__ */ s.createElement("th", { key: h, className: "k-calendar-th" }, l)))
    ), /* @__PURE__ */ s.createElement(
      "tbody",
      {
        role: "rowgroup",
        className: "k-calendar-tbody"
      },
      !this.isHorizontal && /* @__PURE__ */ s.createElement("tr", { role: "presentation", className: "k-calendar-tr" }, /* @__PURE__ */ s.createElement(
        "th",
        {
          scope: "col",
          colSpan: e,
          className: "k-calendar-caption"
        },
        i
      )),
      o.map((l, h) => /* @__PURE__ */ s.createElement("tr", { role: "row", className: "k-calendar-tr", key: h }, this.weekNumber && this.buildWeekNumber(l, h), this.buildRow(l)))
    ));
  }
  getWeekNumber(t) {
    return !this.weekNumber || !this.intl ? null : v(t, this.intl.firstDay());
  }
};
c.propTypes = {
  activeRangeEnd: r.oneOf(["start", "end", null]),
  activeView: r.number.isRequired,
  cellUID: r.string.isRequired,
  direction: r.oneOf(["horizontal", "vertical"]),
  focusedDate: r.instanceOf(Date).isRequired,
  max: r.instanceOf(Date).isRequired,
  min: r.instanceOf(Date).isRequired,
  onChange: r.func,
  selectedDate: r.oneOfType(
    [r.instanceOf(Date), r.arrayOf(r.instanceOf(Date))]
  ),
  showWeekNumbers: r.bool,
  viewDate: r.instanceOf(Date).isRequired
}, c.defaultProps = {
  direction: "vertical",
  selectedDate: d(),
  showWeekNumbers: !1
};
let m = c;
w(m);
export {
  m as View
};
