import { drawing as draw } from '@progress/kendo-drawing';
import ShapeElement from "../../core/shape-element";
import { deepExtend, setDefaultOptions } from '../../common';
import { CENTER, CIRCLE, LEFT, RECT, TOP, WHITE } from "../../common/constants";
import MarkerLineArea from "./legend-item-line-area";
import { DEFAULT_MARKER_BORDER_WIDTH, DEFAULT_MARKER_SIZE } from "./legend-item-marker";

var LegendItemLine = (function (ShapeElement) {
    function LegendItemLine () {
        ShapeElement.apply(this, arguments);
    }

    if ( ShapeElement ) LegendItemLine.__proto__ = ShapeElement;
    LegendItemLine.prototype = Object.create( ShapeElement && ShapeElement.prototype );
    LegendItemLine.prototype.constructor = LegendItemLine;

    LegendItemLine.prototype.getElement = function getElement () {
        var group = new draw.Group();
        var element = new draw.MultiPath({
            stroke: {
                color: this.options.border.color,
                opacity: this.options.opacity,
                width: this.options.height,
                dashType: this.options.dashType
            }
        });
        var box = this.paddingBox;
        var centerY = box.center().y;

        element
            .moveTo(box.x1, centerY)
            .lineTo(box.x2, centerY);

        group.append(element);

        return group;
    };

    LegendItemLine.prototype.createHighlight = function createHighlight () {
        this.highlight = [ this.createHighlightLine(), this.createHighlightMarker() ];
        return this.highlight;
    };

    LegendItemLine.prototype.createHighlightLine = function createHighlightLine () {
        var options = deepExtend({}, {
            border: {
                color: this.options.border.color,
                opacity: this.options.border.opacity,
                dashType: this.options.border.dashType
            }
        }, this.options.highlight);
        var highlightLine = new MarkerLineArea(options);
        highlightLine.reflow(this.parent.paddingBox.clone());

        this.highlightLine = highlightLine.getElement();

        return this.highlightLine;
    };

    LegendItemLine.prototype.createHighlightMarker = function createHighlightMarker () {
        var options = deepExtend({}, {
            background: this.options.background,
        }, this.options.highlight.markers);
        var highlightMarker = new ShapeElement(options);
        var paddingBox = this.parent.paddingBox;
        var borderWidth = options.border.width;
        var box = this.parent.box.clone();

        box.pad({
            left: borderWidth - paddingBox.x1,
            top: borderWidth - paddingBox.y1
        });

        highlightMarker.reflow(box);
        this.highlightMarker = highlightMarker.getElement();

        return this.highlightMarker;
    };

    return LegendItemLine;
}(ShapeElement));

setDefaultOptions(LegendItemLine, {
    border: {
        width: 0
    },
    type: RECT,
    align: LEFT,
    vAlign: CENTER,
    highlight: {
        border: {
            width: DEFAULT_MARKER_BORDER_WIDTH
        },
        markers: {
            type: CIRCLE,
            width: DEFAULT_MARKER_SIZE,
            height: DEFAULT_MARKER_SIZE,
            border: {
                width: DEFAULT_MARKER_BORDER_WIDTH,
                color: WHITE
            },
            align: LEFT,
            vAlign: TOP
        }
    }
});

export default LegendItemLine;
