import calculateSlope from './calculate-slope';
import checkAllPositive from './check-all-positive';
import getTrendlineData from './get-trendline-data';

function exponentialTrendline(context) {
    var options = context.options;
    var categoryAxis = context.categoryAxis;
    var seriesValues = context.seriesValues;

    var data = getData({ seriesValues: seriesValues, categoryAxis: categoryAxis, options: options });
    if (data) {
        return Object.assign({}, options,

            {type: 'line',
            data: data,
            categoryField: 'category',
            field: 'value'});
    }

    return null;
}

var valueGetter = function (fieldName) { return function (ref) {
        var categoryIx = ref.categoryIx;
        var valueFields = ref.valueFields;

        return ({ xValue: categoryIx + 1, yValue: Math.log(valueFields[fieldName]) });
; }    };

function getData(ref) {
    var seriesValues = ref.seriesValues;
    var categoryAxis = ref.categoryAxis;
    var options = ref.options;

    var sourceValues = seriesValues();
    if (!checkAllPositive(sourceValues, options.field)) {
        return null;
    }

    var ref$1 = calculateSlope(sourceValues, valueGetter(options.field));
    var slope = ref$1.slope;
    var intercept = ref$1.intercept;
    var count = ref$1.count;
    if (count > 0) {
        // Exponential trendline equation:
        // y = a * e ^ (b * x)
        var a = Math.exp(intercept);
        var b = slope;

        return getTrendlineData(function (x) { return a * Math.exp(b * x); }, categoryAxis);
    }

    return null;
}

export default exponentialTrendline;
