/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as r from "react";
import x from "./ListItem.mjs";
import z from "./ListGroupItem.mjs";
import { areSame as k, getItemValue as C } from "./utils.mjs";
import { provideLocalizationService as D, registerForLocalization as F } from "@progress/kendo-react-intl";
import { nodata as N, messages as M } from "../messages/index.mjs";
class $ extends r.Component {
  renderItems() {
    const {
      textField: a,
      valueField: t,
      groupField: e,
      groupMode: o,
      isMultiColumn: u,
      optionsGuid: d,
      skip: p = 0,
      virtual: c,
      focusedIndex: h,
      highlightSelected: v = !0,
      value: s,
      data: S,
      itemRender: R,
      groupHeaderItemRender: w
    } = this.props, E = Array.isArray(s);
    let y = 0;
    return S.map((i, l) => {
      const f = p + l, g = p + l + y, L = i.disabled ? !1 : v && (!E && k(i, s, t) || E && s.findIndex((b) => k(b, i, t)) !== -1);
      let n, m, I;
      return l > 0 && e !== void 0 && (m = C(i, e), I = C(S[l - 1], e), m && I && m !== I && (n = m)), n !== void 0 && o === "modern" && (y += 1), [
        n !== void 0 && o === "modern" && /* @__PURE__ */ r.createElement(
          z,
          {
            id: `option-${d}-${g}`,
            virtual: c,
            key: f + "-group-item",
            group: n,
            isMultiColumn: u,
            render: w
          }
        ),
        /* @__PURE__ */ r.createElement(
          x,
          {
            id: `option-${d}-${n !== void 0 && o === "modern" ? g + 1 : g}`,
            virtual: c,
            dataItem: i,
            groupMode: o,
            selected: L,
            focused: h === l,
            index: f,
            key: f,
            onClick: this.props.onClick,
            textField: a,
            group: n,
            render: R,
            disabled: i.disabled
          }
        )
      ];
    });
  }
  renderNoValueElement(a) {
    const t = this.props.noDataRender, e = /* @__PURE__ */ r.createElement("div", { className: "k-nodata" }, /* @__PURE__ */ r.createElement("div", null, a.toLanguageString(N, M[N])));
    return t ? t.call(void 0, e) : e;
  }
  render() {
    const a = D(this), {
      id: t,
      show: e,
      wrapperCssClass: o,
      wrapperStyle: u,
      listStyle: d,
      listRef: p,
      wrapperRef: c,
      listClassName: h = "k-list-ul",
      ariaSetSize: v
    } = this.props, s = this.renderItems();
    return s.length ? /* @__PURE__ */ r.createElement(
      "div",
      {
        className: o,
        style: u,
        ref: c,
        onMouseDown: this.props.onMouseDown,
        onBlur: this.props.onBlur,
        onScroll: this.props.onScroll,
        unselectable: "on"
      },
      /* @__PURE__ */ r.createElement(
        "ul",
        {
          id: t,
          role: "listbox",
          "aria-hidden": e ? void 0 : !0,
          "aria-setsize": v,
          className: h,
          ref: p,
          style: d
        },
        s
      ),
      this.props.scroller && /* @__PURE__ */ r.createElement("div", { className: "k-height-container" }, this.props.scroller)
    ) : this.renderNoValueElement(a);
  }
}
F($);
export {
  $ as default
};
