// TODO: Remove and replace with Map/WeakMap.
var HashMap = function HashMap() {
    this._map = new Map();
};

HashMap.prototype.get = function get (key) {
    return this._map.get(key);
};

HashMap.prototype.set = function set (key, value) {
    this._map.set(key, value);
};

export default HashMap;
