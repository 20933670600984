/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { addYears as u, addDecades as c, firstYearOfDecade as f, lastYearOfDecade as T, durationInDecades as d, cloneDate as p } from "@progress/kendo-date-math";
import { Action as l } from "../models/NavigationAction.mjs";
import { EMPTY_SELECTIONRANGE as M } from "../models/SelectionRange.mjs";
import { range as S, getToday as N, isInRange as m, intersects as O, isInSelectionRange as _ } from "../../utils.mjs";
const $ = [[]], w = 4, b = 3, C = {
  [l.Left]: (i) => u(i, -1),
  [l.Up]: (i) => u(i, -5),
  [l.Right]: (i) => u(i, 1),
  [l.Down]: (i) => u(i, 5),
  [l.PrevView]: (i) => c(i, -1),
  [l.NextView]: (i) => c(i, 1),
  [l.FirstInView]: (i) => f(i),
  [l.LastInView]: (i) => T(i)
};
class U {
  addToDate(e, t) {
    return c(e, t);
  }
  datesList(e, t) {
    return S(0, t).map((r) => c(e, r));
  }
  data(e) {
    const {
      cellUID: t,
      focusedDate: r,
      isActiveView: o,
      max: n,
      min: a,
      selectedDate: g,
      selectionRange: E = M,
      viewDate: h
    } = e;
    if (!h)
      return $;
    const q = S(0, w), Y = f(h), D = T(h), A = N();
    return S(0, b).map((y) => {
      const V = u(Y, y * w);
      return q.map((L) => {
        const s = this.normalize(u(V, L), a, n), I = this.isEqual(s, E.start), R = this.isEqual(s, E.end), F = !I && !R && _(s, E), v = o && (Array.isArray(g) ? this.isSelectedFromArray(s, g, a, n) : m(g, a, n) && this.isEqual(s, g));
        return O(s, Y, D) ? {
          formattedValue: this.value(s),
          id: `${t}${s.getTime()}`,
          isFocused: this.isEqual(s, r),
          isSelected: v,
          isWeekend: !1,
          isInRange: m(s, a, n),
          isRangeStart: I,
          isRangeMid: F,
          isRangeEnd: R,
          isRangeSplitEnd: F && this.isEqual(s, D),
          isRangeSplitStart: F && this.isEqual(s, Y),
          isToday: this.isEqual(s, A),
          title: this.cellTitle(s),
          value: s
        } : null;
      });
    });
  }
  isSelectedFromArray(e, t, r, o) {
    let n = !1;
    return t.forEach((a) => {
      m(e, r, o) && this.isEqual(e, a) && (n = !0);
    }), n;
  }
  isEqual(e, t) {
    return !e || !t ? !1 : e.getFullYear() === t.getFullYear();
  }
  isInArray(e, t) {
    if (!t.length)
      return !1;
    const r = e.getFullYear();
    return t[0].getFullYear() <= r && r <= t[t.length - 1].getFullYear() + 9;
  }
  isInRange(e, t, r) {
    const o = e.getFullYear(), n = !t || t.getFullYear() <= o, a = !r || o <= r.getFullYear();
    return n && a;
  }
  isRangeStart(e) {
    return e.getFullYear() % 100 === 0;
  }
  isInSameView(e, t) {
    return d(e, t) === 0;
  }
  move(e, t) {
    const r = C[t];
    return r ? r(e) : e;
  }
  cellTitle(e) {
    return e.getFullYear().toString();
  }
  navigationTitle(e) {
    return e ? f(e).getFullYear().toString() : "";
  }
  title(e) {
    return e ? `${f(e).getFullYear()} - ${T(e).getFullYear()}` : "";
  }
  rowLength(e) {
    return w;
  }
  skip(e, t) {
    return d(t, e);
  }
  total(e, t) {
    return d(e, t) + 1;
  }
  value(e) {
    return e ? e.getFullYear().toString() : "";
  }
  viewDate(e, t, r = 1) {
    return d(e, t) < r ? c(e, -1) : e;
  }
  normalize(e, t, r) {
    return e < t && this.isEqual(e, t) ? p(t) : e > r && this.isEqual(e, r) ? p(r) : e;
  }
}
export {
  U as DecadeViewService
};
