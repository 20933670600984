/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { usePropsContext as c } from "../hooks/usePropsContext.mjs";
const a = () => e.createContext((t) => t), x = (t, o) => e.forwardRef((r, n) => {
  const s = c(t, r);
  return /* @__PURE__ */ e.createElement(o, { ...s, ref: n });
});
export {
  a as createPropsContext,
  x as withPropsContext
};
