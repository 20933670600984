/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
const e = "numerictextbox.increment", o = "numerictextbox.decrement", t = "slider.increment", r = "slider.decrement", a = "slider.dragTitle", n = "colorGradient.r", l = "colorGradient.g", i = "colorGradient.b", c = "colorGradient.a", s = "colorGradient.hex", d = "colorGradient.contrastRatio", u = "colorGradient.colorGradientAALevel", G = "colorGradient.colorGradientAAALevel", A = "colorGradient.colorGradientPass", g = "colorGradient.colorGradientFail", p = "colorGradient.hueSliderLabel", C = "colorGradient.alphaSliderLabel", P = "colorGradient.toggleInputsButton", x = "flatColorPicker.cancelBtn", b = "flatColorPicker.applyBtn", k = "flatColorPicker.colorGradientBtn", m = "flatColorPicker.colorPaletteBtn", B = "flatColorPicker.clearBtn", h = "checkbox.validation", f = "checkbox.optionalText", v = "radioButton.validation", L = "switch.validation", w = "colorPicker.dropdownButtonAriaLabel", D = "rating.ariaLabel", V = "signature.clear", y = "signature.maximize", z = "signature.minimize", I = {
  [e]: "Increase value",
  [o]: "Decrease value",
  [t]: "Increase",
  [r]: "Decrease",
  [a]: "Drag",
  [n]: "r",
  [l]: "g",
  [i]: "b",
  [c]: "a",
  [s]: "hex",
  [d]: "Contrast ratio",
  [u]: "AA",
  [G]: "AAA",
  [A]: "Pass",
  [g]: "Fail",
  [p]: "Hue slider",
  [C]: "Alpha slider",
  [P]: "Toggle colorgradient inputs",
  [x]: "Cancel",
  [b]: "Apply",
  [k]: "Color Gradient view",
  [m]: "Color Palette view",
  [B]: "Clear color value",
  [h]: "Please check this box if you want to proceed!",
  [f]: "(Optional)",
  [v]: "Please select option if you want to proceed!",
  [L]: "Please turn on if you want to proceed!",
  [w]: "Select",
  [D]: "Rating",
  [V]: "Clear",
  [y]: "Maximize",
  [z]: "Minimize"
};
export {
  f as checkboxOptionalText,
  h as checkboxValidation,
  c as colorGradientA,
  G as colorGradientAAALevel,
  u as colorGradientAALevel,
  C as colorGradientAlphaSliderLabel,
  i as colorGradientB,
  d as colorGradientContrastRatio,
  g as colorGradientFail,
  l as colorGradientG,
  s as colorGradientHex,
  p as colorGradientHueSliderLabel,
  A as colorGradientPass,
  n as colorGradientR,
  P as colorGradientToggleInputsButton,
  w as colorPickerDropdownButtonAriaLabel,
  b as flatColorPickerApplyBtn,
  x as flatColorPickerCancelBtn,
  B as flatColorPickerClearBtn,
  k as flatColorPickerColorGradientBtn,
  m as flatColorPickerColorPaletteBtn,
  I as messages,
  o as numericDecreaseValue,
  e as numericIncreaseValue,
  v as radioButtonValidation,
  D as ratingAriaLabel,
  V as signatureClear,
  y as signatureMaximize,
  z as signatureMinimize,
  r as sliderDecreaseValue,
  a as sliderDragTitle,
  t as sliderIncreaseValue,
  L as switchValidation
};
