/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { Keys as K, classNames as L, IconWrap as T } from "@progress/kendo-react-common";
import { useTableKeyboardNavigation as z } from "@progress/kendo-react-data-tools";
import { useLocalization as P } from "@progress/kendo-react-intl";
import { caretAltDownIcon as W, caretAltLeftIcon as F, caretAltRightIcon as G } from "@progress/kendo-svg-icons";
import * as t from "react";
import { messages as H, groupCollapse as j, groupExpand as q } from "../messages/index.mjs";
const V = (e) => {
  let c = null;
  const { columnIndex: a, level: s, columnsCount: m, rowType: h, dataItem: n, field: w, onChange: r, expanded: o, render: C, dataIndex: f, ariaColumnIndex: D, isRtl: v } = e, d = w || "", N = z(e.id), S = P();
  let i = null, g = null, y = null;
  const M = t.useCallback(
    (l) => {
      l.isDefaultPrevented() || l.keyCode === K.enter && r && (l.preventDefault(), r({
        dataItem: n,
        dataIndex: f,
        syntheticEvent: l,
        field: void 0,
        value: !o
      }));
    },
    [o, r, n, f]
  ), k = t.useCallback((l) => {
    e.onContextMenu && e.onContextMenu.call(void 0, l, e.dataItem, e.field);
  }, [e.onContextMenu, e.dataItem, e.field]), x = (l, R) => {
    const I = o ? j : q, A = S.toLanguageString(I, H[I]);
    return i = {
      className: "k-table-td",
      style: { ...e.style, ...R },
      colSpan: l,
      key: "g-colspan",
      role: "gridcell",
      "aria-selected": !1,
      "aria-expanded": o,
      "aria-colindex": D,
      onKeyDown: M,
      onContextMenu: k,
      ...N
    }, y = /* @__PURE__ */ t.createElement("p", { className: "k-reset" }, /* @__PURE__ */ t.createElement(
      "a",
      {
        onClick: (E) => {
          E.preventDefault(), r && r({
            dataItem: n,
            dataIndex: f,
            syntheticEvent: E,
            field: void 0,
            value: !o
          });
        },
        href: "#",
        tabIndex: -1,
        "aria-label": A
      },
      /* @__PURE__ */ t.createElement(
        T,
        {
          name: o ? "caret-alt-down" : v ? "caret-alt-left" : "caret-alt-right",
          icon: o ? W : v ? F : G
        }
      )
    ), n[d] instanceof Date && n[d].toString ? n[d].toString() : n[d]), /* @__PURE__ */ t.createElement(
      "td",
      {
        ...i,
        key: i.key
      },
      y
    );
  };
  a === void 0 || s === void 0 || a < s || m === void 0 || h !== "groupHeader" || n[d] === void 0 ? (i = {
    style: e.style,
    key: "g" + a,
    className: L(
      "k-table-td",
      "k-group-cell",
      { "k-grid-content-sticky": e.locked }
    ),
    role: "gridcell",
    onContextMenu: k
  }, c = /* @__PURE__ */ t.createElement(
    "td",
    {
      ...i,
      key: i.key
    }
  )) : a <= s && !e.locked ? c = x(m - a, {}) : a <= s && e.locked && (g = {
    className: "k-table-td",
    role: "gridcell",
    colSpan: m - a,
    style: { borderLeftWidth: 0, borderRightWidth: 0 },
    onContextMenu: k
  }, c = /* @__PURE__ */ t.createElement(t.Fragment, null, x(0, { position: "sticky", zIndex: 2 }), /* @__PURE__ */ t.createElement(
    "td",
    {
      ...g
    }
  )));
  const b = e.rowType || "data", u = e.cells;
  if (u && u.group && u.group[b]) {
    const l = u.group[b];
    return /* @__PURE__ */ t.createElement(l, { ...e, tdProps: i, td2Props: g }, y);
  }
  return C ? C.call(void 0, c, e) : c;
};
export {
  V as GridGroupCell
};
