/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as e from "react";
import { ActionSheet as f, ActionSheetHeader as k, ActionSheetFooter as E } from "@progress/kendo-react-layout";
import { xIcon as v } from "@progress/kendo-svg-icons";
import { Button as a } from "@progress/kendo-react-buttons";
const o = 500, y = (c) => {
  const {
    footer: t,
    children: s,
    windowWidth: l = 0,
    navigatable: r,
    navigatableElements: m,
    expand: d,
    animation: p,
    onClose: n,
    adaptiveTitle: b,
    mobileFilter: i
  } = c, h = {
    navigatable: r || !1,
    navigatableElements: m || [],
    expand: d,
    animation: p !== !1,
    onClose: n,
    animationStyles: l <= o ? { top: 0, width: "100%", height: "100%" } : void 0,
    className: l <= o ? "k-adaptive-actionsheet k-actionsheet-fullscreen" : "k-adaptive-actionsheet k-actionsheet-bottom"
  };
  return /* @__PURE__ */ e.createElement(f, { ...h }, /* @__PURE__ */ e.createElement(k, { className: "k-text-center" }, /* @__PURE__ */ e.createElement("div", { className: "k-actionsheet-titlebar-group k-hbox" }, /* @__PURE__ */ e.createElement("div", { className: "k-actionsheet-title" }, /* @__PURE__ */ e.createElement("div", null, b)), /* @__PURE__ */ e.createElement("div", { className: "k-actionsheet-actions" }, /* @__PURE__ */ e.createElement(
    a,
    {
      tabIndex: 0,
      "aria-label": "Cancel",
      "aria-disabled": "false",
      type: "button",
      fillMode: "flat",
      icon: "x",
      svgIcon: v,
      onClick: n
    }
  ))), i && /* @__PURE__ */ e.createElement("div", { className: "k-actionsheet-titlebar-group k-actionsheet-filter" }, i)), s, t && /* @__PURE__ */ e.createElement(E, { className: "k-actions k-actions-stretched" }, /* @__PURE__ */ e.createElement(
    a,
    {
      size: "large",
      tabIndex: 0,
      "aria-label": t.cancelText,
      "aria-disabled": "false",
      type: "button",
      onClick: t.onCancel
    },
    t.cancelText
  ), /* @__PURE__ */ e.createElement(
    a,
    {
      tabIndex: 0,
      themeColor: "primary",
      size: "large",
      "aria-label": t.applyText,
      "aria-disabled": "false",
      type: "button",
      onClick: t.onApply
    },
    t.applyText
  )));
};
export {
  y as AdaptiveMode
};
