/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import * as l from "react";
import { firefox as d } from "./utils/index.mjs";
class u {
  constructor(t = 0, e, h, i) {
    this.total = t, this.offsets = [], this.heights = [];
    let s = 0;
    for (let r = 0; r < t; r++) {
      this.offsets.push(s);
      const a = i && i[r].expanded && i[r].rowType === "data" ? h : e;
      s += a, this.heights.push(a);
    }
  }
  height(t) {
    return this.heights[t];
  }
  index(t) {
    if (t < 0)
      return;
    const e = this.offsets.reduce((h, i, s) => {
      if (h !== void 0)
        return h;
      if (i === t)
        return s;
      if (i > t)
        return s - 1;
    }, void 0);
    return e === void 0 ? this.total - 1 : e;
  }
  offset(t) {
    return this.offsets[t];
  }
  totalHeight() {
    const t = this.offsets[this.offsets.length - 1], e = this.heights[this.heights.length - 1];
    return t + e;
  }
}
class p {
  constructor(t) {
    this.table = null, this.containerHeight = 0, this.topCacheCount = 0, this.attendedSkip = 0, this.propsSkip = 0, this.total = 0, this.scrollableVirtual = !1, this.realSkip = 0, this.pageSize = 0, this.PageChange = null, this.tableBodyRef = l.createRef(), this.fixedScroll = !1, this.askedSkip = void 0, this.containerRef = l.createRef(), this.tableTransform = "", this.scrollSyncing = !1, this.lastLoaded = 0, this.firstLoaded = 0, this.lastScrollTop = 0, this.reactVersion = Number.parseFloat(l.version), this.firstLoaded = this.pageSize, this.lastLoaded = this.realSkip + this.pageSize, this.scrollHandler = this.scrollHandler.bind(this);
  }
  get container() {
    return this.containerRef.current;
  }
  translate(t, e) {
    this.scrollableVirtual && this.table && (d || this.reactVersion <= 17 || e ? this.table.style.transform = "translateY(" + t + "px)" : this.tableTransform = "translateY(" + t + "px)");
  }
  changePage(t, e) {
    this.PageChange && this.PageChange({ skip: Math.max(0, t), take: this.pageSize }, e);
  }
  reset() {
    this.scrollSyncing = !0, !this.fixedScroll && (this.container && (this.container.scrollTop = 0), this.translate(0, !0));
  }
  scrollHandler(t) {
    if (!this.scrollableVirtual || !this.container || !this.table || !this.rowHeightService || !this.containerRef.current)
      return;
    if (this.scrollSyncing) {
      this.scrollSyncing = !1;
      return;
    }
    const e = this.container.scrollTop, h = this.lastScrollTop >= e, i = !h;
    this.lastScrollTop = e;
    let s = this.rowHeightService.index(e), r = this.rowHeightService.offset(s);
    const { offsetHeight: a } = this.containerRef.current, f = this.rowHeightService.index(e + a);
    if (i && f >= this.lastLoaded && this.lastLoaded < this.total) {
      const o = s + this.pageSize - this.total;
      o > 0 && (s = s - o, r = this.rowHeightService.offset(s)), this.firstLoaded = s, this.translate(r);
      let c = this.firstLoaded + this.pageSize;
      this.lastLoaded = Math.min(c, this.total), this.changePage(this.firstLoaded, t);
    } else if (h && s < this.firstLoaded) {
      const o = Math.floor(this.pageSize * 0.3);
      this.firstLoaded = Math.max(s - o, 0), this.translate(this.rowHeightService.offset(this.firstLoaded)), this.lastLoaded = Math.min(this.firstLoaded + this.pageSize, this.total), this.changePage(this.firstLoaded, t);
    }
  }
}
export {
  u as RowHeightService,
  p as VirtualScrollFixed
};
