/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { validatePackage as o } from "@progress/kendo-licensing";
const i = ["telerik.com", "progress.com", "stackblitz.io", "csb.app"], l = o;
function n(t) {
  return !i.some((e) => {
    var a;
    return (a = globalThis.document) == null ? void 0 : a.location.hostname.endsWith(e);
  }) && !o(t);
}
export {
  n as shouldShowValidationUI,
  l as validatePackage
};
