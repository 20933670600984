/**
 * @license
 *-------------------------------------------------------------------------------------------
 * Copyright © 2024 Progress Software Corporation. All rights reserved.
 * Licensed under commercial license. See LICENSE.md in the package root for more information
 *-------------------------------------------------------------------------------------------
 */
"use client";
import { DIALOGS_SELECTOR as f, DATA_DIALOGS_ID as S, ZINDEX_DIALOGS_STEP as r } from "./constants.mjs";
const o = (i, t, n) => {
  let e = i;
  if (t && t.defaultView) {
    let x = t.querySelectorAll(f), l = !1;
    return x.forEach((d) => {
      let a = t.defaultView.getComputedStyle(d, null);
      if (d.getAttribute(S) !== n && a.zIndex !== null) {
        let I = parseInt(a.zIndex, 10);
        I >= e && (e = I, l = !0);
      }
    }), l ? e + r : e;
  }
  return e;
};
export {
  o as getMaxZIndex
};
